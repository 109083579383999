import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccessSetsGuard} from '../../guards/access-sets.guard';
import {environment} from 'src/environments/environment';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {ManageCampaignComponent} from './components/manage-campaign/manage-campaign.component';
import {CustomerSettingsComponent} from './components/customer-settings/customer-settings.component';
import {ListReportDashboardsComponent} from './components/list-report-dashboards/list-report-dashboards.component';
import {ReportDashboardComponent} from './components/list-report-dashboards/components/report-dashboard/report-dashboard.component';
import {UserGroupsComponent} from './components/user-groups/user-groups.component';

const routes: Routes = [
    {
        path: 'monitoring',
        component: DashboardComponent,
        canActivate: [AccessSetsGuard],
        data: {
            permissions: [environment.permissions.admin],
        },
    },
    {
        path: 'reportDashboards',
        component: ListReportDashboardsComponent,
        canActivate: [AccessSetsGuard],
        data: {
            permissions: [environment.permissions.admin],
        },
    },
    {
        path: 'reportDashboard/:dashboard_id',
        component: ReportDashboardComponent,
        canActivate: [AccessSetsGuard],
        data: {
            permissions: [environment.permissions.admin],
        },
    },
    {
        path: 'customerSettings',
        component: CustomerSettingsComponent,
        canActivate: [AccessSetsGuard],
        data: {
            permissions: [environment.permissions.admin],
        },
    },
    {
        path: 'customerSettings/:email',
        component: CustomerSettingsComponent,
        canActivate: [AccessSetsGuard],
        data: {
            permissions: [environment.permissions.admin],
        },
    },
    {
        path: 'manageCampaign/:campaign_id',
        component: ManageCampaignComponent,
        canActivate: [AccessSetsGuard],
        data: {
            permissions: [environment.permissions.admin],
        },
    },
    {
        path: 'userGroups',
        component: UserGroupsComponent,
        canActivate: [AccessSetsGuard],
        data: {
            permissions: [environment.permissions.admin],
        },
    },
    { path: '', pathMatch: 'full', redirectTo: '/adminManagement/dashboard' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminManagementRoutingModule {}
