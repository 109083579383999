import {createAction, props} from '@ngrx/store';
import {Influencer} from 'src/app/types/influencer/influencer';

export const selectInfluencer = createAction('[Search] Select Influencer', props<{ influencer: Influencer }>());

// export const searchInfluencers = createAction('[Search] Search Influencers', props<{ search: InstagramSearch | YoutubeSearch }>());
export const searchInfluencers = createAction('[Search] Search Influencers', props<{ search: any }>());
export const searchInfluencersSuccess =
  createAction('[Search] Search Influencers Success', props<{ influencers: Influencer[], hits: number, hits_pagination: number, last_page: boolean, tags: {} }>());
export const searchInfluencersFail = createAction('[Search] Search Influencers Fail', props<{ error: any }>());

// BOTON DE MAS INFLUENCERS, (PAGINACION)
export const getMoreInfluencers = createAction('[Search] Get More Influencers');
export const getMoreInfluencersSuccess = createAction('[Search] Get More Influencers Success', props<{ influencers: Influencer[] }>());
export const getMoreInfluencersFail = createAction('[Search] Get More Influencers Fail', props<{ error: any }>());


// ADD INFLUENCER TIPPING SELECTED
export const addInfluencerFromTipping = createAction('[Search] Add influencer tipping selected', props<{ influencer: Influencer }>());

// SEARCH INSTAGRAM
export const searchInstagrammer = createAction('[Search] Search Instagrammer by name', props<{ name: string }>());
export const searchInstagrammerByUsername = createAction('[Search] Search Instagrammer by username', props<{ name: string }>());
export const searchInstagrammerSuccess = createAction('[Search] Search Instagrammer by name success', props<{ influencer: Influencer }>());
export const searchInstagrammerFail = createAction('[Search] Search Instagrammer by name fail', props<{ influencer: string }>());


// SEARCH YOUTUBE
export const searchYoutuber = createAction('[Search] Search Youtuber by name', props<{ name: string }>());
export const searchYoutuberByUsername = createAction('[Search] Search Youtuber by username', props<{ name: string }>());
export const searchYoutuberSuccess = createAction('[Search] Search Youtuber by name success', props<{ influencer: Influencer }>());
export const searchYoutuberFail = createAction('[Search] Search Youtuber by name fail', props<{ influencer: string }>());

// SEARCH TIKTOK
export const searchTiktoker = createAction('[Search] Search Tiktoker by name', props<{ name: string }>());
export const searchTiktokerByUsername = createAction('[Search] Search Tiktoker by Username', props<{ name: string }>());
export const searchTiktokerSuccess = createAction('[Search] Search Tiktoker by name success', props<{ influencer: Influencer }>());
export const searchTiktokerFail = createAction('[Search] Search Tiktoker by name fail', props<{ influencer: string }>());

export const openAnalyzeAudienceDialog = createAction('[Search] Open Analyze Audience Dialog', props<{ influencer: Influencer }>());
export const closeAnalyzeAudienceDialog = createAction('[Search] Close Analyze Audience Dialog', props<{ influencer: Influencer }>());
