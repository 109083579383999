import {Component, OnDestroy, OnInit} from '@angular/core';
import {Sort} from '@angular/material';
import {AdminManagementService} from '../../../../store/admin-management.service';

@Component({
  selector: 'app-dashboard-social-login',
  templateUrl: './dashboard-social-login.component.html',
  styleUrls: ['./dashboard-social-login.component.scss']
})
export class DashboardSocialLoginComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['username', 'last_login', 'status', 'expiration_date'];

  loading: boolean;

  dataSocial = [];
  private subscriptions = [];

  constructor(private adminManagementService: AdminManagementService) { }

  ngOnInit() {
    this.loading = true;
    this.subscriptions.push(
      this.adminManagementService.getSocialLoginData().subscribe( result => {
        this.dataSocial = result.body;
        this.loading = false;
      }, () => {
        this.dataSocial = [];
        this.loading = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  sortData(sort: Sort) {
    const data = this.dataSocial['users'].slice();
    if (!sort.active || sort.direction === '') {
      this.dataSocial = data;
    }
    this.dataSocial['users'] = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username':
          return this.compare(a.username, b.username, isAsc);
        case 'last_login':
          return this.compare(a.last_login, b.last_login, isAsc);
        case 'status':
          return this.compare(a.status, b.status, isAsc);
        case 'expiration_date':
          return this.compare(a.expiration_date, b.expiration_date, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
