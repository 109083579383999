export class UserChangePasswordClass {
    existingPassword = new ExistingPasswordClass();
    password = new PasswordClass();
}

export class ExistingPasswordClass{
    Name = 'existing_password';
    Value = 'n/a';
}

export class PasswordClass{
    Name = 'password';
    Value = 'n/a';
}