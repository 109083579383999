import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'toFixed'
})
export class ToFixedPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value = Number(value);
    if (value || value === 0) {
      const formattedValue = value.toFixed(2);
      const parts = formattedValue.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Agregar comas como separadores de miles
      if (parts[1] === '00') {
        return parts[0]; // No mostrar decimales .00
      } else {
        return parts.join('.');
      }
    } else {
      return '';
    }
  }

}
