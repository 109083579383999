import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';

import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {AmplifyService} from 'aws-amplify-angular';
import {Intercom} from 'ng-intercom';

@Injectable({
  providedIn: 'root',
})
export class Interceptor403Service implements HttpInterceptor {
  constructor(
      private router: Router,
      private snackBar: MatSnackBar,
      private _amplifyService: AmplifyService,
      private intercom: Intercom,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(tap(
        (response: HttpEvent<any>) => {
        },
        (error: HttpErrorResponse) => {
            if (error.status === 403) {
                this.openSnackBar(error.error.message_error, 'Ok');
                this.logout();
            }
        },
        () => {
        }
      ));
  }

    openSnackBar(message, action) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    logout() {
        this.intercom.shutdown();
        this._amplifyService
            .auth()
            .signOut()
            .then(() => {
                this.router.navigate(['/auth/login']);
            });
    }
}
