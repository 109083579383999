import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ReportService} from '../../../../../services/report.service';
import {MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-modal-share-influencer-report-url',
  templateUrl: './modal-share-influencer-report-url.component.html',
  styleUrls: ['./modal-share-influencer-report-url.component.scss']
})
export class ModalShareInfluencerReportUrlComponent implements OnInit, OnDestroy {

  url = 'Your URL will show up here';
  currency_type = '';

  copyUrl = false;

  loading = false;

  private subscriptions = [];

  constructor(
    public _reportService: ReportService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    this.loading = true;
    this.getInfluencerReportUrl();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getInfluencerReportUrl(): void {
    this.subscriptions.push(
      this._reportService.getInfluencerReportUrl(this.data.campaignId, this.data.influencerId).subscribe( response => {
        this.loading = false;
        this.url = response.body.shared_influencer_report;
      }, () => {
        this.loading = false;
        this.openSnackBar('Oops something went wrong, please try again', 'Close');
      })
    );
  }

  copyToClipboardUrl() {
    this.copyUrl = true;
    const new_html_element = document.createElement('textarea');
    new_html_element.style.position = 'fixed';
    new_html_element.style.left = '0';
    new_html_element.style.top = '0';
    new_html_element.style.opacity = '0';
    new_html_element.value = this.url;
    document.body.appendChild(new_html_element);
    new_html_element.focus();
    new_html_element.select();
    document.execCommand('copy');
    document.body.removeChild(new_html_element);
    this.openSnackBar('Copied to clipboard', 'Close');
    setTimeout( () => { this.copyUrl = false; }, 700 );
  }

  openSnackBar(message, action) {
    this.snackbar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
