import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ThemeService} from '../../../../../services/theme.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-influencer-clicks-cpa-chart',
  templateUrl: './clicks-cpa-chart.component.html',
  styleUrls: ['./clicks-cpa-chart.component.scss']
})
export class ClicksCpaChartComponent implements OnInit {

  theme: any;
  @Input() clicks;
  @Input() cpa;
  @Input() bins;

  // Values of the line
  public lineChartData: ChartDataSets[] = [
    { data: [], label: 'Conversions' },
    { data: [], label: 'Clicks', yAxisID: 'y-axis-1' },
  ];
  // Horizontal Positions
  public lineChartLabels = ['0', '0', '0', '0', '0', '0', '0', '0', '0'];

  // Colors of the lines
  public lineChartColors = [
    { // empty for after set the colors from the theme
      backgroundColor: '',
      borderColor: '',
      pointBackgroundColor: '',
      pointBorderColor: '',
      pointHoverBackgroundColor: '',
      pointHoverBorderColor: '',
    },
    { // red
      backgroundColor: '',
      borderColor: '',
      pointBackgroundColor: '',
      pointBorderColor: '',
      pointHoverBackgroundColor: '',
      pointHoverBorderColor: ''
    }
  ];

  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    hover: {
      intersect: false
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        display: true,
        type: 'time',
        distribution: 'series',
        gridLines: {
          display: true
        },
        time: {
          unit: 'month',
          displayFormats: {
            day: 'D MMM, YYYY',
            week: 'll',
            month: 'll',
            quarter: 'll',
            year: 'll'
          },
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 15
        }
      }],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            beginAtZero: true,
            stepSize: 1
          },
        },
        {
          id: 'y-axis-1',
          position: 'right',
          ticks: {
            beginAtZero: true,
            stepSize: 1
          },
        }
      ],
    },
    annotation: {
      annotations: [
      ],
    },
  };

  public lineChartLegend = true;
  public lineChartType = 'line';

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    this.theme = this.themeService.getTheme();
    // Set the colors from the theme, to first line
    this.lineChartColors[0].backgroundColor = this.addAlpha(this.theme.primary, 0.7);
    this.lineChartColors[0].borderColor =  this.addAlpha(this.theme.audienceLighten, 1);
    this.lineChartColors[0].pointBackgroundColor = 'gray';
    this.lineChartColors[0].pointBorderColor = 'black';
    this.lineChartColors[0].pointHoverBackgroundColor = 'white';
    this.lineChartColors[0].pointHoverBorderColor = 'black';
    // Set the colors from the theme, to second line
    this.lineChartColors[1].backgroundColor = this.addAlpha(this.theme.secondary, 0.7);
    this.lineChartColors[1].borderColor = this.addAlpha(this.theme.secondaryDarken, 1);
    this.lineChartColors[1].pointBackgroundColor = 'gray';
    this.lineChartColors[1].pointBorderColor = 'black';
    this.lineChartColors[1].pointHoverBackgroundColor = 'white';
    this.lineChartColors[1].pointHoverBorderColor = 'black';

    const maxClicksValueOfYClicks = Math.max(...this.clicks);
    const maxClicksValueOfYCpa = Math.max(...this.cpa);


    if (maxClicksValueOfYClicks <= 20 && maxClicksValueOfYCpa <= 20) {
      this.lineChartOptions.scales.yAxes = [
        {
          id: 'y-axis-0',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: 'Conversions',
            fontStyle: 'bold',
            fontColor: this.theme.primary,
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 20,
            maxTicksLimit: 5,
          }
        },
        {
          id: 'y-axis-1',
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: 'Clicks',
            fontStyle: 'bold',
            fontColor: this.theme.secondary,
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 20,
            maxTicksLimit: 5,

          }
        }
      ];
      for (let index = 0; index < this.clicks.length ; index++) {
        this.lineChartData[0].data.push({y: this.cpa[index], x: new Date(this.bins[index] * 1000)});
        this.lineChartData[1].data.push({y: this.clicks[index], x: new Date(this.bins[index] * 1000)});
      }
    } else {
      this.lineChartOptions.scales.yAxes = [
        {
          id: 'y-axis-0',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: 'Conversions',
            fontStyle: 'bold',
            fontColor: this.theme.primary,
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
          }
        },
        {
          id: 'y-axis-1',
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: 'Clicks',
            fontStyle: 'bold',
            fontColor: this.theme.secondary,
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
          }
        }
      ];
      for (let index = 0; index < this.clicks.length ; index++) {
        this.lineChartData[0].data.push({y: this.cpa[index], x: new Date(this.bins[index] * 1000)});
        this.lineChartData[1].data.push({y: this.clicks[index], x: new Date(this.bins[index] * 1000)});
      }
    }

    // Set the headers column
    // this.lineChartLabels = this.bins;
  }

  addAlpha(color: string, opacity: number): string {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

}
