import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';

@Component({
  selector: 'app-dashboard-crawl-chart',
  templateUrl: './dashboard-crawl-chart.component.html',
  styleUrls: ['./dashboard-crawl-chart.component.scss']
})
export class DashboardCrawlChartComponent implements OnInit {

  @Input() data;
  @Input() serviceCrawl;

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        display: true,
        type: 'time',
        distribution: 'series',
        gridLines: {
          display: true
        },
        time: {
          unit: 'month',
          displayFormats: {
            day: 'D MMM, YYYY',
            week: 'll',
            month: 'll',
            quarter: 'll',
            year: 'll'
          },
        },
      }],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
          }
        }
      ],
    }
  };

  public barChartLabels = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
  ];

  constructor() { }

  ngOnInit() {
    let numCrawls = 0;
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].instagram.requested > 0 ) {
        numCrawls += this.data[i].instagram.requested;
      }
      if ( this.data[i].youtube.requested > 0 ) {
        numCrawls += this.data[i].youtube.requested;
      }
      if (this.data[i].tiktok.requested > 0 ) {
        numCrawls += this.data[i].tiktok.requested;
      }
    }

    if (numCrawls <= 20) {
      this.barChartOptions.scales.yAxes = [
        {
          id: 'y-axis-0',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: 'Searchs',
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 20,
            maxTicksLimit: 20,
            stepSize: 1,
          }
        },
      ];
    }
    if (this.serviceCrawl === 'Light') {
      this.barChartData.push({data: [], label: 'Tiktok Requested', stack: 'a' });
      this.barChartData.push({data: [], label: 'Youtube Requested', stack: 'a' });
      this.barChartData.push({data: [], label: 'Instagram Requested', stack: 'a' });

      this.barChartData.push({data: [], label: 'Tiktok Success', stack: 'b' });
      this.barChartData.push({data: [], label: 'Youtube Success', stack: 'b' });
      this.barChartData.push({data: [], label: 'Instagram Success', stack: 'b' });

      for (let index = 0; index < this.data.length; index ++) {
        // Establecemos las fechas
        this.barChartLabels.push( new Date(this.data[index].timestamp * 1000));
        // Set the values
        this.barChartData[0].data.push(this.data[index].tiktok.requested);
        this.barChartData[1].data.push(this.data[index].youtube.requested);
        this.barChartData[2].data.push(this.data[index].instagram.requested);

        this.barChartData[3].data.push(this.data[index].tiktok.success);
        this.barChartData[4].data.push(this.data[index].youtube.success);
        this.barChartData[5].data.push(this.data[index].instagram.success);
      }
    } else {
      this.barChartData.push({data: [], label: 'Instagram Requested', stack: 'a' });
      this.barChartData.push({data: [], label: 'Instagram Success', stack: 'b' });

      for (let index = 0; index < this.data.length; index ++) {
        // Establecemos las fechas
        this.barChartLabels.push( new Date(this.data[index].timestamp * 1000));
        this.barChartData[0].data.push(this.data[index].instagram.requested);
        this.barChartData[1].data.push(this.data[index].instagram.success);
      }
    }

  }

}
