import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalExtendCampaignComponent} from '../../modals/modal-extend-campaign/modal-extend-campaign.component';
import {MatDialog} from '@angular/material';
import {environment} from '../../../../../../../environments/environment';
import {AmplifyService} from 'aws-amplify-angular';
import {BriefService} from '../../../../../../services/brief.service';
import {form_tooltips} from '../../external/brief-tooltips';

@Component({
  selector: 'app-brief-step1',
  templateUrl: './brief-step1.component.html',
  styleUrls: ['./brief-step1.component.scss']
})
export class BriefStep1Component implements OnInit, OnChanges {

  // COMPONENT VARS
  form_tooltips = form_tooltips;
  update_form: FormGroup;
  upload_brand_logo = false;
  errorGeneratingMusicLink = false;
  generatingMusicLink = false;
  error_brand_logo_upload = '';

  // INPUT VARS
  @Input() campaign_extended;
  @Input() campaign_id;
  @Input() brand_logo;
  @Input() brand_name;
  @Input() has_report;
  @Input() date_start;
  @Input() date_end;
  @Input() hashtags_compulsory_elements;
  @Input() mentions_compulsory_elements;
  @Input() payment_type;
  @Input() language_for_emails;
  @Input() start_date_before_today;
  @Input() start_date_under_one_week_from_today;
  @Input() music_data;

  // OUTPUT VARS
  @Output() contacting_influencers = new EventEmitter<any>();
  @Output() callSaveChanges = new EventEmitter<any>();
  @Output() callActivateSendProposalsButton = new EventEmitter<any>();
  @Output() callActivateCreateReportButton = new EventEmitter<any>();

  musicLinks: string[] = [];

  constructor(private dialog: MatDialog,
              private _amplifyService: AmplifyService,
              private _briefService: BriefService,
  ) {
    // Establish the Reactive Form
    this.update_form = new FormGroup({
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('', [Validators.required]),
      brand_name: new FormControl('', [Validators.required]),
      payment_type: new FormControl('', [Validators.required]),
      hashtags_compulsory_elements: new FormControl(''),
      mentions_compulsory_elements: new FormControl(''),
      language_for_emails: new FormControl(''),
      music_data: new FormControl(''),
    });
  }

  static getUrlImageWithTime(url_image) {
    if (url_image !== 'n/a') {
      return url_image + '?ts=' + new Date().getTime();
    } else {
      return url_image;
    }
  }

  static getHashtags(hashtags: string) {
    const hashtags_array = hashtags.split('#');
    const hashtags_array_with_hash = [];
    for (let i = 0; i < hashtags_array.length; i++) {
      if (hashtags_array[i].startsWith('#')) {
        hashtags_array_with_hash.push(hashtags_array[i].toString().replace(/,/g, ' ').replace(/\s+/g, ''));
      } else if (
          !hashtags_array[i].startsWith('#') &&
          hashtags_array[i] !== ''
      ) {
        hashtags_array_with_hash.push('#' + hashtags_array[i].toString().replace(/,/g, ' ').replace(/\s+/g, ''));
      }
    }
    return hashtags_array_with_hash;
  }

  static setHashtags(hashtags: Array<string>) {
    return hashtags.toString().replace(/,/g, ' ');
  }

  static setCpaLinks(links: Array<string>) {
    return links.toString().replace(/,/g, ' ');
  }

  /**
   * It sets the dates in the correct format, need to do this because the setValue
   * cant intro the days and month with the format 1, need be 01
   * @param date, It receives a date to format it
   * @returns a string
   */
  static setFormatDates(date) {
    const day_date = date.getDate();
    let correct_day_date = '';

    if (day_date < 10) {
      correct_day_date = '0' + String(day_date);
    } else {
      correct_day_date = String(day_date);
    }
    const month_date = date.getMonth() + 1;
    let correct_month_date = '';
    if (month_date < 10) {
      correct_month_date = '0' + month_date;
    } else {
      correct_month_date = String(month_date);
    }
    const year_date = date.getFullYear();

    return year_date + '-' + correct_month_date + '-' + correct_day_date;
  }

  static getMentions(mentions: string) {
    const mentions_array = mentions.split('@');
    const mentions_array_with_atsign = [];
    for (let i = 0; i < mentions_array.length; i++) {
      if (mentions_array[i].startsWith('@')) {
        mentions_array_with_atsign.push(mentions_array[i].toString().replace(/,/g, ' ').replace(/\s+/g, ''));
      } else if (
          !mentions_array[i].startsWith('@') &&
          mentions_array[i] !== ''
      ) {
        mentions_array_with_atsign.push('@' + mentions_array[i].toString().replace(/,/g, ' ').replace(/\s+/g, ''));
      }
    }
    return mentions_array_with_atsign;
  }

  static getExtensionFromImage(file_type: string): string {
    return file_type.replace('image/', '.');
  }

  static getCpaLinks(urls: string) {
    const links_array = urls.split(' ');
    const links_array_with_atsign = [];
    for (let i = 0; i < links_array.length; i++) {
      links_array_with_atsign.push(links_array[i]);
    }
    if ( links_array_with_atsign[0] === '' ) {
      return [];
    } else {
      return links_array_with_atsign;
    }
  }
  static setMentions(mentions: Array<string>) {
    return mentions.toString().replace(/,/g, ' ');
  }

  ngOnInit() {
    if (this.date_end !== undefined) {
      this.update_form.controls['end_date'].setValue(
        new Date((this.date_end - 86350) * 1000)
      );
    }
    if (this.date_start !== undefined) {
      this.update_form.controls['start_date'].setValue(
        new Date(this.date_start * 1000)
      );
    }
    this.viewStartDate();

    if (this.brand_name !== undefined) {
      this.update_form.controls['brand_name'].setValue(this.brand_name);
    }

    if (this.music_data === undefined) {
      this.music_data = [];
    }

    if (this.hashtags_compulsory_elements !== undefined) {
      this.update_form.controls['hashtags_compulsory_elements'].setValue(
          BriefStep1Component.setHashtags(this.hashtags_compulsory_elements)
      );
    }

    if (this.mentions_compulsory_elements !== undefined) {
      this.update_form.controls['mentions_compulsory_elements'].setValue(
          BriefStep1Component.setMentions(
              this.mentions_compulsory_elements
          )
      );
    }

    if (this.payment_type !== undefined) {
      this.update_form.controls['payment_type'].setValue(this.payment_type);
    }

    if (this.has_report) {
      this.update_form.controls['start_date'].disable();
      this.update_form.controls['end_date'].disable();
      this.update_form.controls['brand_name'].disable();
      this.update_form.controls['payment_type'].disable();
    }

    if (this.language_for_emails) {
      this.update_form.controls['language_for_emails'].setValue(this.language_for_emails);
    }

    this.controlActivateSendProposalsButton();
  }


  checkIfContainHashtagOrMention(type) {
    let haveLetter;
    let inputName;
    let iconType;
    if (type === 'hashtag') {
      inputName = 'hashtags_compulsory_elements';
      iconType = '#';
    } else if (type ==='mention') {
      inputName = 'mentions_compulsory_elements';
      iconType = '@';
    }
    // si el input contiene espacios y no es el primero es decir que tiene siguientes.
    if (this.update_form
        .get(inputName)
        .value.includes(' ') &&  this.update_form
        .get(inputName)
        .value.charAt(0) !== ' ') {
          // Usar una expresión regular para encontrar hashtags vacíos
      const temporalValue = this.update_form
          .get(inputName)
          .value.split(' ');
      for (let i = 0; i < temporalValue.length; i++) {
        if (!temporalValue[i].startsWith(iconType) && temporalValue[i].length > 1) {
          temporalValue[i] = iconType + temporalValue[i];
          this.update_form
              .get(inputName)
              .setValue(temporalValue.toString().replace(/,/g, ' '));
        }
      }
      // Construir la expresión regular dinámicamente
      const regexString = `${iconType}\\s+`;
      const regex = new RegExp(regexString, 'g');
      this.update_form
              .get(inputName)
              .setValue(this.update_form
                .get(inputName)
                .value.replace(regex, ''));
    } else {
      const first_letter_of_keyword: string = this.update_form
          .get(inputName)
          .value.charAt(0);
      haveLetter = first_letter_of_keyword === iconType;
      if (haveLetter === true) {
        const temporal_value  = this.update_form.value[inputName].replace(/\s/g, '').toLowerCase();
        this.update_form.get(inputName).setValue(temporal_value);
      } else {
        if (this.update_form.controls[inputName].value !== '') {
          this.update_form.controls[inputName].
          setValue(iconType + this.update_form.controls[inputName].value.trim());
        }
      }
    }
  }

  /**
   * Update the brief, send all params from the form and the service select the correct values and update brief
   */
  saveChanges(attributeName, attributeValue) {
    if (attributeName === 'mentions_compulsory_elements') {
      attributeValue = BriefStep1Component.getMentions(
          this.update_form.controls[attributeName].value
      );
    }
    if (attributeName === 'hashtags_compulsory_elements') {
      attributeValue = BriefStep1Component.getHashtags(
          this.update_form.controls[attributeName].value
      );
      attributeName = 'hashtag_list';
    }
    this.callSaveChanges.emit({'attributeName': attributeName, 'attributeValue': attributeValue});
  }

  /**
   * Modal para extender la campaña,
   * update end_date
   */
  extendCampaign() {
    const dialogRef = this.dialog.open(ModalExtendCampaignComponent, {
      width: '450px',
      height: '240px',
      autoFocus: false,
      data: {campaign_id: this.campaign_id, end_date: this.update_form.controls['end_date'].value}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'success') {
        this.date_end = result.end_date;
        this.update_form.controls['end_date'].setValue(result.end_date);
      }
    });
  }

  /**
   * This function receive the image from input file and upload in S3 Bucket and in brief section in dynamo.
   * @param fileInput , receive $event from the html, have all attributes from image
   */
  uploadBrandLogo(fileInput: any): void {
    this.error_brand_logo_upload = '';
    const file = fileInput.target.files[0];
    this.upload_brand_logo = true;
    if (file.size <= 5242880) {
      if (
          file.type === 'image/png' ||
          file.type === 'image/jpeg' ||
          file.type === 'image/jpg'
      ) {
        const extension = BriefStep1Component.getExtensionFromImage(
            file.type
        );
        const config = {
          bucket: environment.aws_resources.s3.buckets.company_logo,
          region: environment.aws_resources.region,
          level: 'public',
          customPrefix: {
            public: '',
          },
        };
        this._amplifyService
            .storage()
            .vault.put(this.campaign_id + extension, file, config)
            .then(() => {
              const url =
                  'https://s3-eu-west-1.amazonaws.com/' +
                  config.bucket +
                  '/' +
                  this.campaign_id +
                  extension;
              const body = {
                brand_logo: url,
              };
              this._briefService.updateBrief(this.campaign_id, body).subscribe(
                  response => {
                    this.brand_logo = BriefStep1Component.getUrlImageWithTime(
                        body.brand_logo
                    );
                    this.upload_brand_logo = false;
                  },
                  () => {
                    this.error_brand_logo_upload =
                        'Oops, something went wrong. Please try again later.';
                  }
              );
            })
            .catch(() => {
              this.error_brand_logo_upload =
                  'Oops, something went wrong. Please try again later.';
              this.upload_brand_logo = false;
            });
      } else {
        this.error_brand_logo_upload =
            'Unsupported file type. Supported file types are: jpg, jpeg and png';
        this.upload_brand_logo = false;
      }
    } else {
      this.error_brand_logo_upload = 'The file exceeds 5MB';
      this.upload_brand_logo = false;
    }
  }
  /**
   * View if the start_date start before to next 7 days
   */
  viewStartDate(type_date?, dateValue?): void {
    this.start_date_under_one_week_from_today =
        new Date(this.update_form.controls['start_date'].value).getTime() <
        new Date().getTime() + 60 * 60 * 24 * 1000 * 5;
    this.start_date_before_today =
        new Date(this.update_form.controls['start_date'].value).getTime() <
        new Date().getTime() - 60 * 60 * 24 * 1000;
    if (type_date !== undefined) {
      this.saveChanges(type_date, dateValue.getTime() / 1000);
    }

    if (
        new Date(this.update_form.controls['start_date'].value).getTime() >
        new Date(this.update_form.controls['end_date'].value).getTime()
    ) {
      this.update_form.controls['end_date'].setValue(
          this.update_form.controls['start_date'].value
      );
      this.saveChanges('date_start', this.update_form.controls['start_date'].value.getTime() / 1000);
      this.saveChanges('date_end', this.update_form.controls['end_date'].value.getTime() / 1000);
    }
  }


  /**
   * If dates or payment type changes that function updates attributes in dynamo.
   * @param event Optional param that contains the value in case of changes are in payment type.
   */
  onCalculatePriceVariableChanges(event): void {
    this.callSaveChanges.emit({'attributeName': 'payment_type', 'attributeValue': event});
  }


  ngOnChanges() {
    this.controlActivateSendProposalsButton();
  }

  controlActivateSendProposalsButton() {
    if (
        // (this.update_form.controls['hashtags_compulsory_elements'].value !== ''
        // || this.update_form.controls['mentions_compulsory_elements'].value !== '')
        // &&
        this.update_form.controls['brand_name'].value !== ''
    ) {
      this.callActivateCreateReportButton.emit(true);
      this.callActivateSendProposalsButton.emit(true);
    } else {
      this.callActivateCreateReportButton.emit(false);
      this.callActivateSendProposalsButton.emit(false);
    }
  }

  addMusicLink() {
    this.errorGeneratingMusicLink = false;
    this.generatingMusicLink = true;
    this._briefService.addMusicLink(this.update_form.get('music_data').value).subscribe( response => {
      this.errorGeneratingMusicLink = false;
      this.music_data.push(response.body);
      this.update_form.reset();
      this.generatingMusicLink = false;
      this.saveChanges('music_data', this.music_data);
    }, () => {
      this.generatingMusicLink = false;
      this.errorGeneratingMusicLink = true;
    });
  }

  removeMusicLink(direccion: string) {
    const index = this.music_data.indexOf(direccion);
    if (index !== -1) {
      this.music_data.splice(index, 1);
    }
    this.saveChanges('music_data', this.music_data);
  }

  openMusicUrl(musicUrl) {
    window.open(musicUrl, '_blank');
  }
}

