import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatSnackBar, Sort} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalNewReportDashboardComponent} from './modals/modal-new-report-dashboard/modal-new-report-dashboard.component';
import {ModalConfirmRemoveItemComponent} from './modals/modal-confirm-remove-item/modal-confirm-remove-item.component';
import {ReportDashboardService} from '../../../../services/report-dashboard.service';

@Component({
  selector: 'app-report-dashboards',
  templateUrl: './list-report-dashboards.component.html',
  styleUrls: ['./list-report-dashboards.component.scss']
})
export class ListReportDashboardsComponent implements OnInit, OnDestroy {
  adminReportDashboardsSearched = false;
  loading = false;
  emailFound = '';

  displayedColumns: string[] =  ['REPORT_DASHBOARD_NAME', 'CREATION_DATE', 'CURRENCY', 'REMOVE_REPORT_DASHBOARD'];

  emailForm: FormGroup;

  reportDashboards = [];
  private subscriptions = [];


  constructor(private dialog: MatDialog,
              private _dashboardService: ReportDashboardService,
              private snackBar: MatSnackBar) {

    this.emailForm = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(
            '^[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$'
        ),
      ]),
    });
  }

  ngOnInit() {
    this.listReportDashboards();
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  listReportDashboards(email?) {
    this.loading = true;
    this.adminReportDashboardsSearched = !!email;
    this.subscriptions.push (
      this._dashboardService.listReportDashboards(email).subscribe( response => {
        if (response.status === 200) {
          this.emailFound = email;
          this.reportDashboards = response.body;
        } else {
          this.openSnackBar('This email havent report dashboards', 'Close');
          this.reportDashboards = [];
        }
        this.loading = false;
      }, error => {
        this.reportDashboards = [];
        this.emailFound = '';
        this.loading = false;
        if (error.status === 404) {
          this.openSnackBar('User not found, please write correctly the email', 'Close');
        } else {
          this.openSnackBar('Oops something went wrong, please try again', 'Close');
        }
      })
  );
  }

  openNewReportDashboardDialog() {
    const dialogRef = this.dialog.open(ModalNewReportDashboardComponent, {
      width: '30%',
      height: 'auto',
      autoFocus: false,
      data: {
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.listReportDashboards();
      }
    });
  }


  confirmDeleteReportDashboardModal(dashboardId, dashboardName) {
    const dialogRef = this.dialog.open(ModalConfirmRemoveItemComponent, {
      width: 'auto',
      height: 'auto',
      autoFocus: false,
      data: {
        item: 'dashboard',
        itemName: dashboardName,
        itemId: dashboardId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.listReportDashboards();
      }
    });
  }


  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  sortData(sort: Sort) {
    const data = this.reportDashboards.slice();
    if (!sort.active || sort.direction === '') {
      this.reportDashboards = data;
    }
    this.reportDashboards = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'REPORT_DASHBOARD_NAME':
          return this.compare(a.dashboard_name, b.dashboard_name, isAsc);
        case 'CREATION_DATE':
          return this.compare(a.c, b.c, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    if (a === undefined) {
      a = 1286287812;
    }
    if (b === undefined) {
      b = 1286287812;
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


}
