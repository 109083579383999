import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ProgressService} from '../../../../../../services/progress.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-progress-approval-share-modal',
  templateUrl: './progress-approval-share-modal.component.html',
  styleUrls: ['./progress-approval-share-modal.component.scss']
})
export class ProgressApprovalShareModalComponent implements OnInit {
  loading = true;
  campaign_id = '';

  url = 'Your URL will show up here';
  copyUrl = false;
  generate_url = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public _progressService: ProgressService,
              public router: Router,
              public snackBar: MatSnackBar
  ) {
    this.campaign_id = this.data.campaign_id;
  }

  ngOnInit() {
    this.generateUrl();
  }

  copyToClipboardUrl() {
    this.copyUrl = true;
    const new_html_element = document.createElement('textarea');
    new_html_element.style.position = 'fixed';
    new_html_element.style.left = '0';
    new_html_element.style.top = '0';
    new_html_element.style.opacity = '0';
    new_html_element.value = this.url;
    document.body.appendChild(new_html_element);
    new_html_element.focus();
    new_html_element.select();
    document.execCommand('copy');
    document.body.removeChild(new_html_element);
    this.openSnackBar('Copied to clipboard', 'Close');
    setTimeout( () => { this.copyUrl = false; }, 700 );
  }

  generateUrl() {
    this.generate_url = true;
    this._progressService.shareProgressContentApproval(this.campaign_id).subscribe(res => {
      if (res.status.toString() === '200') {
        this.url = res.body.shared_url;
        this.loading = false;
      }
    });
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
