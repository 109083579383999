import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {CampaignsService} from '../../../../services/campaigns.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-confirm-delete-campaign-modal',
    templateUrl: './confirm-delete-campaign-modal.component.html',
    styleUrls: ['./confirm-delete-campaign-modal.component.scss']
})
export class ConfirmDeleteCampaignModalComponent implements OnInit {

    campaign_name = '';
    campaign_id = '';
    deleting_campaign = false;
    checkbox_form: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private _campaignService: CampaignsService,
                public dialogRef: MatDialogRef<ConfirmDeleteCampaignModalComponent>,
                public snackBar: MatSnackBar) {

        this.campaign_name = this.data.campaign_name;
        this.campaign_id = this.data.campaign_id;
        this.checkbox_form = new FormGroup({
            'checkout_checkbox': new FormControl(false, [Validators.requiredTrue])
        });
    }

    ngOnInit() {
    }

    deleteCampaign(campaign_id) {
        this.deleting_campaign = true;
        this._campaignService.deleteCampaign(campaign_id).subscribe( () => {
            this.dialogRef.close();
            this.snackBar.open('Campaign has been deleted successfully', 'Ok', {
                duration: 2000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
        }, () => {
            this.dialogRef.close();
            this.snackBar.open('There has been an error. Please try again.', 'Ok', {
                duration: 2000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
        });

    }


}
