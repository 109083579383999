import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {selectAppViewPort} from '../../../../../../store/app/app.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../store';
import {AdminManagementService} from '../../../../store/admin-management.service';
import {CampaignsModalComponent} from '../../../../../../components/private/campaigns/campaigns-modal/campaigns-modal.component';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ReportInfluencerContentModalComponent} from '../../../../../../components/private/campaigns/report-campaign/report-influencer-content-modal/report-influencer-content-modal.component';
import {Router} from '@angular/router';
import {RemoveInfluencerComponent} from '../../../../../../components/private/campaigns/remove-influencer/remove-influencer.component';

@Component({
  selector: 'app-influencers-campaign-info',
  templateUrl: './influencers-campaign-info.component.html',
  styleUrls: ['./influencers-campaign-info.component.scss']
})
export class InfluencersCampaignInfoComponent implements OnInit {

  @Input() influencers;
  @Input() currency_type;
  @Input() currency_symbol;

  @Input() only_report;
  @Input() campaignId;

  @Input() date_start;
  @Input() date_end;

  influencers_for_update = [];
  viewport;

  influencers_selected = [];
  backup_influencers = [];

  private subscriptions = [];

  loading;
  campaignChanges = false;

  @ViewChild('price', { static: false }) price: ElementRef;

  constructor(public store: Store<State>,
              private _adminService: AdminManagementService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              public router: Router,
              private adminManagementService: AdminManagementService) {}

  ngOnInit() {
    this.loading = true;
    this.subscriptions.push(
        this.store.select(selectAppViewPort).subscribe(result => {
          this.viewport = result;
        })
    );
    this.influencers.forEach( influencer => {
      if (influencer.state !== 'backup') {
        this.influencers_selected.push(influencer);
      } else if (influencer.state === 'backup') {
        this.backup_influencers.push(influencer);
      }
    });
    this.setInfluencerTotalCost();
  }

  setVirtualScrollHeight(array_length) {
    if (this.viewport === 'handset') {
      if (array_length >= 10) {
        return '950px';
      } else {
        return (array_length * 420).toString() + 'px';
      }
    } else {
      if (array_length >= 10) {
        return '950px';
      } else {
        return (array_length * 110).toString() + 'px';
      }
    }
  }


  increaseTotalCost(influencer, i) {
    influencer.correction_index += 0.01;
    this.totalCostInfluencer(influencer.influencer_id);
    this.updateCorrectionIndex(
        influencer.influencer_id,
        influencer.correction_index
    );
    document.getElementById('influencer-row' + i).classList.add('border-orange');
    this.campaignChanges = true;
  }

  totalCostInfluencer(id) {
    let result_posts;
    let result_stories;
    let result_mentions;
    let result_videos;
    for (let i = 0; i < this.influencers.length; i++) {
      if (this.influencers[i].influencer_id === id) {
        if (this.influencers[i].network === 'instagram') {
          result_posts =
              this.influencers[i].instagram_services.posts *
              this.influencers[i].price_per_post_plus_perc;
          result_stories =
              this.influencers[i].instagram_services.stories *
              this.influencers[i].price_per_story_plus_perc;
          this.influencers[i].total_cost =
              (result_posts + result_stories) *
              this.influencers[i].correction_index;
        } else if (this.influencers[i].network === 'youtube') {
          result_mentions =
              this.influencers[i].youtube_services.mentions *
              this.influencers[i].stats.price_per_mention_plus_perc;
          result_videos =
              this.influencers[i].youtube_services.videos *
              this.influencers[i].stats.price_per_video_plus_perc;
          this.influencers[i].total_cost =
              (result_mentions + result_videos) *
              this.influencers[i].correction_index;
        } else if (this.influencers[i].network === 'tiktok') {
          result_videos =
              this.influencers[i].tiktok_services.tiktok_videos *
              this.influencers[i].price_per_tiktok_video_plus_perc;
          this.influencers[i].total_cost =
              (result_videos) *
              this.influencers[i].correction_index;
        }
      }
    }
    this.loading = false;
  }

  setInfluencerTotalCost() {
    let result_posts;
    let result_stories;
    let result_mentions;
    let result_videos;
    for (let i = 0; i < this.influencers.length; i++) {
      if (this.influencers[i].network === 'instagram') {
        result_posts =
            this.influencers[i].instagram_services.posts *
            this.influencers[i].price_per_post_plus_perc;
        result_stories =
            this.influencers[i].instagram_services.stories *
            this.influencers[i].price_per_story_plus_perc;
        this.influencers[i].total_cost =
            (result_posts + result_stories) *
            this.influencers[i].correction_index;
      } else if (this.influencers[i].network === 'youtube') {
        result_mentions =
            this.influencers[i].youtube_services.mentions *
            this.influencers[i].stats.price_per_mention_plus_perc;
        result_videos =
            this.influencers[i].youtube_services.videos *
            this.influencers[i].stats.price_per_video_plus_perc;
        this.influencers[i].total_cost =
            (result_mentions + result_videos) *
            this.influencers[i].correction_index;
      } else if (this.influencers[i].network === 'tiktok') {
        result_videos =
            this.influencers[i].tiktok_services.tiktok_videos *
            this.influencers[i].price_per_tiktok_video_plus_perc;
        this.influencers[i].total_cost =
            (result_videos) *
            this.influencers[i].correction_index;
      }
      this.totalCostInfluencer(this.influencers[i].influencer_id);
    }
  }

  updateCorrectionIndex(influencer_id, correction_index) {
    const formated_correction_index = Number(correction_index.toFixed(12));
    this._adminService
        .manageCampaignUpdateInfluencerCorrectionIndex(
            this.campaignId,
            influencer_id,
            formated_correction_index
        )
        .subscribe();
  }


  zeros(num, size) {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }

  validateInput() {
    // Obtén la referencia al elemento de entrada
    const inputElement = this.price.nativeElement;
    let valor = inputElement.value;

    // Elimina todos los caracteres no numéricos, excepto comas y puntos
    valor = valor.replace(/[^\d,.]/g, '');

    // Reemplaza comas por vacío para que no afecten el formato
    valor = valor.replace(/,/g, '');

    // Divide la cadena en partes utilizando el punto como separador decimal
    const partes = valor.split('.');

    if (partes.length > 2) {
      // Si hay más de un punto decimal, elimina los extras
      valor = partes[0] + '.' + partes.slice(1).join('');
    } else {
      // Formatea la parte entera con comas como separadores de miles (estilo americano)
      partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Combina la parte entera y decimal con el punto como separador decimal
      valor = partes.join('.');
    }

    // Actualiza el valor del elemento de entrada
    inputElement.value = valor;
  }

  validateEntry(event: any): boolean {
    // const inputChar = String.fromCharCode(event.charCode);
    // // Permitir solo números, punto decimal y teclas de control (como retroceso)
    // return /^[\d.]+$/.test(inputChar) || event.charCode === 0 || event.charCode === 8;

    const inputChar = String.fromCharCode(event.charCode);
    // Permitir solo números, punto decimal, comas como separadores de miles y teclas de control (como retroceso)
    return /^[\d,.]+$/.test(inputChar) || event.charCode === 0 || event.charCode === 8;
  }

  setInfluencerCost(price_field, influencer, i) {
    if (price_field.value !== '') {
      // Quitar las comas de price_field.value si están presentes
      const priceWithoutCommas = price_field.value.replace(/,/g, '');

      const influencer_new_total_cost = Number(priceWithoutCommas).toFixed(2);
      let influencer_initial_total_cost;

      /* We need evit the correction_index === 0 for not enter in bugError in division by 0 */
      if (influencer.correction_index === 0) {
        influencer_initial_total_cost = Number(influencer.total_cost / 1);
      } else {
        influencer_initial_total_cost = Number(
            influencer.total_cost / influencer.correction_index
        ).toFixed(2);
      }
      /* If the last price is 0, then we need view the original price of the influencer */
      if (influencer_initial_total_cost === 0) {
        influencer.correction_index = 1;
        this.totalCostInfluencer(influencer.influencer_id);
        influencer.correction_index =
            Number(influencer_new_total_cost) / Number(influencer.total_cost);
      } else {
        influencer.correction_index =
            Number(influencer_new_total_cost) /
            Number(influencer_initial_total_cost);
      }
      document.getElementById('influencer-row' + i).classList.add('border-orange');
      this.campaignChanges = true;
      this.totalCostInfluencer(influencer.influencer_id);
      // this.callRefreshChanges.emit();
      this.updateCorrectionIndex(
          influencer.influencer_id,
          influencer.correction_index
      );
    }
  }

  decreaseTotalCost(influencer, i) {
    if (influencer.correction_index > 0) {
      influencer.correction_index -= 0.01;
      this.totalCostInfluencer(influencer.influencer_id);
      this.updateCorrectionIndex(
          influencer.influencer_id,
          influencer.correction_index
      );
      document.getElementById('influencer-row' + i).classList.add('border-orange');
      this.campaignChanges = true;
      // this.callRefreshChanges.emit();
    }
  }


  addTiktokVideo(influencer, i) {
    influencer.tiktok_services.tiktok_videos += 1;
    influencer.click_video += 1;
    this.influencers_for_update.forEach((influencer_for_update, index) => {
      if (influencer_for_update.influencer_id === influencer.influencer_id) {
        delete this.influencers_for_update[index];
        this.influencers_for_update = this.influencers_for_update.filter(function (el) {
          return el != null;
        });
      }
    });
    this.influencers_for_update.push({
      influencer_id: influencer.influencer_id,
      network: influencer.network,
      tiktok_videos: influencer.tiktok_services.tiktok_videos,
    });
    influencer.warning =
        influencer.tiktok_services.tiktok_videos === 0;
    // this.checkDisableUpdate();

    document.getElementById('influencer-row' + i).classList.add('border-orange');
    this.campaignChanges = true;
    this.totalCostInfluencer(influencer.influencer_id);
    // this.callRefreshChanges.emit();
  }


  subtractMention(influencer, i) {
    influencer.youtube_services.mentions -= 1;
    if (influencer.youtube_services.mentions >= 0) {
      influencer.click_mention -= 1;
    }
    if (influencer.youtube_services.mentions < 0) {
      influencer.youtube_services.mentions = 0;
    }
    this.influencers_for_update.forEach((influencer_for_update, index) => {
      if (influencer_for_update.influencer_id === influencer.influencer_id) {
        delete this.influencers_for_update[index];
        this.influencers_for_update = this.influencers_for_update.filter(function (el) {
          return el != null;
        });
      }
    });
    this.influencers_for_update.push({
      influencer_id: influencer.influencer_id,
      network: influencer.network,
      videos: influencer.youtube_services.videos,
      mentions: influencer.youtube_services.mentions,
    });
    // this.checkDisableUpdate();
    influencer.warning =
        influencer.youtube_services.videos === 0 &&
        influencer.youtube_services.mentions === 0;

    document.getElementById('influencer-row' + i).classList.add('border-orange');
    this.campaignChanges = true;
    this.totalCostInfluencer(influencer.influencer_id);
    // this.callRefreshChanges.emit();
  }

  addStory(influencer, i) {
    influencer.instagram_services.stories += 1;
    this.influencers_for_update.forEach((influencer_for_update, index) => {
      if (influencer_for_update.influencer_id === influencer.influencer_id) {
        delete this.influencers_for_update[index];
        this.influencers_for_update = this.influencers_for_update.filter(function (el) {
          return el != null;
        });
      }
    });
    this.influencers_for_update.push({
      influencer_id: influencer.influencer_id,
      network: influencer.network,
      stories: influencer.instagram_services.stories,
      posts: influencer.instagram_services.posts,
    });
    influencer.click_story += 1;

    // this.checkDisableUpdate();
    influencer.warning =
        influencer.instagram_services.posts === 0 &&
        influencer.instagram_services.stories === 0;

    document.getElementById('influencer-row' + i).classList.add('border-orange');
    this.campaignChanges = true;
    this.totalCostInfluencer(influencer.influencer_id);
    // this.callRefreshChanges.emit();
  }

  subtractStory(influencer, i) {
    if (influencer.instagram_services.stories >= 0) {
      influencer.click_story -= 1;
    }
    if (influencer.instagram_services.stories < 0) {
      influencer.instagram_services.stories = 0;
    }
    influencer.instagram_services.stories -= 1;
    this.influencers_for_update.forEach((influencer_for_update, index) => {
      if (influencer_for_update.influencer_id === influencer.influencer_id) {
        delete this.influencers_for_update[index];
        this.influencers_for_update = this.influencers_for_update.filter(function (el) {
          return el != null;
        });
      }
    });
    this.influencers_for_update.push({
      influencer_id: influencer.influencer_id,
      network: influencer.network,
      stories: influencer.instagram_services.stories,
      posts: influencer.instagram_services.posts,
    });
    // this.checkDisableUpdate();
    influencer.warning =
        influencer.instagram_services.posts === 0 &&
        influencer.instagram_services.stories === 0;

    document.getElementById('influencer-row' + i).classList.add('border-orange');
    this.campaignChanges = true;
    this.totalCostInfluencer(influencer.influencer_id);
    // this.callRefreshChanges.emit();
  }


  subtractPost(influencer, i) {
    if (influencer.instagram_services.posts >= 0) {
      influencer.click_post -= 1;
    }
    if (influencer.instagram_services.posts < 0) {
      influencer.instagram_services.posts = 0;
    }
    influencer.instagram_services.posts -= 1;
    this.influencers_for_update.forEach((influencer_for_update, index) => {
      if (influencer_for_update.influencer_id === influencer.influencer_id) {
        delete this.influencers_for_update[index];
        this.influencers_for_update = this.influencers_for_update.filter(function (el) {
          return el != null;
        });
      }
    });
    this.influencers_for_update.push({
      influencer_id: influencer.influencer_id,
      network: influencer.network,
      stories: influencer.instagram_services.stories,
      posts: influencer.instagram_services.posts,
    });
    // this.checkDisableUpdate();
    influencer.warning =
        influencer.instagram_services.posts === 0 &&
        influencer.instagram_services.stories === 0;

    document.getElementById('influencer-row' + i).classList.add('border-orange');
    this.campaignChanges = true;
    this.totalCostInfluencer(influencer.influencer_id);
    // this.callRefreshChanges.emit();
  }


  addPost(influencer, i) {
    influencer.instagram_services.posts += 1;
    this.influencers_for_update.forEach((influencer_for_update, index) => {
      if (influencer_for_update.influencer_id === influencer.influencer_id) {
        delete this.influencers_for_update[index];
        this.influencers_for_update = this.influencers_for_update.filter(function (el) {
          return el != null;
        });
      }
    });
    this.influencers_for_update.push({
      influencer_id: influencer.influencer_id,
      network: influencer.network,
      stories: influencer.instagram_services.stories,
      posts: influencer.instagram_services.posts,
    });
    influencer.click_post += 1;
    // this.checkDisableUpdate();
    influencer.warning =
        influencer.instagram_services.posts === 0 &&
        influencer.instagram_services.stories === 0;

    document.getElementById('influencer-row' + i).classList.add('border-orange');
    this.campaignChanges = true;
    this.totalCostInfluencer(influencer.influencer_id);
    // this.callRefreshChanges.emit();
  }

  addMention(influencer, i) {
    influencer.youtube_services.mentions += 1;
    influencer.click_mention += 1;
    this.influencers_for_update.forEach((influencer_for_update, index) => {
      if (influencer_for_update.influencer_id === influencer.influencer_id) {
        delete this.influencers_for_update[index];
        this.influencers_for_update = this.influencers_for_update.filter(function (el) {
          return el != null;
        });
      }
    });
    this.influencers_for_update.push({
      influencer_id: influencer.influencer_id,
      network: influencer.network,
      videos: influencer.youtube_services.videos,
      mentions: influencer.youtube_services.mentions,
    });
    influencer.warning =
        influencer.youtube_services.videos === 0 &&
        influencer.youtube_services.mentions === 0;
    // this.checkDisableUpdate();

    document.getElementById('influencer-row' + i).classList.add('border-orange');
    this.campaignChanges = true;
    this.totalCostInfluencer(influencer.influencer_id);
    // this.callRefreshChanges.emit();
  }

  subtractYoutubeVideo(influencer, i) {
    influencer.youtube_services.videos -= 1;
    if (influencer.youtube_services.videos >= 0) {
      influencer.click_video -= 1;
    }
    if (influencer.youtube_services.videos < 0) {
      influencer.youtube_services.videos = 0;
    }
    this.influencers_for_update.forEach((influencer_for_update, index) => {
      if (influencer_for_update.influencer_id === influencer.influencer_id) {
        delete this.influencers_for_update[index];
        this.influencers_for_update = this.influencers_for_update.filter(function (el) {
          return el != null;
        });
      }
    });
    this.influencers_for_update.push({
      influencer_id: influencer.influencer_id,
      network: influencer.network,
      videos: influencer.youtube_services.videos,
      mentions: influencer.youtube_services.mentions,
    });
    // this.checkDisableUpdate();
    influencer.warning =
        influencer.youtube_services.videos === 0 &&
        influencer.youtube_services.mentions === 0;

    document.getElementById('influencer-row' + i).classList.add('border-orange');
    this.campaignChanges = true;
    this.totalCostInfluencer(influencer.influencer_id);
    // this.callRefreshChanges.emit();
  }

  subtractTiktokVideo(influencer, i) {
    influencer.tiktok_services.tiktok_videos -= 1;
    if (influencer.tiktok_services.tiktok_videos >= 0) {
      influencer.click_video -= 1;
    }
    if (influencer.tiktok_services.tiktok_videos < 0) {
      influencer.tiktok_services.tiktok_videos = 0;
    }
    this.influencers_for_update.forEach((influencer_for_update, index) => {
      if (influencer_for_update.influencer_id === influencer.influencer_id) {
        delete this.influencers_for_update[index];
        this.influencers_for_update = this.influencers_for_update.filter(function (el) {
          return el != null;
        });
      }
    });
    this.influencers_for_update.push({
      influencer_id: influencer.influencer_id,
      network: influencer.network,
      tiktok_videos: influencer.tiktok_services.tiktok_videos
    });
    // this.checkDisableUpdate();
    document.getElementById('influencer-row' + i).classList.add('border-orange');
    this.campaignChanges = true;
    influencer.warning =
        influencer.tiktok_services.tiktok_videos === 0 &&
        this.totalCostInfluencer(influencer.influencer_id);

    // this.callRefreshChanges.emit();
  }


  addYoutubeVideo(influencer, i) {
    influencer.youtube_services.videos += 1;
    influencer.click_video += 1;
    this.influencers_for_update.forEach((influencer_for_update, index) => {
      if (influencer_for_update.influencer_id === influencer.influencer_id) {
        delete this.influencers_for_update[index];
        this.influencers_for_update = this.influencers_for_update.filter(function (el) {
          return el != null;
        });
      }
    });
    this.influencers_for_update.push({
      influencer_id: influencer.influencer_id,
      network: influencer.network,
      videos: influencer.youtube_services.videos,
      mentions: influencer.youtube_services.mentions,
    });
    influencer.warning =
        influencer.youtube_services.videos === 0 &&
        influencer.youtube_services.mentions === 0;
    // this.checkDisableUpdate();

    document.getElementById('influencer-row' + i).classList.add('border-orange');
    this.campaignChanges = true;
    this.totalCostInfluencer(influencer.influencer_id);
    // this.callRefreshChanges.emit();
  }


  /**
   * Method that opens the campaigns list modal
   * @param insta_id It passes the instagram_id from the specific card for adding it to a campaign
   */
  openCampaignsDialog(influencer): void {
    this.dialog.open(CampaignsModalComponent, {
      width: '790px',
      height: '350px',
      panelClass: 'remove-padding',
      autoFocus: false,
      data: {
        instagram_id: influencer.influencer_id,
        network: influencer.network,
        full_name: influencer.full_name,
        profile_pic_url: influencer.profile_pic_url,
        username: influencer.username
      },
    });
  }

  switchInfluencer(influencer) {
    this.loading = true;
    this._adminService
        .updateInfluencerState(this.campaignId, influencer.influencer_id, influencer.state)
        .subscribe(
            () => {
              if (influencer.state === 'backup') {
                influencer.state = 'added';
                this.influencers_selected.push(influencer);
                this.backup_influencers.forEach(( influ, i) => {
                  if (influ.influencer_id === influencer.influencer_id) {
                    this.backup_influencers = this.backup_influencers.filter((item, index) => index !== i);
                  }
                });
              } else {
                influencer.state = 'backup';
                this.backup_influencers.push(influencer);
                this.influencers_selected.forEach(( influ, i) => {
                  if (influ.influencer_id === influencer.influencer_id) {
                    this.influencers_selected = this.influencers_selected.filter((item, index) => index !== i);
                  }
                });
              }
              this.loading = false;
              this.openSnackBar('Influencer moved successfully', 'Ok');
            },
            () => {
              this.loading = false;
              this.openSnackBar('Something went wrong.', 'Ok');
            }
        );
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  hideInfluencer(influencer) {
    influencer.hide_influencer = !influencer.hide_influencer;
    const dataForUpdate = {};
    dataForUpdate['hide_influencer'] = influencer.influencer_id;
    dataForUpdate['campaign_id'] = this.campaignId;
    this.subscriptions.push(
        this.adminManagementService.updateManageCampaign(this.campaignId, dataForUpdate).subscribe( () => {
          this.openSnackBar('Updated successfully', 'Close');
        }, () => {
          this.openSnackBar('Something went wrong', 'Close');
        })
    );
  }

  openRemoveInfluencerDialog(influencer) {
    const dialogRef = this.dialog.open(RemoveInfluencerComponent, {
      width: '490px',
      height: '320px',
      panelClass: 'remove-padding',
      autoFocus: false,
      data: {
        campaign_id: this.campaignId,
        influencer_id: influencer.influencer_id,
        username: influencer.username,
        network: influencer.network
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== '') {
        if (result['status'] === 200) {
          let influencersForRemove;
          let isAdditional;
          if (influencer.state === 'backup') {
            isAdditional = true;
            influencersForRemove = this.backup_influencers;
          } else {
            isAdditional = false;
            influencersForRemove = this.influencers_selected;
          }
          for (let i = 0; i < influencersForRemove.length; i++) {
            if (influencer.influencer_id === influencersForRemove[i].influencer_id) {
              if (isAdditional) {
                this.backup_influencers = influencersForRemove.filter((item, index) => index !== i);
              } else {
                this.influencers_selected = influencersForRemove.filter((item, index) => index !== i);
              }
            }
          }
          this.loading = false;
          this.openSnackBar('Influencer was removed successfully.', 'Ok');
        } else {
          this.loading = false;
          this.openSnackBar('There has been an error. Please try again.', 'Ok');
        }
      }
    });
  }

  openModalReportInfluencerContent(influencer_id) {
    this.dialog
        .open(ReportInfluencerContentModalComponent, {
          width: '80%',
          height: '83%',
          autoFocus: false,
          data: {
            influencer_id: influencer_id,
            campaign_id: this.campaignId,
            currency_type: this.currency_type,
            currency_symbol: this.currency_symbol,
            is_share: false,
            dateStart: this.date_start,
            dateEnd: this.date_end,
            isShare: false,
          },
        })
        .afterClosed()
        .subscribe(() => {
          // this.getReport(); ver que hago aqui, esto tiene que verse
        });
  }


  editAudience(influencerId) {
    this.router.navigate(['app/editAudience'], {
      queryParams:
          {
            influencerId: influencerId,
            campaignId: this.campaignId,
          }
    });
  }


  saveChanges() {
    const dataForUpdate = {};
    dataForUpdate['influencers'] = this.influencers_for_update;
    dataForUpdate['campaign_id'] = this.campaignId;
    this.subscriptions.push(
        this.adminManagementService.updateManageCampaign(this.campaignId, dataForUpdate).subscribe( () => {
          this.openSnackBar('Updated successfully', 'Close');
          this.influencers.forEach((influencer, i) => {
            document.getElementById('influencer-row' + i).classList.remove('border-orange');
          });
        }, () => {
          this.openSnackBar('Something went wrong', 'Close');
        })
    );
  }

  actionPostMentionAllInfluencers(action) {
    this.influencers.forEach((influencer, i) => {
      if (influencer.state !== 'backup') {
        document.getElementById('influencer-row' + i).classList.add('border-orange');
        this.campaignChanges = true;
        if (influencer.network === 'instagram') {
          if (action === 'subtract') {
            if (influencer.instagram_services.posts > 0) {
              influencer.instagram_services.posts -= 1;
              this.influencers_for_update.forEach(
                  (influencer_for_update, index) => {
                    if (
                        influencer_for_update.influencer_id ===
                        influencer.influencer_id
                    ) {
                      delete this.influencers_for_update[index];
                      this.influencers_for_update = this.influencers_for_update.filter(function (el) {
                        return el != null;
                      });
                    }
                  }
              );
              this.influencers_for_update.push({
                influencer_id: influencer.influencer_id,
                network: influencer.network,
                stories: influencer.instagram_services.stories,
                posts: influencer.instagram_services.posts,
              });
            }
          } else if (action === 'add') {
            influencer.instagram_services.posts += 1;
            this.influencers_for_update.forEach(
                (influencer_for_update, index) => {
                  if (
                      influencer_for_update.influencer_id ===
                      influencer.influencer_id
                  ) {
                    delete this.influencers_for_update[index];
                    this.influencers_for_update = this.influencers_for_update.filter(function (el) {
                      return el != null;
                    });
                  }
                }
            );
            this.influencers_for_update.push({
              influencer_id: influencer.influencer_id,
              network: influencer.network,
              stories: influencer.instagram_services.stories,
              posts: influencer.instagram_services.posts,
            });
          }
        } else if (influencer.network === 'youtube') {
          if (action === 'subtract') {
            if (influencer.youtube_services.mentions > 0) {
              influencer.youtube_services.mentions -= 1;
              this.influencers_for_update.forEach(
                  (influencer_for_update, index) => {
                    if (
                        influencer_for_update.influencer_id ===
                        influencer.influencer_id
                    ) {
                      delete this.influencers_for_update[index];
                      this.influencers_for_update = this.influencers_for_update.filter(function (el) {
                        return el != null;
                      });
                    }
                  }
              );
              this.influencers_for_update.push({
                influencer_id: influencer.influencer_id,
                network: influencer.network,
                videos: influencer.youtube_services.videos,
                mentions: influencer.youtube_services.mentions,
              });
            }
          } else if (action === 'add') {
            influencer.youtube_services.mentions += 1;
            this.influencers_for_update.forEach(
                (influencer_for_update, index) => {
                  if (
                      influencer_for_update.influencer_id ===
                      influencer.influencer_id
                  ) {
                    delete this.influencers_for_update[index];
                    this.influencers_for_update = this.influencers_for_update.filter(function (el) {
                      return el != null;
                    });
                  }
                }
            );
            this.influencers_for_update.push({
              influencer_id: influencer.influencer_id,
              network: influencer.network,
              videos: influencer.youtube_services.videos,
              mentions: influencer.youtube_services.mentions,
            });
          }
        }
        this.totalCostInfluencer(influencer.influencer_id);
      }
    });
  }

  actionStorieVideoAllInfluencers(action) {
    this.influencers.forEach((influencer, i) => {
      if (influencer.state !== 'backup') {
        document.getElementById('influencer-row' + i).classList.add('border-orange');
        this.campaignChanges = true;
        if (influencer.network === 'instagram') {
          if (action === 'subtract') {
            if (influencer.instagram_services.stories > 0) {
              influencer.instagram_services.stories -= 1;
              this.influencers_for_update.forEach(
                  (influencer_for_update, index) => {
                    if (
                        influencer_for_update.influencer_id ===
                        influencer.influencer_id
                    ) {
                      delete this.influencers_for_update[index];
                      this.influencers_for_update = this.influencers_for_update.filter(function (el) {
                        return el != null;
                      });
                    }
                  }
              );
              this.influencers_for_update.push({
                influencer_id: influencer.influencer_id,
                network: influencer.network,
                posts: influencer.instagram_services.posts,
                stories: influencer.instagram_services.stories,
              });
            }
          } else if (action === 'add') {
            influencer.instagram_services.stories += 1;
            this.influencers_for_update.forEach(
                (influencer_for_update, index) => {
                  if (
                      influencer_for_update.influencer_id ===
                      influencer.influencer_id
                  ) {
                    delete this.influencers_for_update[index];
                    this.influencers_for_update = this.influencers_for_update.filter(function (el) {
                      return el != null;
                    });
                  }
                }
            );
            this.influencers_for_update.push({
              influencer_id: influencer.influencer_id,
              network: influencer.network,
              posts: influencer.instagram_services.posts,
              stories: influencer.instagram_services.stories,
            });
          }
        } else if (influencer.network === 'youtube') {
          if (action === 'subtract') {
            if (influencer.youtube_services.videos > 0) {
              influencer.youtube_services.videos -= 1;
              this.influencers_for_update.forEach(
                  (influencer_for_update, index) => {
                    if (
                        influencer_for_update.influencer_id ===
                        influencer.influencer_id
                    ) {
                      delete this.influencers_for_update[index];
                      this.influencers_for_update = this.influencers_for_update.filter(function (el) {
                        return el != null;
                      });
                    }
                  }
              );
              this.influencers_for_update.push({
                influencer_id: influencer.influencer_id,
                network: influencer.network,
                videos: influencer.youtube_services.videos,
                mentions: influencer.youtube_services.mentions,
              });
            }
          } else if (action === 'add') {
            influencer.youtube_services.videos += 1;
            this.influencers_for_update.push({
              influencer_id: influencer.influencer_id,
              network: influencer.network,
              videos: influencer.youtube_services.videos,
              mentions: influencer.youtube_services.mentions,
            });
          }
        } else if (influencer.network === 'tiktok') {
          if (action === 'subtract') {
            if (influencer.tiktok_services.tiktok_videos > 0) {
              influencer.tiktok_services.tiktok_videos -= 1;
              this.influencers_for_update.forEach(
                  (influencer_for_update, index) => {
                    if (
                        influencer_for_update.influencer_id ===
                        influencer.influencer_id
                    ) {
                      delete this.influencers_for_update[index];
                      this.influencers_for_update = this.influencers_for_update.filter(function (el) {
                        return el != null;
                      });
                    }
                  }
              );
              this.influencers_for_update.push({
                influencer_id: influencer.influencer_id,
                network: influencer.network,
                tiktok_videos: influencer.tiktok_services.tiktok_videos,
              });
            }
          } else if (action === 'add') {
            influencer.tiktok_services.tiktok_videos += 1;
            this.influencers_for_update.push({
              influencer_id: influencer.influencer_id,
              network: influencer.network,
              tiktok_videos: influencer.tiktok_services.tiktok_videos,
            });
          }
        }
        this.totalCostInfluencer(influencer.influencer_id);
      }
    });
  }

}
