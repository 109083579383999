import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Influencer} from 'src/app/types/influencer/influencer';
import {InfluencersService} from '../../../../../store/influencers/influencers.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-influencer-profile',
  templateUrl: './influencer-profile.component.html',
  styleUrls: ['./influencer-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfluencerProfileComponent implements OnInit, OnDestroy {

  @Input() influencer: Influencer;
  @Input() user: any;
  @Input() loadingProfile: boolean;

  @Output() analyze: EventEmitter<Influencer> = new EventEmitter();
  @Output() add: EventEmitter<Influencer> = new EventEmitter();

  loadingFullCrawl: boolean;
  errorFullCrawl: boolean;

  errorFullCrawlMessage: string;

  private subscriptions: Subscription[] = [];

  constructor(
      public _influencerService: InfluencersService,
      private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  doAnalyze() {
    this.analyze.emit(this.influencer);
  }


  fullCrawlInfluencer(username, id, type) {
    this.loadingFullCrawl = true;
    this.subscriptions.push(
      this._influencerService.newFullCrawl(username, id, type).subscribe( response => {
        this.influencer = response;
        this.loadingFullCrawl = false;
        this.errorFullCrawl = false;
        this.cdr.detectChanges();
      }, error => {
        this.loadingFullCrawl = false;
        this.errorFullCrawl = true;
        this.errorFullCrawlMessage = error.error.message;
        this.cdr.detectChanges();
      })
    );
  }

  scrollTo(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
