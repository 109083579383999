import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {openPlansDialog} from '../../../../store/app/app.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../../store';

@Component({
  selector: 'app-modal-plan-limits-exceeded',
  templateUrl: './modal-plan-limits-exceeded.component.html',
  styleUrls: ['./modal-plan-limits-exceeded.component.scss']
})
export class ModalPlanLimitsExceededComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private store: Store<State>) { }

  ngOnInit() {
  }


  openPlansModal() {
    this.store.dispatch(openPlansDialog());
  }

  redirectCallSales() {
    window.open('https://calendly.com/nicolas-11posts/plans-and-pricing', '_blank');
  }

  redirectCalendly() {
    window.open('https://calendly.com/nicolas-11posts/30min', '_blank');
  }
}
