import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse, HttpErrorResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import {map, tap} from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class Interceptor401Service implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(tap(
        (response: HttpEvent<any>) => {
        },
        (error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.router.navigate(['/app/search']);
          }
        },
        () => {
        }
      ));
  }
}
