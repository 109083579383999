import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {ProgressService} from '../../../../../../../../services/progress.service';

@Component({
  selector: 'app-modal-confirm-remove-proposal',
  templateUrl: './modal-confirm-remove-proposal.component.html',
  styleUrls: ['./modal-confirm-remove-proposal.component.css']
})
export class ModalConfirmRemoveProposalComponent implements OnInit {

  sending_proposal = false;

  constructor(private _progressService: ProgressService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ModalConfirmRemoveProposalComponent>,
              public snackBar: MatSnackBar) { }

  ngOnInit() {
  }


  removeProposal() {
    this.sending_proposal = true;
    this._progressService.removeProposal(this.data.campaign_id, this.data.proposal_id).subscribe(result => {
      if (result['status'] === 200) {
        this.dialogRef.close('success');
        this.sending_proposal = false;
      }
    }, error => {
      this.dialogRef.close();
      this.snackBar.open('There was an error trying to remove the proposal.', 'Ok', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.sending_proposal = false;
    });
  }
}
