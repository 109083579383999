import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  private progressUrl = environment.aws_resources.lambda_endpoint.api;
  apiKey = environment.aws_resources.api_key;

  constructor(private http: HttpClient) { }

  getProgress(campaign_id: string) {
    return this.http.get(this.progressUrl + 'progress/' + campaign_id, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }


  getinfluencerProposals(campaignId: string, influencerId: string) {
    return this.http.get(this.progressUrl + 'progress/' + campaignId + '/influencer/' + influencerId + '/view-proposal', {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getinfluencerSharedProposals(campaignHash: string, influencerId: string) {
    return this.http.get(this.progressUrl + 'progress/campaign_hash/' + campaignHash + '/influencer/' + influencerId + '/view-proposal', {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  shareProgressContentApproval(campaign_id: string) {
    const body = {
      platform_id: environment.platform_id,
    };
    return this.http.post(this.progressUrl + 'progress/' + campaign_id + '/share', body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getSharedProgressContentApproval(hash_progress_id) {
    return this.http.get(this.progressUrl + 'progress/shared/' + hash_progress_id, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  acceptAllProposals(hash_progress_id) {
    return this.http.put(this.progressUrl + 'progress/shared/' + hash_progress_id, {}, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  acceptProposal(hash_progress_id, proposal_id) {
    return this.http.put(this.progressUrl + 'progress/shared/' + hash_progress_id + '/proposal/' + proposal_id + '/accept', {}, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  rejectProposal(hash_progress_id, proposal_id, reason) {
    const body = {
      reason: reason
    };

    return this.http.put(this.progressUrl + 'progress/shared/' + hash_progress_id + '/proposal/' + proposal_id + '/reject', body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  resendInvitation(campaign_id, influencers_ids) {

    return this.http.post(this.progressUrl + 'progress/' + campaign_id + '/check_reinvite', influencers_ids, { observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  uploadProposal(campaign_id, proposal) {
    return this.http.post(this.progressUrl + 'progress/' + campaign_id, proposal, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  saveInfluencerEmail(campaign_id, influencer_id, invitation_mail) {
    return this.http.put(this.progressUrl + 'progress/' + campaign_id + '/influencer/' + influencer_id + '/save_email', invitation_mail, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getInfluencerPhoneNumber(campaign_id, influencer_id) {
    return this.http.get(this.progressUrl + 'progress/' + campaign_id + '/influencer/' + influencer_id + '/get-phone-number', {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  removeProposal(campaign_id, proposal_id) {
    const body = {};
    return this.http.put(this.progressUrl + 'progress/' + campaign_id + '/proposal/' + proposal_id + '/delete', body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }


  getProgressExportCSV(campaign_id) {
    return this.http.get(this.progressUrl + 'progress/' + campaign_id + '/export', {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  exportToCsv(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ';';
    const keys = Object.keys(rows[0]);
    const csvData = keys.join(separator) + '\n' + rows.map(row => {
      return keys.map(k => {
        let cell = row[k] === null || row[k] === undefined ? '' : row[k];
        cell = cell instanceof Date
          ? cell.toLocaleString()
          : cell.toString().replace(/"/g, '""');
        if (cell.search(/([",\n])/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator);
    }).join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

}
