import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';

@Component({
  selector: 'app-chart-user-searchs',
  templateUrl: './chart-user-searchs.component.html',
  styleUrls: ['./chart-user-searchs.component.css']
})
export class ChartUserSearchsComponent implements OnInit {

  @Input() searchData;
  @Input() numSearchs;


  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        display: true,
        type: 'time',
        distribution: 'series',
        gridLines: {
          display: true
        },
        time: {
          unit: 'month',
          displayFormats: {
            day: 'D MMM, YYYY',
            week: 'll',
            month: 'll',
            quarter: 'll',
            year: 'll'
          },
        },
      }],
      yAxes: [
        {
          ticks: {
            min: 0, // it is for ignoring negative step.
            beginAtZero: true,
            callback: function(value, index, values) {
              if (Math.floor(value) === value) {
                return value;
              }
            }
          }
        }
      ],
    }
  };

  public barChartLabels = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
  ];

  constructor() { }

  ngOnInit() {
    this.setChartData();
  }


  setChartData() {
    this.barChartData.push({data: [], label: 'Instagram: ' + this.numSearchs.instagram.requested});
    this.barChartData.push({data: [], label: 'Youtube: ' + this.numSearchs.youtube.requested});
    this.barChartData.push({data: [], label: 'Tiktok: ' + this.numSearchs.tiktok.requested});

    // let numSearchs = 0;
    // for (let i = 0; i < this.searchData.length; i++) {
    //   if (this.searchData[i].search_requests_count > 0) {
    //     numSearchs += this.searchData[i].search_requests_count;
    //   }
    // }
    //
    // if (numSearchs <= 20) {
    //   this.barChartOptions.scales.yAxes = [
    //     {
    //       id: 'y-axis-0',
    //       position: 'left',
    //       scaleLabel: {
    //         display: true,
    //         labelString: 'Searchs',
    //       },
    //       ticks: {
    //         beginAtZero: true,
    //         suggestedMin: 0,
    //         suggestedMax: 20,
    //         maxTicksLimit: 20,
    //         stepSize: 1,
    //       }
    //     },
    //   ];
    // }



    for (let index = 0; index < this.searchData.length; index ++) {
      // Establecemos las fechas
      this.barChartLabels.push( new Date(this.searchData[index].timestamp * 1000));
      // Set the values
      this.barChartData[0].data.push(this.searchData[index].instagram.requested);
      this.barChartData[1].data.push(this.searchData[index].youtube.requested);
      this.barChartData[2].data.push(this.searchData[index].tiktok.requested);

    }

  }
}
