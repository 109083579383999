import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {ReportService} from '../../../../../../services/report.service';
import {ShowStoryModalComponent} from '../../../show-story-modal/show-story-modal.component';
import * as selectorsAuth from '../../../../../../store/auth/authentication.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../store';
import {FormUploadStoryManuallyComponent} from './form-upload-story-manually/form-upload-story-manually.component';

@Component({
  selector: 'app-modal-upload-stories-manually',
  templateUrl: './modal-upload-stories-manually.component.html',
  styleUrls: ['./modal-upload-stories-manually.component.scss']
})
export class ModalUploadStoriesManuallyComponent implements OnInit, OnDestroy {

  loading: boolean;
  firstLoad: boolean;
  breakpoint: number;
  notContent = true;

  searchedLiveStories = false;

  minDate: any;
  maxDate: any;
  dateForm: FormGroup;

  stories: Array<any> = [];
  private subscriptions = [];

  planNameShown = '';

  constructor(private _reportService: ReportService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ModalUploadStoriesManuallyComponent>,
              public snackBar: MatSnackBar,
              public dialog: MatDialog,
              public store: Store<State>,
  ) {

    this.dateForm = new FormGroup({
      'requested_date': new FormControl('', [Validators.required])
    });


    this.minDate = new Date (this.data.dateStart * 1000);
    this.maxDate = new Date(this.data.dateEnd * 1000);

    this.minDate.setDate(this.minDate.getDate() - 3);
    this.maxDate.setDate(this.maxDate.getDate() + 3);


    if (new Date() > new Date(this.data.date_end * 1000)) {
      this.dateForm.controls['requested_date'].setValue(new Date(this.data.date_end * 1000));
    } else {
      this.dateForm.controls['requested_date'].setValue(new Date(new Date().getTime()));
    }
  }

  ngOnInit() {
    this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
      this.planNameShown = user.PLAN_NAME_SHOWN;
    });
    this.getStories();
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 6;
  }

  getStories() {
    this.loading = true;
    this.firstLoad = !this.dateForm.touched;
    const date = this.dateForm.controls['requested_date'].value;
    const reqDate = Math.trunc(date.getTime() / 1000);
    this.stories = [];
    this.subscriptions.push(
      this._reportService.getStories(this.data.campaign_id, this.data.influencerId, reqDate).subscribe(response => {
      if (response['status'].toString() === '200') {
        this.notContent = false;
        this.stories = Object.keys(response['body']).length !== 0 ? response['body'] : [];
        this.loading = false;
        this.stories.forEach( (story, i) => {
          story['loading'] = false;
        });
      } else if (response['status'].toString() === '204') {
        this.openSnackBar('There are no stories collected for this date', 'Ok');
        this.notContent = true;
        this.loading = false;
      }
      }, () => {
      this.loading = false;
      this.notContent = true;
      this.openSnackBar('Something went wrong, please try again', 'Ok');
      })
    );
  }

  getLiveStories() {
    this.searchedLiveStories = true;
    this.loading = true;
    this.subscriptions.push(
      ...[
        this._reportService.getLiveStories(this.data.campaign_id, this.data.influencerId).subscribe( () => {
          this.openSnackBar('Analyzing influencer stories successfully', 'Close');
          this.getStories();
          this.loading = false;
        }, error => {
          if (error.error.message) {
            this.openSnackBar(error.error.message, 'Close');
          } else {
            this.openSnackBar('Oops something went wrong, please try again', 'Close');
          }
          this.loading = false;
        })
      ]);
  }

  openShowStoryDialog(media): void {
    let config: any;
    media['origin'] = 'report';
    config = {
      width: 'auto',
      height: '100vh',
      autoFocus: false,
      data: {
        media: media
      },
      backdropClass: 'story-dialog-backdrop-class',
      panelClass: 'story-dialog-panel-class'
    };
    this.dialog.open(ShowStoryModalComponent, config);
  }

  add_story(story) {
    story.loading = true;
    this.subscriptions.push(
      this._reportService.addStoryManually(this.data.campaign_id, this.data.influencerId, story.story_id).subscribe( result => {
          story.used_in_campaign = true;
          this.getStories();
          this.openSnackBar('Story added successfully', 'Ok');
        story.loading = false;
      }, error => {
        this.openSnackBar('Something went wrong, please try again', 'Ok');
        story.loading = false;
      })
    );
  }

  urlChecker(storyUrl) {
    if (this.data.influencerLinkadiaAdUrl === 'N/A' || this.data.influencerLinkadiaAdUrl === storyUrl) {
      return 'green';
    } else if (storyUrl === 'N/A') {
      return 'red';
    } else {
      return 'orange';
    }
  }


  formUploadStoryManually() {
    this.dialog.open(FormUploadStoryManuallyComponent, {
      width: '650px',
      height: 'auto',
      autoFocus: false,
      data: {
        influencerId: this.data.influencerId,
        campaignId: this.data.campaign_id,
      },
      backdropClass: 'story-dialog-backdrop-class',
      panelClass: 'story-dialog-panel-class',
    });
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
  }
}
