import {Component, Input, OnInit} from '@angular/core';
import {ViewMoreModalComponent} from '../../influencer-lateral-profile/modals/view-more-modal/view-more-modal.component';
import {MatDialog} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-locations-by-city',
  templateUrl: './locations-by-city.component.html',
  styleUrls: ['./locations-by-city.component.scss', '../locations-by-country/locations-by-country.component.scss']
})
export class LocationsByCityComponent implements OnInit {

  @Input() city_location;

  constructor(
      private dialog: MatDialog,
      private translate: TranslateService
  ) { }

  ngOnInit() {
  }


  openViewMoreModal(data, col1, col2, titleModal) {
    const translatedTitleModal = this.translate.instant(titleModal);
    this.dialog.open(ViewMoreModalComponent, {
      width: 'auto',
      autoFocus: false,
      maxHeight: '90vh',
      data: {
        data: data,
        col1: col1,
        col2: col2,
        titleModal: translatedTitleModal
      },
    });
  }
}
