import {User} from '../../types/user';
import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './authentication.actions';

export const authenticationKey = 'authentication';

export interface AuthenticationState {
  user: User;
  email: string;
  accessSets: any;
  loginLoading: boolean;
  loginError: string;
  signUpLoading: boolean;
  signUpError: string;
  forgotPasswordLoading: boolean;
  forgotPasswordError: string;
  resetPasswordLoading: boolean;
  resetPasswordError: string;
  resendVerificationEmailLoading: boolean;
  resendVerificationEmailError: string;
}

export const initialAuthenticationState = {
  user: null,
  email: null,
  accessSets: {},
  loginLoading: false,
  loginError: null,
  signUpLoading: false,
  signUpError: null,
  forgotPasswordLoading: false,
  forgotPasswordError: null,
  resetPasswordLoading: false,
  resetPasswordError: null,
  resendVerificationEmailLoading: false,
  resendVerificationEmailError: null,
};

function _clearErrors() {
  return {
    loginError: null,
    signUpError: null,
    forgotPasswordError: null,
    resetPasswordError: null,
    resendVerificationEmailError: null,
  };
}

export const authenticationReducers = {
  clearErrors(state) {
    return {
      ...state,
      ..._clearErrors(),
    };
  },
  accessSetsSuccess(state, { accessSets }) {
    return {
      ...state,
      accessSets,
    };
  },
  login(state) {
    return { ...state, loginLoading: true };
  },
  loginSuccess(state, { user }) {
    return { ...state, loginLoading: false, user, ..._clearErrors() };
  },
  loginError(state, { error }) {
    let message = null;

    if (error.code === 'UserNotConfirmedException') {
      message =
        'User account has not been validated, check your email to validate the account or use the resend email validation link below if you can\'t find it. Double check your spam folder as you might have received it there';
    } else {
      message = error.message;
    }

    return { ...state, loginLoading: false, loginError: message };
  },
  logout(state) {
    window.localStorage.setItem('userViewModalFreeTrial', 'false');
    return {
      ...state,
      loginLoading: false,
      user: null,
      loginError: null,
    };
  },
  signUp(state) {
    return { ...state, signUpLoading: true };
  },
  signUpSuccess(state) {
    return { ...state, signUpLoading: false, ..._clearErrors() };
  },
  signUpError(state, { error }) {
    const message = error.message;
    return { ...state, signUpLoading: false, signUpError: message };
  },
  // REGISTER EMAIL STEP 1
  signUpStep1(state, { email }) {
    return { ...state, signUpLoading: true };
  },
  signUpStep1Success(state) {
    return { ...state, signUpLoading: false, ..._clearErrors() };
  },
  signUpStep1Error(state, { error }) {
    const message = error.message;
    return { ...state, signUpLoading: false, signUpError: message };
  },
  getEmaillFromHash(state, { hash }) {
    return { ...state, signUpLoading: true };
  },
  getEmaillFromHashSuccess(state, { email }) {
    return { ...state, email, signUpLoading: false, ..._clearErrors() };
  },
  getEmaillFromHashError(state, { error }) {
    const message = error.error.message;
    return { ...state, signUpLoading: false, signUpError: message };
  },
  forgotPassword(state) {
    return { ...state, forgotPasswordLoading: true };
  },
  forgotPasswordSuccess(state) {
    return {
      ...state,
      forgotPasswordLoading: false,
      ..._clearErrors(),
    };
  },
  forgotPasswordError(state, { error }) {
    let message = null;

    if (error.code === 'UserNotFoundException') {
      message = 'This account doesn\'t exist';
    } else if (error.code === 'InvalidParameterException') {
      message = 'This account doesn\'t exists or is not verified';
    } else {
      message = error.message;
    }

    return {
      ...state,
      forgotPasswordLoading: false,
      forgotPasswordError: message,
    };
  },
  resetPassword(state) {
    return { ...state, resetPasswordLoading: true };
  },
  resetPasswordSuccess(state) {
    return {
      ...state,
      resetPasswordLoading: false,
      ..._clearErrors(),
    };
  },
  resetPasswordError(state, { error }) {
    let message = null;

    if (
      error.code === 'ExpiredCodeException' ||
      error.code === 'CodeMismatchException'
    ) {
      message = 'Invalid code';
    } else {
      message = error.message;
    }

    return {
      ...state,
      resetPasswordLoading: false,
      resetPasswordError: message,
    };
  },
  resendVerificationEmail(state) {
    return { ...state, resendVerificationEmailLoading: true };
  },
  resendVerificationEmailSuccess(state) {
    return {
      ...state,
      resendVerificationEmailLoading: false,
      ..._clearErrors(),
    };
  },
  resendVerificationEmailError(state, { error }) {
    let message = null;

    if (error.code === 'UserNotFoundException') {
      message = 'User doesn\'t exists';
    } else if (error.code === 'InvalidParameterException') {
      message = error.message + ' Please log in.';
    } else {
      message = error.message;
    }

    return {
      ...state,
      resendVerificationEmailLoading: false,
      resendVerificationEmailError: message,
    };
  },
};

export function reducer(
  state: AuthenticationState | undefined,
  action: Action
) {
  return createReducer(
    initialAuthenticationState,
    on(actions.clearErrors, authenticationReducers.clearErrors),
    on(actions.loginUser, authenticationReducers.login),
    on(actions.loginUserSuccess, authenticationReducers.loginSuccess),
    on(actions.loginUserFail, authenticationReducers.loginError),
    on(
      actions.resendVerificationEmail,
      authenticationReducers.resendVerificationEmail
    ),
    on(
      actions.resendVerificationEmailSuccess,
      authenticationReducers.resendVerificationEmailSuccess
    ),
    on(
      actions.resendVerificationEmailFail,
      authenticationReducers.resendVerificationEmailError
    ),
    on(actions.signUp, authenticationReducers.signUp),
    on(actions.signUpSuccess, authenticationReducers.signUpSuccess),
    on(actions.signUpFail, authenticationReducers.signUpError),
    on(actions.forgotPassword, authenticationReducers.forgotPassword),
    on(
      actions.forgotPasswordSuccess,
      authenticationReducers.forgotPasswordSuccess
    ),
    on(actions.forgotPasswordFail, authenticationReducers.forgotPasswordError),
    on(actions.resetPassword, authenticationReducers.resetPassword),
    on(
      actions.resetPasswordSuccess,
      authenticationReducers.resetPasswordSuccess
    ),
    on(actions.resetPasswordFail, authenticationReducers.resetPasswordError),
    on(actions.signUpStep1, authenticationReducers.signUpStep1),
    on(actions.signUpStep1Success, authenticationReducers.signUpStep1Success),
    on(actions.signUpStep1Fail, authenticationReducers.signUpStep1Error),
    on(actions.getAccessSetsSuccess, authenticationReducers.accessSetsSuccess),
    on(actions.getEmailFromHash, authenticationReducers.getEmaillFromHash),
    on(
      actions.getEmailFromHashSuccess,
      authenticationReducers.getEmaillFromHashSuccess
    ),
    on(
      actions.getEmailFromHashFail,
      authenticationReducers.getEmaillFromHashError
    ),
  )(state, action);
}
