import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  @Input() report;
  @Input() currency_type;
  @Input() currency_symbol;

  constructor() { }

  ngOnInit() {}

  percent(estimated, current) {
    if (estimated === 0 && current > 0) {
      return 100;
    } else if (estimated === 0) {
      return 0;
    } else {
      return (current / estimated) * 100;
    }
  }
}
