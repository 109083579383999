import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BriefService} from '../../../../../../services/brief.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-modal-edit-influencer-email',
  templateUrl: './modal-edit-influencer-email.component.html',
  styleUrls: ['./modal-edit-influencer-email.component.scss']
})
export class ModalEditInfluencerEmailComponent implements OnInit {

  influencerEmail: FormGroup;

  constructor(private _briefService: BriefService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<ModalEditInfluencerEmailComponent>,
              ) { }


  ngOnInit() {
    this.influencerEmail =  new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(
        "^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$"
      )
      ])
    });
    if (this.data.email !== '' ) {
      this.influencerEmail.controls['email'].setValue(this.data.email);
    }
  }


  editInfluencerEmail() {
    this._briefService.updateInfluencerEmail(this.data.campaignId, this.data.influencerId, this.influencerEmail.controls['email'].value).subscribe( response => {
      this.openSnackBar('Email updated successfully', 'Close');
      this.dialogRef.close({status: 'success', invitation_mail: this.influencerEmail.controls['email'].value });
    }, error => {
      this.openSnackBar('Oops something went wrong, please try again', 'Close');
    });
  }


  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
