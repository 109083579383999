import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {CampaignsService} from '../../../../services/campaigns.service';
import {Store} from '@ngrx/store';
import {State} from '../../../../store';
import * as selectors from '../../../../store/auth/authentication.selectors';

@Component({
  selector: 'app-detail-campaign',
  templateUrl: './detail-campaign.component.html',
  styleUrls: ['./detail-campaign.component.scss'],
})
export class DetailCampaignComponent implements OnInit, OnDestroy {

  campaign: [];
  loading: boolean;
  campaign_id: string;
  CAN_UNIQUE_AUDIENCE: [];

  private subscriptions = [];

  constructor(
    private _campaignsService: CampaignsService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public store: Store<State>,
) {
    this.campaign_id = this.activatedRoute.params['value'].id;
  }

  ngOnInit() {
    this.subscriptions.push(
        this.store.select(selectors.selectAccessSets).subscribe(access => {
          this.CAN_UNIQUE_AUDIENCE = access.CAN_UNIQUE_AUDIENCE;
        }),
    );
    this.getCampaign();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getCampaign() {
    this.loading = true;
    this.subscriptions.push(
        this._campaignsService.getCampaign(this.campaign_id).subscribe(
            resp => {
                this.campaign = resp['body'];
                this.loading = false;
            },
            error => {
                if (
                    error.error.statusCode === 404 ||
                    error.status === 400 ||
                    error.status === 500
                ) {
                    this.router.navigate(['/app/campaigns']);
                }
                this.loading = false;
            })
    );

  }

}
