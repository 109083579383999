import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BriefService} from '../../../../../../../../services/brief.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-modal-add-link-influencer',
  templateUrl: './modal-add-link-influencer.component.html',
  styleUrls: ['./modal-add-link-influencer.component.scss']
})
export class ModalAddLinkInfluencerComponent implements OnInit {

  errorIndividualLink = true;
  errorMessage = false;
  influencerLinkForm: FormGroup;

  constructor(private _briefService: BriefService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<ModalAddLinkInfluencerComponent>,
  ) { }

  ngOnInit() {
    this.influencerLinkForm =  new FormGroup({
      link: new FormControl('', [Validators.required
      ])
    });
    if (this.data.link !== '' ) {
      this.influencerLinkForm.controls['link'].setValue(this.data.link);
    }
  }


  addInfluencerLink() {
    const dict_attribute = {};
    dict_attribute['influencers'] = [{
      correction_index: this.data.correction_index,
      custom_destination_url: this.influencerLinkForm.controls['link'].value,
      description: this.data.description,
      influencer_id: this.data.influencer_id,
    }];
    this._briefService.updateBrief(this.data.campaign_id, dict_attribute).subscribe( response => {
      this.openSnackBar('Link save successfully', 'Close');
      this.dialogRef.close({status: 'success', link: this.influencerLinkForm.controls['link'].value });
      this.data.custom_destination_url = this.influencerLinkForm.controls['link'].value;
      this.errorIndividualLink = false;
      this.errorMessage = false;
    }, error => {
      this.openSnackBar('Oops something went wrong, please try again', 'Close');
    });
  }


  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  getIndividualDestinationUrl() {
    const url = this.influencerLinkForm.controls['link'].value;
    if (url === '') {
      this.errorIndividualLink = false;
      this.errorMessage = false;
    } else {
      const reg = new RegExp('^(http|https)://');
      const urls = url.split(' ');
      let countLinksOk = 0;
      for (let index = 0; index < urls.length; index++) {
        if (reg.test(urls[index])) {
          countLinksOk += 1;
        }
      }
      this.errorIndividualLink = countLinksOk !== urls.length;
      if (this.errorIndividualLink) {
        this.errorMessage = true;
      }
    }
  }

}
