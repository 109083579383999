import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-summary-campaign',
  templateUrl: './summary-campaign.component.html',
  styleUrls: ['./summary-campaign.component.scss']
})
export class SummaryCampaignComponent implements OnInit {

  @Input() summary;

  constructor() { }

  ngOnInit() {
  }

  percent(value, total) {
    if (total === 0 && value > 0) {
      return 100;
    } else if (total === 0) {
      return 0;
    } else {
      return Math.round((100 * value) / total);
    }
  }
}
