import {Pipe, PipeTransform} from '@angular/core';

// This pipe cuts the string when it exceeds teen characters
@Pipe({
  name: 'cutString'
})
export class CutStringPipe implements PipeTransform {

  transform(value: string, args: string[]): string {
    if ( value === null || value === undefined ) {
      return '';
    } else {
      const LIMIT = args.length > 0 ? parseInt(args[0], 10) : 10;
      const TRAIL = args.length > 1 ? args[1] : '...';

      return value.length > LIMIT ? value.substring(0, LIMIT) + TRAIL : value;
    }

  }

}
