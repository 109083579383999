import {NgModule} from '@angular/core';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {UserAttributesComponent} from './components/customer-settings/components/user-attributes/user-attributes.component';
import {UserConfigComponent} from './components/customer-settings/components/user-config/user-config.component';
import {UsersInfoComponent} from './components/customer-settings/components/users-info/users-info.component';
import {DashboardCrawlsComponent} from './components/dashboard/components/dashboard-crawls/dashboard-crawls.component';
import {DashboardCrawlChartComponent} from './components/dashboard/components/dashboard-crawls/charts/dashboard-crawl-chart/dashboard-crawl-chart.component';
import {ManageCampaignComponent} from './components/manage-campaign/manage-campaign.component';
import {DashboardSocialLoginComponent} from './components/dashboard/components/dashboard-social-login/dashboard-social-login.component';
import {DashboardActiveCampaignsComponent} from './components/dashboard/components/dashboard-active-campaigns/dashboard-active-campaigns.component';
import {DashboardLatestCampaignsComponent} from './components/dashboard/components/dashboard-latest-campaigns/dashboard-latest-campaigns.component';
import {DashboardLambdaErrorsComponent} from './components/dashboard/components/dashboard-lambda-errors/dashboard-lambda-errors.component';
import {AdminManagementComponent} from './components/admin-management.component';
import {SummaryCampaignComponent} from './components/manage-campaign/components/summary-campaign/summary-campaign.component';
import {CampaignInfoComponent} from './components/manage-campaign/components/campaign-info/campaign-info.component';
import {InfluencersCampaignInfoComponent} from './components/manage-campaign/components/influencers-campaign-info/influencers-campaign-info.component';
import {AdminManagementRoutingModule} from './adminManagement-routing.module';
import {SharedModule} from '../shared/shared.module';
import {AngularMaterialModule} from '../angular-material.module';
import {CustomerSettingsComponent} from './components/customer-settings/customer-settings.component';
import {UserCampaignsComponent} from './components/customer-settings/components/user-campaigns/user-campaigns.component';
import {UserChartsActivityComponent} from './components/customer-settings/components/user-charts-activity/user-charts-activity.component';
import {ChartUserFullCrawlComponent} from './components/customer-settings/components/user-charts-activity/charts/chart-user-full-crawl/chart-user-full-crawl.component';
import {ChartUserSearchsComponent} from './components/customer-settings/components/user-charts-activity/charts/chart-user-searchs/chart-user-searchs.component';
import {CompleteUserConfigComponent} from './components/customer-settings/modals/complete-user-config/complete-user-config.component';
import {CompleteUserAttributesComponent} from './components/customer-settings/modals/complete-user-attributes/complete-user-attributes.component';
import {PrivateModule} from '../private/private.module';
import {ListReportDashboardsComponent} from './components/list-report-dashboards/list-report-dashboards.component';
import {ModalNewReportDashboardComponent} from './components/list-report-dashboards/modals/modal-new-report-dashboard/modal-new-report-dashboard.component';
import {ReportDashboardComponent} from './components/list-report-dashboards/components/report-dashboard/report-dashboard.component';
import {ModalAddCampaignToDashboardComponent} from './components/list-report-dashboards/modals/modal-add-campaign-to-dashboard/modal-add-campaign-to-dashboard.component';
import {ModalDashboardGenerateLinkSharedComponent} from './components/list-report-dashboards/modals/modal-dashboard-generate-link-shared/modal-dashboard-generate-link-shared.component';
import {ModalConfirmRemoveItemComponent} from './components/list-report-dashboards/modals/modal-confirm-remove-item/modal-confirm-remove-item.component';
import {ModalEditDashboardNameComponent} from './components/list-report-dashboards/modals/modal-edit-dashboard-name/modal-edit-dashboard-name.component';
import {CampaignsReportDashboardComponent} from './components/list-report-dashboards/components/campaigns-report-dashboard/campaigns-report-dashboard.component';
import {ModalInvitationInfluencersWarningComponent} from '../../components/private/campaigns/brief-campaign/modals/modal-invitation-influencers-warning/modal-invitation-influencers-warning.component';
import {TransferAdminModalComponent} from './modals/transfer-admin-modal/transfer-admin-modal.component';
import {UserGroupsComponent} from './components/user-groups/user-groups.component';


const COMPONENTS = [
    AdminManagementComponent,
    UserAttributesComponent,
    UserConfigComponent,
    UsersInfoComponent,
    DashboardComponent,
    DashboardCrawlsComponent,
    DashboardCrawlChartComponent,
    ManageCampaignComponent,
    DashboardSocialLoginComponent,
    DashboardActiveCampaignsComponent,
    DashboardLatestCampaignsComponent,
    DashboardLambdaErrorsComponent,
    SummaryCampaignComponent,
    CampaignInfoComponent,
    InfluencersCampaignInfoComponent,
    CustomerSettingsComponent,
    UserCampaignsComponent,
    UserChartsActivityComponent,
    ChartUserFullCrawlComponent,
    ChartUserSearchsComponent,
    ListReportDashboardsComponent,
    ReportDashboardComponent,
    CampaignsReportDashboardComponent,
];

const ENTRY_COMPONENTS = [
    CompleteUserConfigComponent,
    CompleteUserAttributesComponent,
    ModalNewReportDashboardComponent,
    ModalAddCampaignToDashboardComponent,
    ModalDashboardGenerateLinkSharedComponent,
    ModalConfirmRemoveItemComponent,
    ModalEditDashboardNameComponent,
    ModalInvitationInfluencersWarningComponent,
    TransferAdminModalComponent,
];
@NgModule({
    declarations: [
        ...COMPONENTS,
        ...ENTRY_COMPONENTS,
        UserGroupsComponent,
    ],
    imports: [
        SharedModule,
        PrivateModule,
        AdminManagementRoutingModule,
        AngularMaterialModule,
    ],
    exports: [
        CampaignsReportDashboardComponent
    ],
    entryComponents: [
        ...ENTRY_COMPONENTS,
    ]
})
export class AdminManagementModule { }
