import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hitPipe'
})
export class HitPipePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value <= 100) {
      return value;
    } else if (value > 100 && value <= 250) {
      return '+100';
    } else if (value > 250 && value <= 500) {
      return '+250';
    } else if (value > 500 && value <= 1000) {
      return '+500';
    } else if (value > 1000 && value <= 2500) {
      return '+1 K';
    } else if (value > 2500 && value <= 5000) {
      return '+2.5 K';
    } else if (value > 5000 && value <= 10000) {
      return '+5 K';
    } else if (value > 10000 && value <= 25000) {
      return '+10 K';
    } else if (value > 25000 && value <= 50000) {
      return '+25 K';
    } else if (value > 50000 && value <= 100000) {
      return '+50 K';
    } else if (value > 100000 && value <= 250000) {
      return '+100 K';
    } else if (value > 250000 && value <= 500000) {
      return '+250 K';
    } else if (value > 500000 && value <= 1000000) {
      return '+500 K';
    } else if (value > 1000000 && value <= 2500000) {
      return '+1 M';
    } else if (value > 2500000 && value <= 5000000) {
      return '+2.5 M';
    } else if (value > 5000000 && value <= 10000000) {
      return '+5 M';
    } else if (value > 10000000 && value <= 25000000) {
      return '+10 M';
    } else if (value > 25000000 && value <= 50000000) {
      return '+25 M';
    } else if (value > 50000000 && value <= 100000000) {
      return '+50 M';
    } else if (value > 100000000) {
      return '+100 M';
    }
  }

}
