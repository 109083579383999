import { Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ReportService} from '../../../../../../../services/report.service';
import {environment} from '../../../../../../../../environments/environment';
import {AmplifyService} from 'aws-amplify-angular';
import * as uuid from 'uuid';

@Component({
  selector: 'app-form-upload-story-manually',
  templateUrl: './form-upload-story-manually.component.html',
  styleUrls: ['./form-upload-story-manually.component.scss']
})
export class FormUploadStoryManuallyComponent implements OnDestroy, OnInit {

  story_url_image = '';
  story_thumbnail_image = '';
  story_id = '';

  loading = false;
  isVideo = false;

  private subscriptions = [];

  formUploadStory: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _reportService: ReportService,
    private snackBar: MatSnackBar,
    private _amplifyService: AmplifyService,
    public dialogRef: MatDialogRef<FormUploadStoryManuallyComponent>
  ) {

    this.formUploadStory = new FormGroup({
      'is_video': new FormControl('', [Validators.required]),
      'taken_at_timestamp': new FormControl('', [Validators.required]),
      'hashtags': new FormControl(''),
      'mentions': new FormControl(''),
      'impressions': new FormControl(''),
      'video_duration': new FormControl(''),
    });
  }

  ngOnInit() {
    this.story_id = uuid.v4();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  uploadStory() {
    this.loading  = true;
    const formStoryData = {
      is_video: this.formUploadStory.controls['is_video'].value,
      story_id: this.story_id,
      story_thumbnail: this.story_thumbnail_image,
      story_url: this.story_url_image,
      taken_at_timestamp: this.formUploadStory.controls['taken_at_timestamp'].value.getTime() / 1000,
      hashtags: this.formUploadStory.controls['hashtags'].value,
      mentions: this.formUploadStory.controls['mentions'].value,
      impressions: this.formUploadStory.controls['impressions'].value,
      video_duration: this.formUploadStory.controls['video_duration'].value,
    };
    this.subscriptions.push(
      this._reportService.uploadStoryManuallyForm(this.data.campaignId, this.data.influencerId, formStoryData).subscribe(() => {
      this.loading  = false;
      this.openSnackBar('Story uploaded successfully', 'Close');
      this.dialogRef.close();
      }, () => {
        this.loading  = false;
        this.openSnackBar('Oops, something went wrong, please try again', 'Close');
      })
    );
  }

  uploadStoryUrlToS3(fileInput: any, story_type): void {
    let url = '';
    const file = fileInput.target.files[0];
    const extension = this.getExtensionFromImage(file.type);
    const config = {
      bucket: environment.aws_resources.s3.buckets.stories,
      region: environment.aws_resources.region,
      level: 'public',
      customPrefix: {
        public: ''
      },
    };
    this._amplifyService.storage().vault.put(this.data.campaignId + '/' + this.data.influencerId  + '__' + this.story_id + extension, file, config).then(() => {
        // Si la subida ha ido bien, entonces guardo la ruta.
        if ( story_type === 'story_url' ) {
          this.story_url_image = 'https://' + config.bucket + '.s3-eu-west-1.amazonaws.com/' + this.data.campaignId + '/' + this.data.influencerId + '__' + this.story_id + extension;
          url = this.story_url_image;
        } else if ( story_type === 'story_thumbnail' ) {
          this.story_thumbnail_image = 'https://' + config.bucket + '.s3-eu-west-1.amazonaws.com/' + this.data.campaignId + '/' + this.data.influencerId + '__' + this.story_id + extension;
          url = this.story_thumbnail_image;
        }
      });
  }

  getExtensionFromImage(file_type: string): string {
    return file_type.replace('image/', '.');
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  changeIsVideo(state) {
    this.isVideo = state;
  }
}
