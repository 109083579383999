import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor401Service } from './401-interceptor.service';
import { Interceptor402Service } from './402-interceptor.service';
import { Interceptor403Service } from 'src/app/interceptor/403-interceptor.service';
import { Interceptor404Service } from './404-interceptor.service';
import { PaxInterceptorService } from './pax-interceptor.service';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: PaxInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: Interceptor403Service,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: Interceptor401Service,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: Interceptor402Service,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: Interceptor404Service,
    multi: true,
  },
];
