import {Component, Input, OnInit} from '@angular/core';
// @ts-ignore
import {Label, MultiDataSet} from 'ng2-charts';
import {ChartType} from 'chart.js';
import {ThemeService} from '../../../../../services/theme.service';

@Component({
  selector: 'app-doughnut-value-empty',
  templateUrl: './doughnut-value-empty.component.html',
  styleUrls: ['./doughnut-value-empty.component.scss']
})
export class DoughnutValueEmptyComponent implements OnInit {
  @Input() audience_limit: number;
  @Input() consumed_audience: number;

  public doughnutChartLabels: Label[] = [];

  public doughnutChartData: MultiDataSet = [[]];

  doughnutChartOptions = {
    legend: {
      display: false,
    },
    responsive: true,
    tooltips: {
      enabled: false,
    },
    hover: {mode: null},
  };

  private donutColors = [
    {
      backgroundColor: [
        // 'rgba(13, 101, 242, 0.8)',
          '',
        'rgba(169, 173, 179, 0.8)',
      ]
    }
  ];

  public doughnutChartType: ChartType = 'doughnut';

  theme = {
    primary: ''
  };

  constructor(themeService: ThemeService
  ) {
    this.theme = themeService.getTheme();
  }

  ngOnInit() {
    this.doughnutChartLabels.push('Searches');
    this.doughnutChartLabels.push('');
    this.doughnutChartData[0].push((this.consumed_audience * 100) / this.audience_limit);
    this.doughnutChartData[0].push(100 - ((this.consumed_audience * 100) / this.audience_limit));
    if ((this.consumed_audience * 100) / this.audience_limit >= 100) {
      this.donutColors[0].backgroundColor[0] = 'rgb(253, 0, 0)';
    } else {
      this.donutColors[0].backgroundColor[0] = this.theme.primary;
    }
  }

}
