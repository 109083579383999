import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-modal-thanks-page',
  templateUrl: './modal-thanks-page.component.html',
  styleUrls: ['./modal-thanks-page.component.scss']
})
export class ModalThanksPageComponent implements OnInit {

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {}

}
