import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {FavouritesService} from '../../../../../../services/favourites.service';
import {Subscription} from 'rxjs';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-modal-remove-favourites-list',
  templateUrl: './modal-remove-favourites-list.component.html',
  styleUrls: ['./modal-remove-favourites-list.component.scss']
})
export class ModalRemoveFavouritesListComponent implements OnInit, OnDestroy {

  deleting_favourite = false;
  checkbox_form: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(private _favouriteService: FavouritesService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ModalRemoveFavouritesListComponent>,
              public snackBar: MatSnackBar,
              private translate: TranslateService,
            ) {
    this.checkbox_form = new FormGroup({
      'checkout_checkbox': new FormControl(false, [Validators.requiredTrue])
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  deleteFavourite() {
    this.deleting_favourite = true;
    const onSuccess = () => {
        this.dialogRef.close('success');
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: this.translate.instant('customSnackbar.favouritesHasBeenDeleted'), type: 'success' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'success-snackbar',
        });
    };

    const onError = () => {
        this.dialogRef.close();
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: this.translate.instant('customSnackbar.thereHasBeenError'), type: 'error' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'error-snackbar',
        });
    };

    if (this.data.network) {
        this.subscriptions.push(
            this._favouriteService.removeInfluencerFromFavouritesList(this.data.favourites_id, this.data.network, this.data.influencer_id).subscribe(onSuccess, onError)
        );
    } else {
        this.subscriptions.push(
            this._favouriteService.removeFavouritesList(this.data.favourites_id).subscribe(onSuccess, onError)
        );
    }
  }

}
