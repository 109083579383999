import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CampaignsService} from '../../../../../../services/campaigns.service';
import {MatDialog} from '@angular/material/dialog';
import {CampaignPlanShareModalComponent} from '../../modals/campaign-plan-share-modal/campaign-plan-share-modal.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material';
import {environment} from '../../../../../../../environments/environment';
import {AmplifyService} from 'aws-amplify-angular';
import {Subscription} from 'rxjs';
import {CustomSnackbarComponent} from '../../../../../../features/shared/components/custom-snackbar/custom-snackbar.component';
import {openPlansDialog} from '../../../../../../store/app/app.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../store';
import { TranslateService } from '@ngx-translate/core';
import { CreatePresentationModalComponent } from 'src/app/features/create-presentation-modal/create-presentation-modal/create-presentation-modal.component';
import { UnlockCreatePresentationModalComponent } from 'src/app/features/create-presentation-modal/unlock-create-presentation-modal/unlock-create-presentation-modal.component';

@Component({
  selector: 'app-campaign-plan-general-info',
  templateUrl: './campaign-plan-general-info.component.html',
  styleUrls: ['./campaign-plan-general-info.component.scss']
})
export class CampaignPlanGeneralInfoComponent implements OnInit, OnDestroy {

  @Input() campaign_id;
  @Input() campaign;
  @Input() canCreatePresentation;
  @Input() campaign_name;
  @Input() total_cost;
  @Input() brand_logo;
  @Input() is_share;
  @Input() currency_code;
  @Input() currency_ratio;
  @Input() currency_symbol;
  @Input() planNameShown;
  @Input() total_cost_per_mille;
  @Input() instagramPostsTotal;
  @Input() instagramReelsTotal;
  @Input() instagramStoriesTotal;
  @Input() tiktokVideosTotal;
  @Input() youtubeVideosTotal;
  @Input() youtubeMentionsTotal;
  @Input() totalInfluencers;

  @Input() avg_engagement_ig;
  @Input() avg_engagement_yt;
  @Input() avg_engagement_tiktok;

  @Input() totalInstagramers;
  @Input() totalYoutubers;
  @Input() totalTiktokers;
  @Input() additional_margin;
  @Input() campaign_total_cost_share;
  @Input() custom_total_estimated_impressions;
  @Input() total_influencers_impressions;
  @Input() total_followers;
  @Input() total_estimated_likes_campaign;
  @Input() influencers;
  @Input() total_comments_campaign;
  @Input() earn_media_value;
  @Input() total_est_reach;
  @Input() audience_overlap;
  @Input() inactive_followers_percentage;
  @Input() CAN_UNIQUE_AUDIENCE;

  @Output() callGetCampaign = new EventEmitter<any>();
  @Output() callGetDataCampaignRealtime = new EventEmitter<any>();
  @Output() callUpdateUniqueAudience = new EventEmitter<any>();

  showEditCampaignName = false;
  updatingCampaignName = false;
  updatingTotalImpressions = false;
  uploading_brand_logo = false;
  showEffect = false;
  deployedUniqueAudience = false;
  deployTotalInfluencers = false;
  showInputEditImpressions = false;


  error_brand_logo_upload = '';
  uniqueAudienceSelected;
  networkUniqueAudienceSelected = 'instagram';

  campaignNameForm: FormGroup;
  impressionsForm: FormGroup;

  presentation_data = {};

  private subscriptions: Subscription[] = [];

  constructor(private _campaignsService: CampaignsService,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private _amplifyService: AmplifyService,
              private store: Store<State>,
              private translate: TranslateService,
  ) {

    this.campaignNameForm = new FormGroup({
      'campaign_name': new FormControl(this.campaign_name, [
        Validators.required,
        Validators.maxLength(25)
      ])
    });
    this.impressionsForm = new FormGroup({
      'totalEstimatedImpressions': new FormControl(),
    });
  }

  ngOnInit() {
    if (this.audience_overlap) {
      if (this.audience_overlap['instagram']) {
        if (this.audience_overlap['instagram'].total_unique_percentage) {
          this.uniqueAudienceSelected = this.audience_overlap['instagram'].total_unique_percentage;
        }
      } else {
        if (this.audience_overlap['youtube'].total_unique_percentage) {
          this.uniqueAudienceSelected = this.audience_overlap['youtube'].total_unique_percentage;
        }
      }
    }
    this.campaignNameForm.controls.campaign_name.setValue(this.campaign_name);
    this.impressionsForm.controls.totalEstimatedImpressions.setValue(this.custom_total_estimated_impressions);
    this.presentation_data = {
      reach_summary: this.total_est_reach,
      followers_summary: this.total_followers,
      audience_quality_summary: this.inactive_followers_percentage,
      likes_summary: this.total_estimated_likes_campaign,
      comments_summary: this.total_comments_campaign,
      emv_summary: this.earn_media_value,
      influencers_count: this.totalInfluencers,
      estimated_cost: this.total_cost,
      estimated_impressions: this.total_influencers_impressions,
      estimated_cost_per_mille: this.total_cost_per_mille,
      total_est_reach: this.total_est_reach,
      avg_engagement_ig: this.avg_engagement_ig,
      avg_engagement_yt: this.avg_engagement_yt,
      avg_engagement_tiktok: this.avg_engagement_tiktok,
      total_unique_audience: this.audience_overlap,
      total_influencers: this.totalInfluencers,
      total_instagramers: this.totalInstagramers,
      total_youtubers: this.totalYoutubers,
      total_tiktokers: this.totalTiktokers,
      total_posts: this.instagramPostsTotal,
      total_stories: this.instagramStoriesTotal,
      total_youtube_mentions: this.youtubeMentionsTotal,
      total_youtube_videos: this.youtubeVideosTotal,
      total_tiktok_videos: this.tiktokVideosTotal,
    };
    this.callGetDataCampaignRealtime.emit(this.presentation_data)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  changeTotalEstimatedImpressions() {
    this.updatingTotalImpressions = true;
    const totalEstimatedImpressions = this.impressionsForm.controls['totalEstimatedImpressions'].value;

    this.subscriptions.push(
        this._campaignsService.updateTotalEstimatedImpressions(this.campaign_id, totalEstimatedImpressions)
            .subscribe(
                () => {
                  this.handleSuccessImpressions(totalEstimatedImpressions);
                },
                () => {
                  this.handleErrorImpressions();
                }
            )
    );
  }

  resetCustomTotalImpressions() {
    this.subscriptions.push(
      this._campaignsService.updateTotalEstimatedImpressions(this.campaign_id, 0)
          .subscribe(
              () => {
                this.handleSuccessImpressions(0);
              },
              () => {
                this.handleErrorImpressions();
              }
          )
        );
  }

  private handleSuccessImpressions(totalEstimatedImpressions: number) {
    this.custom_total_estimated_impressions = Number(totalEstimatedImpressions);
    this.showInputEditImpressions = false;
    this.updatingTotalImpressions = false;
    this.callGetCampaign.emit();

    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message: this.translate.instant('shareCampaignPlanModal.changesSavedSuccessfully'), type: 'success' },
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success-snackbar',
    });
  }

  private handleErrorImpressions() {
    this.showInputEditImpressions = false;
    this.updatingTotalImpressions = false;
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message: this.translate.instant('shareCampaignPlanModal.oopsError'), type: 'error' },
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'error-snackbar',
    });
  }


  validateEntryPercentage(event: KeyboardEvent): boolean {
    return /^\d+$/.test(String.fromCharCode(event.charCode)) || event.charCode === 0 || event.charCode === 8;
  }

  openCampaignPlanShareModal(): void {
    const dialogRef = this.dialog.open(CampaignPlanShareModalComponent, {
      width: '600px',
      height: 'auto',
      panelClass: 'remove-padding',
      autoFocus: false,
      data: {
        campaign_id: this.campaign_id,
        total_cost: this.total_cost,
        total_cost_per_mille: this.total_cost_per_mille,
        currency_code: this.currency_code,
        currency_symbol: this.currency_symbol,
        planNameShown: this.planNameShown,
        totalInstagramers: this.totalInstagramers,
        totalYoutubers: this.totalYoutubers,
        totalTiktokers: this.totalTiktokers,
        instagramPostsTotal: this.instagramPostsTotal,
        instagramStoriesTotal: this.instagramStoriesTotal,
        tiktokVideosTotal: this.tiktokVideosTotal,
        youtubeVideosTotal: this.youtubeVideosTotal,
        youtubeMentionsTotal: this.youtubeMentionsTotal,
        campaign_total_cost_share: this.campaign_total_cost_share,
        custom_total_estimated_impressions: this.custom_total_estimated_impressions,
        additional_margin: this.additional_margin - 1,
        totalInfluencers: this.totalInfluencers,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if ('additional_margin' in result) {
        this.campaign_total_cost_share = result.campaign_total_cost_share;
        this.additional_margin = result.additional_margin;
      }
    });
  }


  openCreatePresentation() {
    if (this.canCreatePresentation) {
      const dialogRef = this.dialog.open(CreatePresentationModalComponent, {
        width: '904px',
        height: '714px',
        panelClass: 'remove-padding',
        autoFocus: false,
        data: {
          campaign_id: this.campaign_id,
          influencers: this.influencers,
          campaign: this.campaign,
          presentation_data: this.presentation_data
        }
      });
      dialogRef.afterClosed().subscribe(result => {
  
      }); 
    } else {
      const dialogRef = this.dialog.open(UnlockCreatePresentationModalComponent, {
        width: '397px',
        height: '381px',
        panelClass: 'remove-padding',
        autoFocus: false,
        data: {
        }
      });
      dialogRef.afterClosed().subscribe(result => {
  
      });
    }
  }

  updateCampaignName() {
    this.updatingCampaignName = true;
    if (this.campaignNameForm.controls.campaign_name.value == null) {
      this.campaignNameForm.controls.campaign_name.setValue(this.campaign_name);
    }
    this.subscriptions.push(
        this._campaignsService.updateCampaignName(this.campaign_id, this.campaignNameForm.controls.campaign_name.value).subscribe( () => {
          this.campaign_name = this.campaignNameForm.controls.campaign_name.value;
          this.showEditCampaignName = false;
          this.updatingCampaignName = false;
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: 'Campaign name changed successfully.', type: 'success' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'success-snackbar',
          });
        }, () => {
          this.showEditCampaignName = false;
          this.updatingCampaignName = false;
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: 'Oops something went wrong, please try again.', type: 'error' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'error-snackbar',
          });
        })
    );
  }

  openSnackbar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  getExtensionFromImage(file_type: string): string {
    return file_type.replace('image/', '.');
  }

  getUrlWithTimestamp(url: string): string {
    return url !== 'n/a' ? url + '?ts=' + new Date().getTime() : url;
  }

  isImageTypeValid(file: File): boolean {
    return ['image/png', 'image/jpeg', 'image/jpg'].includes(file.type);
  }

  uploadToS3(file: File, extension: string): Promise<string> {
    const config = {
      bucket: environment.aws_resources.s3.buckets.company_logo,
      region: environment.aws_resources.region,
      level: 'public',
      customPrefix: {
        public: '',
      },
    };

    return this._amplifyService.storage().vault.put(this.campaign_id + extension, file, config)
        .then(() => 'https://s3-eu-west-1.amazonaws.com/' + config.bucket + '/' + this.campaign_id + extension);
  }

  updateCampaignLogo(url: string): void {
    const body = { brand_logo: url };

    this.subscriptions.push(
        this._campaignsService.updateCampaignLogo(this.campaign_id, body).subscribe(
            () => {
              this.brand_logo = this.getUrlWithTimestamp(body.brand_logo);
              this.uploading_brand_logo = false;
            },
            () => {
              this.error_brand_logo_upload = 'Oops, something went wrong. Please try again later.';
              this.uploading_brand_logo = false;
            }
        )
    );

  }

  uploadBrandLogo(fileInput: any): void {
    this.error_brand_logo_upload = '';
    this.uploading_brand_logo = true;

    const file = fileInput.target.files[0];

    if (file.size <= 5242880) {
      if (this.isImageTypeValid(file)) {
        const extension = this.getExtensionFromImage(file.type);

        this.uploadToS3(file, extension)
            .then(url => this.updateCampaignLogo(url))
            .catch(() => {
              this.error_brand_logo_upload = 'Oops, something went wrong. Please try again later.';
              this.uploading_brand_logo = false;
            });
      } else {
        this.error_brand_logo_upload = 'Unsupported file type. Supported file types are: jpg, jpeg, and png';
        this.uploading_brand_logo = false;
      }
    } else {
      this.error_brand_logo_upload = 'The file exceeds 5MB';
      this.uploading_brand_logo = false;
    }
  }

  unlockUniqueAudience() {
    if ( environment.platform_id === '11posts' ) {
      this.store.dispatch(openPlansDialog());
    }
  }

  updateUniqueAudience() {
    this.callUpdateUniqueAudience.emit();
  }

}
