import { environment } from './environments/environment';

export let amplify = {
  Auth: {
    identityPoolId: environment.aws_resources.cognito.identity_pool_id,
    region: environment.aws_resources.region,
    identityPoolRegion: environment.aws_resources.region,
    userPoolId: environment.aws_resources.cognito.user_pool_id,
    userPoolWebClientId: environment.aws_resources.cognito.client_id,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
  API: {
    endpoints: [
      {
        name: "influencer",
        endpoint: environment.aws_resources.lambda_endpoint.api,
        region: environment.aws_resources.region
      }
    ]
  }
};
