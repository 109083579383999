import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-confirmed-pause-cancel-plan',
  templateUrl: './modal-confirmed-pause-cancel-plan.component.html',
  styleUrls: ['./modal-confirmed-pause-cancel-plan.component.scss']
})
export class ModalConfirmedPauseCancelPlanComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }
}
