import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {UserChangePasswordClass} from '../../../classes/user-change-password-class';
import {UserChangePassword} from '../../../interfaces/user-change-password-interface';
import {AmplifyService} from 'aws-amplify-angular';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  /* Elements from the form */
  change_password_form: FormGroup;

  /* Elements from the Class */
  new_password;
  confirm_password;
  user: UserChangePassword;

  /* Errors, when ocurrent saving changes. */
  change_password__error_response = false;
  change_password__error_response_message: string = '';

  /* Sucess, when ocurrent saving changes. */
  change_password__success_response = false;
  change_password__success_response_message: string = '';


  constructor(public router: Router,
              private _amplifyService: AmplifyService) {
    this.change_password_form = new FormGroup({
      'old_password': new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)([A-Za-z]|[^ ]){8,}$')]),
      'new_password': new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)([A-Za-z]|[^ ]){8,}$')]),
      'confirm_password': new FormControl(''),
    });
    this.change_password_form.controls['new_password'].setValidators([Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)([A-Za-z]|[^ ]){8,}$'), this.checkPasswordNewPassword.bind(this)]);
    this.change_password_form.controls['confirm_password'].setValidators([Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)([A-Za-z]|[^ ]){8,}$'), this.checkPassword.bind(this)]);
    // Create the Class
    this.user = new UserChangePasswordClass();
  }


  ngOnInit() {
  }

  /**
   * For check the password
   * @param {FormControl} control
   * @returns {any}
   */
  checkPassword(control: FormControl) {
    if (control.value !== this.new_password) {
      return {
        no_equal: true
      };
    }
    return null;
  }

  /**
   * For validate if the passwords are equals
   * @param {FormControl} control
   * @returns {null}
   */
  checkPasswordNewPassword(control: FormControl) {
    if (control.value !== this.confirm_password) {
      this.change_password_form.get('confirm_password').setErrors({
        no_equal: true
      });
    } else if (control.value === this.confirm_password) {
      this.change_password_form.get('confirm_password').setErrors(null);
    }
    return null;
  }

  changePassword(){
    this._amplifyService.auth().currentAuthenticatedUser().then(user => {
      this._amplifyService.auth().changePassword(user, this.change_password_form.value.old_password, this.change_password_form.value.new_password).then(() => {
        this.change_password__error_response = false;
        this.change_password__success_response = true;
        this.change_password__success_response_message = 'Successful password change';
      }).catch(error => {
        this.change_password__success_response = false;
        this.change_password__error_response = true;
        if (error.message == 'Attempt limit exceeded, please try after some time.') {
          this.change_password__error_response_message = 'Error: you have made many attempts to change your password';
        } else if (error.message == 'Incorrect username or password.') {
          this.change_password__error_response_message = 'Error: your current password is not valid';
        } else {
          this.change_password__error_response_message = 'Error: internal server';
        }
      })
    })
  }

}
