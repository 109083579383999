import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Influencer } from '../../../../../types/influencer/influencer';
import { ModalShareInfluencerGenerateLinkComponent } from '../../../../private/modals/modal-share-influencer-generate-link/modal-share-influencer-generate-link.component';
import { MatDialog } from '@angular/material/dialog';
import { InfluencerProfileService } from '../../../../../services/influencer-profile.service';
import { round } from 'lodash';
import { ModalFavouritesListComponent } from '../../../../private/components/favourites/modals/modal-favourites-list/modal-favourites-list.component';
import { ModalRefreshInfluencerDataComponent } from '../../../../private/modals/modal-refresh-influencer-data/modal-refresh-influencer-data.component';
import {Store} from '@ngrx/store';
import {State} from '../../../../../store';
import * as selectorsAuth from '../../../../../store/auth/authentication.selectors';

@Component({
    selector: 'app-lateral-profile-presentation',
    templateUrl: './lateral-profile-presentation.component.html',
    styleUrls: ['./lateral-profile-presentation.component.scss']
})
export class LateralProfilePresentationComponent implements OnInit, OnDestroy {

    @Input() influencer: Influencer;
    @Input() inSharePublic: boolean;
    @Input() inCampaign: boolean;

    @Output() add = new EventEmitter();
    @Output() refreshInfluencer = new EventEmitter();


    breakpoint: number;
    pagesCarrouselForShow: number;

    isLoading = true;
    loadingMedias = false;

    medias = [];
    mediasForShow = [];

    urlForImage: string;
    planNameShown = '';

    private subscriptions = [];

    constructor(
        private dialog: MatDialog,
        public _influencerProfileService: InfluencerProfileService,
        private cdr: ChangeDetectorRef,
        private store: Store<State>,
    ) { }

    ngOnInit() {
        this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
            this.planNameShown = user.PLAN_NAME_SHOWN;
          });
        this.getInfluencerMedias();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    hideLoader() {
        this.isLoading = false;
    }


    openModalShareInfluencerGenerateLink() {
        const dialogRef = this.dialog.open(ModalShareInfluencerGenerateLinkComponent, {
            width: '400px',
            height: '300px',
            autoFocus: false,
            data: {
                influencerId: this.influencer.id,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    getInfluencerMedias() {
        this.subscriptions.forEach(s => s.unsubscribe());
        if (this.influencer.avg_comments) {
            if (this.influencer['influencer_hash_id']) {
                this.subscriptions.push(
                    this._influencerProfileService.getInfluencerMediasShareInfluencer(this.influencer['influencer_hash_id']).subscribe(response => {
                        this.medias = response.body.medias;
                        this.loadingMedias = false;
                        this.pagesCarrouselForShow = (round(this.medias.length / 3));
                        this.mediasForShow = this.medias.slice(0, 3);
                        if (this.influencer.type === 'instagram') {
                            this.urlForImage = 'https://www.instagram.com/p/';
                        } else if (this.influencer.type === 'youtube') {
                            this.urlForImage = 'https://www.youtube.com/watch?v=';
                        } else if (this.influencer.type === 'tiktok') {
                            this.urlForImage = 'https://www.tiktok.com/@' + this.influencer.username + '/video/';
                        }
                        this.mediasForShow = this.medias.slice(0, 3);
                        this.cdr.detectChanges();
                    }, () => {
                        this.medias = [];
                        this.mediasForShow = [];
                        this.loadingMedias = false;
                    })
                );
            } else if (this.influencer['shared_campaign_plan_hash']) {
                this.subscriptions.push(
                    this._influencerProfileService.getInfluencerMediasShareCampaign(this.influencer['shared_campaign_plan_hash'], this.influencer.id, this.influencer.type).subscribe(response => {
                        this.medias = response.body.medias;
                        this.loadingMedias = false;
                        this.pagesCarrouselForShow = (round(this.medias.length / 3));
                        this.mediasForShow = this.medias.slice(0, 3);
                        if (this.influencer.type === 'instagram') {
                            this.urlForImage = 'https://www.instagram.com/p/';
                        } else if (this.influencer.type === 'youtube') {
                            this.urlForImage = 'https://www.youtube.com/watch?v=';
                        } else if (this.influencer.type === 'tiktok') {
                            this.urlForImage = 'https://www.tiktok.com/@' + this.influencer.username + '/video/';
                        }
                        this.mediasForShow = this.medias.slice(0, 3);
                        this.cdr.detectChanges();
                    }, () => {
                        this.medias = [];
                        this.mediasForShow = [];
                        this.loadingMedias = false;
                    })
                );
            } else {
                this.loadingMedias = true;
                this.subscriptions.push(
                    this._influencerProfileService.getInfluencerMedias(this.influencer.id, this.influencer.type).subscribe(response => {
                        this.loadingMedias = false;
                        this.medias = response.medias;
                        this.pagesCarrouselForShow = (round(this.medias.length / 3));
                        this.mediasForShow = this.medias.slice(0, 3);
                        if (this.influencer.type === 'instagram') {
                            this.urlForImage = 'https://www.instagram.com/p/';
                        } else if (this.influencer.type === 'youtube') {
                            this.urlForImage = 'https://www.youtube.com/watch?v=';
                        } else if (this.influencer.type === 'tiktok') {
                            this.urlForImage = 'https://www.tiktok.com/@' + this.influencer.username + '/video/';
                        }
                        this.mediasForShow = this.medias.slice(0, 3);
                        this.cdr.detectChanges();
                    }, () => {
                        this.medias = [];
                        this.mediasForShow = [];
                        this.loadingMedias = false;
                        this.cdr.detectChanges();
                    })
                );
            }
        }
    }

    errorHandler(event) {
        event.target.src = '/assets/img/png/user_not_exists.png';
    }

    onResize(event) {
        this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
    }

    openFavouritesList(influencer) {
        this.dialog.open(ModalFavouritesListComponent, {
            width: '40%',
            height: '40%',
            minWidth: '540px',
            minHeight: '300px',
            autoFocus: false,
            data: {
                influencerId: influencer.id,
                network: influencer.type,
                username: influencer.username
            },
        });
    }

    updateInformation() {
        const dialogRef = this.dialog.open(ModalRefreshInfluencerDataComponent, {
            width: '550px',
            height: 'auto',
            autoFocus: false,
            data: {
                fetch_timestamp: this.influencer.fetch_timestamp
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'success') {
                this.refreshInfluencer.emit();
            }
        });
    }
}
