import {Component, Input, OnInit} from '@angular/core';
import {round} from 'lodash';

@Component({
  selector: 'app-sponsored-posts',
  templateUrl: './sponsored-posts.component.html',
  styleUrls: ['./sponsored-posts.component.scss']
})
export class SponsoredPostsComponent implements OnInit {

  @Input() sponsoredPosts;
  @Input() titlePosts;

  sponsoredPostsForShow = [];
  pageSponsoredPosts: number;

  pagesSponsoredForShow: number;

  constructor() { }

  ngOnInit() {
    if (this.sponsoredPosts) {
      this.pageSponsoredPosts = 0;
      this.sponsoredPostsForShow = this.sponsoredPosts.slice(0, 3);
    }
    this.pagesSponsoredForShow = (round(this.sponsoredPosts.length / 3));
    if (this.pagesSponsoredForShow === 0) {
      this.pagesSponsoredForShow = 1;
    }
  }


  changeSponsoredPostsPages(action) {
    if (action === 'fordward') {
      this.pageSponsoredPosts -= 1;
    } else {
      if (action === 'next') {
        this.pageSponsoredPosts += 1;
      }
    }
    if (this.pageSponsoredPosts === 0) {
      this.sponsoredPostsForShow = this.sponsoredPosts.slice(0, 3);
    } else if (this.pageSponsoredPosts === 1) {
      this.sponsoredPostsForShow = this.sponsoredPosts.slice(3, 6);
    }  else if (this.pageSponsoredPosts === 2) {
      this.sponsoredPostsForShow = this.sponsoredPosts.slice(6, 9);
    }
  }
}
