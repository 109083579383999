import {Component, Input, OnInit} from '@angular/core';
import {ViewMoreModalComponent} from '../../../modals/view-more-modal/view-more-modal.component';
import {MatDialog} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-influencer-lookalikes',
  templateUrl: './influencer-lookalikes.component.html',
  styleUrls: ['./influencer-lookalikes.component.scss']
})
export class InfluencerLookalikesComponent implements OnInit {

  @Input() lookalikes;
  @Input() titleLookalikes;
  @Input() network;
  @Input() inSharePublic;
  @Input() disableEvents;


  constructor(
      private dialog: MatDialog,
      private translate: TranslateService
  ) { }

  ngOnInit() {}


  errorHandler(event) {
    event.target.src = '/assets/img/png/default-user.png';
  }

  openViewMoreModal (data, col1, col2, titleModal) {
    if (this.disableEvents) {
      event.preventDefault();
      event.stopPropagation(); // Prevenir la propagación del evento
      return;
    } else {
      const translatedTitleModal = this.translate.instant(titleModal);
      this.dialog.open(ViewMoreModalComponent, {
        width: 'auto',
        autoFocus: false,
        maxHeight: '90vh',
        data: {
          data: data,
          col1: col1,
          col2: col2,
          titleModal: translatedTitleModal,
          network: this.network
        },
      });
    }
  }


  redirectLookalike(params) {
    if (this.disableEvents) {
      event.preventDefault();
      event.stopPropagation(); // Prevenir la propagación del evento
      return;
    } else {
      let url = '';
      if (this.network === 'instagram') {
        url = 'https://www.instagram.com/' + params.username;
      } else if (this.network === 'youtube') {
        url = 'https://www.youtube.com/channel/' + params.id;

      } else if (this.network === 'tiktok') {
        url = 'https://www.tiktok.com/@' + params.username;
      }
      window.open(url, '_blank');
    }
  }

}
