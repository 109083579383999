import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InfluencersService implements OnInit {

    private influencerUrl = environment.aws_resources.lambda_endpoint.api;
    oldParams;

    constructor(private http: HttpClient) {}

    ngOnInit() {}

    public getInfluencers(search_params) {
        let params = new HttpParams();

        for (const key in search_params) {
          if (search_params.hasOwnProperty(key)) {
            if (key !== 'atb') {
              params = params.append(key, search_params[key]);
            }
          }
        }
        this.oldParams = Object.assign({}, params);
        return this.http.get(this.influencerUrl + 'search/influencer-search', {observe: 'response', params}).pipe(map((data: any) => {
            return data;
        }));
    }

    public getYoutubeCountries(category?) {
    return this.http.get(this.influencerUrl + 'search/search-yt_unique_countries', {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  public getTiktokCountries(category?) {
    return this.http.get(this.influencerUrl + 'search/search-tt_unique_countries', {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  public getLocations(network, query) {
      let params = new HttpParams();
      params = params.append('network', network);
      params = params.append('query', query);
      return this.http.get(this.influencerUrl + 'search/location', {observe: 'response', params}).pipe(map((data: any) => {
          return data;
      }));
  }

    public getLanguages(network) {
        let params = new HttpParams();
        params = params.append('network', network);
        return this.http.get(this.influencerUrl + 'search/languages', {observe: 'response', params}).pipe(map((data: any) => {
            return data;
        }));
    }


    public getBrandInterests(network) {
        let params = new HttpParams();
        params = params.append('network', network);
        return this.http.get(this.influencerUrl + 'search/brands_interests', {observe: 'response', params}).pipe(map((data: any) => {
            return data;
        }));
    }

    public getLookALikes(network, query) {
        let params = new HttpParams();
        params = params.append('network', network);
        params = params.append('query', query);
        return this.http.get(this.influencerUrl + 'search/lookalike', {observe: 'response', params}).pipe(map((data: any) => {
            return data;
        }));
    }

    public getKeywords(network, keywords) {
        let params = new HttpParams();
        params = params.append('network', network);
        params = params.append('topic', keywords);
        return this.http.get(this.influencerUrl + 'search/keywords', {observe: 'response', params}).pipe(map((data: any) => {
            return data;
        }));
    }

    public getYoutubeCategories(country?) {
        if (country) {
          let params = new HttpParams();
          params = params.append('country', country);

          return this.http.get(this.influencerUrl + 'search/search-yt_unique_video_categories', {observe: 'response', params}).pipe(map((data: any) => {
            return data;
          }));
        } else {
          return this.http.get(this.influencerUrl + 'search/search-yt_unique_video_categories', {observe: 'response'}).pipe(map((data: any) => {
            return data;
          }));
        }
    }

    public paginateCards( page) {
        let params = new HttpParams();
        for (let i = 0; i < this.oldParams.updates.length; i++) {
            if (this.oldParams.updates[i].param === 'page') {
                this.oldParams.updates[i].value = page;
            }
            params = params.append(this.oldParams.updates[i].param, this.oldParams.updates[i].value);
        }
        return this.http.get(this.influencerUrl + 'search', {observe: 'response', params}).pipe(map((data: any) => {
          return data;
        }));
    }

    public shareInfluencerGenerateLink(influencerId) {
      return this.http.get(this.influencerUrl + 'influencer/' + influencerId + '/share', {observe: 'response'}).pipe(map((data: any) => {
        return data;
      }));
    }

    public getShareInfluencer(influencerId) {
      let params = new HttpParams();
      params = params.append('influencer_shared_id', influencerId);
      return this.http.get(this.influencerUrl + 'influencer/shared_profile', {observe: 'response', params}).pipe(map((data: any) => {
        return data;
      }));
    }

    public getInfluencersTipping(typed_word, network?) {
        let params = new HttpParams();
        params = params.append('typed_word', typed_word);
        params = params.append('network', network);
        return this.http.get(this.influencerUrl + 'influencer/search_typped', {observe: 'response', params}).pipe(map((data: any) => {
            return data;
        }));
    }

    public getTopicsTipping(typed_word, network?) {
        let params = new HttpParams();
        params = params.append('topic', typed_word);
        params = params.append('network', network);
        return this.http.get(this.influencerUrl + 'search/topics', {observe: 'response', params}).pipe(map((data: any) => {
            return data;
        }));
    }

    public sortInfluencersSearch(search_params) {
        let params = new HttpParams();
        for (const key in search_params) {
            if (search_params.hasOwnProperty(key)) {
                if (key !== 'atb') {
                    params = params.append(key, search_params[key]);
                }
            }
        }
        return this.http.get(this.influencerUrl + 'search/influencer-search', {observe: 'response', params}).pipe(map((data: any) => {
            return data;
        }));
    }


    public requestInfluencer(username, network) {
        const body = {
            username: username,
            network: network
        };
        return this.http.post(this.influencerUrl + 'search/analyze-influencer', body, {observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }
}
