import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {ProgressService} from '../../../../../../services/progress.service';

@Component({
  selector: 'app-progress-reject-proposal-modal',
  templateUrl: './progress-reject-proposal-modal.component.html',
  styleUrls: ['./progress-reject-proposal-modal.component.css']
})
export class ProgressRejectProposalModalComponent implements OnInit {
  reason_form: FormGroup;
  loading = false;

  constructor(private _progressService: ProgressService,
              public dialogRef: MatDialogRef<ProgressRejectProposalModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.reason_form = new FormGroup({
      'reason': new FormControl('', [
        Validators.required,
        Validators.maxLength(250)
      ])
    });
  }

  ngOnInit() { }

  rejectProposal(reason) {
    this._progressService.rejectProposal(this.data.hash_progress_id, this.data.proposal.proposal_id, reason).subscribe(result => {
      if (result.status.toString() === '200') {
        this.dialogRef.close(reason);
      } else {
        this.dialogRef.close('error');
      }
    }, error => {
      this.dialogRef.close('error');
    });
  }

}
