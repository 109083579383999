import {NgModule} from '@angular/core';
import {PrivateRoutingModule} from './private-routing.module';
import {InfluencerProfileComponent} from 'src/app/components/private/influencer-profile/influencer-profile.component';
import {CampaignsComponent} from 'src/app/components/private/campaigns/campaigns.component';
import {DetailCampaignComponent} from 'src/app/components/private/campaigns/detail-campaign/detail-campaign.component';
import {BriefCampaignComponent} from 'src/app/components/private/campaigns/brief-campaign/brief-campaign.component';
import {ProgressCampaignComponent} from 'src/app/components/private/campaigns/progress-campaign/progress-campaign.component';
import {ProgressInfluencersCampaignComponent} from '../../components/private/campaigns/progress-campaign/components/progress-influencers-campaign/progress-influencers-campaign.component';
import {ReportCampaignComponent} from 'src/app/components/private/campaigns/report-campaign/report-campaign.component';
import {AccountSettingsComponent} from 'src/app/components/private/account_settings/account-settings.component';
import {ChangePasswordComponent} from 'src/app/components/private/change_password/change-password.component';
import {SharedModule} from '../shared/shared.module';
import {AngularMaterialModule} from '../angular-material.module';
import {RemoveInfluencerComponent} from 'src/app/components/private/campaigns/remove-influencer/remove-influencer.component';
import {RemovePostComponent} from 'src/app/components/private/campaigns/remove-post/remove-post.component';
import {ConfirmSendInvitationModalComponent} from 'src/app/components/private/campaigns/confirm-send-invitation-modal/confirm-send-invitation-modal.component';
import {StateConfirmSendInvitationModalComponent} from 'src/app/components/private/campaigns/state-confirm-send-invitation-modal/state-confirm-send-invitation-modal.component';
import {ReportShareModalComponent} from 'src/app/components/private/campaigns/report-share-modal/report-share-modal.component';
import {ModalShareInfluencerReportUrlComponent} from '../../components/private/campaigns/report-campaign/modal-share-influencer-report-url/modal-share-influencer-report-url.component';
import {ConfirmDeleteCampaignModalComponent} from 'src/app/components/private/campaigns/confirm-delete-campaign-modal/confirm-delete-campaign-modal.component';
import {CancelInvitationComponent} from 'src/app/components/private/campaigns/cancel-invitation/cancel-invitation.component';
import {ConfirmEarlyStartDateModalComponent} from 'src/app/components/private/campaigns/confirm-early-start-date-modal/confirm-early-start-date-modal.component';
import {ShowInspirationalModalComponent} from 'src/app/components/private/campaigns/show-inspirational-modal/show-inspirational-modal.component';
import {CampaignPlanShareModalComponent} from 'src/app/components/private/campaigns/detail-campaign/modals/campaign-plan-share-modal/campaign-plan-share-modal.component';
import {ProgressApprovalShareModalComponent} from '../../components/private/campaigns/progress-campaign/modals/progress-approval-share-modal/progress-approval-share-modal.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {ModalConfirmResendInvitationComponent} from '../../components/private/campaigns/progress-campaign/modals/modal-confirm-resend-invitation/modal-confirm-resend-invitation.component';
import {ModalUploadProposalComponent} from '../../components/private/campaigns/progress-campaign/modals/modal-upload-proposal/modal-upload-proposal.component';
import {ConfirmActionInfluencerStateComponent} from '../../components/private/campaigns/progress-campaign/modals/modal-confirm-action-influencer-state/confirm-action-influencer-state.component';
import {ModalConfirmationSendPreviousEmailComponent} from '../../components/private/campaigns/brief-campaign/modals/modal-confirmation-send-previous-email/modal-confirmation-send-previous-email.component';
import {AdminEditAudienceComponent} from '../../components/private/campaigns/detail-campaign/admin-edit-audience/admin-edit-audience.component';
import {FormUploadStoryManuallyComponent} from '../../components/private/campaigns/report-campaign/report-influencer-content-modal/modal-upload-stories-manually/form-upload-story-manually/form-upload-story-manually.component';
import {ModalExtendCampaignComponent} from '../../components/private/campaigns/brief-campaign/modals/modal-extend-campaign/modal-extend-campaign.component';
import {ModalViewCodeConversionsLinksComponent} from '../../components/private/campaigns/brief-campaign/modals/modal-view-code-conversions-links/modal-view-code-conversions-links.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ModalInfluencerLinkComponent} from '../../components/private/campaigns/progress-campaign/modals/modal-influencer-link/modal-influencer-link.component';
import {ModalEditInfluencerEmailComponent} from '../../components/private/campaigns/brief-campaign/modals/modal-edit-influencer-email/modal-edit-influencer-email.component';
import {ProgressHeaderComponent} from '../../components/private/campaigns/progress-campaign/components/progress-header/progress-header.component';
import {FreePlanFormComponent} from './components/free-plan-form/free-plan-form.component';
import {ModalAdvantagesPerformancePlanComponent} from '../../components/private/campaigns/brief-campaign/modals/modal-advantages-performance-plan/modal-advantages-performance-plan.component';
import {BriefStep1Component} from '../../components/private/campaigns/brief-campaign/components/brief-step1/brief-step1.component';
import {BriefStep2Component} from '../../components/private/campaigns/brief-campaign/components/brief-step2/brief-step2.component';
import {BriefStep3Component} from '../../components/private/campaigns/brief-campaign/components/brief-step3/brief-step3.component';
import {ModalAddCommentInfluencerComponent} from '../../components/private/campaigns/brief-campaign/components/brief-step3/modals/modal-add-comment-influencer/modal-add-comment-influencer.component';
import {ModalAddLinkInfluencerComponent} from '../../components/private/campaigns/brief-campaign/components/brief-step3/modals/modal-add-link-influencer/modal-add-link-influencer.component';
import {PreviewEmailInfluencerComponent} from '../../components/private/campaigns/brief-campaign/components/preview-email-influencer/preview-email-influencer.component';
import {ProfileNeedUpdateModalComponent} from '../../components/private/campaigns/detail-campaign/modals/profile-need-update-modal/profile-need-update-modal.component';
import {FavouritesComponent} from './components/favourites/favourites.component';
import {ModalCreateFavouritesListComponent} from './components/favourites/modals/modal-create-favourites-list/modal-create-favourites-list.component';
import {ModalRemoveFavouritesListComponent} from './components/favourites/modals/modal-remove-favourites-list/modal-remove-favourites-list.component';
import {ModalFavouritesListComponent} from './components/favourites/modals/modal-favourites-list/modal-favourites-list.component';
import {EditProfileSettingsComponent} from '../../components/private/account_settings/components/edit-profile-settings/edit-profile-settings.component';
import {ShowInvoicesSettingsComponent} from '../../components/private/account_settings/components/show-invoices-settings/show-invoices-settings.component';
import {TranslateModule} from '@ngx-translate/core';
import {ModalSelectBonusComponent} from './modals/modal-select-bonus/modal-select-bonus.component';
import {ModalRefreshInfluencerDataComponent} from './modals/modal-refresh-influencer-data/modal-refresh-influencer-data.component';
import {ModalAddCommentInfluencerReportComponent} from '../../components/private/campaigns/report-campaign/modal-add-comment-influencer-report/modal-add-comment-influencer-report.component';
import {EmailDownloadAllContentModalComponent} from '../../components/private/campaigns/report-campaign/modals/email-download-all-content-modal/email-download-all-content-modal.component';
import { ModalRememberUpdatePaymentMethodComponent } from './modals/modal-remember-update-payment-method/modal-remember-update-payment-method.component';
import { ModalViewStatsMediaComponent } from 'src/app/components/private/campaigns/report-campaign/modals/modal-view-stats-media/modal-view-stats-media.component';
import { ModalInfoUpdateInfluencersComponent } from './components/favourites/modals/modal-info-update-influencers/modal-info-update-influencers.component';
import { CreatePresentationModalComponent } from '../create-presentation-modal/create-presentation-modal/create-presentation-modal.component';
import { StepCampaignPlanComponent } from '../create-presentation-modal/components/step-campaign-plan/step-campaign-plan.component';
import { StepStylesComponent } from '../create-presentation-modal/components/step-styles/step-styles.component';
import { ModalSelectCancelPausePlanComponent } from './components/cancel-pause-plan/modals/modal-select-cancel-pause-plan/modal-select-cancel-pause-plan.component';
import { ModalPausePlanComponent } from './components/cancel-pause-plan/modals/modal-pause-plan/modal-pause-plan.component';
import { ModalCancelPlanComponent } from './components/cancel-pause-plan/modals/modal-cancel-plan/modal-cancel-plan.component';
import { ModalCancelPlanFormComponent } from './components/cancel-pause-plan/modals/modal-cancel-plan-form/modal-cancel-plan-form.component';
import { ModalPlans } from '../modal-plans/modal-plans.module';
import { ModalConfirmedPauseCancelPlanComponent } from './components/cancel-pause-plan/modals/modal-confirmed-pause-cancel-plan/modal-confirmed-pause-cancel-plan.component';
import { ModalReactivatePlanComponent } from './components/cancel-pause-plan/modals/modal-reactivate-plan/modal-reactivate-plan.component';
import { UnlockCreatePresentationModalComponent } from '../create-presentation-modal/unlock-create-presentation-modal/unlock-create-presentation-modal.component';


const COMPONENTS = [
  InfluencerProfileComponent,
  CampaignsComponent,
  DetailCampaignComponent,
  BriefCampaignComponent,
  ProgressCampaignComponent,
  ProgressInfluencersCampaignComponent,
  ProgressHeaderComponent,
  ReportCampaignComponent,
  AccountSettingsComponent,
  EditProfileSettingsComponent,
  ShowInvoicesSettingsComponent,
  ChangePasswordComponent,
  ModalConfirmResendInvitationComponent,
  ModalViewStatsMediaComponent,
  ModalUploadProposalComponent,
  FormUploadStoryManuallyComponent,
  ConfirmActionInfluencerStateComponent,
  ModalConfirmationSendPreviousEmailComponent,
  AdminEditAudienceComponent,
  FreePlanFormComponent,
  BriefStep1Component,
  BriefStep2Component,
  BriefStep3Component,
  PreviewEmailInfluencerComponent,
  FavouritesComponent,
  StepCampaignPlanComponent,
  StepStylesComponent
];

const ENTRY_COMPONENTS = [
  RemoveInfluencerComponent,
  RemovePostComponent,
  ConfirmSendInvitationModalComponent,
  StateConfirmSendInvitationModalComponent,
  ReportShareModalComponent,
  ModalShareInfluencerReportUrlComponent,
  ConfirmDeleteCampaignModalComponent,
  CancelInvitationComponent,
  ConfirmEarlyStartDateModalComponent,
  ShowInspirationalModalComponent,
  CampaignPlanShareModalComponent,
  ProfileNeedUpdateModalComponent,
  ProgressApprovalShareModalComponent,
  ModalConfirmResendInvitationComponent,
  ModalViewStatsMediaComponent,
  FormUploadStoryManuallyComponent,
  ModalUploadProposalComponent,
  ConfirmActionInfluencerStateComponent,
  ModalConfirmationSendPreviousEmailComponent,
  ModalExtendCampaignComponent,
  ModalViewCodeConversionsLinksComponent,
  ModalInfluencerLinkComponent,
  ModalEditInfluencerEmailComponent,
  ModalAddCommentInfluencerComponent,
  ModalAddLinkInfluencerComponent,
  ModalAdvantagesPerformancePlanComponent,
  ModalCreateFavouritesListComponent,
  ModalRemoveFavouritesListComponent,
  ModalFavouritesListComponent,
  ModalInfoUpdateInfluencersComponent,
  ModalSelectBonusComponent,
  ModalRefreshInfluencerDataComponent,
  ModalAddCommentInfluencerReportComponent,
  ModalRememberUpdatePaymentMethodComponent,
  EmailDownloadAllContentModalComponent,
  CreatePresentationModalComponent,
  UnlockCreatePresentationModalComponent,
  ModalSelectCancelPausePlanComponent,
  ModalPausePlanComponent,
  ModalCancelPlanComponent,
  ModalCancelPlanFormComponent,
  ModalConfirmedPauseCancelPlanComponent,
  ModalReactivatePlanComponent,
];
@NgModule({
  declarations: [
    ...COMPONENTS,
    ...ENTRY_COMPONENTS,
  ],
    imports: [
        SharedModule,
        AngularMaterialModule,
        PrivateRoutingModule,
        GooglePlaceModule,
        ScrollingModule,
        TranslateModule,
        ModalPlans
    ],
  entryComponents: [
    ...ENTRY_COMPONENTS,
  ]
})
export class PrivateModule { }
