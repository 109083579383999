import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../../../../environments/environment';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {AmplifyService} from 'aws-amplify-angular';
import {ProgressService} from '../../../../../../services/progress.service';
import {DomSanitizer} from '@angular/platform-browser';
import * as uuid from 'uuid';


@Component({
  selector: 'app-modal-upload-proposal',
  templateUrl: './modal-upload-proposal.component.html',
  styleUrls: ['./modal-upload-proposal.component.scss']
})
export class ModalUploadProposalComponent implements OnInit {

  // General variables
  proposal_url = '';
  proposal_type = '';


  // Control variables
  uploading = false;
  error_insight_image_upload = '';
  is_story = false;

  proposal_form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public snackBar: MatSnackBar,
              private _amplifyService: AmplifyService,
              private _progressService: ProgressService,
              private _sanitizer: DomSanitizer,
              public dialogRef: MatDialogRef<ModalUploadProposalComponent>) {
    this.proposal_form = new FormGroup({
      captions: new FormControl(''),
      media_type: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
  }


  uploadProposalToDynamo() {
    const proposal = {
      'influencer_id': this.data['influencer_id'],
      'captions': this.proposal_form.controls['captions'].value,
      'media_type': (this.proposal_form.controls['media_type'].value === 'post' || this.proposal_form.controls['media_type'].value === 'story') ?
          this.proposal_form.controls['media_type'].value + this.proposal_type :
          this.proposal_form.controls['media_type'].value,
      'display_url': this.proposal_url,
      'network': this.data['influencer_network']
    };
    this._progressService.uploadProposal(this.data['campaign_id'], proposal).subscribe(response => {
      this.openSnackBar('Proposal has been upload successfully', 'Close');
      this.dialogRef.close('uploaded');
    }, () => {
      this.openSnackBar('Something went wrong', 'Close');
      this.dialogRef.close();
    });
  }

  uploadImageToS3(fileInput: any) {
    this.uploading = true;
    try {
      this.error_insight_image_upload = '';
      const file = fileInput.target.files[0];
      if ( file.size <= 5,12e+8) {
        if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'video/quicktime' || file.type === 'video/mp4') {
          if (fileInput.target.files && fileInput.target.files[0]) {
            this.is_story = file.type === 'video/mp4' && this.data.influencer_network === 'instagram';
            this.proposal_type = (file.type.split('/', 1)[0] === 'image') ? '_img' : '_video';
            const extension = this.getExtensionFromMedia(file.type);
            const config = {
              bucket: environment.aws_resources.s3.buckets.proposals,
              region: environment.aws_resources.region,
              level: 'public',
              customPrefix: {
                public: ''
              },
            };
            const random_uuid = uuid.v4();
            this._amplifyService.storage().vault.put(this.data.campaign_id + '/' + this.data.influencer_id + '/' + random_uuid +  extension, file, config).then(() => {
              this.uploading = false;
              this.proposal_url = 'https://' + config.bucket + '.s3-eu-west-1.amazonaws.com/' + this.data.campaign_id + '/' + this.data.influencer_id + '/' + random_uuid + extension;
            }).catch(() => {
              this.uploading = false;
              this.error_insight_image_upload = 'Something went wrong. Please try again later.';
            });
          }
        } else {
          this.error_insight_image_upload = 'Unsupported file type. Supported file types are: jpg, jpeg, png, quicktime and mp4';
          this.uploading = false;
        }
      } else {
        this.error_insight_image_upload = 'The file exceeds 20MB';
        this.uploading = false;
      }
    } catch (e) {
      this.error_insight_image_upload = 'Something went wrong. Please try again later.';
      this.uploading = false;
    }
  }


  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  getExtensionFromMedia(file_type: string): string {
    if (this.data.influencer_network === 'instagram' && file_type !== 'video/mp4') {
      return file_type.replace('image/', '.');
    } else if (this.data.influencer_network === 'youtube' || this.data.influencer_network === 'tiktok' || file_type === 'video/mp4') {
      return file_type.replace('video/', '.');
    }
  }

}
