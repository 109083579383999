import {Action, createReducer, on} from '@ngrx/store';

import * as actions from './app.actions';
import {changeViewPort, setCurrency, setCurrencySymbol, setLanguage} from './app.actions';

export const appKey = 'app';

export interface AppState {
  viewPort: string;
  userGroup: any;
  language: string;
  currency: string;
  currencySymbol: string;
}

export const initialAppState: AppState = {
  viewPort: 'web',
  userGroup: null,
  language: '',
  currency: '',
  currencySymbol: '',
};

export const appReducers = {
  getPublicUserGroup(state) {
    return { ...state };
  },
  getPublicUserGroupSuccess(state, { userGroup }) {
    return { ...state, loginLoading: false, userGroup };
  },
  getUserGroup(state) {
    return { ...state };
  },
  getUserGroupSuccess(state, { userGroup }) {
    return { ...state, loginLoading: false, userGroup };
  },
};

export const appReducer = createReducer(
    initialAppState,
  on(changeViewPort, (state, { viewPort }) => {
    return Object.assign({}, state, { viewPort });
  }),
    on(setLanguage, (state, { language }) => {
      return Object.assign({}, state, { language });
    }),
    on(setCurrency, (state, { currency }) => {
      return Object.assign({}, state, { currency });
    }),
    on(setCurrencySymbol, (state, { currencySymbol }) => {
      return Object.assign({}, state, { currencySymbol });
    }),
    on(actions.getPublicUserGroup, appReducers.getPublicUserGroup),
    on(actions.getPublicUserGroupSuccess, appReducers.getPublicUserGroupSuccess),
    on(actions.getUserGroup, appReducers.getUserGroup),
    on(actions.getUserGroupSuccess, appReducers.getUserGroupSuccess),
);

export function reducer(state: AppState | undefined, action: Action) {
  return appReducer(state, action);
}
