import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalAddCampaignToDashboardComponent} from '../../modals/modal-add-campaign-to-dashboard/modal-add-campaign-to-dashboard.component';
import {environment} from '../../../../../../../environments/environment';
import {MatDialog, Sort} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalConfirmRemoveItemComponent} from '../../modals/modal-confirm-remove-item/modal-confirm-remove-item.component';

@Component({
  selector: 'app-campaigns-report-dashboard',
  templateUrl: './campaigns-report-dashboard.component.html',
  styleUrls: ['./campaigns-report-dashboard.component.scss']
})
export class CampaignsReportDashboardComponent implements OnInit {

  campaign_logo_platform = environment.platform_logo;
  dashboard_id;

  @Input() dashboards;
  @Input() isShare;
  @Output() callGetReportDashboard = new EventEmitter<any>();

  displayedColumns: string[] =  ['CAMPAIGN_LOGO', 'CAMPAIGN_NAME', 'CREATION_DATE', 'LAUNCH_DATE', 'CAMPAIGN_STATUS', 'REMOVE_CAMPAIGN'];
  displayedSharedColumns: string[] =  ['CAMPAIGN_LOGO', 'CAMPAIGN_NAME', 'CREATION_DATE', 'LAUNCH_DATE', 'CAMPAIGN_STATUS'];

  constructor(
      private dialog: MatDialog,
      private activatedRoute: ActivatedRoute,
      private router: Router) { }

  ngOnInit() {
    this.dashboard_id = this.activatedRoute.params['value'].dashboard_id;
  }


  addCampaignToDashboard() {
    const dialogRef = this.dialog.open(ModalAddCampaignToDashboardComponent, {
      width: 'auto',
      height: 'auto',
      autoFocus: false,
      data: {
        dashboard_id: this.dashboard_id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.callGetReportDashboard.emit();
      }
    });
  }

  redirectToNewWindow(HashId, route) {
    const url = this.router.serializeUrl(
        this.router.createUrlTree([`${route}/${HashId}`])
    );
    window.open(url, '_blank');
  }


  deleteCampaignInDashboard(dashboard_id, dashboard_name, campaign_id) {
    const dialogRef = this.dialog.open(ModalConfirmRemoveItemComponent, {
      width: 'auto',
      height: 'auto',
      autoFocus: false,
      data: {
        item: 'campaign',
        itemName: dashboard_name,
        itemId: dashboard_id,
        campaignId: campaign_id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.callGetReportDashboard.emit();
      }
    });
  }

  sortData(sort: Sort) {
    const data = this.dashboards.slice();
    if (!sort.active || sort.direction === '') {
      this.dashboards = data;
    }
    this.dashboards = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'REPORT_DASHBOARD_NAME':
          return this.compare(a.dashboard_name, b.dashboard_name, isAsc);
        case 'CREATION_DATE':
          return this.compare(a.c, b.c, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    if (a === undefined) {
      a = 1286287812;
    }
    if (b === undefined) {
      b = 1286287812;
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
