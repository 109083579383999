import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot} from '@angular/router';

import {Observable} from 'rxjs/Observable';
import {from, of} from 'rxjs';
import {catchError, map, switchMap, take} from 'rxjs/operators';

import {Store} from '@ngrx/store';
import {State} from 'src/app/store';
import * as selectors from '../store/auth/authentication.selectors';
import {getAccessSetsSuccess} from '../store/auth/authentication.actions';
import {Actions, ofType} from '@ngrx/effects';
import isEmpty from 'lodash/isEmpty';
import {AmplifyService} from 'aws-amplify-angular';
import {openPreviousPlansDialog} from '../store/app/app.actions';
import {environment} from '../../environments/environment';
import { ModalReactivatePlanComponent } from '../features/private/components/cancel-pause-plan/modals/modal-reactivate-plan/modal-reactivate-plan.component';
import { MatDialog } from '@angular/material';


@Injectable({
  providedIn: 'root',
})
export class AccessSetsGuard implements CanActivate, CanLoad {
  constructor(
    public store: Store<State>,
    public router: Router,
    private actions$: Actions,
    private amplifyService: AmplifyService,
    private dialog: MatDialog,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkAccessSets(route, state);
  }

  canLoad(route: Route): boolean | Promise<boolean> | Observable<boolean> {
    return this.checkAccessSets(route);
  }

  checkAccessSets(route, state?) {
    const logged = this.checkLogin(state);
    return this.store.select(selectors.selectAccessSets).pipe(
      // withLatestFrom(this.checkAmplifyUser()),
      switchMap(accessSets => {
        if (isEmpty(accessSets)) {
          return this.actions$.pipe(
            ofType(getAccessSetsSuccess),
            map(action => action.accessSets)
          );
        }
        return of(accessSets);
      }),
      map(accessSets => {
        if ((accessSets.payment_status === 'active' && accessSets.paused_from !== undefined) || accessSets.payment_status === 'paused' || (accessSets.payment_status === 'active' && accessSets.cancellation_effective_date !== undefined)) {
          let isCancel = accessSets.paused_from !== undefined && accessSets.paused_from !== null 
          ? false 
          : true;
          if (accessSets.payment_status === 'paused') {
            isCancel = false;
          }
          this.dialog
          .open(ModalReactivatePlanComponent, {
            width: '410px',
            height: 'auto',
            autoFocus: false,
            data: {
              paused_from: accessSets.paused_from !== undefined && accessSets.paused_from !== null 
              ? accessSets.paused_from 
              : accessSets.cancellation_effective_date,
              isCancel:  accessSets.payment_status === 'paused'
              ? false
              : accessSets.paused_from == null

            }
          })
          .afterClosed();
        }
        const userPermissions = Object.keys(accessSets);
        const guardRestrictions = route.data.permissions[0];

        const hasPermissions = guardRestrictions.every(item => {
          return userPermissions.includes(item);
        });
        if (hasPermissions) {
          return true;
        } else {
          throw new Error(`This USER can not access to this section`);
        }
      }),
      take(1),
      catchError(err => {
        // alert('This USER can not access to this section');
        // this.router.navigate(['app/search']);
        if ( environment.platform_id === '11posts' ) {
          this.store.dispatch(openPreviousPlansDialog());
        } else if ( environment.platform_id === 'binfluencer') {
          this.router.navigate(['/app/search']);
        }
        return of(false);
      })
    );
  }

  checkAmplifyUser() {
    return from(this.amplifyService.auth().currentSession()).pipe(take(1));
  }

  checkLogin(state) {
    return this.amplifyService
      .auth()
      .currentAuthenticatedUser()
      .catch(() => {
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
        return false;
      });
  }
}
