import {selectAppViewPort} from '../../../../../store/app/app.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../../store';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource, Sort} from '@angular/material';
import {ReportService} from '../../../../../services/report.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-table-activity-feed',
  templateUrl: './table-activity-feed.component.html',
  styleUrls: ['./table-activity-feed.component.scss']
})
export class TableActivityFeedComponent implements OnInit, OnDestroy {

  @Input() isModal;
  @Input() campaignId;
  @Input() influencerId;
  @Input() isShare;

  pageSelected = 0;
  itemsSize = 10;
  viewport;
  response;
  loading;

  displayedColumns: string[] = ['Influencer Username', 'Network', 'Influencer Link', 'Activity Date',
    'Activity Type', 'Page Loaded'];

  private subscriptions = [];
  dataSource;

  paginationFormGroup: FormGroup;

  constructor(private store: Store<State>,
              private _reportService: ReportService
  ) {
    this.paginationFormGroup = new FormGroup({
      'itemsPerPage': new FormControl('10'),
    });
  }

  ngOnInit() {
    this.loading = true;
    this.subscriptions.push(
      this.store.select(selectAppViewPort).subscribe(result => {
        this.viewport = result;
      })
    );
    this.getConversions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  sortData(sort: Sort) {
    const data = this.response.activity_feed.slice();
    if (!sort.active || sort.direction === '') {
      this.response.activity_feed = data;
    }
    this.response.activity_feed = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Influencer Username':
          return this.compare(a.influencer_username, b.influencer_username, isAsc);
        case 'Network':
          return this.compare(a.network, b.network, isAsc);
        case 'Influencer Link':
          return this.compare(a.influencer_link, b.influencer_link, isAsc);
        case 'Activity Date':
          return this.compare(a.activity_ts, b.activity_ts, isAsc);
        case 'Activity Type':
          return this.compare(a.activity_type, b.activity_type, isAsc);
        case 'Page Loaded':
          return this.compare(a.page_loaded, b.page_loaded, isAsc);
        default:
          return 0;
      }
    });
  }

  getConversions() {
    if (this.influencerId !== undefined && this.isShare) {
      this.subscriptions.push(
          this._reportService.getRecentInfluencerShareConversions(this.campaignId, this.influencerId, this.itemsSize, this.pageSelected).subscribe(
              result => {
                this.response = result['body'];
                this.dataSource =  new MatTableDataSource<any>(this.response.activity_feed);
                this.loading = false;
              }, () => {
                this.loading = false;
              })
      );
    } else if (this.influencerId !== undefined && !this.isShare) {
      this.subscriptions.push(
          this._reportService.getRecentInfluencerConversions(this.campaignId, this.influencerId, this.itemsSize, this.pageSelected).subscribe(
              result => {
                this.response = result['body'];
                this.dataSource =  new MatTableDataSource<any>(this.response.activity_feed);
                this.loading = false;
              }, () => {
                this.loading = false;
              })
      );
    } else {
      if (this.isShare) {
        this.subscriptions.push(
            this._reportService.getRecentConversionsShare(this.campaignId, this.itemsSize, this.pageSelected).subscribe(
                result => {
                  this.response = result['body'];
                  this.dataSource =  new MatTableDataSource<any>(this.response.activity_feed);
                  this.loading = false;
                }, () => {
                  this.loading = false;
                })
        );
      } else {
        this.subscriptions.push(
            this._reportService.getRecentConversions(this.campaignId, this.itemsSize, this.pageSelected).subscribe(
                result => {
                  this.response = result['body'];
                  this.dataSource =  new MatTableDataSource<any>(this.response.activity_feed);
                  this.loading = false;
                }, () => {
                  this.loading = false;
                })
        );
      }
    }
  }


  compare(a, b, isAsc) {
    // campaign launch time undefined, campañas antiguas y campañas sin ejecutar
    if (a === undefined) {
      a = 1286287812;
    }
    if (b === undefined) {
      b = 1286287812;
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  changeItemsSize(event) {
    this.itemsSize = event.value;
    this.getConversions();
  }

  changePage(page) {
    this.pageSelected = page;
    this.getConversions();
  }
}
