import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-gender-chart',
  templateUrl: './gender-chart.component.html',
  styleUrls: ['./gender-chart.component.scss', '../locations-by-country/locations-by-country.component.scss']
})
export class GenderChartComponent implements OnInit {

  @Input() genderData;

  constructor() { }

  ngOnInit() {
  }

}
