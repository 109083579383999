import {createFeatureSelector, createSelector} from '@ngrx/store';

import {authenticationKey, AuthenticationState} from './authentication.reducers';

export const selectAuthenticationState = createFeatureSelector<
  AuthenticationState
>(authenticationKey);

export const selectUser = createSelector(
  selectAuthenticationState,
  state => state.user
);

export const selectLoginLoading = createSelector(
  selectAuthenticationState,
  state => state.loginLoading
);

export const selectLoadingError = createSelector(
  selectAuthenticationState,
  state => state.loginError
);

export const selectSignUpLoading = createSelector(
  selectAuthenticationState,
  state => state.signUpLoading
);

export const selectSignUpError = createSelector(
  selectAuthenticationState,
  state => state.signUpError
);

export const selectSignUpStep1Loading = createSelector(
  selectAuthenticationState,
  state => state.signUpLoading
);

export const selectSignUpStep1Error = createSelector(
  selectAuthenticationState,
  state => state.signUpError
);

export const selectResendVerificationEmailLoading = createSelector(
  selectAuthenticationState,
  state => state.resendVerificationEmailLoading
);

export const selectResendVerificationEmailError = createSelector(
  selectAuthenticationState,
  state => state.resendVerificationEmailError
);

export const selectForgotPasswordLoading = createSelector(
  selectAuthenticationState,
  state => state.forgotPasswordLoading
);

export const selectForgotPasswordError = createSelector(
  selectAuthenticationState,
  state => state.forgotPasswordError
);

export const selectResetPasswordLoading = createSelector(
  selectAuthenticationState,
  state => state.resetPasswordLoading
);

export const selectResetPasswordError = createSelector(
  selectAuthenticationState,
  state => state.resetPasswordError
);

export const selectAccessSets = createSelector(
  selectAuthenticationState,
  state => state.accessSets
);


export const selectEmailFromHash = createSelector(
  selectAuthenticationState,
  state => state.email
);

export const selectEmailFromHashLoading = createSelector(
  selectAuthenticationState,
  state => state.loginLoading
);

export const selectEmailFromHashError = createSelector(
  selectAuthenticationState,
  state => state.loginError
);
