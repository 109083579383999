import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../store';
import { ModalPlansService } from '../../../store/modal-plans.service';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { ModalPlansComponent } from '../modal-plans.component';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '../../../../../../environments/environment';
import { Plan } from '../../../types/plans';
import { openConfirmUpgradePlanDialog } from '../../../../../store/app/app.actions';
import { AuthenticatedUser } from '../../../../../classes/authenticated-user-class';
import { AmplifyService } from 'aws-amplify-angular';
import { ModalThanksPageComponent } from '../../modal-thanks-page/modal-thanks-page.component';
import { getAccessSets, getLoggedUser } from '../../../../../store/auth/authentication.actions';
import { ModalReactivatePlanComponent } from 'src/app/features/private/components/cancel-pause-plan/modals/modal-reactivate-plan/modal-reactivate-plan.component';
import * as selectorsAuth from '../../../../../store/auth/authentication.selectors';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import { TranslateService } from '@ngx-translate/core';


declare var Paddle: any;

@Component({
    selector: 'app-modal-plans-card',
    templateUrl: './modal-plans-card.component.html',
    styleUrls: ['./modal-plans-card.component.scss']
})
export class ModalPlansCardComponent implements OnInit, OnDestroy {

    @Input() plan: Plan;
    @Input() isMonthlyPayment: boolean;
    @Input() email: string;
    @Input() activePlan: boolean;
    @Input() nameActivePlan: string;
    @Input() cancelledPlan: boolean;
    @Input() isCancelPlanModal: boolean;
    @Input() planToUpdate: string;


    @Output() callLoadingSpinner = new EventEmitter<any>();

    userSub;
    authenticated_user: AuthenticatedUser;

    payment_status = '';
    paused_from = '';
    cancellation_effective_date = '';
    plan_to_reactivate = '';
    plan_to_reactivate_id = '';

    isMonthlyId:boolean;

    private subscriptions: Subscription[] = [];


    // Aplicamos la clase condicionalmente al host
    @HostBinding('class.isCancelPlanModal') get specialClass() {
        return this.isCancelPlanModal;
    }

    constructor(private store: Store<State>,
        private modalPlansService: ModalPlansService,
        private snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<ModalPlansComponent>,
        public router: Router,
        private gtmService: GoogleTagManagerService,
        private _amplifyService: AmplifyService,
        private dialog: MatDialog,
        private translate: TranslateService,
    ) {
        Paddle.Setup({
            vendor: environment.paddle_vendor,
        });
        if (!environment.production) {
            Paddle.Environment.set('sandbox');
        }
    }
    async getAllAttributes() {
        const user = await this._amplifyService.auth().currentAuthenticatedUser({ bypassCache: true });
        this.authenticated_user = new AuthenticatedUser(user);
        this.userSub = this.authenticated_user.getAttribute('sub');
    }

    ngOnInit() {
        this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
            this.paused_from = user.paused_from;
            this.payment_status = user.payment_status;
            this.cancellation_effective_date = user.cancellation_effective_date;
            this.plan_to_reactivate = user.plan_to_reactivate_name;
            this.plan_to_reactivate_id = user.plan_to_reactivate_id;
        });
        this.isMonthlyId = this.plan['paddleSubscriptionIdMonthly'].includes(this.plan_to_reactivate_id)
        ? true
        : this.plan['paddleSubscriptionIdYearly'].includes(this.plan_to_reactivate_id)
        ? false
        : undefined;
    }

    openCheckout(isMonthlyPayment, plan) {
        const productId = isMonthlyPayment ? plan.paddleSubscriptionIdMonthly : plan.paddleSubscriptionIdYearly;
        if (this.activePlan === true) {
            this.store.dispatch(openConfirmUpgradePlanDialog({ plan, productId }));
        } else {
            this.callLoadingSpinner.emit(true);
            Paddle.Checkout.open(
                {
                    product: productId,
                    email: this.email,
                    disableLogout: true,
                    successCallback: (data) => {
                        this.subscriptions.push(
                            ...[
                                this.modalPlansService.savePaddlePayment(data).subscribe(() => {
                                    this.store.dispatch(getLoggedUser());
                                    this.store.dispatch(getAccessSets());
                                    this.callLoadingSpinner.emit(false);
                                    this.dialogRef.close();
                                    // aqui iria la llamada a googletagmanager
                                    const gtmTag = {
                                        event: 'checkoutSuccess',
                                        data: 'my-custom-event',
                                    };
                                    this.gtmService.pushTag(gtmTag);
                                    this.dialog.open(ModalThanksPageComponent, {
                                        width: '410px',
                                        height: 'auto',
                                        autoFocus: false,
                                        data: {
                                            planName: plan.planName,
                                        }
                                    });
                                }, () => {
                                    this.callLoadingSpinner.emit(false);
                                    this.snackBar.openFromComponent(CustomSnackbarComponent, {
                                        data: { message: this.translate.instant('customSnackbar.thereHasBeenError'), type: 'error' },
                                        duration: 3000,
                                        horizontalPosition: 'right',
                                        verticalPosition: 'top',
                                        panelClass: 'error-snackbar',
                                    });
                                })
                            ]
                        );
                    },
                    closeCallback: (data) => {
                        this.callLoadingSpinner.emit(false);
                        // if the user cancel the paddle modal
                        this.snackBar.openFromComponent(CustomSnackbarComponent, {
                            data: { message: this.translate.instant('customSnackbar.youClosedThePayment'), type: 'error' },
                            duration: 3000,
                            horizontalPosition: 'right',
                            verticalPosition: 'top',
                            panelClass: 'error-snackbar',
                        });
                    }
                }
            );
        }

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    openSnackBar(message, action) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    redirectCallSales() {
        window.open('https://calendly.com/nicolas-11posts/plans-and-pricing', '_blank');
    }

    // Función para formatear el precio, a los precios anuales establecer separador de miles con ,
    formatPrice(price: number): string {
        return price.toLocaleString('en-US');
    }


    reactivateSubscriptionPlan() {
        this.dialog
            .open(ModalReactivatePlanComponent, {
                width: '410px',
                height: 'auto',
                autoFocus: false,
                data: {
                    paused_from: this.paused_from !== undefined && this.paused_from !== null
                        ? this.paused_from
                        : this.cancellation_effective_date,
                    isCancel: this.paused_from !== undefined && this.paused_from !== null
                        ? false
                        : true
                }
            })
            .afterClosed();
            this.dialogRef.close();
    }


    isLetsGoButtonVisible(plan: Plan): boolean {
        return !plan.activePlan && this.plan_to_reactivate !== plan.planName;
      }
      
      isLetsGoButtonDisabled(plan: Plan): boolean {
        return plan.planName === 'FREE' || (this.cancellation_effective_date !== undefined || this.paused_from !== undefined || this.payment_status === 'paused') ; 
      }
      
      isReactivateButtonVisible(plan: Plan): boolean {
        return (this.payment_status === 'paused'  && this.plan_to_reactivate === plan.planName) || plan.planName !== 'FREE' && plan.activePlan && (this.cancellation_effective_date !== undefined || this.paused_from !== undefined);
      }
      
      isCurrentPlanDisabledButtonVisible(plan: Plan): boolean {
        return (
           (plan.activePlan && plan.planName === 'FREE') || (this.cancellation_effective_date === undefined && this.paused_from === undefined  && plan.activePlan));
      }
      

}
