import { Component, OnInit } from '@angular/core';
import {openPlansDialog} from '../../../../store/app/app.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../../store';
import {MatDialogRef} from '@angular/material';
import * as selectorsAuth from '../../../../store/auth/authentication.selectors';

@Component({
  selector: 'app-modal-previous-plans',
  templateUrl: './modal-previous-plans.component.html',
  styleUrls: ['./modal-previous-plans.component.scss']
})
export class ModalPreviousPlansComponent implements OnInit {

  planNameShown = '';

  constructor( private store: Store<State>,
               public dialogRef: MatDialogRef<ModalPreviousPlansComponent>) { }

  ngOnInit() {
    this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
      this.planNameShown = user.PLAN_NAME_SHOWN;
    });
  }


  openPlansModal() {
    this.store.dispatch(openPlansDialog());
    this.dialogRef.close();
  }
}
