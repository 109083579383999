import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';


@Component({
  selector: 'app-chart-user-full-crawl',
  templateUrl: './chart-user-full-crawl.component.html',
  styleUrls: ['./chart-user-full-crawl.component.css']
})
export class ChartUserFullCrawlComponent implements OnInit, OnDestroy {

  private subscriptions = [];
  @Input() activityDataForChart;
  @Input() type_crawl;

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        display: true,
        type: 'time',
        distribution: 'series',
        gridLines: {
          display: true
        },
        time: {
          unit: 'month',
          displayFormats: {
            day: 'D MMM, YYYY',
            week: 'll',
            month: 'll',
            quarter: 'll',
            year: 'll'
          },
        },
      }],
      yAxes: [
        {
          ticks: {
            min: 0, // it is for ignoring negative step.
            beginAtZero: true,
            callback: function(value, index, values) {
              if (Math.floor(value) === value) {
                return value;
              }
            }
          }
        }
      ],
    }
  };

  public barChartLabels = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
  ];

  constructor() { }

  ngOnInit() {
    this.setChartData();
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }



  setChartData() {
    this.barChartData.push({data: [], label: 'Tiktok Requested', stack: 'a' });
    this.barChartData.push({data: [], label: 'Youtube Requested', stack: 'a' });
    this.barChartData.push({data: [], label: 'Instagram Requested', stack: 'a' });

    this.barChartData.push({data: [], label: 'Tiktok Success', stack: 'b' });
    this.barChartData.push({data: [], label: 'Youtube Success', stack: 'b' });
    this.barChartData.push({data: [], label: 'Instagram Success', stack: 'b' });

    let numCrawls = 0;
    for (let i = 0; i < this.activityDataForChart.length; i++) {
      if (this.activityDataForChart[i].instagram.requested > 0 ) {
        numCrawls += this.activityDataForChart[i].instagram.requested;
      }
      if ( this.activityDataForChart[i].youtube.requested > 0 ) {
        numCrawls += this.activityDataForChart[i].youtube.requested;
      }
      if (this.activityDataForChart[i].tiktok.requested > 0 ) {
        numCrawls += this.activityDataForChart[i].tiktok.requested;
      }
    }

    if (numCrawls <= 20) {
      this.barChartOptions.scales.yAxes = [
        {
          id: 'y-axis-0',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: 'Searchs',
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 20,
            maxTicksLimit: 20,
            stepSize: 1,
          }
        },
      ];
    }
    for (let index = 0; index < this.activityDataForChart.length; index ++) {
      // Establecemos las fechas
      this.barChartLabels.push( new Date(this.activityDataForChart[index].timestamp * 1000));
      // Set the values
      this.barChartData[0].data.push(this.activityDataForChart[index].tiktok.requested);
      this.barChartData[1].data.push(this.activityDataForChart[index].youtube.requested);
      this.barChartData[2].data.push(this.activityDataForChart[index].instagram.requested);

      this.barChartData[3].data.push(this.activityDataForChart[index].tiktok.success);
      this.barChartData[4].data.push(this.activityDataForChart[index].youtube.success);
      this.barChartData[5].data.push(this.activityDataForChart[index].instagram.success);
      }


  }
}
