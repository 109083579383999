import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReportDashboardService {
    private dashboardUrl = environment.aws_resources.lambda_endpoint.api;

    constructor(private http: HttpClient) {}

    public getDashboard(dashboard_id) {
        return this.http.get(this.dashboardUrl + 'campaigns-dashboard/' + dashboard_id, {observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }


    public getSharedDashboard(dashboard_id) {
        return this.http.get(this.dashboardUrl + 'campaigns-dashboard/shared/' + dashboard_id, {observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }

    public removeDashboard(dashboard_id, campaign_id?) {
        if (campaign_id) {
            return this.http.delete(this.dashboardUrl + 'campaigns-dashboard/' + dashboard_id + '/delete_campaign/' + campaign_id, {observe: 'response'}).pipe(map((data: any) => {
                return data;
            }));
        } else {
            return this.http.delete(this.dashboardUrl + 'campaigns-dashboard/' + dashboard_id, {observe: 'response'}).pipe(map((data: any) => {
                return data;
            }));
        }
    }


    listReportDashboards(userEmail?) {
        // contemplamos las dos opciones de si manda con email o no por params
        if (userEmail) {
            let params = new HttpParams();
            params = params.append('user_email', userEmail);
            return this.http.get(this.dashboardUrl + 'campaigns-dashboard/list_dashboards', { observe: 'response', params}).pipe(map((data: any) => {
                return data;
            }));
        } else {
            return this.http.get(this.dashboardUrl + 'campaigns-dashboard/list_dashboards', { observe: 'response'}).pipe(map((data: any) => {
                return data;
            }));
        }
    }


    createReportDashboard(reportDashboardName, currencyType) {
        const body = {
            'dashboard_name':  reportDashboardName,
            'currency': currencyType
        };
        return this.http.post(this.dashboardUrl + 'campaigns-dashboard', body, { observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }

    addCampaignToDashboard(dashboard_id, campaign_id) {
        const body = {
            'campaign_id':  campaign_id,
        };
        return this.http.post(this.dashboardUrl + 'campaigns-dashboard/' +  dashboard_id + '/add_campaign', body, { observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }


    updateDashboard(dashboard_id, attributesForUpdate) {
        let body;
        if ('dashboard_name' in attributesForUpdate) {
            body = {
                'dashboard_name': attributesForUpdate['dashboard_name']
            };
        } else {
            body = {
                'dashboard_logo': attributesForUpdate['dashboard_logo']
            };
        }
        return this.http.post(this.dashboardUrl + 'campaigns-dashboard/' +  dashboard_id + '/update', body, { observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }
}
