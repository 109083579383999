export const ATTRIBUTES_TO_UPDATE = {
  'custom:company_name': '',
  'custom:company_number': '',
  'address': '',
  'custom:country': '',
  'custom:city': '',
  'custom:state': '',
  'custom:zip_code': '',
  'custom:currency_type': '',
};
