import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BriefService} from '../../../../../../../../services/brief.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-modal-add-comment-influencer',
  templateUrl: './modal-add-comment-influencer.component.html',
  styleUrls: ['./modal-add-comment-influencer.component.scss']
})
export class ModalAddCommentInfluencerComponent implements OnInit {

  influencerDescription: FormGroup;
  activatedButtonSave = false;

  constructor(private _briefService: BriefService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<ModalAddCommentInfluencerComponent>,
  ) { }

  ngOnInit() {
    this.influencerDescription =  new FormGroup({
      description: new FormControl('', [Validators.required])
    });
    if (this.data.description !== '' ) {
      this.influencerDescription.controls['description'].setValue(this.data.description);
    }
  }


  addInfluencerComment() {
    const dict_attribute = {};
    dict_attribute['influencers'] = [{
      correction_index: this.data.correction_index,
      custom_destination_url: this.data.link,
      description: this.influencerDescription.controls['description'].value,
      influencer_id: this.data.influencer_id,
    }];
    this._briefService.updateBrief(this.data.campaign_id, dict_attribute).subscribe( response => {
      this.openSnackBar('Comment save successfully', 'Close');
      this.dialogRef.close({status: 'success', comment: this.influencerDescription.controls['description'].value });
      this.activatedButtonSave = false;
    }, error => {
      this.openSnackBar('Oops something went wrong, please try again', 'Close');
    });
  }


  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }


  getInfluencerDescription() {
    this.activatedButtonSave = true;
  }

}
