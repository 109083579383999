import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-modal-dashboard-generate-link-shared',
  templateUrl: './modal-dashboard-generate-link-shared.component.html',
  styleUrls: ['./modal-dashboard-generate-link-shared.component.scss']
})
export class ModalDashboardGenerateLinkSharedComponent implements OnInit {

  copying = false;
  urlPlatoformId;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.urlPlatoformId = this.data.dashboard_url_shared;
  }


  copyToClipboardUrl() {
    this.copying = true;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.urlPlatoformId;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.openSnackBar('Copied to clipboard', 'Close');
    setTimeout( () => { this.copying = false; }, 700 );
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
