import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {CampaignsService} from '../../../../../../services/campaigns.service';
import {MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-profile-need-update-modal',
  templateUrl: './profile-need-update-modal.component.html',
  styleUrls: ['./profile-need-update-modal.component.scss']
})
export class ProfileNeedUpdateModalComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  upgraded = false;
  loading = false;
  estimated_time: number;
  num_influencers_analyze: number;

  constructor(
      private _campaignService: CampaignsService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  upgradeCampaign() {
    this.loading = true;
    this.subscriptions.push(
        this._campaignService.upgradeDetailCampaign(this.data.campaignId, this.data.influencerId, this.data.isPublic).subscribe(response => {
            this.estimated_time = response.body.estimated_time;
            this.num_influencers_analyze = response.body.num_influencers_analyze;
            this.upgraded = true;
            this.loading = false;
            }, () => {
            this.openSnackBar('Oops something went wrong, please try again', 'close');
            this.upgraded = false;
            this.loading = false;
        })
    );
  }

  openSnackBar(message, action) {
      this.snackBar.open(message, action, {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
      });
  }

}
