import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {Influencer} from 'src/app/types/influencer/influencer';
import {ModalShareInfluencerGenerateLinkComponent} from '../../../../private/modals/modal-share-influencer-generate-link/modal-share-influencer-generate-link.component';
import {MatDialog} from '@angular/material/dialog';
import {ModalFavouritesListComponent} from '../../../../private/components/favourites/modals/modal-favourites-list/modal-favourites-list.component';
import {openPlansDialog} from '../../../../../store/app/app.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../../../store';
import {environment} from '../../../../../../environments/environment';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-influencer-card',
  templateUrl: './influencer-card.component.html',
  styleUrls: ['./influencer-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfluencerCardComponent implements OnInit {
  @Input() user: any;

  @Input() influencer: Influencer;

  @Input() cardType: string;

  @Output() add: EventEmitter<Influencer> = new EventEmitter();

  @Output() view: EventEmitter<Influencer> = new EventEmitter();

  @Output() selectInfluencerForAdd: EventEmitter<Influencer> = new EventEmitter();


  currencyType: string;

  influencerSelectedForm: FormGroup;

  constructor(private dialog: MatDialog,
              private store: Store<State>
            ) {
                this.influencerSelectedForm = new FormGroup({
                  'selected': new FormControl(''),
                });
              }

  ngOnInit() {
    if (this.user && this.user.attributes) {
      this.currencyType = this.user.attributes['custom:currency_type'];
    }
  }

  openModalShareInfluencerGenerateLink() {
    const dialogRef = this.dialog.open(ModalShareInfluencerGenerateLinkComponent, {
      width: '400px',
      height: '300px',
      autoFocus: false,
      data: {
        influencerId: this.influencer.id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openFavouritesList(influencer) {
    this.dialog.open(ModalFavouritesListComponent, {
      width: '40%',
      height: '40%',
      minWidth: '540px',
      minHeight: '300px',
      autoFocus: false,
      data: {
        influencerId: influencer.id,
        network: influencer.type,
        username: influencer.username
      },
    });
  }

  openPlans() {
    if (environment.platform_id !== 'binfluencer') {
      this.store.dispatch(openPlansDialog());
    }
  }


  desactivateAllCheckbox() {
    this.influencerSelectedForm.controls.selected.setValue(false);
  }

  selectInfluencerForAddToCampaign(event: any, influencer) {
    // Si el checkbox está marcado, agregar la clase
    if (event.checked) {
      const cardElement = document.getElementById(influencer.influencer_id); // Asegúrate de que este sea el identificador correcto para tu carta
      if (cardElement) {
        cardElement.classList.add('selected-card');
      }
    } else {
      // Si el checkbox está desmarcado, quitar la clase
      const cardElement = document.getElementById(influencer.influencer_id); // Asegúrate de que este sea el identificador correcto para tu carta
      if (cardElement) {
        cardElement.classList.remove('selected-card');
      }
    }
    event.influencer_id = influencer.id;
    event.network = influencer.type;
    event.full_name = influencer.full_name;
    event.profile_pic_url = influencer.profile_pic_url;
    event.username = influencer.username;
    this.selectInfluencerForAdd.emit(event);
  }
  
}
