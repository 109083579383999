import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AdminManagementService} from '../../../../store/admin-management.service';
import {MatSnackBar, MatTableDataSource, Sort} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-users-info',
  templateUrl: './users-info.component.html',
  styleUrls: ['./users-info.component.scss']
})
export class UsersInfoComponent implements OnInit, OnDestroy {

  private subscriptions = [];

  usersInfoResponse = [];
  usersInfoSelected = [];
  @Output() callViewAccountSettings = new EventEmitter<any>();

  loading = false;
  morePagination = true;
  displayedColumns = ['username', 'group', 'phone_number', 'plan_type', 'currency_type', 'email_verified', 'registration_date', 'last_login', 'view'];
  emailForm: FormGroup;

  dataSource = new MatTableDataSource<any>();

  constructor(private adminManagementService: AdminManagementService,
              private snackbar: MatSnackBar,
              private router: Router) {
    this.emailForm = new FormGroup({
      'email': new FormControl('', [
        Validators.pattern(
            '^[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$'
        ),
      ]),
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getUsersInfo(paginationToken, username?) {
    this.loading = true;
    this.subscriptions.push(
      this.adminManagementService.getUsersInfo(paginationToken, username).subscribe( response => {
        if ( response.status === 204 ) {
          this.openSnackBar('You have already seen all the users', 'Close');
          this.loading = false;
        } else {
          if (response.body.pagination_token === undefined ) {
            this.morePagination = false;
          }
          this.usersInfoResponse['users_array'] = response.body.users_array;
          this.usersInfoSelected = this.usersInfoResponse['users_array'];
          this.dataSource.data = this.usersInfoSelected;
          this.usersInfoResponse['pagination_token'] = response.body.pagination_token;
          this.loading = false;
        }

      }, () => {
        this.openSnackBar('Oops something went wrong, please try again later', 'Close');
        this.loading = false;
        this.loading = false;
      })
    );

  }

  viewMorePaginationUsers() {
    this.loading = true;
    if (this.emailForm.controls['email'].value !== '' && this.emailForm.controls['email'].value !== undefined) {
      this.getUsersInfo(this.usersInfoResponse['pagination_token'], this.emailForm.controls['email'].value);
    } else {
      this.getUsersInfo(this.usersInfoResponse['pagination_token']);
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar']
    });
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username':
          return this.compare(a.username, b.username, isAsc);
        case 'sub':
          return this.compare(a.sub, b.sub, isAsc);
        case 'group':
          return this.compare(a.group, b.group, isAsc);
        case 'phone_number':
          return this.compare(a.phone_number, b.phone_number, isAsc);
        case 'plan_type':
          return this.compare(a.plan_type, b.plan_type, isAsc);
        case 'currency_type':
          return this.compare(a.currency_type, b.currency_type, isAsc);
        case 'email_verified':
          return this.compare(a.email_verified, b.email_verified, isAsc);
        case 'registration_date':
          return this.compare(a.registration_date, b.registration_date, isAsc);
        case 'last_login':
          return this.compare(a.last_login, b.last_login, isAsc);
        default:
          return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  viewAccountSettings(username) {
    this.router.navigate(['/adminManagement/customerSettings/' + username]);
  }

  searchAccount() {
    this.getUsersInfo(0, this.emailForm.controls['email'].value);
  }

}
