import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-show-reason-influencer-rejected-modal',
  templateUrl: './show-reason-influencer-rejected-modal.component.html',
  styleUrls: ['./show-reason-influencer-rejected-modal.component.scss']
})
export class ShowReasonInfluencerRejectedModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
