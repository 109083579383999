import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminManagementService} from '../../store/admin-management.service';

@Component({
  selector: 'app-transfer-admin-modal',
  templateUrl: './transfer-admin-modal.component.html',
  styleUrls: ['./transfer-admin-modal.component.scss']
})
export class TransferAdminModalComponent implements OnInit, OnDestroy {

  emailForTransferForm: FormGroup;

  loading = false;
  transferFinished = false;

  errorTransfer = 0;
  successTransfer = 0;

  private subscriptions = [];

  constructor(
      private adminManagementService: AdminManagementService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private snackBar: MatSnackBar
  ) {
    this.emailForTransferForm =  new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(
          "^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$"
      )
      ])
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  transferCampaigns() {
    this.loading = true;
    this.data.campaignsForTransfer.forEach((campaignId, index) => {
      this.subscriptions.push(this.adminManagementService.transferCampaigns(campaignId, this.data.user_sub, this.emailForTransferForm.controls['email'].value).subscribe( () => {
            this.successTransfer += 1;
            if (index + 1 === this.data.campaignsForTransfer.length) {
              this.loading = false;
            }
          }, error => {
            this.errorTransfer += 1;
            if (index + 1 === this.data.campaignsForTransfer.length) {
              this.loading = false;
            }
          })
      );
    });
    this.transferFinished = true;
  }


  transferAccount() {
    this.loading =  true;
    this.subscriptions.push(this.adminManagementService.transferAccount(this.data.user_sub, this.emailForTransferForm.controls['email'].value).subscribe( () => {
      this.openSnackBar('Account successfully transferred', 'Ok');
      this.loading = false;
    }, error => {
      this.openSnackBar(error.error.message, 'Ok');
      this.loading = false;
    }));
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
