import {Component, Inject, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InfluencerProfileService } from '../../../../services/influencer-profile.service';
import { AnalyzeInfluencerComponent } from '../../../share/modals/analyze-influencer/analyze-influencer.component';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-add-youtuber-modal',
  templateUrl: './add-youtuber-modal.component.html',
  styleUrls: ['./add-youtuber-modal.component.scss']
})
export class AddYoutuberModalComponent implements OnInit {
  youtube_form: FormGroup;
  tiktok_form: FormGroup;
  loading = false;
  regexYoutube = /(\/channel)\/[\w-]+|(\/user)\/[\w-]+/;

  constructor(public _influencerProfileService: InfluencerProfileService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<AddYoutuberModalComponent>,
              public snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.youtube_form = new FormGroup({
      'youtuber_id': new FormControl('', [
        Validators.required,
        Validators.pattern(this.regexYoutube)
      ]),
    });
    this.tiktok_form = new FormGroup({
      'tiktoker_id': new FormControl('', [
        Validators.required,
        Validators.pattern(/https?:\/\/(www\.)?[tiktok]{1,256}\.[com]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)
      ]),
    });
  }
  ngOnInit() { }

  sendForm(network) {
    let url_match;
    let url;
    if (network === 'youtube') {
      url = this.youtube_form.get('youtuber_id').value;
      url_match = this.regexYoutube.exec(url);
    } else if ( network === 'tiktok') {
      url = this.tiktok_form.get('tiktoker_id').value;
      url_match = url.split('@');
      url_match[0] = url_match[1].split('?')[0];
    }
    this.analyzeInfluencer(url_match[0], '', network, 'True');
  }

  analyzeInfluencer(username: string, influencer_id: string, network: string, notify_user: string) {
    this.loading = true;
    this._influencerProfileService.analyzeInfluencer(username, influencer_id, network, notify_user).subscribe(data => {
      if (notify_user === 'True') {
        this.openAnalyzeInfluencerResponseDialog(data);
      }
    }, error => {
      if (notify_user === 'True') {
        this.openAnalyzeInfluencerResponseDialog(error);
      }
    });
  }

  /**
   * Modal response of the Analyze influencer, tell to client if we are analysing the influencer or an error ocurred
   * @param data
   */
  public openAnalyzeInfluencerResponseDialog(data): void {
    this.dialogRef.close();
    this.dialog.open(AnalyzeInfluencerComponent, {
      height: '250px',
      width: '400px',
      autoFocus: false,
      data: {data: data}
    });
  }

}
