import {Component, Input, OnInit} from '@angular/core';
import {Influencer} from '../../../../../../../types/influencer/influencer';
import {environment} from '../../../../../../../../environments/environment';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../../store';
import {openPlansDialog} from '../../../../../../../store/app/app.actions';

@Component({
  selector: 'app-influencer-profile-stats',
  templateUrl: './influencer-profile-stats.component.html',
  styleUrls: ['./influencer-profile-stats.component.scss']
})
export class InfluencerProfileStatsComponent implements OnInit {

  @Input() influencer: Influencer;
  @Input() currency_type;
  @Input() currency_symbol;
  @Input() planNameShown;

  constructor(
    private store: Store<State>,
  ) { }

  ngOnInit() {}

  openPlans() {
    if (environment.platform_id !== 'binfluencer') {
      this.store.dispatch(openPlansDialog());
    }
  }

}
