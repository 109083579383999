import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CampaignsService} from '../../../../services/campaigns.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-remove-influencer',
  templateUrl: './remove-influencer.component.html',
  styleUrls: ['./remove-influencer.component.scss']
})
export class RemoveInfluencerComponent implements OnInit, OnDestroy {

  loading = false;
  private subscriptions = [];

  constructor(private _campaignsService: CampaignsService,
              public dialogRef: MatDialogRef<RemoveInfluencerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  removeInfluencer() {
    this.loading = true;
  
    const influencer_ids = this.data.influencers 
      ? this.data.influencers.map(influencer => influencer.influencer_id) 
      : [this.data.influencer_id];
  
    this.subscriptions.push(
      this._campaignsService.removeInfluencerFromCampaign(this.data.campaign_id, influencer_ids).subscribe(
        result => this.dialogRef.close(result),
        error => this.dialogRef.close(error)
      )
    );
  }
  

}
