import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin-management',
  templateUrl: './admin-management.component.html',
  styleUrls: ['./admin-management.component.scss']
})
export class AdminManagementComponent {

  @Input() user_email;

  constructor(private router: Router) {}

  selectOption (option) {
    if (option === 'dashboard') {
      this.router.navigate(['/adminManagement/monitoring']);
    } else if (option === 'customer') {
      this.router.navigate(['/adminManagement/customerSettings']);
    } else if (option === 'reportDashboards') {
      this.router.navigate(['/adminManagement/reportDashboards']);
    } else if (option === 'userGroups') {
      this.router.navigate(['/adminManagement/userGroups']);
    }
  }
}
