import {Component, OnDestroy, OnInit} from '@angular/core';
import * as selectorsApp from '../../../../../../store/app/app.selectors';
import {selectAppViewPort} from '../../../../../../store/app/app.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../store';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ReportDashboardService} from '../../../../../../services/report-dashboard.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalDashboardGenerateLinkSharedComponent} from '../../modals/modal-dashboard-generate-link-shared/modal-dashboard-generate-link-shared.component';
import {environment} from '../../../../../../../environments/environment';
import {AmplifyService} from 'aws-amplify-angular';
import {ModalEditDashboardNameComponent} from '../../modals/modal-edit-dashboard-name/modal-edit-dashboard-name.component';

@Component({
  selector: 'app-report-dashboard',
  templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.scss']
})
export class ReportDashboardComponent implements OnInit, OnDestroy {

  viewport;
  dashboards = [];
  loading = false;

  dashboard_id;


  error_brand_logo_upload = '';
  uploading_brand_logo;


  private subscriptions = [];

  constructor(public store: Store<State>,
              private _dashboardService: ReportDashboardService,
              private activatedRoute: ActivatedRoute,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private router: Router,
              private _amplifyService: AmplifyService,
  ) { }

  static getExtensionFromImage(file_type: string): string {
    return file_type.replace('image/', '.');
  }

  static getUrlImageWithTime(url_image) {
    if (url_image !== 'n/a') {
      return url_image + '?ts=' + new Date().getTime();
    } else {
      return url_image;
    }
  }

  ngOnInit() {
    this.loading =  true;
    this.dashboard_id = this.activatedRoute.params['value'].dashboard_id;
    this.store.select(selectAppViewPort).subscribe(result => {
      this.viewport = result;
    });
    this.getReportDashboard();
  }

  getReportDashboard() {
    this.loading =  true;
    this._dashboardService.getDashboard(this.dashboard_id).subscribe( result => {
      this.dashboards = result.body;
      if (this.dashboards['dashboard_logo'] === '') {
        this.subscriptions.push(
            this.store.select(selectorsApp.selectUserGroup).subscribe(userGroup => {
              if (userGroup !== null ) {
                this.dashboards['dashboard_logo'] = userGroup.logo_url;
              }
            })
        );
      }
      this.dashboards['influencers_in_report'] = [this.dashboards['influencer_count']];
      this.loading =  false;
    }, () => {
      this.openSnackBar('Oops something went wrong, please try again', 'Close');
      this.loading =  false;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  openModalDashboardGenerateLinkShared() {
    this.dialog.open(ModalDashboardGenerateLinkSharedComponent, {
      width: 'auto',
      height: 'auto',
      autoFocus: false,
      data: {
        dashboard_url_shared: this.dashboards['dashboard_shared_url'] ,
      }
    });
  }

  editDashboardName() {
    const dialogRef = this.dialog.open(ModalEditDashboardNameComponent, {
      width: '500px',
      height: 'auto',
      autoFocus: false,
      data: {
        dashboard_id: this.dashboard_id,
        dashboard_name: this.dashboards['dashboard_name'],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dashboards['dashboard_name'] = result;
      }
    });
  }

  editDashboardLogo(fileInput) {
    const file = fileInput.target.files[0];
    this.uploading_brand_logo = true;
    if (file.size <= 5242880) {
      if (
          file.type === 'image/png' ||
          file.type === 'image/jpeg' ||
          file.type === 'image/jpg'
      ) {
        const extension = ReportDashboardComponent.getExtensionFromImage(
            file.type
        );
        const config = {
          bucket: environment.aws_resources.s3.buckets.dashboards_logo,
          region: environment.aws_resources.region,
          level: 'public',
          customPrefix: {
            public: '',
          },
        };
        this._amplifyService
            .storage()
            .vault.put(this.dashboard_id + extension, file, config)
            .then(() => {
              const url =
                  'https://s3-eu-west-1.amazonaws.com/' +
                  config.bucket +
                  '/' +
                  this.dashboard_id +
                  extension;
              const body = {
                dashboard_logo: url,
              };
              this._dashboardService.updateDashboard(this.dashboard_id, body).subscribe(
                  () => {
                    this.dashboards['dashboard_logo'] = ReportDashboardComponent.getUrlImageWithTime(
                        body.dashboard_logo
                    );
                    this.uploading_brand_logo = false;
                  },
                  () => {
                    this.error_brand_logo_upload =
                        'Oops, something went wrong. Please try again later.';
                  }
              );
            })
            .catch(() => {
              this.error_brand_logo_upload =
                  'Oops, something went wrong. Please try again later.';
              this.uploading_brand_logo = false;
            });
      } else {
        this.error_brand_logo_upload =
            'Unsupported file type. Supported file types are: jpg, jpeg and png';
        this.uploading_brand_logo = false;
      }
    } else {
      this.error_brand_logo_upload = 'The file exceeds 5MB';
      this.uploading_brand_logo = false;
    }
  }

  setVirtualScrollHeight(array_length) {
    if (this.viewport === 'handset') {
      if (array_length >= 10) {
        return '1300px';
      } else {
        return (array_length * 400).toString() + 'px';
      }
    } else {
      if (array_length >= 10) {
        return '1300px';
      } else {
        return (array_length * 100).toString() + 'px';
      }
    }
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
