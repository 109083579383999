import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import * as actions from './modal-plans.actions';
import {of} from 'rxjs';
import {ModalPlansService} from './modal-plans.service';
import {select, Store} from '@ngrx/store';
import {selectPlans} from './modal-plans.selectors';
import {PlanState} from './modal-plans.reducers';


@Injectable()
export class ModalPlansEffects {

  constructor(
    private actions$: Actions,
    private modalPlansService: ModalPlansService,
    private store: Store<PlanState>) {
  }

  getPlans$  = createEffect(() => this.actions$.pipe(
    ofType(actions.getPlans),
    withLatestFrom(this.store.pipe(select(selectPlans))),
    switchMap(([action, cachedPlans]) => {
        // if (cachedPlans) {
        //   return of(actions.getPlansSuccess({plans: cachedPlans}));
        // }
        return this.modalPlansService.getPlans().pipe(
          map(plans => {
            return actions.getPlansSuccess({ plans }); }),
          catchError(error => of(actions.getPlansFail({ error })))
        );
      }
    )
  ));

}
