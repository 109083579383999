import {Component, Inject, OnInit} from '@angular/core';
import {ReportService} from '../../../../../services/report.service';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-modal-add-comment-influencer-report',
  templateUrl: './modal-add-comment-influencer-report.component.html',
  styleUrls: ['./modal-add-comment-influencer-report.component.scss']
})
export class ModalAddCommentInfluencerReportComponent implements OnInit {

  influencerCommentForm: FormGroup;

  constructor(
      private snackBar: MatSnackBar,
      private _reportService: ReportService,
      public dialogRef: MatDialogRef<ModalAddCommentInfluencerReportComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.influencerCommentForm = new FormGroup({
      'influencerComment': new FormControl(''),
      'displayInfluencerComment': new FormControl(''),
    });
  }

  ngOnInit() {
    if (this.data.comment) {
      this.influencerCommentForm.controls.influencerComment.setValue(this.data.comment);
    }
    if (this.data.displayComment) {
      this.influencerCommentForm.controls.displayInfluencerComment.setValue(this.data.displayComment);
    }
  }


  saveInfluencerReportComment() {
    const commentValue = this.influencerCommentForm.controls.influencerComment.value;
    const displayValue = this.influencerCommentForm.controls.displayInfluencerComment.value;

    if (commentValue !== '') {
      const displayCommentInShare = displayValue === '' ? false : displayValue;
      this._reportService.saveInfluencerComment(this.data.campaign_id, this.data.influencer_id, commentValue, displayCommentInShare).subscribe( () => {
        this.openSnackBar('Saved successfully', 'Ok');
        this.dialogRef.close({'status': 'success', 'comment': commentValue});
      }, () => {
        this.openSnackBar('Oops, something went wrong. Please try again', 'Ok');
      });
    }
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
