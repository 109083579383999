import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FavouritesService {

    private urlApi = environment.aws_resources.lambda_endpoint.api;

    constructor(private http: HttpClient) {
    }

    createFavouritesList(favouriteListName) {
        const body = {
            favorite_list_name: favouriteListName,
        };
        return this.http.post(this.urlApi + 'favorites/', body, {observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }

    getFavouritesList(influencerId?) {
        if (influencerId) {
            return this.http.get(this.urlApi + 'favorites/list/' + influencerId, {observe: 'response'}).pipe(map((data: any) => {
                return data;
            }));
        } else {
            return this.http.get(this.urlApi + 'favorites/list', {observe: 'response'}).pipe(map((data: any) => {
                return data;
            }));
        }
    }

    getFavouritesExportCSV(favouriteId) {
        return this.http.get(this.urlApi + 'favorites/' + favouriteId + '/download', {observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }

    exportToCsv(filename, rows) {
        if (!rows || !rows.length) {
            return;
        }
        const separator = ';';
        // Definir las claves en el orden deseado
        const keys = ['username', 'full_name', 'influencer_id', 'network', 'engagement_rate', 'followed_by_count'];
        const csvData = keys.join(separator) + '\n' + rows.map(row => {
            return keys.map(k => {
                let cell = row[k] === null || row[k] === undefined ? '' : row[k];
                cell = cell instanceof Date
                    ? cell.toLocaleString()
                    : cell.toString().replace(/"/g, '""');
                if (cell.search(/([",\n])/g) >= 0) {
                    cell = `"${cell}"`;
                }
                return cell;
            }).join(separator);
        }).join('\n');
    
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    getInfluencersFavouritesList(favoriteListId) {
        return this.http.get(this.urlApi + 'favorites/' + favoriteListId + '/influencer', {observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }

    addInfluencerToFavouriteList(favouriteId, influencerId, username, network) {
        const body = {
            influencer_id: influencerId,
            network: network,
            username: username,
        };
        return this.http.post(this.urlApi + 'favorites/' + favouriteId + '/influencer', body, {observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }

    removeFavouritesList(favorite_list_id) {
        return this.http.delete(this.urlApi + 'favorites/' + favorite_list_id , {observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }

    removeInfluencerFromFavouritesList(favorite_list_id, network, influencer_id) {
        let params = new HttpParams();
        params = params.append('influencer_id', influencer_id);
        params = params.append('network', network);

        return this.http.delete(this.urlApi + 'favorites/' + favorite_list_id + '/influencer' , {observe: 'response', params}).pipe(map((data: any) => {
            return data;
        }));
    }


    fullCrawlInfluencers(influencers) {
        return this.http.put(this.urlApi + 'crawl-control/'  + 'batch_analyze', influencers, {observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }
}
