export class BriefCampaignForUpdateClass {
  date_start = 0;
  date_end = 0;
  date_end_extended = 0;
  brand_name = '';
  send_gift_influencer = false;
  payment_type = 'cpm';
  campaign_general_description = '';
  hashtag_list = [];
  mentions_compulsory_elements = [];
  destination_url = '';
  inspirational_media = [];
  influencers = [];
  language_for_emails = '';
  monetary_for_emails = '';
}
