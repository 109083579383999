import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer, authenticationKey } from './authentication.reducers';
import { AuthenticationEffects } from './authentication.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(authenticationKey, reducer),
    EffectsModule.forFeature([AuthenticationEffects])
  ],
  exports: [
    StoreModule
  ]
})
export class AuthenticationStoreModule {

}
