import {Component, OnInit} from '@angular/core';
import {AdminManagementService} from '../../store/admin-management.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-manage-campaign',
  templateUrl: './manage-campaign.component.html',
  styleUrls: ['./manage-campaign.component.scss']
})
export class ManageCampaignComponent implements OnInit {

  loading;
  campaign;
  campaign_id;

  private subscriptions = [];

  constructor(private adminManagementService: AdminManagementService,
              private activatedRouter: ActivatedRoute,
  ) { }

  ngOnInit() {
      this.campaign_id = this.activatedRouter.params['value'].campaign_id;
      this.loading = true;
      this.subscriptions.push(
          this.adminManagementService.getManageCampaign(this.campaign_id).subscribe( result => {
              this.campaign = result.body;
              this.loading = false;
              }, () => {
              this.campaign = [];
              this.loading = false;
          })
      );
  }

}
