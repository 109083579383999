import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ReportInfluencerContentModalComponent} from '../../report-influencer-content-modal/report-influencer-content-modal.component';
import {MatDialog} from '@angular/material';
import {ModalShareInfluencerReportUrlComponent} from '../../modal-share-influencer-report-url/modal-share-influencer-report-url.component';
import {selectAppViewPort} from '../../../../../../store/app/app.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../store';
import {ModalFavouritesListComponent} from '../../../../../../features/private/components/favourites/modals/modal-favourites-list/modal-favourites-list.component';
import {ModalAddCommentInfluencerReportComponent} from '../../modal-add-comment-influencer-report/modal-add-comment-influencer-report.component';

@Component({
  selector: 'app-report-influencers',
  templateUrl: './report-influencers.component.html',
  styleUrls: ['./report-influencers.component.scss']
})
export class ReportInfluencersComponent implements OnInit, OnDestroy {

  @Input() report;
  @Input() currency_type;
  @Input() currency_symbol;
  @Input() campaign_id;
  @Input() shared;

  viewport;

  private subscriptions = [];

  constructor(public dialog: MatDialog,
              private store: Store<State>,
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectAppViewPort).subscribe(result => {
        this.viewport = result;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  openModalReportInfluencerContent(influencer_id) {
    this.dialog
      .open(ReportInfluencerContentModalComponent, {
        width: '80%',
        height: '83%',
        autoFocus: false,
        data: {
          influencer_id: influencer_id,
          campaign_id: this.campaign_id,
          currency_type: this.currency_type,
          currency_symbol: this.currency_symbol,
          is_share: this.shared,
          dateStart: this.report.date_start,
          dateEnd: this.report.date_end,
          isShare: this.shared,
        },
      })
      .afterClosed()
      .subscribe(() => {
        // this.getReport(); ver que hago aqui, esto tiene que verse
      });
  }

  setVirtualScrollHeight(array_length) {
    if (this.viewport === 'handset') {
      if (array_length >= 10) {
        return '1300px';
      } else {
        return (array_length * 400).toString() + 'px';
      }
    } else {
      if (array_length >= 10) {
        return '1300px';
      } else {
        return (array_length * 100).toString() + 'px';
      }
    }

  }

  shareInfluencerReport(influencerId) {
    this.dialog.open(ModalShareInfluencerReportUrlComponent, {
      width: '600px',
      height: '330px',
      autoFocus: false,
      data: {
        campaignId: this.campaign_id,
        influencerId: influencerId,
      },
    });
  }

  openFavouritesList(influencer) {
    this.dialog.open(ModalFavouritesListComponent, {
      width: '40%',
      height: '40%',
      minWidth: '540px',
      minHeight: '300px',
      autoFocus: false,
      data: {
        influencerId: influencer.influencer_id,
        network: influencer.network,
        username: influencer.username
      },
    });
  }

  openModalAddInfluencerComment(influencer) {
    const dialogRef = this.dialog.open(ModalAddCommentInfluencerReportComponent, {
      width: '500px',
      height: 'auto',
      autoFocus: false,
      data: {
        influencer_id: influencer.influencer_id,
        campaign_id: this.campaign_id,
        comment: influencer.qualitative_comment_data.comment,
        displayComment: influencer.qualitative_comment_data.share_comment,
        isShare: this.shared,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'success') {
        influencer.qualitative_comment_data.comment = result.comment;
      }
    });
  }

}
