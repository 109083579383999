import {Injectable} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AiService {

    private url = environment.aws_resources.lambda_endpoint.api;

    constructor(private http: HttpClient) {}

    public getConversations(campaignId) {
        return this.http.get(this.url + 'ai-suite/ai-get-brief-conversation/' + campaignId, {observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }
    public getSuggestions() {
        return this.http.get(this.url + 'ai-suite/ai-get-brief-suggestions', {observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }
}
