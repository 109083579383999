import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material';
import {ReportDashboardService} from '../../../../../../services/report-dashboard.service';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from '../../../../../../store/auth/authentication.service';
import * as selectorsAuth from '../../../../../../store/auth/authentication.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../store';

@Component({
  selector: 'app-modal-new-report-dashboard',
  templateUrl: './modal-new-report-dashboard.component.html',
  styleUrls: ['./modal-new-report-dashboard.component.scss']
})
export class ModalNewReportDashboardComponent implements OnInit , OnDestroy {

  new_report_dashboard_form: FormGroup;
  loading = false;

  currencies = [];

  private subscriptions = [];

  constructor(private _dashboardService: ReportDashboardService,
              private _authService: AuthenticationService,
              private store: Store<State>,
              public dialogRef: MatDialogRef<ModalNewReportDashboardComponent>,
              private snackBar: MatSnackBar) {

    this.new_report_dashboard_form = new FormGroup({
      'report_dashboard_name': new FormControl('', [
        Validators.required,
        Validators.maxLength(25)
      ]),
      'custom:currency_type': new FormControl('', [
        Validators.required,
      ]),
    });
  }

  ngOnInit() {
    this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
      this.new_report_dashboard_form.controls['custom:currency_type'].setValue(user.currency);
    });
    this.subscriptions.push(
        this._authService.getPublicCurrencies().subscribe( response => {
          this.currencies = response;
        }, () => {
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  createReportDashboard(reportDashboardName) {
    this.loading = true;
    if (this.new_report_dashboard_form.valid) {
      this._dashboardService.createReportDashboard(reportDashboardName, this.new_report_dashboard_form.controls['custom:currency_type'].value).subscribe( () => {
        this.loading = false;
        this.openSnackBar('Dashboard created successfully', 'Ok');
        this.dialogRef.close('success');
      }, () => {
        this.loading = false;
        this.openSnackBar('There has been an error. Please try again.', 'Ok');
      });
    }
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
