import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog, MatTableDataSource, Sort} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TransferAdminModalComponent} from '../../../../modals/transfer-admin-modal/transfer-admin-modal.component';

@Component({
  selector: 'app-user-campaigns',
  templateUrl: './user-campaigns.component.html',
  styleUrls: ['./user-campaigns.component.scss']
})
export class UserCampaignsComponent implements OnInit {

  @Input() campaigns;
  @Input() user_sub;

  @Output() callRefreshCampaigns = new EventEmitter<any>();


  displayedColumns: string[] = [
    'campaign_name', 'creation_date', 'launch_date', 'campaign_status', 'admin_mode', 'transfer_campaign',
  ];

  selectTransferCampaings = false;

  emailForm: FormGroup;
  checkboxTransferCampaignForm: FormGroup;

  campaignsForTransfer = [];

  dataSource = new MatTableDataSource<any>();

  constructor(
      private dialog: MatDialog
  ) {
    this.emailForm = new FormGroup({
      'email': new FormControl('', [
        Validators.pattern(
            '^[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$'
        ),
      ]),
    });
    this.checkboxTransferCampaignForm = new FormGroup({});
  }

  ngOnInit() {
    this.dataSource.data = this.campaigns;
    // Create and get input emails
    this.campaigns.forEach(
        (campaign, index_pending) => {
          this.checkboxTransferCampaignForm.setControl(
              'campaign_' + index_pending,
              new FormControl('')
          );
        }
    );
  }


  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
    }
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'campaign_name':
          return this.compare(a.campaign_name, b.campaign_name, isAsc);
        case 'creation_date':
          return this.compare(a.creation_date, b.creation_date, isAsc);
        case 'launch_date':
          return this.compare(a.launch_date, b.launch_date, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  searchAccount($event) {
    this.dataSource = new MatTableDataSource(this.campaigns.filter(e => e.campaign_name.toLowerCase().includes($event.target.value.toLowerCase())));
  }

  addCampaignToTransfer(event, campaignId) {
    if (event.checked) {
      this.campaignsForTransfer.push(campaignId);
    } else {
      for (
          let index = 0;
          index < this.campaignsForTransfer.length;
          index++
      ) {
        if (
            campaignId ===
            this.campaignsForTransfer[index]
        ) {
          this.campaignsForTransfer.splice(index, 1);
        }
      }
    }
    this.selectTransferCampaings = this.campaignsForTransfer.length > 0;
  }

  transferCampaigns() {
    const dialogRef = this.dialog.open(TransferAdminModalComponent, {
      width: '800px',
      height: '300px',
      autoFocus: false,
      data: {
        isTransferCampaign: true,
        campaignsForTransfer: this.campaignsForTransfer,
        user_sub: this.user_sub
      }
    });
    dialogRef.afterClosed().subscribe(() => {
        this.callRefreshCampaigns.emit();
    });
  }

}
