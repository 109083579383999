import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private reportUrl = environment.aws_resources.lambda_endpoint.api;

  constructor(private http: HttpClient) {}

  public getReport(campaign_id) {
    return this.http.get(this.reportUrl + 'reports/' + campaign_id, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  /** add influencer media **/
  addPostManually(campaign_id, instagram_id, media_shortcode, media_type?) {
    let body = {};
    if (media_type) {
      body = {
        media_type: media_type
      };
    }

    return this.http.post(this.reportUrl + 'reports-influencers/' + campaign_id + '/influencer/' + instagram_id + '/instagram_post/' + media_shortcode, body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }


  updatePostManually(campaign_id, instagram_id, media_shortcode, media_type?) {
    let body = {};
    if (media_type) {
      body = {
        media_type: media_type
      };
    }

    return this.http.put(this.reportUrl + 'reports-influencers/' + campaign_id + '/influencer/' + instagram_id + '/update_media/' + media_shortcode, body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  removePost (campaign_id, instagram_id, media_shortcode) {
    return this.http.delete(this.reportUrl + 'reports-influencers/' + campaign_id + '/influencer/' + instagram_id + '/media_id/' + media_shortcode, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getStories(campaign_id, instagram_id, requested_date) {
    let params = new HttpParams();
    params = params.append('requested_date', requested_date);

    return this.http.get(this.reportUrl + 'reports-influencers/' + campaign_id + '/influencer/' + instagram_id + '/stories', {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }


  getLiveStories(campaignId, influencerId) {
    return this.http.get(this.reportUrl + 'influencer/' + 'get_live_stories/' + campaignId + '/' + influencerId, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  addStoryManually(campaign_id, instagram_id, story_id) {
    const body = {};
    return this.http.post(this.reportUrl + 'reports-influencers/' + campaign_id + '/influencer/' + instagram_id + '/stories/' + story_id, body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  public shareReport(campaign_id) {
    const body = {
      platform_id: environment.platform_id,
    };
    return this.http.post(this.reportUrl + 'reports/' + campaign_id + '/share', body,  {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  public getSharedReport(hash_report_id) {
    return this.http.get(this.reportUrl + 'reports/shared/' + hash_report_id, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  public getSharedInfluencerReport(hash_report_id, hash_influencer_id) {
    return this.http.get(this.reportUrl + 'reports-influencers/shared/' + hash_report_id + '/influencer_report/' +  hash_influencer_id, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getInfluencerReportUrl(campaignId, influencerId) {
    const body = {
      platform_id : environment.platform_id,
    };
    return this.http.post(this.reportUrl + 'reports-influencers/get_shared_individual_report_url/' + campaignId + '/influencer/' + influencerId, body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }


  public getInfluencerReportData(campaign_id, influencer_id) {
    return this.http.get(this.reportUrl + 'reports-influencers/' + campaign_id + '/influencer/' + influencer_id, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  public getSharedInfluencerReportData(campaign_id, influencer_id) {
    return this.http.get(this.reportUrl + 'reports-influencers/shared/' + campaign_id + '/influencer/' + influencer_id, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  public updateShareReport(report_hash) {
    return this.http.get(this.reportUrl + 'reports/send_to_upsert_report_data/' + report_hash, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  public sendToUpdateReportData(campaign_id) {
    return this.http.get(this.reportUrl + 'reports/' + campaign_id + '/send_to_upsert_report_data_queue', {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  updateImpressions(campaign_id, media_id, new_stats) {
    const body = {
      new_stats: new_stats
    };
    return this.http.post(this.reportUrl + 'reports-influencers/' + campaign_id + '/update/' + media_id, body, { observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  /** Upsert media insnight url **/
  uploadInsights(campaign_id, media_id, image_insight) {
    const body = {
      image_insight: image_insight
    };
    return this.http.put(this.reportUrl + 'reports-influencers/' + campaign_id + '/media/' + media_id, body, { observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  sendEmailAllContent(campaign_id, email) {
    const body = {
      destination_email: email
    };
    return this.http.put(this.reportUrl + 'reports/' + campaign_id + '/download_medias', body, { observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  uploadStoryManuallyForm(campaignId, influencerId, story) {
    return this.http.post(this.reportUrl + 'reports-influencers/' + campaignId + '/influencer/' + influencerId + '/upload_custom_story', story, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }


  getReportExportCSV(campaign_id) {
    return this.http.get(this.reportUrl + 'reports/' + campaign_id + '/export', {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getRecentConversions(campaign_id, items_per_page, page_to_load) {
    let params = new HttpParams();
    params = params.append('items_per_page', items_per_page);
    params = params.append('page_to_load', page_to_load);
    return this.http.get(this.reportUrl + 'campaigns/' + campaign_id + '/campaign_activity_feed', {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  getRecentInfluencerConversions(campaign_id, influencerId, items_per_page, page_to_load, ) {
    let params = new HttpParams();
    params = params.append('items_per_page', items_per_page);
    params = params.append('page_to_load', page_to_load);
    return this.http.get(this.reportUrl + 'campaigns/' + campaign_id + '/influencer/' + influencerId + '/activity_feed', {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  getRecentConversionsShare(report_hash, items_per_page, page_to_load) {
    let params = new HttpParams();
    params = params.append('items_per_page', items_per_page);
    params = params.append('page_to_load', page_to_load);

    return this.http.get(this.reportUrl + 'campaigns/shared/' + report_hash + '/campaign_activity_feed', {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  getRecentInfluencerShareConversions(report_hash, influencerId, items_per_page, page_to_load) {
    let params = new HttpParams();
    params = params.append('items_per_page', items_per_page);
    params = params.append('page_to_load', page_to_load);
    return this.http.get(this.reportUrl + 'campaigns/shared/' + report_hash + '/influencer/' + influencerId + '/activity_feed', {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  exportToCsv(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ';';
    const keys = Object.keys(rows[0]);
    const csvData = keys.join(separator) + '\n' + rows.map(row => {
      return keys.map(k => {
        let cell = row[k] === null || row[k] === undefined ? '' : row[k];
        cell = cell instanceof Date
          ? cell.toLocaleString()
          : cell.toString().replace(/"/g, '""');
        if (cell.search(/([",\n])/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator);
    }).join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  saveGeneralPublishedComment(campaign_id, comment, forShare, isGeneral) {
    let body = {};
    if (isGeneral) {
      body = {
        comment: comment,
        share_comment: forShare,
        comment_type: 'general_comment',
      };
    } else {
      body = {
        comment: comment,
        share_comment: forShare,
        comment_type: 'top_comment',
      };
    }
    return this.http.post(this.reportUrl + 'reports/comment/' + campaign_id, body, { observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  saveInfluencerComment(campaign_id, influencer_id, comment, forShare) {
    const body = {
      comment: comment,
      share_comment: forShare
    };
    return this.http.post(this.reportUrl + 'reports-influencers/' + campaign_id + '/comment/' + influencer_id, body, { observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }
}
