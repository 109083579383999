import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'roundPercentageInput'
})
export class RoundPercentageInputPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value < 0) {
      return Math.round(0);
    }
    return Math.round(value);
  }

}
