import {Component, Input, OnInit} from '@angular/core';
import {selectAppViewPort} from '../../../../../store/app/app.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../../store';

@Component({
  selector: 'app-header-ai-dropdown-bar',
  templateUrl: './header-ai-dropdown-bar.component.html',
  styleUrls: ['./header-ai-dropdown-bar.component.scss']
})
export class HeaderAiDropdownBarComponent implements OnInit {

  showChat = false;
  viewport = '';

  @Input() campaignId;


  constructor(
      private store: Store<State>
  ) { }

  ngOnInit() {
    this.store.select(selectAppViewPort).subscribe(result => {
      this.viewport = result;
    });
  }

  deployChat() {
    if (this.showChat === true) {
      this.showChat = false;
    } else if (this.showChat === false) {
      this.showChat = true;
    }
  }
}
