import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {ReportDashboardService} from '../../../../../../services/report-dashboard.service';

@Component({
  selector: 'app-modal-add-campaign-to-dashboard',
  templateUrl: './modal-add-campaign-to-dashboard.component.html',
  styleUrls: ['./modal-add-campaign-to-dashboard.component.scss']
})
export class ModalAddCampaignToDashboardComponent implements OnInit, OnDestroy {

  add_campaign_to_dashboard_form: FormGroup;
  loading = false;

  private subscriptions = [];

  constructor(private _dashboardService: ReportDashboardService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<ModalAddCampaignToDashboardComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.add_campaign_to_dashboard_form = new FormGroup({
      'campaign_id': new FormControl('', [
        Validators.required
      ])
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  addCampaignToDashboard(campaign_id) {
    this.loading = true;
    if (this.add_campaign_to_dashboard_form.valid) {
      this._dashboardService.addCampaignToDashboard(this.data.dashboard_id, campaign_id).subscribe( response => {
        this.openSnackBar(response.body.message, 'Close');
        this.loading = false;
        this.dialogRef.close('success');
      }, error => {
        this.loading = false;
        if (error.error.message) {
          this.openSnackBar(error.error.message, 'Ok');
        } else {
          this.openSnackBar('There has been an error. Please try again.', 'Ok');
        }
      });
    }
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
