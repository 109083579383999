import {AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import * as actions from '../../store/modal-plans.actions';
// import {Plan} from '../../types/plans';
import * as selectors from '../../store/modal-plans.selectors';
import * as selectorsAuth from '../../../../store/auth/authentication.selectors';
import {Observable, Subscription} from 'rxjs';
import {PlanState} from '../../store/modal-plans.reducers';
import {AuthenticationService} from '../../../../store/auth/authentication.service';
import * as selectorsApp from '../../../../store/app/app.selectors';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import { ModalSelectCancelPausePlanComponent } from 'src/app/features/private/components/cancel-pause-plan/modals/modal-select-cancel-pause-plan/modal-select-cancel-pause-plan.component';


@Component({
  selector: 'app-modal-plans',
  templateUrl: './modal-plans.component.html',
  styleUrls: ['./modal-plans.component.scss']
})
export class ModalPlansComponent implements OnInit, OnDestroy, AfterViewChecked {

  loading_spinner = false;
  error_message = '';
  platformBrandLogo = '';
  isMonthlyPayment = true;
  plans$: Observable<any[]>;
  cancelledPlan = false;

  private subscriptions: Subscription[] = [];

  @Output() email;
  @Output() activePlan;
  @Output() nameActivePlan;

  loading_payment = false;

  constructor(
    public store: Store<PlanState>,
    private _authService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.plans$ = this.store.select(selectors.selectPlans);

    this.subscriptions.push(
        this.store.select(selectors.selectPlansLoading).subscribe(loading => {
          this.loading_spinner = loading;
          this.cdr.detectChanges();
        }),
        this.store.select(selectorsAuth.selectUser).subscribe(user => {
          this.email = user.username;
        }),
        this.store.select(selectors.selectPlansError).subscribe(errMessage => {
          this.error_message = errMessage;
        })
    );
    this.store.dispatch(actions.getPlans());
    this._authService.getPlanExpirationData().subscribe( res => {
      if (res['state'] === 'deleted') {
        this.cancelledPlan = true;
      }
    });

    this.plans$.subscribe( plans => {
      if (plans !== null) {
        this.activePlan = false;
        plans.forEach(plan => {
          if (plan.activePlan === true && plan.planName !== 'FREE') {
            this.activePlan = true;
            this.nameActivePlan = plan.planName;
          } else if (plan.activePlan === true) {
            this.nameActivePlan = plan.planName;
          }
        });
      }
    });
  }

  ngAfterViewChecked() {
    this.subscriptions.push(
        this.store.select(selectorsApp.selectUserGroup).subscribe(userGroup => {
          if (userGroup !== null ) {
            this.platformBrandLogo = userGroup.logo_url;
            this.cdr.detectChanges();
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  trackByPlan(index, item) {
    return !item ? null : item.id;
  }

  changeSlidePayment () {
    this.isMonthlyPayment = !this.isMonthlyPayment;
  }

  redirectCallSales() {
    window.open('https://calendly.com/nicolas-11posts/plans-and-pricing', '_blank');
  }

  cancelPlan() {
    this.dialog
    .open(ModalSelectCancelPausePlanComponent, {
      width: '415px',
      height: 'auto',
      panelClass: 'container-modal-plans',
      autoFocus: false,
      data: {}
    })
    .afterClosed();
  }

  freeTrial() {
    window.open('https://calendly.com/nicolas-11posts/plans-and-pricing', '_blank');
  }
}
