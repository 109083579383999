import {Component, Input, OnInit} from '@angular/core';
import { ShowStoryModalComponent } from '../../../../../components/private/campaigns/show-story-modal/show-story-modal.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-top-influencer-medias',
  templateUrl: './top-influencer-medias.component.html',
  styleUrls: ['./top-influencer-medias.component.scss']
})
export class TopInfluencerMediasComponent implements OnInit {

  @Input() media: any;

  constructor(
    private dialog: MatDialog
  ) {}

  ngOnInit() {}


  openShowStoryDialog(media): void {
    media['origin'] = 'report';
    const config = {
      width: 'auto',
      height: '100vh',
      autoFocus: false,
      data: {
        media: media
      },
      backdropClass: 'story-dialog-backdrop-class',
      panelClass: '',
    };
    if (media.network === 'instagram') {
      config.panelClass = 'story-dialog-panel-class';
    } else if (media.network === 'youtube' || media.network === 'tiktok') {
      config.panelClass = 'youtube-video-dialog-panel-class';
      this.media.video_url = this.media.url;
    }
    this.dialog.open(ShowStoryModalComponent, config);
  }

}
