import {Component, Input, OnInit} from '@angular/core';
import * as actions from '../../../search/store/search.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../../store';

@Component({
  selector: 'app-similar-influencers',
  templateUrl: './similar-influencers.component.html',
  styleUrls: ['./similar-influencers.component.scss']
})
export class SimilarInfluencersComponent implements OnInit {

  @Input() similarInfluencers: any;
  @Input() network: string;

  constructor(
    private store: Store<State>
  ) { }

  ngOnInit() {}

  openInfluencerProfile(username) {
    const influencer = null;
    this.store.dispatch(actions.selectInfluencer({ influencer }));
    if (this.network === 'instagram') {
      this.store.dispatch(actions.searchInstagrammer({ name: username }));
    } else if (this.network === 'youtube') {
      this.store.dispatch(actions.searchYoutuber({ name: username }));
    } else if (this.network === 'tiktok') {
      this.store.dispatch(actions.searchTiktoker({ name: username }));
    }
  }

}
