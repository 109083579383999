import {createAction, props} from '@ngrx/store';
import {Login} from '../../types/login';
import {User} from '../../types/user';
import {AccessSets} from '../../types/access-sets';
import {RegisterForm} from '../../types/register-form';

export const getLoggedUser = createAction('[Auth] get user from storage');

export const getAccessSets = createAction('[Auth] get access sets from user');
export const getAccessSetsSuccess = createAction(
  '[Auth] get access sets from user success',
  props<{ accessSets: AccessSets }>()
);

export const loginUser = createAction(
  '[Auth] Login user',
  props<{ user: Login; returnUrl: string }>()
);
export const loginUserSuccess = createAction(
  '[Auth] Login user success',
  props<{ user: User }>()
);
export const loginUserFail = createAction(
  '[Auth] Login user fail',
  props<{ error: any }>()
);

export const forgotPassword = createAction(
  '[Auth] forgot password',
  props<{ email: string }>()
);
export const forgotPasswordSuccess = createAction(
  '[Auth] forgot password success'
);
export const forgotPasswordFail = createAction(
  '[Auth] forgot password fail',
  props<{ error: any }>()
);

export const resendVerificationEmail = createAction(
  '[Auth] resend email',
  props<{ email: string }>()
);
export const resendVerificationEmailSuccess = createAction(
  '[Auth] resend email success'
);
export const resendVerificationEmailFail = createAction(
  '[Auth] resend email fail',
  props<{ error: any }>()
);

/// SIGNUP STEP 1
export const signUpStep1 = createAction(
  '[Auth] save email',
  props<{ email: string }>()
);
export const signUpStep1Success = createAction(
  '[Auth] signUpStep1 success',
  props<{ hash: string }>()
);
export const signUpStep1Fail = createAction(
  '[Auth] save email fail',
  props<{ error: any }>()
);

export const resetPassword = createAction(
  '[Auth] reset password',
  props<{ code: string; email: string; password: string }>()
);
export const resetPasswordSuccess = createAction(
  '[Auth] reset password success'
);
export const resetPasswordFail = createAction(
  '[Auth] reset password fail',
  props<{ error: any }>()
);

export const getEmailFromHash = createAction(
  '[Auth] Get email from hash',
  props<{ hash: string }>()
);
export const getEmailFromHashSuccess = createAction(
  '[Auth] Get email from hash success',
  props<{ email: string }>()
);
export const getEmailFromHashFail = createAction(
  '[Auth] Get email from hash fail',
  props<{ error: any }>()
);

export const signUp = createAction(
  '[Auth] signUp',
  props<{ request: RegisterForm }>()
);
export const signUpSuccess = createAction(
  '[Auth] signUp success',
  props<{ email: string }>()
);
export const signUpFail = createAction(
  '[Auth] signUp fail',
  props<{ error: any }>()
);

export const clearErrors = createAction('[Auth] clear errors');

export const logout = createAction('[Auth] logout');

export const handle401 = createAction('[Auth] handle 401 authentication error');
export const handle402 = createAction('[Auth] handle 402 payment error');
export const handle403 = createAction('[Auth] handle 403 authorization error');
