import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AdminManagementService} from '../../../../store/admin-management.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {AmplifyService} from 'aws-amplify-angular';
import {environment} from '../../../../../../../environments/environment';
import {InspirationalMedia} from '../../../../../../interfaces/brief-campaign-for-update-interface';
import {ShowInspirationalModalComponent} from '../../../../../../components/private/campaigns/show-inspirational-modal/show-inspirational-modal.component';
import {ModalViewCodeConversionsLinksComponent} from '../../../../../../components/private/campaigns/brief-campaign/modals/modal-view-code-conversions-links/modal-view-code-conversions-links.component';
import isEmpty from 'lodash/isEmpty';

@Component({
  selector: 'app-campaign-info',
  templateUrl: './campaign-info.component.html',
  styleUrls: ['./campaign-info.component.scss']
})
export class CampaignInfoComponent implements OnInit, OnDestroy {

  @Input() campaignData;
  @Input() campaign_id;

  dataForUpdate = {};
  itemsSaved = [];
  inspirational_media: Array<InspirationalMedia> = [];
  manageCampaign_form: FormGroup;

  error_upload_brand_logo = '';
  error_inspirational_media_upload = '';

  breakpoint;
  notviewCode;

  errorConversionLinks;

  changesForm = false;
  private subscriptions = [];

  constructor(private adminManagementService: AdminManagementService,
              private snackBar: MatSnackBar,
              private _amplifyService: AmplifyService,
              private dialog: MatDialog) {
    // Establish the Reactive Form
    this.manageCampaign_form = new FormGroup({
      brand_name: new FormControl(''),
      campaign_name:  new FormControl(''),
      start_date: new FormControl(''),
      end_date: new FormControl(''),
      send_gift_influencer: new FormControl(''),
      monetary_compensation: new FormControl(''),
      campaign_general_description: new FormControl(''),
      hashtags_compulsory_elements: new FormControl(''),
      mentions_compulsory_elements: new FormControl(''),
      destination_url: new FormControl(''),
      cpa_link: new FormControl(''),
      language_for_emails: new FormControl(''),
      monetary_for_emails: new FormControl(''),
    });
  }
  static setHashtags(hashtags: Array<string>) {
    return hashtags.toString().replace(/,/g, ' ');
  }

  static setMentions(mentions: Array<string>) {
    return mentions.toString().replace(/,/g, ' ');
  }

  ngOnInit() {
    // Seteamos el response en el formulario
    this.manageCampaign_form.controls['brand_name'].setValue(this.campaignData.brand_name);
    this.manageCampaign_form.controls['campaign_name'].setValue(this.campaignData.campaign_name);
    this.manageCampaign_form.controls['mentions_compulsory_elements'].setValue(CampaignInfoComponent.setMentions(this.campaignData.mentions_compulsory_elements));
    this.manageCampaign_form.controls['hashtags_compulsory_elements'].setValue(CampaignInfoComponent.setHashtags(this.campaignData.hashtag_list));
    this.manageCampaign_form.controls['campaign_general_description'].setValue(this.campaignData.campaign_general_description);
    this.manageCampaign_form.controls['monetary_compensation'].setValue(this.campaignData.monetary_compensation);
    this.manageCampaign_form.controls['language_for_emails'].setValue(this.campaignData.language_for_emails);
    this.manageCampaign_form.controls['monetary_compensation'].setValue(this.campaignData.payment_type);
    this.manageCampaign_form.controls['destination_url'].setValue(this.campaignData.destination_url);
    this.manageCampaign_form.controls['cpa_link'].setValue(this.campaignData.cpa_link);
    this.manageCampaign_form.controls['start_date'].setValue(new Date(this.campaignData.date_start * 1000));
    this.manageCampaign_form.controls['end_date'].setValue(new Date(this.campaignData.date_end * 1000));
    if (this.campaignData.send_gift_influencer === true) {
      this.manageCampaign_form.controls['send_gift_influencer'].setValue( 'true');
    } else {
      this.manageCampaign_form.controls['send_gift_influencer'].setValue( 'false');
    }
    this.inspirational_media = this.campaignData['inspirational_media'];
    if (this.campaignData.cpa_link !== undefined) {
      this.notviewCode = true;
      this.manageCampaign_form.controls['cpa_link'].setValue(this.campaignData.cpa_link);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  saveUserConfig() {
    this.dataForUpdate['campaign_id'] = this.campaign_id;
    this.subscriptions.push(
        this.adminManagementService.updateManageCampaign(this.campaign_id, this.dataForUpdate).subscribe( () => {
          this.openSnackBar('Updated successfully', 'Close');
        }, () => {
          this.openSnackBar('Something went wrong', 'Close');
        })
    );
    for (let i = 0; i <= this.itemsSaved.length - 1; i ++) {
      document.getElementById(this.itemsSaved[i]).classList.remove('border-orange');
    }
    this.itemsSaved = [];
  }

  putItemForUpdate(item_name) {
    if (item_name === 'start_date' || item_name === 'end_date') {
      this.dataForUpdate[item_name] = (new Date(this.manageCampaign_form.controls[item_name].value).getTime() / 1000) + 86400;
    } else if (item_name === 'hashtags_compulsory_elements') {
      this.dataForUpdate['hashtag_list'] = this.transformToArray(this.manageCampaign_form.controls[item_name].value, '#');
    } else if (item_name === 'mentions_compulsory_elements') {
      this.dataForUpdate[item_name] = this.transformToArray(this.manageCampaign_form.controls[item_name].value, '@');
    } else if (item_name === 'cpa_link') {
      this.dataForUpdate[item_name] = this.transformToArray(this.manageCampaign_form.controls[item_name].value, '');
    } else {
      this.dataForUpdate[item_name] = this.manageCampaign_form.controls[item_name].value;
    }
    this.itemsSaved.push(item_name);
    this.changesForm = true;
    document.getElementById(item_name).classList.add('border-orange');
  }

  transformToArray(list: string, type) {
    const array = list.split(' ');
    const array_with_hash = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].startsWith(type)) {
        array_with_hash.push(array[i]);
      } else if (
          !array[i].startsWith(type) &&
          array[i] !== ''
      ) {
        array_with_hash.push(type + array[i]);
      }
    }
    return array_with_hash;
  }

  /**
   * This function receive the image from input file and upload in S3 Bucket and in brief section in dynamo.
   * @param fileInput , receive $event from the html, have all attributes from image
   */
  uploadBrandLogo(fileInput: any): void {
      const file = fileInput.target.files[0];
      if (file.size <= 5242880) {
      if (
          file.type === 'image/png' ||
          file.type === 'image/jpeg' ||
          file.type === 'image/jpg'
      ) {
        const extension = this.getExtensionFromImage(
            file.type
        );
        const config = {
          bucket: environment.aws_resources.s3.buckets.company_logo,
          region: environment.aws_resources.region,
          level: 'public',
          customPrefix: {
            public: '',
          },
        };
        this._amplifyService
            .storage()
            .vault.put(this.campaign_id + extension, file, config)
            .then(() => {
              const url =
                  'https://s3-eu-west-1.amazonaws.com/' +
                  config.bucket +
                  '/' +
                  this.campaign_id +
                  extension;
              const body = {
                brand_logo: url,
              };
              this.dataForUpdate['brand_logo'] = url;
              this.campaignData['brand_logo'] = this.getUrlImageWithTime(url);
              this.saveUserConfig();
            })
            .catch(() => {
              this.error_upload_brand_logo =
                  'Oops, something went wrong. Please try again later.';
            });
      } else {
        this.error_upload_brand_logo =
            'Unsupported file type. Supported file types are: jpg, jpeg and png';
      }
    } else {
      this.error_upload_brand_logo = 'The file exceeds 5MB';
    }
  }

  getExtensionFromImage(file_type: string): string {
    return file_type.replace('image/', '.');
  }

  getUrlImageWithTime(url_image) {
    if (url_image !== 'n/a') {
      return url_image + '?ts=' + new Date().getTime();
    } else {
      return url_image;
    }
  }

  uploadInspirationalPicture(fileInput: any): void {
    this.error_inspirational_media_upload = '';
    const media_file = fileInput.target.files[0];

    if (
        media_file.type === 'video/mp4' ||
        media_file.type === 'video/quicktime'
    ) {
      if (media_file.size <= 70 * 1024 * 1024) {
        this.settingUpMediaForSaving(media_file, true);
      } else {
        this.error_inspirational_media_upload =
            'The supported files are videos up to 70MB as ".mp4" or ".mov" and images up to 10MB as ".jpg", ".jpeg" or ".png".';
      }
    } else if (
        media_file.type === 'image/png' ||
        media_file.type === 'image/jpeg' ||
        media_file.type === 'image/jpg'
    ) {
      if (media_file.size <= 10 * 1024 * 1024) {
        this.settingUpMediaForSaving(media_file, false);
      } else {
        this.error_inspirational_media_upload =
            'The supported files are videos up to 70MB as ".mp4" or ".mov" and images up to 10MB as ".jpg", ".jpeg" or ".png".';
      }
    } else {
      this.error_inspirational_media_upload =
          'Unsupported file type. Supported file types are: jpg, jpeg, png, mp4 and mov';
    }
  }

  settingUpMediaForSaving(media_file, is_video) {
    const file_name = media_file.name;
    let new_name = file_name;

    const config = {
      bucket: environment.aws_resources.s3.buckets.inspirational_pictures,
      region: environment.aws_resources.region,
      level: 'public',
      customPrefix: {
        public: '',
      },
    };

    this._amplifyService
        .storage()
        .vault.list(this.campaign_id + '/', config)
        .then(resp => {
          if (resp.length !== 0) {
            resp.forEach(media => {
              if (media.key === this.campaign_id + '/' + file_name) {
                new_name = this.setFileNameTimestamp(file_name);
              }
            });
          }

          this.savingInspirationalMedia(new_name, media_file, is_video);
        })
        .catch(() => {
          this.error_inspirational_media_upload =
              'Oops, something went wrong. Please try again later.';
        });
  }


  savingInspirationalMedia(file_name, file, is_video) {
    const config = {
      bucket: environment.aws_resources.s3.buckets.inspirational_pictures,
      region: environment.aws_resources.region,
      level: 'public',
      customPrefix: {
        public: '',
      },
    };

    this._amplifyService
        .storage()
        .vault.put(this.campaign_id + '/' + file_name, file, config)
        .then(() => {
          const url =
              'https://s3-eu-west-1.amazonaws.com/' +
              config.bucket +
              '/' +
              this.campaign_id +
              '/' +
              file_name;

          const media = {
            url: url,
            is_video: is_video,
          };

          this.inspirational_media.push(media);
          this.dataForUpdate['inspirational_media'] = this.inspirational_media;
          this.campaignData['inspirational_media'] = this.inspirational_media;
          this.saveUserConfig();

        })
        .catch(() => {
          this.error_inspirational_media_upload =
              'Oops, something went wrong. Please try again later.';
        });
  }

  setFileNameTimestamp(file_name) {
    const current_timestamp = new Date().getTime();
    const file_name_array = file_name.split('.');
    return (
        file_name_array[0] +
        '_' +
        current_timestamp.toString() +
        '.' +
        file_name_array[1]
    );
  }



  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar']
    });
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 2 : 6;
  }

  openShowInspirationalDialog(media): void {
    this.dialog.open(ShowInspirationalModalComponent, {
      width: 'auto',
      height: '100vh',
      autoFocus: false,
      data: { media },
      backdropClass: 'story-dialog-backdrop-class',
      panelClass: 'story-dialog-panel-class',
    });
  }

  removeInspirationalMedia(inspirational_url) {
    let index = -1;
    this.campaignData.inspirational_media.forEach((inspirational_media, idx) => {
      if (inspirational_media.url === inspirational_url) {
        index = idx;
      }
    });
    if (index > -1) {
      this.campaignData.inspirational_media.splice(index, 1);
      this.dataForUpdate['inspirational_media'] = this.campaignData.inspirational_media;
    }
    this.saveUserConfig();
  }

  /**
   * Abrir modal que muestra la info que tienen que copiar
   * conversion links en formato <script>
   */
  viewCodeConversionLinks () {
    this.dialog.open(ModalViewCodeConversionsLinksComponent, {
      width: '600px',
      height: 'auto',
      autoFocus: false,
      data: {
        conversions: this.campaignData.cpa_link
      }
    });
  }

  deleteCpaLink(index) {
    delete this.campaignData.cpa_link[index];
    this.campaignData.cpa_link = this.campaignData.cpa_link.filter(function( element ) {
      return element !== undefined;
    });
    this.notviewCode = !isEmpty(this.campaignData.cpa_link);
    this.dataForUpdate['cpa_link'] = this.campaignData.cpa_link;
    this.saveUserConfig();
  }
}
