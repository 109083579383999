import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ShowStoryModalComponent } from '../show-story-modal/show-story-modal.component';

@Component({
  selector: 'app-show-inspirational-modal',
  templateUrl: './show-inspirational-modal.component.html',
  styleUrls: ['./show-inspirational-modal.component.css']
})
export class ShowInspirationalModalComponent implements OnInit {
  media;

  constructor(public dialogRef: MatDialogRef<ShowStoryModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.data['origin'] = 'report';
    this.media = data['media'];
  }

  ngOnInit() {
  }

}
