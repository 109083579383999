import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-influencer-line-charts',
  templateUrl: './influencer-line-charts.component.html',
  styleUrls: ['./influencer-line-charts.component.scss']
})
export class InfluencerLineChartsComponent implements OnInit {

  @Input() growth;

  constructor() { }

  ngOnInit() {}

}
