import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-modal-view-code-conversions-links',
  templateUrl: './modal-view-code-conversions-links.component.html',
  styleUrls: ['./modal-view-code-conversions-links.component.scss']
})
export class ModalViewCodeConversionsLinksComponent implements OnInit {

  conversions = '';
  copying = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    const firstUrl = String(new URL(this.data.conversions[0]).pathname).replace('%7B%7D', '{}');
    this.conversions = "['" + firstUrl + "'";
    for (let index = 1; index < this.data.conversions.length; index ++) {
      this.conversions += (', ' + "'" + new URL(this.data.conversions[index]).pathname.replace('%7B%7D', '{}')) + "'";
    }
    this.conversions += ']';
  }

  copyScriptstoClipboard() {
    this.copying = true;
    let scriptsData = '<script>\n document.binflu_thanks =' + this.conversions + ' \n</script>\n';
    scriptsData += '\n<script type="text/javascript" src="https://pixel-files-for-clients.s3-eu-west-1.amazonaws.com/prod/main.bundle.js" defer="">\n' + '</script>';
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = scriptsData;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    setTimeout(() => {this.copying = false; }, 400);
    this.openSnackBar('Data copied successfully', 'Close');
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
