import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {ReportDashboardService} from '../../../../../../services/report-dashboard.service';

@Component({
  selector: 'app-modal-edit-dashboard-name',
  templateUrl: './modal-edit-dashboard-name.component.html',
  styleUrls: ['./modal-edit-dashboard-name.component.scss']
})
export class ModalEditDashboardNameComponent implements OnInit {

  loading;

  edit_dashboard_name_form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private _dashboardService: ReportDashboardService,
              public dialogRef: MatDialogRef<ModalEditDashboardNameComponent>,
              private snackBar: MatSnackBar) {

    this.edit_dashboard_name_form = new FormGroup({
      'dashboard_name': new FormControl('', [
        Validators.required,
        Validators.maxLength(25)
      ])
    });
    this.edit_dashboard_name_form.controls['dashboard_name'].setValue(this.data.dashboard_name);
  }

  ngOnInit() {
  }

  updateDashboardName(dashboard_name) {
    this.loading = true;
    if (this.edit_dashboard_name_form.valid) {
      const attributesForUpdate = {
        dashboard_name: dashboard_name
      };
      this._dashboardService.updateDashboard(this.data.dashboard_id, attributesForUpdate).subscribe( () => {
        this.openSnackBar('Dashboard name changed successfully', 'Close');
        this.loading = false;
        this.dialogRef.close(dashboard_name);
      }, () => {
        this.openSnackBar('Oops something went wrong, please try again', 'Close');
        this.loading = false;
      });
    }
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
