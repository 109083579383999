import { createFeatureSelector, createSelector } from '@ngrx/store';
import { plansKey, PlanState } from './modal-plans.reducers';

export const selectPlanState = createFeatureSelector<PlanState>(plansKey);

export const selectPlans = createSelector(
  selectPlanState,
  state => state.plans
);

export const selectPlansLoading = createSelector(
  selectPlanState,
  state => state.getPlansLoading
);

export const selectPlansError = createSelector(
  selectPlanState,
  state => state.getPlansError
);
