import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {State} from '../../../../../store';
import {selectAppViewPort} from '../../../../../store/app/app.selectors';

@Component({
  selector: 'app-private-stepper',
  templateUrl: './private-stepper.component.html',
  styleUrls: ['./private-stepper.component.scss']
})
export class PrivateStepperComponent implements OnInit {

  @Input() has_campaign: boolean;
  @Input() has_brief: boolean;
  @Input() has_progress: boolean;
  @Input() has_report: boolean;
  @Input() campaign_id: string;
  @Input() component_selected: string;

  @Output() callCreateBrief = new EventEmitter<any>();


  viewport: string;


  constructor(
      private router: Router,
      private store: Store<State>,
  ) { }

  ngOnInit() {
    this.store.select(selectAppViewPort).subscribe(result => {
      this.viewport = result;
    });
  }

  createBrief() {
    this.callCreateBrief.emit();
  }


}
