import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ReportService} from '../../../../../services/report.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {environment} from '../../../../../../environments/environment';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {ModalFavouritesListComponent} from '../../../../private/components/favourites/modals/modal-favourites-list/modal-favourites-list.component';
import {setLanguage} from '../../../../../store/app/app.actions';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {State} from '../../../../../store';
import * as selectorsApp from '../../../../../store/app/app.selectors';
import {ModalAddCommentInfluencerReportComponent} from '../../../../../components/private/campaigns/report-campaign/modal-add-comment-influencer-report/modal-add-comment-influencer-report.component';

@Component({
  selector: 'app-report-influencer-content-header',
  templateUrl: './report-influencer-content-header.component.html',
  styleUrls: ['./report-influencer-content-header.component.scss']
})
export class ReportInfluencerContentHeaderComponent implements OnInit, OnDestroy {

  @Input() influencer;
  @Input() sharedInfluencerReport;
  @Input() report_hash;

  registerPlatformUrl = '';
  languageSelected = '';
  platformBrandLogo = '';

  private subscriptions = [];

  constructor(private _reportService: ReportService,
              private snackbar: MatSnackBar,
              private activatedRoute: ActivatedRoute,
              private dialog: MatDialog,
              private router: Router,
              public translate: TranslateService,
              public store: Store<State>,
  ) { }

  ngOnInit() {
    this.platformBrandLogo = this.influencer.brand_logo;
    this.store.select(selectorsApp.selectLanguage).subscribe(response => {
      this.languageSelected = response;
      // this.translate.setDefaultLang('response');
    });
    if (this.activatedRoute.snapshot.queryParams.lang) {
      this.translate.setDefaultLang(this.activatedRoute.snapshot.queryParams.lang);
      this.translate.use(this.activatedRoute.snapshot.queryParams.lang);
      this.languageSelected = this.activatedRoute.snapshot.queryParams.lang;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  updateShareReport() {
    this.subscriptions.push(
        this._reportService.updateShareReport(this.report_hash).subscribe(
        () => {
          this.openSnackBar('The report will be updated shortly.', 'Close');
        },
        err => {
          this.openSnackBar(
            err.error.message,
            'Close'
          );
        }
      )
    );
  }

  loginSocial() {
    // window.open(environment.icmRedirect, '_self');
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = environment.icmRedirect;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.openSnackBar(' Copied to clipboard', 'Ok');
  }

  openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar']
    });
  }

  openFavouritesList(influencer) {
    this.dialog.open(ModalFavouritesListComponent, {
      width: '40%',
      height: '40%',
      minWidth: '540px',
      minHeight: '300px',
      autoFocus: false,
      data: {
        influencerId: influencer.influencer_id,
        network: influencer.network,
        username: influencer.username
      },
    });
  }

  switchLang(lang: string) {
    this.store.dispatch(setLanguage({ language: lang }));
    this.translate.use(lang);
    this.languageSelected = lang;

    // Logica para modificar el queryparams del router URL
    // Obtener el valor actual de los parámetros de la URL.
    const queryParams = { ...this.activatedRoute.snapshot.queryParams };
    // Modificar o agregar el nuevo valor de los parámetros.
    queryParams.lang = lang; // Aquí puedes establecer el valor que desees.
    // Crear un objeto de tipo NavigationExtras y establecer los nuevos parámetros en él.
    const navigationExtras: NavigationExtras = {
      queryParams: queryParams,
      // Opcional: Si deseas reemplazar los parámetros existentes en lugar de agregarlos, utiliza queryParamsHandling: 'merge'.
      // queryParamsHandling: 'merge'
    };
    // Navegar a la misma ruta con los nuevos parámetros.
    this.router.navigate([], navigationExtras);
  }

  openModalAddInfluencerComment(influencer) {
    this.dialog.open(ModalAddCommentInfluencerReportComponent, {
      width: '500px',
      height: 'auto',
      autoFocus: false,
      data: {
        influencer_id: influencer.influencer_id,
        campaign_id: '',
        comment: influencer.qualitative_comment_data.comment,
        displayComment: influencer.qualitative_comment_data.share_comment,
        isShare: true,
      },
    });
  }

}
