import { Component, Inject, OnInit } from '@angular/core';
import { BriefService } from '../../../../services/brief.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-cancel-invitation',
  templateUrl: './cancel-invitation.component.html',
  styleUrls: ['./cancel-invitation.component.css']
})
export class CancelInvitationComponent implements OnInit {

  loading = false;

  constructor(private _briefService: BriefService,
              public dialogRef: MatDialogRef<CancelInvitationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
  }

  cancelInfluencer() {
    this.loading = true;
    this._briefService.cancelInfluencer(this.data.campaign_id, this.data.influencer_id).subscribe( result => {
      this.dialogRef.close(result);
    });
  }

}

