import {Plan} from '../types/plans';
import {createAction, props} from '@ngrx/store';

export const getPlans = createAction(
  '[Auth] Get Plans',
);
export const getPlansSuccess = createAction(
  '[Auth] Get Plans success',
  props<{ plans: Plan[] }>()
);
export const getPlansFail = createAction(
  '[Auth] Get Plans fail',
  props<{ error: any }>()
);
