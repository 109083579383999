import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import {AmplifyService} from 'aws-amplify-angular';
import {AuthenticatedUser} from '../../../../classes/authenticated-user-class';

@Component({
  selector: 'app-state-confirm-send-invitation-modal',
  templateUrl: './state-confirm-send-invitation-modal.component.html',
  styleUrls: ['./state-confirm-send-invitation-modal.component.scss']
})
export class StateConfirmSendInvitationModalComponent implements OnInit {

  state = '';
  campaign_id: any;
  company_logo_image = '';
  user: AuthenticatedUser;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public router: Router,
              private _amplifyService: AmplifyService ) {
      this.state = this.data.invitation.status;
      this.campaign_id = this.data.campaign_id;
  }

  ngOnInit() {
      this.setUserData();
  }

    async setUserData() {
        let user = await this._amplifyService.auth().currentAuthenticatedUser();
        this.user = new AuthenticatedUser(user);
        this.company_logo_image = this.user.getAttribute('custom:company_logo_image');
    }

  redirectProgress() {
    setTimeout( () => { this.router.navigate(['/app/progress/' + this.campaign_id]); }, 2000 );
  }


}
