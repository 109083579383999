import {NgModule} from '@angular/core';
import {ModalPlansCardComponent} from './components/modal-plans/modal-plans-card/modal-plans-card.component';
import {ModalPlansStoreModule} from './store/modal-plans-store.module';
import {CommonModule} from '@angular/common';
import {ModalPlansComponent} from './components/modal-plans/modal-plans.component';
import {
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatTooltipModule
} from '@angular/material';
import {ModalConfirmUpgradePlanComponent} from './components/modal-plans/modal-plans-card/modal-confirm-upgrade-plan/modal-confirm-upgrade-plan.component';
import {ModalPreviousPlansComponent} from './components/modal-previous-plans/modal-previous-plans.component';
import {RouterModule} from '@angular/router';
import {ModalPlanLimitsExceededComponent} from './components/modal-plan-limits-exceeded/modal-plan-limits-exceeded.component';
import {ModalThanksPageComponent} from './components/modal-thanks-page/modal-thanks-page.component';
import {TranslateModule} from '@ngx-translate/core';


const ENTRY_COMPONENTS = [
    ModalPlansCardComponent,
    ModalConfirmUpgradePlanComponent,
    ModalPreviousPlansComponent,
    ModalPlanLimitsExceededComponent,
    ModalConfirmUpgradePlanComponent,
    ModalPreviousPlansComponent,
    ModalThanksPageComponent
];

const COMPONENTS = [
    ModalPlansCardComponent,
    ModalPlansComponent,
    ModalConfirmUpgradePlanComponent,
    ModalPreviousPlansComponent,
    ModalPlanLimitsExceededComponent,
    ModalThanksPageComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ModalThanksPageComponent,
  ],
    imports: [
        ModalPlansStoreModule,
        CommonModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatIconModule,
        MatDialogModule,
        RouterModule,
        MatTooltipModule,
        MatDividerModule,
        MatButtonModule,
        TranslateModule,
    ],
  exports: [
    ModalPlansCardComponent
  ],
  entryComponents: [
    ...ENTRY_COMPONENTS
  ],
})
export class ModalPlans { }
