import {Component, Inject, OnInit} from '@angular/core';
import {selectAppViewPort} from '../../../../../store/app/app.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../../store';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {CampaignsService} from '../../../../../services/campaigns.service';
import {Router} from '@angular/router';
import {RemoveInfluencerComponent} from '../../../../private/campaigns/remove-influencer/remove-influencer.component';
import {CustomSnackbarComponent} from '../../../../../features/shared/components/custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-modal-influencer-data-info',
  templateUrl: './modal-influencer-data-info.component.html',
  styleUrls: ['./modal-influencer-data-info.component.scss']
})
export class ModalInfluencerDataInfoComponent implements OnInit {

  loading: boolean;
  viewport = '';
  private subscriptions = [];

  constructor(
      private store: Store<State>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private _campaignsService: CampaignsService,
      private router: Router,
      public snackBar: MatSnackBar,
      private dialog: MatDialog,
      public dialogRef: MatDialogRef<ModalInfluencerDataInfoComponent>,

  ) { }

  ngOnInit() {
    this.subscriptions.push(
        this.store.select(selectAppViewPort).subscribe(result => {
          this.viewport = result;
        })
    );
  }

  setVirtualScrollHeight(array_length) {
    if (this.viewport === 'handset') {
      // if (array_length >= 2) {
      //   return '150px';
      // } else {
      //   return (array_length * 400).toString() + 'px';
      // }
    } else {
      if (array_length > 2) {
        return '120px';
      } else {
        return (array_length * 60).toString() + 'px';
      }
    }
  }

  createBrief() {
    this.loading = true;
    this.subscriptions.push(
        this._campaignsService
            .createBrief(this.data.campaign_id)
            .subscribe(() => {
              this.router.navigate([
                '/app/brief/' + this.data.campaign_id,
              ]);
              this.loading = false;
              this.snackBar.openFromComponent(CustomSnackbarComponent, {
                data: { message: 'Brief created successfully', type: 'success' },
                duration: 3000,
                horizontalPosition: 'right',
                verticalPosition: 'top',
                panelClass: 'success-snackbar',
              });
            }, () => {
              this.loading = false;
              this.snackBar.openFromComponent(CustomSnackbarComponent, {
                data: { message: 'Oops something went wrong, please try again.', type: 'error' },
                duration: 3000,
                horizontalPosition: 'right',
                verticalPosition: 'top',
                panelClass: 'error-snackbar',
              });
            })
    );
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }


  openRemoveInfluencerDialog(influencer): void {
    const dialogRef = this.dialog.open(RemoveInfluencerComponent, {
      width: '490px',
      height: '320px',
      panelClass: 'remove-padding',
      autoFocus: false,
      data: {
        campaign_id: this.data.campaign_id,
        influencer_id: influencer.influencer_id,
        username: influencer.username,
        network: influencer.network
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status != null) {
        if (result.status === 200) {
          this.dialogRef.close(result);
        }
      }
    });
  }

}
