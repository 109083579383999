import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GiftService {

    private giftUrl = environment.aws_resources.lambda_endpoint.api;

    constructor(private http: HttpClient) {}

    public sendGiftForm(campaign_id, influencer_id, form_data) {
        const body = {
            form_data: form_data
        };
        return this.http.put(this.giftUrl + 'last-mille-influencers/gift_form/' + influencer_id + '/' + campaign_id, body, {observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }
    public getGiftForm(campaign_id, invited_token) {
        return this.http.get(this.giftUrl + 'last-mille-influencers/gift_form/' + invited_token + '/' + campaign_id, {observe: 'response'}).pipe(map((data: any) => {
            return data;
        }));
    }
}
