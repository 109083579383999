import {Component, Input, OnInit} from '@angular/core';
// @ts-ignore
import {Color, Label, MultiDataSet} from 'ng2-charts';
import {ChartType} from 'chart.js';
import {round} from 'lodash';
import {ThemeService} from '../../../../../../services/theme.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {

  @Input() chartData;
  @Input() componentNameCalled;

  totalPercentage = 0;
  totalOthersPercentage = 0;

  theme = {};

  public doughnutChartLabels: Label[] = [];

  public doughnutChartData: MultiDataSet = [[]];

  doughnutChartOptions = {
  };

  public doughnutChartColors: Color[] = [
    { backgroundColor: [] }
    // Puedes agregar más colores según la cantidad de partes en tu gráfico
  ];

  temporalChartGender = [];

  public ChartType = 'doughnut';
  public doughnutChartType: ChartType = 'doughnut';

  constructor(
      themeService: ThemeService,
      private translate: TranslateService,
  ) {
    this.theme = themeService.getTheme();
  }

  ngOnInit() {
    if (this.componentNameCalled === 'Gender') {
      this.doughnutChartOptions = {
        responsive: true,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontSize: 13,
            boxWidth: 12, // Ajusta este valor según sea necesario
            padding: 10,
            generateLabels: function(chart) {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                return data.labels.map(function(label, i) {
                  const dataset = data.datasets[0];
                  const value = dataset.data[i];
                  const total = dataset.data.reduce(function(acc, val) {
                    return acc + val;
                  }, 0);
                  const percentage = ((value / total) * 100);
                  const formattedPercentage = (percentage % 1 === 0) ? percentage.toFixed(0) : percentage.toFixed(2);
                  return {
                    text: label + ': '  + '' + formattedPercentage + '%',
                    fillStyle: dataset.backgroundColor[i],
                    hidden: isNaN(dataset.data[i]) || dataset.data[i] === 0,
                    index: i
                  };
                });
              }
              return [];
            }
          },
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const value = dataset.data[tooltipItem.index];
              const total = dataset.data.reduce(function (acc, val) {
                return acc + val;
              }, 0);
              const percentage = ((value / total) * 100);
              const formattedPercentage = (percentage % 1 === 0) ? percentage.toFixed(0) : percentage.toFixed(2);

              return data.labels[tooltipItem.index] + ': ' + formattedPercentage + '%';
            }
          }
        }
      };
      const themeColors = [this.theme['primary'], this.theme['secondary']];
      this.doughnutChartColors = [
        { backgroundColor: themeColors }
      ];
      this.chartData.forEach( data => {
        if (data.code === 'female') {
          this.temporalChartGender[0] = { ...data, code: this.translate.instant('campaignPlan.stats.woman') };
        } else {
          this.temporalChartGender[1] = { ...data, code: this.translate.instant('campaignPlan.stats.men') };
        }
      });
      this.chartData = this.temporalChartGender;
    } else {
      this.doughnutChartOptions = {
        responsive: true,
        legend: {
          display: true,
          labels: {
            fontSize: 12,
            boxWidth: 10,
            padding: 8,
          }
        }
      };
    }
    this.chartData.forEach((data, index) => {
      if (index <= 4) {
        if (data['tag']) {
          this.doughnutChartLabels.push(data['tag']);
        } else {
          this.doughnutChartLabels.push(data['code']);
        }
        this.doughnutChartData[0].push(round(data['weight'] * 100));
        this.totalPercentage +=  data['weight'] * 100;
      }
    });

    if (this.componentNameCalled !== 'Gender') {
      this.totalOthersPercentage = 100 - this.totalPercentage;
      this.doughnutChartLabels.push(this.translate.instant('campaignPlan.stats.others'));
      this.doughnutChartData[0].push(round(this.totalOthersPercentage));
    }
  }
}
