import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-progress-actions-confirmation-modal',
  templateUrl: './progress-actions-confirmation-modal.component.html',
  styleUrls: ['./progress-actions-confirmation-modal.component.scss']
})
export class ProgressActionsConfirmationModalComponent implements OnInit {
  loading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
