import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-collaborated-brands',
  templateUrl: './collaborated-brands.component.html',
  styleUrls: ['./collaborated-brands.component.scss']
})
export class CollaboratedBrandsComponent {

  @Input() collaboratedBrands: any;

}
