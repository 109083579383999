import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {ReportService} from '../../../../../../services/report.service';

@Component({
  selector: 'app-email-download-all-content-modal',
  templateUrl: './email-download-all-content-modal.component.html',
  styleUrls: ['./email-download-all-content-modal.component.scss']
})
export class EmailDownloadAllContentModalComponent implements OnInit, OnDestroy {

  loading = true;

  influencerEmail: FormGroup;

  private subscriptions = [];

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private _reportService: ReportService,
      private snackBar: MatSnackBar,
      private dialogRef: MatDialogRef<EmailDownloadAllContentModalComponent>
  ) {
    this.influencerEmail =  new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(
          '^[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$'
      )])
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    this.loading = true;
    this.influencerEmail.controls.email.setValue(this.data.email);
    this.loading = false;
  }

  sendEmailAllContent() {
    this.loading = true;
    this.subscriptions.push(
        this._reportService.sendEmailAllContent(this.data.campaign_id, this.influencerEmail.controls.email.value).subscribe( response => {
          this.openSnackBar(response.body.message, 'Close');
          this.dialogRef.close('success');
        }, error => {
          this.loading = false;
          if (error.error.message) {
            this.openSnackBar(error.error.message, 'Close');
          } else {
            this.openSnackBar('Oops something went wrong, please try again later', 'Close');
          }
        })
    );
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
