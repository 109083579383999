import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material';
import {ReportService} from '../../../../../../services/report.service';

@Component({
  selector: 'app-general-report-comment',
  templateUrl: './general-report-comment.component.html',
  styleUrls: ['./general-report-comment.component.scss']
})
export class GeneralReportCommentComponent implements OnInit {

  @Input() topMedias;
  @Input() campaignId;
  @Input() comment;
  @Input() share_comment;
  @Input() isShare;

  reportCommentsForm: FormGroup;

  constructor(
      private snackBar: MatSnackBar,
      private _reportService: ReportService,
  ) {
    this.reportCommentsForm = new FormGroup({
      'reportComment': new FormControl(''),
      'displayReportComment': new FormControl(false),
    });
  }

  ngOnInit() {
    if (this.comment)  {
      this.reportCommentsForm.controls.reportComment.setValue(this.comment);
    }
    if (this.share_comment) {
      this.reportCommentsForm.controls.displayReportComment.setValue(this.share_comment);
    }
  }

  saveGeneralReportComment() {
    const commentValue = this.reportCommentsForm.controls.reportComment.value;
    const displayValue = this.reportCommentsForm.controls.displayReportComment.value;

    if (commentValue !== '') {
      const displayCommentInShare = displayValue === '' ? false : displayValue;
      this._reportService.saveGeneralPublishedComment(this.campaignId, commentValue, displayCommentInShare, true)
          .subscribe(
              () => this.openSnackBar('Saved successfully', 'Ok', false),
              () => this.openSnackBar('Oops, something went wrong. Please try again', 'Ok', true)
          );
    }
  }


  openSnackBar(message, action, isError) {
    // const panelClass = isError ? ['success-snackbar-container'] : ['error-snackbar-container'];
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      // panelClass: panelClass
    });
  }

}
