import { Component, Inject, OnInit } from '@angular/core';
import {ShowStoryModalComponent} from '../../../show-story-modal/show-story-modal.component';
import {ProgressActionsConfirmationModalComponent} from '../progress-actions-confirmation-modal/progress-actions-confirmation-modal.component';
import {ProgressRejectProposalModalComponent} from '../progress-reject-proposal-modal/progress-reject-proposal-modal.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ProgressService} from '../../../../../../services/progress.service';
import { ModalConfirmRemoveProposalComponent } from './modals/modal-confirm-remove-proposal/modal-confirm-remove-proposal.component';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-influencer-proposals-modal',
  templateUrl: './influencer-proposals-modal.component.html',
  styleUrls: [
    './influencer-proposals-modal.component.scss',
  ],
})
export class InfluencerProposalsModalComponent implements OnInit {

  hash_progress_id = '';

  binded_progress: any;
  influencer: any;

  changes = false;
  loading = true;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private _progressService: ProgressService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InfluencerProposalsModalComponent>,
    private router: Router
  ) {
    this.influencer = this.data.influencer;
    this.hash_progress_id = this.data.hash_progress_id;
    this.binded_progress = this.data.binded_progress;
  }

  ngOnInit() {
    this.loading = false;
    // this.getInfluencerProposals();
  }

  // getInfluencerProposals() {
  //   // Request from progress proposals
  //   if (this.data.hash_progress_id === '') {
  //     this._progressService.getinfluencerProposals(this.data.campaign_id, this.data.influencer.influencer_id).subscribe( result => {
  //       this.influencer.proposals = result.body['influencer_proposals'];
  //       this.loading = false;
  //     }, error => {
  //       this.loading = false;
  //       this.openSnackBar('Oops something went wrong, please try again later', 'Close');
  //     });
  //     // Request from shared progess proposals
  //   } else {
  //       this._progressService.getinfluencerSharedProposals(this.data.campaign_id, this.data.influencer.influencer_id).subscribe( result => {
  //         this.influencer.proposals = result.body['influencer_proposals'];
  //         this.loading = false;
  //       }, error => {
  //         this.loading = false;
  //         this.openSnackBar('Oops something went wrong, please try again later', 'Close');
  //       });
  //   }
  //
  // }

  influencerPosts(influencer_proposals) {
    const posts = [];
    if (influencer_proposals.length !== 0) {
      influencer_proposals.forEach(item => {
        if (
          item.media_type === 'post_img' ||
          item.media_type === 'post_video'
        ) {
          posts.push(item);
        }
      });
    }
    return posts;
  }

  influencerStories(influencer_proposals) {
    const stories = [];
    if (influencer_proposals.length !== 0) {
      influencer_proposals.forEach(item => {
        if (
          item.media_type === 'story_img' ||
          item.media_type === 'story_video'
        ) {
          stories.push(item);
        }
      });
    }

    return stories;
  }

  influencerVideos(influencer_proposals) {
    const videos = [];
    if (influencer_proposals.length !== 0) {
      influencer_proposals.forEach(item => {
        if (item.media_type === 'video') {
          videos.push(item);
        }
      });
    }

    return videos;
  }

  influencerMentions(influencer_proposals) {
    const mentions = [];
    if (influencer_proposals.length !== 0) {
      influencer_proposals.forEach(item => {
        if (item.media_type === 'mention') {
          mentions.push(item);
        }
      });
    }

    return mentions;
  }

  openShowApprovalContentDialog(media, network): void {
    let config: any;
    media['origin'] = 'progress';

    if (network === 'instagram') {
      config = {
        width: 'auto',
        height: '100vh',
        autoFocus: false,
        data: {
          media: media,
        },
        backdropClass: 'story-dialog-backdrop-class',
        panelClass: 'story-dialog-panel-class',
      };
    } else {
      config = {
        width: 'auto',
        height: '100vh',
        autoFocus: false,
        data: {
          media: media,
        },
        backdropClass: 'story-dialog-backdrop-class',
        panelClass: 'youtube-video-dialog-panel-class',
      };
    }

    this.dialog.open(ShowStoryModalComponent, config);
  }

  acceptProposal(proposal_id, username) {
    const config = {
      width: '35%',
      height: '20%',
      minWidth: '450px',
      minHeight: '200px',
      autoFocus: false,
      data: {
        title: 'Approve proposal for @' + username,
        content: 'Are you sure you want to approve this proposal?',
        action_button: 'Approve',
        spinner_action: 'Approving proposal',
      },
    };

    this.dialog
      .open(ProgressActionsConfirmationModalComponent, config)
      .beforeClosed()
      .subscribe(closing_message => {
        if (closing_message === 'perform_action') {
          this._progressService
            .acceptProposal(this.hash_progress_id, proposal_id)
            .subscribe(
              resp => {
                if (resp.status.toString() === '200') {
                  this.influencer.proposals.forEach(prop => {
                    if (prop.proposal_id === proposal_id) {
                      prop.proposal_status = 'accepted';
                      this.changes = true;
                    }
                  });
                  this.openSnackBar(
                    'The proposal was accepted successfully.',
                    'OK'
                  );
                } else {
                  this.openSnackBar(
                    'There was an error trying to accept the proposal.',
                    'OK'
                  );
                }
              },
              error => {
                this.openSnackBar(
                  'There was an error trying to accept the proposal.',
                  'OK'
                );
              }
            );
        }
      });
  }

  openRejectProposalDialog(username, proposal) {
    this.dialog
      .open(ProgressRejectProposalModalComponent, {
        width: '45%',
        height: '45%',
        minWidth: '450px',
        minHeight: '350px',
        autoFocus: false,
        data: {
          hash_progress_id: this.hash_progress_id,
          username: username,
          proposal: proposal,
        },
      })
      .afterClosed()
      .subscribe(res => {
        if (res !== '' && res !== undefined && res !== 'error') {
          this.influencer.proposals.forEach(prop => {
            if (prop.proposal_id === proposal.proposal_id) {
              prop.proposal_status = 'rejected';
              prop['reason'] = res;
              this.changes = true;
            }
          });
          this.openSnackBar('The proposal was rejected successfully.', 'OK');
        } else if (res === 'error') {
          this.openSnackBar(
            'There was an error trying to reject the proposal.',
            'OK'
          );
        }
      });
  }

  openRemoveProposalDialog(proposal) {
    this.dialog
      .open(ModalConfirmRemoveProposalComponent, {
        width: '35%',
        height: '25%',
        minWidth: '450px',
        minHeight: '250px',
        autoFocus: false,
        data: {
          campaign_id: proposal.campaign_id,
          influencer_id: proposal.influencer_id,
          proposal_id: proposal.proposal_id,
        },
      })
      .afterClosed()
      .subscribe(res => {
        if (res === 'success') {
          this.refreshProposals(proposal);
        }
      });
  }

  refreshProposals(proposal) {
    this.loading = true;
    this._progressService.getProgress(proposal.campaign_id).subscribe(
      result => {
        if (result['status'].toString() === '200') {
          this.influencer = [];
          this.binded_progress = result['body'];
          for (
            let index = 0;
            index < this.binded_progress.influencers.length;
            index++
          ) {
            if (
              this.binded_progress['influencers'][index]['influencer_id'] ===
              proposal.influencer_id
            ) {
              this.influencer = this.binded_progress['influencers'][index];
            }
          }

          this.influencer.proposals.forEach(proposals => {
            if (proposals.proposal_status === 'accepted') {
              this.influencer['accepted_proposals']++;
              this.influencer['total_active_proposals']++;
            } else if (proposals.proposal_status === 'pending') {
              this.influencer['pending_proposals']++;
              this.influencer['total_active_proposals']++;
            }
          });
          this.loading = false;
        } else {
          this.router.navigate(['/app/campaigns']);
        }
      },
      error => {
        if (error.status === 404 || error.status === 400) {
          this.router.navigate(['/app/campaigns']);
        }
      }
    );
  }

  /**
   * @description Show snackbar with the messages and actions granted
   * @param message
   * @param action
   */
  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
