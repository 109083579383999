import {Component, OnDestroy, OnInit} from '@angular/core';
import {Sort} from '@angular/material';
import {AdminManagementService} from '../../../../store/admin-management.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-dashboard-latest-campaigns',
  templateUrl: './dashboard-latest-campaigns.component.html',
  styleUrls: ['./dashboard-latest-campaigns.component.scss']
})
export class DashboardLatestCampaignsComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = [
    'campaign_name', 'owner_username', 'date_start', 'date_end',
    'influencers_added', 'influencers_waiting_email', 'influencers_invited', 'influencers_accepted', 'influencers_cancel_rejected',
    'total_influencers', 'total_progress', 'total_social_login_influencers', 'total_instagram_stories_crawled' , 'total_instagram_stories_done', 'total_instagram_posts_done' ,
    'total_tiktok_videos_done', 'total_youtube_mentions_done', 'total_youtube_videos_done',
  ];

  form_crawls_timeRange: FormGroup;

  latestCampaigns = [];
  private subscriptions = [];
  loading = true;

  constructor(private adminManagementService: AdminManagementService) {
    this.form_crawls_timeRange = new FormGroup({
      'crawl_timeRange': new FormControl('86400'),
    });
  }

  ngOnInit() {
    this.loading = true;
    this.subscriptions.push(
      this.adminManagementService.getLatestCampaigns(86400).subscribe( result => {
        if (result.body !== {}) {
          this.latestCampaigns = result.body.latest_campaigns;
        }
        this.loading = false;
      }, () => {
        this.latestCampaigns = [];
        this.loading = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  sortData(sort: Sort) {
    const data = this.latestCampaigns.slice();
    if (!sort.active || sort.direction === '') {
      this.latestCampaigns = data;
    }
    this.latestCampaigns = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'campaign_name':
          return this.compare(a.campaign_name, b.campaign_name, isAsc);
        case 'date_end':
          return this.compare(a.date_end, b.date_end, isAsc);
        case 'date_start':
          return this.compare(a.date_start, b.date_start, isAsc);
        case 'influencers_accepted':
          return this.compare(a.influencers_accepted, b.influencers_accepted, isAsc);
        case 'owner_username':
          return this.compare(a.owner_username, b.owner_username, isAsc);
        case 'influencers_added':
          return this.compare(a.influencers_added, b.influencers_added, isAsc);
        case 'influencers_cancel_rejected':
          return this.compare(a.influencers_cancel_rejected, b.influencers_cancel_rejected, isAsc);
        case 'influencers_invited':
          return this.compare(a.influencers_invited, b.influencers_invited, isAsc);
        case 'influencers_waiting_email':
          return this.compare(a.influencers_waiting_email, b.influencers_waiting_email, isAsc);
        case 'total_instagram_stories_crawled':
          return this.compare(a.total_instagram_stories_crawled, b.total_instagram_stories_crawled, isAsc);
        case 'total_instagram_posts_done':
          return this.compare(a.total_instagram_posts_done, b.total_instagram_posts_done, isAsc);
        case 'total_instagram_stories_done':
          return this.compare(a.total_instagram_stories_done, b.total_instagram_stories_done, isAsc);
        case 'total_tiktok_videos_done':
          return this.compare(a.total_tiktok_videos_done, b.total_tiktok_videos_done, isAsc);
        case 'total_youtube_mentions_done':
          return this.compare(a.total_youtube_mentions_done, b.total_youtube_mentions_done, isAsc);
        case 'total_youtube_videos_done':
          return this.compare(a.total_youtube_videos_done, b.total_youtube_videos_done, isAsc);
        case 'total_social_login_influencers':
          return this.compare(a.total_social_login_influencers, b.total_social_login_influencers, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  changeTimeQuery(hours) {
    this.loading = true;
    this.subscriptions.push(
      this.adminManagementService.getLatestCampaigns(hours.value).subscribe( result => {
        this.latestCampaigns = result.body.latest_campaigns;
        this.loading = false;
      }, () => {
        this.latestCampaigns = [];
        this.loading = false;
      })
    );
  }

}
