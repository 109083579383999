import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-analyze-audience-influencer',
  templateUrl: './analyze-audience-influencer.component.html',
  styleUrls: ['./analyze-audience-influencer.component.scss']
})
export class AnalyzeAudienceInfluencerComponent implements OnInit {

  message: string = '';
  ok_sub_message: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data.data.status === 201) {
      this.message = 'This influencer\'s audience will be analyzed shortly';
      this.ok_sub_message = 'You will receive an email with a link to this influencer\'s audience analyzed. This action usually up to 1 minute.';
    } else if (this.data.data.status === 200) {
      this.message = 'This influencer\'s audience is already being analyzed';
      this.ok_sub_message = 'You will receive an email with a link to this influencer\'s analyzed profile. This action usually up to 1 minute';
    } else {
      this.message = 'Oops something went wrong, please try again later';
    }
  }

  ngOnInit() {
  }

}
