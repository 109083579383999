import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {FormControl, FormGroup} from '@angular/forms';
import {BriefService} from '../../../../../../services/brief.service';

@Component({
  selector: 'app-modal-extend-campaign',
  templateUrl: './modal-extend-campaign.component.html',
  styleUrls: ['./modal-extend-campaign.component.scss']
})
export class ModalExtendCampaignComponent implements OnInit {

  extend_campaign: FormGroup;
  time_stamp_date: any;
  maxDate;

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
               private _briefService: BriefService,
               private snackBar: MatSnackBar,
               public dialogRef: MatDialogRef<ModalExtendCampaignComponent>) {
    // Establish the Reactive Form
    this.extend_campaign = new FormGroup({
      'end_date': new FormControl(data.end_date),
    });
  }

  ngOnInit() {
    this.maxDate = new Date(new Date().getTime() + (1000 * 60 * 60 * 4392));
  }

  extendCampaign() {
    this.time_stamp_date = (new Date(this.extend_campaign.controls['end_date'].value).getTime() / 1000) + 86350;

    // Observable for call the service, function updateAttributes
    this._briefService.extendCampaign(this.data.campaign_id, this.time_stamp_date).subscribe(result => {
      if (result['status'] === 200) {
        this.openSnackBar('Updated successfully', 'Close');
        this.dialogRef.close({status: 'success', end_date: this.extend_campaign.controls['end_date'].value });
      }
    }, error => {
      this.openSnackBar('Something went wrong', 'Close');

    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar']
    });
  }

}
