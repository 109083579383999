import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ThemeService} from '../../../../../../services/theme.service';

@Component({
  selector: 'app-horizontal-gender-chart',
  templateUrl: './horizontal-gender-chart.component.html',
  styleUrls: ['./horizontal-gender-chart.component.scss']
})
export class HorizontalGenderChartComponent implements OnInit, OnChanges {

  @Input() ageData;

  tapGenderSelected = 'total';

  public barChartType = 'horizontalBar';
  public barChartLegend = false;

  // Gender Char Data
  public barChartLabels: string[];
  public barChartData: any[];
  // Gender Chart Config
  public barChartColors: any[];

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: false,
          ticks: {
            display: true,
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
            color: ['rgba(0, 0, 0, 0)'],
          },
          ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, values) {
              return value.length > 20
                  ? value.substring(0, 20) + '...'
                  : value;
            },
            fontColor: '#a8a8a8',
            fontSize: 10,
          },
        },
      ],
    },
  };

  theme: any;

  constructor(themeService: ThemeService) {
    this.theme = themeService.getTheme();
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.setBarChartValues();
    this.barChartColors = [
      {
        backgroundColor: [
          this.theme.audienceLighten,
          this.theme.audienceDarken,
          this.theme.audienceLighten,
          this.theme.audienceDarken,
          this.theme.audienceLighten,
          this.theme.audienceDarken,
        ],
      },
    ];
  }

  changeTapGenderAudience(tap) {
    this.tapGenderSelected = tap;
    this.setBarChartValues();
  }


  setBarChartValues() {
    this.barChartLabels = ['', '', '', '', ''];
    this.barChartData = [
      {
        data: [0, 0, 0, 0, 0],
      },
    ];
    this.ageData[this.tapGenderSelected].forEach((ageGender, i) => {
      this.barChartLabels[i] = ageGender.range;
      this.barChartData[0].data[i] =
          Math.round(ageGender.weight * 100 * 100) / 100;
    });
  }
}
