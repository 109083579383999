import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InfluencerProfileComponent} from 'src/app/components/private/influencer-profile/influencer-profile.component';
import {CampaignsComponent} from 'src/app/components/private/campaigns/campaigns.component';
import {DetailCampaignComponent} from 'src/app/components/private/campaigns/detail-campaign/detail-campaign.component';
import {BriefCampaignComponent} from 'src/app/components/private/campaigns/brief-campaign/brief-campaign.component';
import {ProgressCampaignComponent} from 'src/app/components/private/campaigns/progress-campaign/progress-campaign.component';
import {ReportCampaignComponent} from 'src/app/components/private/campaigns/report-campaign/report-campaign.component';
import {AccountSettingsComponent} from 'src/app/components/private/account_settings/account-settings.component';
import {ChangePasswordComponent} from 'src/app/components/private/change_password/change-password.component';
import {environment} from 'src/environments/environment';
import {AdminEditAudienceComponent} from '../../components/private/campaigns/detail-campaign/admin-edit-audience/admin-edit-audience.component';
import {FreePlanFormComponent} from './components/free-plan-form/free-plan-form.component';
import {FavouritesComponent} from './components/favourites/favourites.component';

const routes: Routes = [
  {
    path: 'influencer',
    component: InfluencerProfileComponent,
    // canActivate: [AccessSetsGuard],
    data: {
      permissions: [environment.permissions.influencer],
    },
  },
  {
    path: 'influencer/:params',
    component: InfluencerProfileComponent,
    // canActivate: [AccessSetsGuard],
    data: {
      permissions: [environment.permissions.influencerDetails],
    },
  },
  {
    path: 'campaigns',
    component: CampaignsComponent,
    // canActivate: [AccessSetsGuard],
    data: {
      permissions: [environment.permissions.campaigns],
    },
  },
  {
    path: 'favourites',
    component: FavouritesComponent,
    // canActivate: [AccessSetsGuard],
    data: {
      permissions: [environment.permissions.campaigns],
    },
  },
  {
    path: 'freeTrial',
    component: FreePlanFormComponent,
    // canActivate: [AccessSetsGuard],
  },
  {
    path: 'campaign/:id',
    component: DetailCampaignComponent,
    // canActivate: [AccessSetsGuard],
    data: {
      permissions: [environment.permissions.campaignDetails],
    },
  },
  {
    path: 'brief/:id',
    component: BriefCampaignComponent,
    // canActivate: [AccessSetsGuard],
    data: {
      permissions: [environment.permissions.brief],
    },
  },
  {
    path: 'progress/:id',
    component: ProgressCampaignComponent,
    // canActivate: [AccessSetsGuard],
    data: {
      permissions: [environment.permissions.progress],
    },
  },
  {
    path: 'report/:id',
    component: ReportCampaignComponent,
    // canActivate: [AccessSetsGuard],
    data: {
      permissions: [environment.permissions.report],
    },
  },
  {
    path: 'editAudience',
    component: AdminEditAudienceComponent,
    // canActivate: [AccessSetsGuard],
  },
  {
    path: 'account',
    component: AccountSettingsComponent,
  },
  {
    path: 'changePassword',
    component: ChangePasswordComponent,
  },
  { path: '', pathMatch: 'full', redirectTo: '/app/search' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrivateRoutingModule {}
