import {Component, Input, OnInit} from '@angular/core';
import {AdminManagementService} from '../../../../store/admin-management.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-user-charts-activity',
  templateUrl: './user-charts-activity.component.html',
  styleUrls: ['./user-charts-activity.component.scss']
})
export class UserChartsActivityComponent implements OnInit {

  private subscriptions = [];
  @Input() email;
  fullCrawlData;
  searchData;
  loading;

  form_timeRange: FormGroup;
  chartSelected = 'search';

  constructor(private adminManagementService: AdminManagementService) {
      this.form_timeRange = new FormGroup({
          'timeRange': new FormControl('2592000'),
      });
  }

  ngOnInit() {
    this.getUserChartsActivity(2592000);
  }

  getUserChartsActivity(hours) {
      this.loading = true;
      this.subscriptions.push(
          this.adminManagementService.getUserChartsACtivity(this.email, hours).subscribe( result => {
              this.fullCrawlData = result.body.full_crawl_data.graph_info;
              this.searchData = result.body.search_data;
              this.loading = false;
          }, () => {
              this.loading = false;
          })
      );
  }


    selectChart(nameChart) {
      this.chartSelected = nameChart;
    }

    changeTimeQuery(hours) {
        this.loading = true;
        this.getUserChartsActivity(hours.value);
    }
}
