import {Pipe, PipeTransform} from '@angular/core';
import {AppState} from '../../../store/app/app.reducer';
import {Store} from '@ngrx/store';
import * as selectorsApp from '../../../store/app/app.selectors';

@Pipe({
    name: 'currencyRedux'
})
export class CurrencyReduxPipe implements PipeTransform {
    constructor(private store: Store<AppState>
    ) {}

    transform(value: number,  currencyGet: string = '', currencySymbol: string = '', digitsInfo: string = '1.2-2'): any {
        if (currencyGet !== '') {
            return this.transformCurrency(value, currencyGet, currencySymbol);
        } else {
            let currencyRedux = '';
            let symbolRedux = '';
            this.store.select(selectorsApp.selectCurrency).subscribe(response => {
                currencyRedux = response;
                symbolRedux = response;
            });
            this.store.select(selectorsApp.selectCurrencySymbol).subscribe(response => {
                symbolRedux = response;
            });
            return this.transformCurrency(value, currencyRedux, symbolRedux);
        }
    }


    transformCurrency(value, currency, symbol) {
        // const formattedValue = this.currencyPipe.transform(value, currency, 'symbol', '1.2-2');
        // // Reemplaza las comas por nada para eliminarlas
        // const formattedValueWithoutCommas = formattedValue.replace(/,/g, '');
        // // Elimina cualquier símbolo de moneda al principio del valor formateado
        // const numberValueRaw = formattedValueWithoutCommas.replace(/^[^\d]+/, '');
        // // Si el valor termina en .00, lo eliminamos.
        // const numberValue = numberValueRaw.endsWith('.00') ? numberValueRaw.slice(0, -3) : numberValueRaw;
        // // Concatena el número (sin .00 si aplica) y el código de moneda en el formato deseado
        // return `${numberValue} ${symbol} (${currency})`;

        // Formatea el valor usando comas como separadores de miles
        const formattedValue = value.toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        // Elimina cualquier símbolo de moneda al principio del valor formateado
        const numberValueRaw = formattedValue.replace(/^[^\d]+/, '');

        // Si el valor termina en ,00, lo eliminamos.
        const numberValue = numberValueRaw.endsWith('.00') ? numberValueRaw.slice(0, -3) : numberValueRaw;

        // Concatena el número (sin ,00 si aplica) y el código de moneda en el formato deseado
        return `${numberValue} ${symbol} (${currency})`;
    }
}
