import {Component, Input, OnInit} from '@angular/core';
// import {MatDialogRef} from '@angular/material';
import {ReportService} from '../../../../../services/report.service';
// import {ReportInfluencerContentModalComponent} from '../../report-influencer-content-modal.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-report-influencer-content-stats',
  templateUrl: './report-influencer-content-stats.component.html',
  styleUrls: ['./report-influencer-content-stats.component.scss']
})
export class ReportInfluencerContentStatsComponent implements OnInit {

  @Input() influencer;
  @Input() data;

  loading;
  campaign_id = '';

  constructor(
              private _reportService: ReportService,
              public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    if (!this.data.campaign_id) {
      this.campaign_id = this.data.hash_report_id;
    } else if (this.data.campaign_id) {
      this.campaign_id = this.data.campaign_id;
    }
  }


  /**
   * @description Refresh stadistics from the posts of influencers, each influencer have the button refresh for the posts
   * @param influencer for compare for update only the posts from the required influencer.
   * @return The correct return call to getReport for refresh the stadistics, the error return show a snackbar with text-errors
   */
  refreshStadisticsPosts(influencer): void {
    this.loading = true;
    this.iteratePosts(influencer);
  }

  iteratePosts(influencer) {
    for (let index_posts = 0; index_posts < influencer.posts.length; index_posts++) {
      if (!influencer.posts[index_posts].deleted) {
        // this.updateInteractionsPosts(index_posts, influencer.posts.length, this.data.influencer_id, influencer.posts[index_posts].shortcode);
        this.updateInteractionsPosts(index_posts, influencer.posts.length, this.data.influencer_id, influencer.posts[index_posts].media_id);
      }
    }
  }

  updateInteractionsPosts(index_post, posts_length, influencer_id, post_shortcode) {
    this._reportService.updatePostManually(this.campaign_id, influencer_id, post_shortcode).subscribe(() => {
      if (index_post + 1 === posts_length) {
        this.getInfluencerReportData();
        this.loading = false;
        this.openSnackBar('The posts statistics have been updating successfully.' , 'OK');
      }
    }, () => {
      this.loading = false;
      this.openSnackBar('There were an error trying to update the posts.' , 'OK');
    });
  }

  /**
   * @description Show snackbar with the messages and actions granted
   * @param message
   * @param action
   */
  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  filteringInfluencerMedia() {
    if (this.influencer.network === 'instagram') {
      const aux_posts = [];
      this.influencer['posts'].forEach((post) => {
        if (post['deleted'] === false) {
          aux_posts.push(post);
        }
      });
      this.influencer['posts'] = aux_posts;

    } else if (this.influencer.network === 'youtube') {
      const aux_videos = [];
      this.influencer['videos'].forEach((video) => {
        if (video['deleted'] === false) {
          aux_videos.push(video);
        }
      });
      this.influencer['videos'] = aux_videos;
    } else if (this.influencer.network === 'tiktok') {
      const aux_videos = [];
      this.influencer['tiktok_videos'].forEach((video) => {
        if (video['deleted'] === false) {
          aux_videos.push(video);
        }
      });
      this.influencer['videos'] = aux_videos;
    }
  }


  getInfluencerReportData() {
    if (!this.data.is_share) {
      this._reportService.getInfluencerReportData(this.campaign_id, this.data.influencer_id).subscribe(result => {
        if (result['status'].toString() === '200') {
          this.influencer = result['body'];
          this.filteringInfluencerMedia();
          this.loading = false;
        }
      }, () => {
        this.openSnackBar('There was an error loading the influencer data. Please try again.', 'Close');
        // this.dialogRef.close();
      });
    } else {
      this._reportService.getSharedInfluencerReportData(this.campaign_id, this.data.influencer_id).subscribe(result => {
        if (result['status'].toString() === '200') {
          this.influencer = result['body'];
          this.filteringInfluencerMedia();
          this.loading = false;
        }
      }, () => {
        this.openSnackBar('There was an error loading the influencer data. Please try again.', 'Close');
        // this.dialogRef.close();
      });
    }
  }


  percent(estimated, current) {
    if (estimated === 0 && current > 0) {
      return 100;
    } else if (estimated === 0) {
      return 0;
    } else {
      return current / estimated * 100;
    }
  }
}
