import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment';
import {AmplifyService} from 'aws-amplify-angular';
import * as uuid from 'uuid';
import { ReportService } from 'src/app/services/report.service';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import { RemovePostComponent } from '../../../remove-post/remove-post.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ShowStoryModalComponent } from '../../../show-story-modal/show-story-modal.component';

@Component({
  selector: 'app-modal-view-stats-media',
  templateUrl: './modal-view-stats-media.component.html',
  styleUrls: ['./modal-view-stats-media.component.scss']
})
export class ModalViewStatsMediaComponent implements OnInit {

  url_insight_image = '';
  error_insight_image_upload = '';

  showInputEditImpressions = false;
  showInputEditReach = false;
  uploading_image = false;

  dataForShow = {
    saved: undefined,
    reach: undefined,
    comments: undefined,
    likes: undefined,
    views: undefined,
    impressions: undefined,
  };

  impressionsForm: FormGroup;
  reachForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private _amplifyService: AmplifyService,
    private _reportService: ReportService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
    this.impressionsForm = new FormGroup({
      'impressions': new FormControl('', [Validators.required]),
    });

    this.reachForm = new FormGroup({
      'reach': new FormControl('', [Validators.required])
    });
   }

  ngOnInit() {
    this.data.media.impressions = Number(this.data.media.impressions);
    this.setData();
  }


  setData() {
    // si tenemos imagen de insight, no mostramos datos.
    if (this.data.media.media_type === 'story_video' || this.data.media.media_type === 'story_img') {
      this.impressionsForm.controls.impressions.setValue(this.data.media.impressions);
    } else {
     if (this.data.media.facebook_insights) {
        this.dataForShow = {
          saved: this.data.media.facebook_insights.saved,
          reach: this.data.media.facebook_insights.reach,
          comments: this.data.media.comments,
          likes: this.data.media.likes,
          views: this.data.media.real_data.views,
          impressions: this.data.media.real_data.impressions,
        }
        this.impressionsForm.controls.impressions.setValue(this.data.media.real_data.impressions);
        this.reachForm.controls.reach.setValue(this.data.media.facebook_insights.reach);
      // si no tenemos ni insight image ni social login, mostramos los datos calculados.
      } else {
        this.dataForShow = {
          saved: undefined,
          reach: undefined,
          comments: this.data.media.comments,
          likes: this.data.media.likes,
          views: this.data.media.real_data.views,
          impressions: this.data.media.real_data.impressions,
        }
        this.impressionsForm.controls.impressions.setValue(this.data.media.real_data.impressions);
      }
    }

  }


  percent(estimated, current) {
    if (estimated === 0 && current > 0) {
      return 100;
    } else if (estimated === 0) {
      return 0;
    } else {
      return current / estimated * 100;
    }
  }

  uploadBrandLogo(fileInput: any): void {
    this.uploading_image = true;
    try {
      this.error_insight_image_upload = '';
      const file = fileInput.target.files[0];
      if (file.size <= 5242880) {
        if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg') {
          const extension = this.getExtensionFromImage(file.type);
          const config = {
            bucket: environment.aws_resources.s3.buckets.report_insight_images,
            region: environment.aws_resources.region,
            level: 'public',
            customPrefix: {
              public: ''
            },
          };
          const random_uuid = uuid.v4();
          this._amplifyService.storage().vault.put(this.data.campaign_id + '/' + this.data.influencer_id + '/' + this.data.media.media_id + '/' + random_uuid + extension, file, config).then(() => {
            const url = 'https://' + config.bucket + '.s3-eu-west-1.amazonaws.com/' + this.data.campaign_id + '/' + this.data.influencer_id + '/' + this.data.media.media_id + '/' + random_uuid + extension;
            this._reportService.uploadInsights(this.data.campaign_id , this.data.media.media_id, url).subscribe(response => {
              this.uploading_image = false;
              this.data.media.associated_media_url = this.getUrlImageWithTime(url);
              this.callCustomSnackbar('Image uploaded successfully', 'success-snackbar', 'success');
            }, () => {
              this.callCustomSnackbar( 'Something went wrong. Please try again later.', 'error-snackbar', 'error');
              this.uploading_image = false;
            });
          }).catch(() => {
            this.callCustomSnackbar( 'Something went wrong. Please try again later.', 'error-snackbar', 'error');
            this.uploading_image = false;
          });
        } else {
          this.callCustomSnackbar( 'Unsupported file type. Select image with types: jpg, jpeg or png.', 'error-snackbar', 'error');
          this.uploading_image = false;
        }
      } else {
        this.callCustomSnackbar( 'The file exceeds 5MB.', 'error-snackbar', 'error');
        this.uploading_image = false;
      }
    } catch (e) {
      this.callCustomSnackbar( 'Something went wrong. Please try again later.', 'error-snackbar', 'error');
      this.uploading_image = false;
    }
  }


  callCustomSnackbar(message, panel, state) {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message: message, type: state },
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: panel,
    });
  }


    getExtensionFromImage(file_type: string): string {
      return file_type.replace('image/', '.');
    }
  
    getUrlImageWithTime(url_image) {
      if (url_image !== 'n/a') {
        return url_image + '?ts=' + new Date().getTime();
      } else {
        return url_image;
      }
    }

    redirectExternalUrl(url) {
      window.open(url, '_blank');
    }


    removeImage() {
      this.dialog.open(RemovePostComponent, {
        width: '550px',
        height: '240px',
        autoFocus: false,
        data: {
          campaign_id: this.data.campaign_id,
          media_id: this.data.media.media_id,
          instagram_id: this.data.influencer_id,
          media_type: 'insight',
        }
      }).afterClosed().subscribe(response => {
        if (response === 'success') {
          this.data.media.associated_media_url =  '';
          this.setData();
          this.callCustomSnackbar('Image removed successfully', 'success-snackbar', 'success');
        } else if (response === 'error') {
          this.callCustomSnackbar( 'Something went wrong. Please try again later.', 'error-snackbar', 'error');
        }
      });
    }
    

    
  saveEdits(name?, value?) {
    let new_stats = {};
    if (this.data.media.network === 'instagram') {
      if (name === 'impressions') {
        new_stats = {
          impressions: value,
        };
      } else if (name === 'reach') {
        new_stats = {
          reach: value,
        };
      }
    }

    this._reportService.updateImpressions(this.data.campaign_id, this.data.media.media_id, new_stats).subscribe(result => {
      this.callCustomSnackbar('Data updated successfully', 'success-snackbar', 'success');
      if (name === 'impressions') {
        if (this.data.media.media_type === 'story_video' || this.data.media.media_type === 'story_img') {
          this.data.media.impressions = value;
        } else {
           this.dataForShow.impressions = value;
        }
        this.showInputEditImpressions = false;
      } else if (name === 'reach') {
        this.showInputEditReach = false;
        this.dataForShow.reach = value;
      }
    }, () => {
      this.callCustomSnackbar( 'Something went wrong. Please try again later.', 'error-snackbar', 'error');
    });
  }


  
  openShowStoryDialog(media): void {
    let config: any;
    config = {
      width: '500px',
      height: '500px',
      autoFocus: false,
      data: {
        media: media,
        is_insight: true,
      },
      backdropClass: 'story-dialog-backdrop-class',
      panelClass: 'story-dialog-panel-class'
    };
    this.dialog.open(ShowStoryModalComponent, config);
  }
}
