import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AmplifyService } from 'aws-amplify-angular';
import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material';
import { DatePickerConfigClass } from 'src/app/classes/date-picker-config-class';
import { InfluencersService } from 'src/app/services/influencers-cards.service';
import { InfluencerProfileService } from 'src/app/services/influencer-profile.service';
import { ReportService } from 'src/app/services/report.service';
import { BriefService } from 'src/app/services/brief.service';
import { IntercomModule } from 'ng-intercom';
import { environment } from 'src/environments/environment';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { RefreshSessionService } from 'src/app/services/refresh-session.service';
import { ProgressService } from 'src/app/services/progress.service';
import { GiftService } from 'src/app/services/gift.service';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { COUNTRY_CODES } from 'src/app/constants/countries';
import { COUNTRIES } from './constants';
import { LoginGuard } from 'src/app/guards/login.guard';
import { httpInterceptorProviders } from 'src/app/interceptor';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IntercomModule.forRoot(environment.intercom.config)
  ],
  providers: [
    AmplifyService,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: DateAdapter, useClass: DatePickerConfigClass },
    httpInterceptorProviders,
    InfluencersService,
    InfluencerProfileService,
    ReportService,
    BriefService,
    CampaignsService,
    RefreshSessionService,
    InfluencerProfileService,
    ProgressService,
    GiftService,
    AuthGuard,
    LoginGuard,
    { provide: COUNTRIES, useValue: COUNTRY_CODES },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. You should only import Core modules in the AppModule only.'
      );
    }
  }
}
