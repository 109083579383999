import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Plan} from '../types/plans';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModalPlansService {

  constructor(private http: HttpClient) {}

  private plansUrl = environment.aws_resources.lambda_endpoint.api;


  private static processPlan(plan): Plan {
    return {
      pricePaidYearly : plan.price_yearly,
      pricePaidMonthly : plan.price_monthly,
      planName: plan.plan_name,
      activePlan: plan.ACTIVE_PLAN,
      paddleSubscriptionIdMonthly: plan.PADDLE_SUBSCRIPTION_ID_MONTHLY,
      paddleSubscriptionIdYearly:  plan.PADDLE_SUBSCRIPTION_ID_YEARLY,
      searchTitle:  'search_title' in plan ? plan.search_title : [],
      support_title: 'support_title' in plan ? plan.support_title : [],
      campaign_title: 'campaign_title' in plan ? plan.campaign_title : [],
      sales_title: 'sales_title' in plan ? plan.sales_title : [],
      limitations: 'limitations' in plan ? plan.limitations : [],
      favourite_title: 'favourite_title' in plan ? plan.favourite_title : [],
      ia_title: 'ia_title' in plan ? plan.ia_title : [],
    };
  }

  getPlans() {
    return this.http.get(this.plansUrl + 'plans/list_plans').pipe(
      map((rawPlans: any[]) => rawPlans.map(ModalPlansService.processPlan))
    );
  }

  savePaddlePayment(payment) {
    const body = {
        paddle_event: payment
      };
    return this.http.put( this.plansUrl + 'plans/checkout_complete', body);
  }

  upgradePlan(plan) {
    const body = {
      plan_to_update: plan
    };
    return this.http.put(this.plansUrl + 'plans/update_subscription_plan', body);
  }

  cancelSubscriptionPlan(formCancelReason) {
    const body = {
      cancellation_reason: formCancelReason
    };
    return this.http.put( this.plansUrl + 'plans/cancel_user_subscription', body);
  }


  getPlanInfo(planName) {
    return this.http.get(this.plansUrl + 'plans/list_active_plan/' + planName, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getBonus() {
    return this.http.get(this.plansUrl + 'plans/list_extra_analyze_bonus', {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  pauseSubscriptionPlan(timestampPaused, reasonPause) {
    const body = {
      pause_duration: timestampPaused,
      pause_reason: reasonPause

    };
    return this.http.put( this.plansUrl + 'plans/pause-subscription', body);
  }

  reactivateSubscriptionPlan() {
    const body = {};
    return this.http.put( this.plansUrl + 'plans/reactivate-subscription', body);
  }
}
