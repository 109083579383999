import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {ChartDataSets} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import {round} from 'lodash';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, OnChanges {

  @Input() chartData;

  // Bar Charts Config
  public barChartOptions: any = {
    scaleShowVerticalLines: true,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [],
      yAxes: [],
    },
  };
  public barChartType = 'bar';
  public barChartLegend = true;

  // Values of the line
  public barChartData: ChartDataSets[] = [];

  public barChartLabels = [];


  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    // Aqui comprobando likes comprovamos si es la grafica doble o la individual. Likes = doble
    if (this.chartData[0].likes >= 0) {
      this.barChartData = [{
        data: [],
        label: ''

      },
        {
          data: [],
          label: '',
          yAxisID: 'y-axis-1' }
      ];
      this.barChartData[0].label = 'Likes';
      this.barChartData[1].label = 'Comments';
      this.barChartOptions.scales.yAxes = [
        {
          id: 'y-axis-0',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: 'Likes',
            fontStyle: 'bold',
            // fontColor: this.theme.primary,
          },
          ticks: {
            precision: 0,
            userCallback: function(follows, index, labels) {
              if (follows === 0) {
                return '0';
              }
              if (!follows) {
                // return '';
                return '0';
              }
              if (follows > 1000000000) {
                const VALUE = follows / 1000000000;
                const DECIMALS = VALUE.toFixed(1).toString();
                const AUX = DECIMALS.split('.');
                if (AUX[1] === '0') {
                  return VALUE.toFixed(0).toString() + 'B';
                } else {
                  return VALUE.toFixed(1).toString() + 'B';
                }
              } else if (follows >= 1000000000) {
                const VALUE = follows / 1000000000;
                return VALUE.toFixed(0).toString() + 'B';
              } else if (follows > 1000000) {
                const VALUE = follows / 1000000;
                const DECIMALS = VALUE.toFixed(1).toString();
                const AUX = DECIMALS.split('.');
                if (AUX[1] === '0') {
                  return VALUE.toFixed(0).toString() + 'M';
                } else {
                  return VALUE.toFixed(1).toString() + 'M';
                }
              } else if (follows >= 1000000) {
                const VALUE = follows / 1000000;
                return VALUE.toFixed(0).toString() + 'M';
              } else if (follows > 1000) {
                const VALUE = follows / 1000;
                const DECIMALS = VALUE.toFixed(1).toString();
                const AUX = DECIMALS.split('.');
                if (AUX[1] === '0') {
                  return VALUE.toFixed(0).toString() + 'K';
                } else {
                  return VALUE.toFixed(1).toString() + 'K';
                }
              } else if (follows >= 1000) {
                const VALUE = follows / 1000;
                return VALUE.toFixed(0).toString() + 'K';
              } else {
                return follows.toFixed();
              }

            },
          }
        },
        {
          id: 'y-axis-1',
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: 'Comments',
            fontStyle: 'bold',
            // fontColor: this.theme.secondary,
          },
          ticks: {
            precision: 0,
            userCallback: function(follows, index, labels) {
              if (follows === 0) {
                return '0';
              }
              if (!follows) {
                // return '';
                return '0';
              }
              if (follows > 1000000000) {
                const VALUE = follows / 1000000000;
                const DECIMALS = VALUE.toFixed(1).toString();
                const AUX = DECIMALS.split('.');
                if (AUX[1] === '0') {
                  return VALUE.toFixed(0).toString() + 'B';
                } else {
                  return VALUE.toFixed(1).toString() + 'B';
                }
              } else if (follows >= 1000000000) {
                const VALUE = follows / 1000000000;
                return VALUE.toFixed(0).toString() + 'B';
              } else if (follows > 1000000) {
                const VALUE = follows / 1000000;
                const DECIMALS = VALUE.toFixed(1).toString();
                const AUX = DECIMALS.split('.');
                if (AUX[1] === '0') {
                  return VALUE.toFixed(0).toString() + 'M';
                } else {
                  return VALUE.toFixed(1).toString() + 'M';
                }
              } else if (follows >= 1000000) {
                const VALUE = follows / 1000000;
                return VALUE.toFixed(0).toString() + 'M';
              } else if (follows > 1000) {
                const VALUE = follows / 1000;
                const DECIMALS = VALUE.toFixed(1).toString();
                const AUX = DECIMALS.split('.');
                if (AUX[1] === '0') {
                  return VALUE.toFixed(0).toString() + 'K';
                } else {
                  return VALUE.toFixed(1).toString() + 'K';
                }
              } else if (follows >= 1000) {
                const VALUE = follows / 1000;
                return VALUE.toFixed(0).toString() + 'K';
              } else {
                return follows.toFixed();
              }

            },
          }
        }
      ];
      this.barChartOptions.scales.xAxes = [{
        display: true,
        type: 'time',
        distribution: 'series',
        gridLines: {
          display: true
        },
        time: {
          unit: 'week',
          isoWeekday: true,
          tooltipFormat: 'D MMM',
          displayFormats: {
            week: 'D MMM'
          }
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 15
        }
      }];

    } else {
      this.barChartData = [{
        data: [],
        labels: [],
        label: 'Followings',
      }];
      this.barChartOptions.scales.yAxes = [
        {
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
          }
        },
      ];
      this.barChartOptions.scales.xAxes = [
        {
          display: true,
          ticks: {
            autoSkip: true,
            maxTicksLimit: 15
          },
        },
      ];
    }
    this.barChartData[0].data = [];
    this.barChartLabels = [];
    // Set the values
    for (let index = 0; index < this.chartData.length ; index++) {
      // Aqui comprobando likes comprobamos si es la grafica doble o la individual. Likes = doble
      if (this.chartData[0].likes >= 0) {
        this.barChartData[0].data.push({y: this.chartData[index].likes, x: this.chartData[index].date * 1000});
        this.barChartData[1].data.push({y: this.chartData[index].comments, x: this.chartData[index].date * 1000});
      } else {
        this.barChartData[0].data.push(round(this.chartData[index].weight * 100));
        this.barChartLabels.push(this.chartData[index].code);
      }
    }
  }
}
