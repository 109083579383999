import {Component, Inject, OnInit} from '@angular/core';
import {BriefService} from '../../../../../../services/brief.service';
import {MAT_DIALOG_DATA, MatDialog, MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-modal-confirmation-send-previous-email',
  templateUrl: './modal-confirmation-send-previous-email.component.html',
  styleUrls: ['./modal-confirmation-send-previous-email.component.scss']
})
export class ModalConfirmationSendPreviousEmailComponent implements OnInit {

  campaignId = '';
  influencerId = '';

  constructor(private _briefService: BriefService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              public snackBar: MatSnackBar
  ) {

    this.campaignId = this.data.campaignId;
    this.influencerId = this.data.influencerId;
  }

  ngOnInit() {}


  sendPreviousEmailInvitation() {
      this._briefService.sendPreviousEmailInvitation(this.campaignId, this.influencerId).subscribe( () => {
        this.openSnackBar('Previous email send successfully', 'Close');
      }, () => {
        this.openSnackBar('Oops something went wrong, please try again', 'Close');
      });
    this.dialog.closeAll();
  }


  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
