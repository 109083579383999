import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ProgressService} from '../../../../../../services/progress.service';
import {ModalConfirmResendInvitationComponent} from '../../modals/modal-confirm-resend-invitation/modal-confirm-resend-invitation.component';
import {FormControl, FormGroup} from '@angular/forms';
import {ConfirmActionInfluencerStateComponent} from '../../modals/modal-confirm-action-influencer-state/confirm-action-influencer-state.component';
import {Store} from '@ngrx/store';
import {State} from 'src/app/store';
import {environment} from '../../../../../../../environments/environment';
import {ModalInfluencerLinkComponent} from '../../modals/modal-influencer-link/modal-influencer-link.component';
import {selectAppViewPort} from '../../../../../../store/app/app.selectors';

@Component({
  selector: 'app-progress-influencers-campaign',
  templateUrl: './progress-influencers-campaign.component.html',
  styleUrls: ['./progress-influencers-campaign.component.scss']
})
export class ProgressInfluencersCampaignComponent implements OnInit, OnDestroy {

  loading = false;
  editInfluencerEmail = false;
  influencer_row_checkbox_checked = false;
  all_influencers_checkbox_checked = false;

  @Input() influencers;
  @Input() campaign_id;
  @Input() progress;
  @Input() influencers_ready;
  @Input() influencers_pending;
  @Input() influencers_canceled;
  @Input() influencers_rejected;
  @Input() influencers_for_resend_invitation;
  @Input() count_influencers_ready;


  @Output() callGetProgress = new EventEmitter();

  private subscriptions = [];
  influencerProgressForm: FormGroup;
  viewport;

  constructor(    private _progressService: ProgressService,
                  private activatedRoute: ActivatedRoute,
                  private dialog: MatDialog,
                  public snackBar: MatSnackBar,
                  public router: Router,
                  public store: Store<State>) {
    this.influencerProgressForm = new FormGroup({});
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectAppViewPort).subscribe(result => {
        this.viewport = result;
      })
    );
    // Create and get input emails
    this.influencers_pending.forEach(
      (influencer_pending, index_pending) => {
        this.influencerProgressForm.setControl(
          'email_' + index_pending,
          new FormControl('')
        );
      }
    );
    this.influencers_pending.sort((a, b) => {
      if (a.invitation_mail && !b.invitation_mail) {
        return -1;
      } else if (!a.invitation_mail && b.invitation_mail) {
        return 1;
      } else {
        return 0;
      }
    });
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  // VirtualScroll
  trackByFn(index) {
    return index;
  }


  saveInfluencerEmail(influencer, email) {
    // const regexp = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;
    const regexp = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,15}$/;
    const resultEmail = regexp.test(email.value);
    if (resultEmail) {
      const invitation_email = {
        invitation_email: email.value,
      };
      this.progress.has_report = true;
      this._progressService
        .saveInfluencerEmail(
          this.campaign_id,
          influencer.influencer_id,
          invitation_email
        )
        .subscribe(
          () => {
            influencer.state = 'invited';
            influencer.invitation_mail = email.value;
            this.editInfluencerEmail = false;
            this.openSnackBar('Email send successfully', 'Close');
          },
          error => {
            this.openSnackBar(
              'Oops something went wrong, please try again later',
              'Close'
            );
          }
        );
    } else {
      this.openSnackBar('Please write an email correctly', 'Close');
    }
  }

  /**
   * @description Show snackbar with the messages and actions granted
   * @param message
   * @param action
   */
  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  openWhatsappModal(influencer_id) {
    this._progressService
      .getInfluencerPhoneNumber(this.campaign_id, influencer_id)
      .subscribe(
        result => {
          const urlRedirect = 'https://wa.me/' + result.body.influencer_phone;
          window.open(urlRedirect);
        },
        error => {
          this.openSnackBar(
            'Oops something went wrong, please try again later',
            'Close'
          );
        }
      );
  }

  deleteInfluencer(influencerId) {
    const dialogRef = this.dialog.open(ConfirmActionInfluencerStateComponent, {
      width: '40%',
      height: '30%',
      minWidth: '450px',
      minHeight: '200px',
      autoFocus: false,
      data: {
        campaign_id: this.campaign_id,
        influencer_id: influencerId,
        action: 'cancel',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== '') {
        if (result['status'] === 200) {
          this.openSnackBar('Influencer was removed successfully.', 'Ok');
          this.loading = true;
          if (this.influencers.length - 1 !== 0) {
            this.influencers_pending.forEach((influencer, idx) => {
              if (influencer.influencer_id === influencerId) {
                this.influencers_pending.splice(idx, 1);
              }
            });
          }
          this.callGetProgress.emit();
        } else {
          this.openSnackBar('There has been an error. Please try again.', 'Ok');
        }
      }
    });
  }


  requestSocialLogin() {
    window.open(environment.icmRedirect, '_blank');
  }


  copyInfluencerDestionationUrl(val: string) {

    this.dialog.open(ModalInfluencerLinkComponent, {
      autoFocus: false,
      data: {
        influencer_link: val,
      },
    });
  }

  setVirtualScrollHeight(array_length) {
    if (this.viewport === 'handset') {
      if (array_length >= 10) {
        return '1300px';
      } else {
        return (array_length * 400).toString() + 'px';
      }
    } else {
      if (array_length >= 10) {
        return '1300px';
      } else {
        return (array_length * 100).toString() + 'px';
      }
    }
  }


  openModalConfirmResendInvitation() {
    this.dialog.open(ModalConfirmResendInvitationComponent, {
      width: '40%',
      height: '40%',
      autoFocus: false,
      data: {
        influencers_ids: this.influencers_for_resend_invitation,
        campaign_id: this.campaign_id,
      },
    });
  }


  prepareAllInfluencersForResend(event) {
    // if the client check the fatherCheckbox
    if (event.checked) {
      this.influencer_row_checkbox_checked = true;
      this.influencers_for_resend_invitation = [];
      for (let index = 0; index < this.influencers_pending.length; index++) {
        if (this.influencers_pending[index]['state'] === 'invited') {
          this.influencers_pending[index].resend_invitation = true;
          this.influencers_for_resend_invitation.push(
            this.influencers_pending[index].influencer_id
          );
        }
      }
    } else {
      this.influencers_for_resend_invitation = [];
      for (let index = 0; index < this.influencers_pending.length; index++) {
        if (this.influencers_pending[index]['state'] === 'invited') {
          this.influencers_pending[index].resend_invitation = false;
        }
      }
    }
  }


  openActionInfluencerDialog(influencer_id, action): void {
    const dialogRef = this.dialog.open(ConfirmActionInfluencerStateComponent, {
      width: '35%',
      height: '25%',
      minWidth: '450px',
      minHeight: '200px',
      autoFocus: false,
      data: {
        campaign_id: this.campaign_id,
        influencer_id: influencer_id,
        action: action,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loading = true;
      if (result['status'] === 200) {
        if (action === 'cancel') {
          this.openSnackBar('Influencer was removed successfully.', 'Ok');
        } else if (action === 'accept') {
          this.openSnackBar('Influencer was accepted successfully.', 'Ok');
        }
        this.callGetProgress.emit();
        this.loading = false;
      } else if (result !== '') {
        this.openSnackBar('There has been an error. Please try again.', 'Ok');
        this.loading = false;
      }
      this.loading = false;
    });
  }

  addInfluencerForSendInvitation(influencer, event) {
    // if the checkbox is activaded, then push to array for resend
    if (event.checked) {
      influencer.resend_invitation = true;
      this.influencers_for_resend_invitation.push(influencer.influencer_id);
    } else {
      // if the checkbox is desactivated, get the influencer_id and remove this
      influencer.resend_invitation = false;
      for (
        let index = 0;
        index < this.influencers_for_resend_invitation.length;
        index++
      ) {
        if (
          influencer.influencer_id ===
          this.influencers_for_resend_invitation[index]
        ) {
          this.influencers_for_resend_invitation.splice(index, 1);
        }
      }
    }
  }


}
