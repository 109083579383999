import {Component, Input, OnInit} from '@angular/core';
import {ShowStoryModalComponent} from '../../../../../components/private/campaigns/show-story-modal/show-story-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {RemovePostComponent} from '../../../../../components/private/campaigns/remove-post/remove-post.component';
import {ModalUploadStoriesManuallyComponent} from '../../../../../components/private/campaigns/report-campaign/report-influencer-content-modal/modal-upload-stories-manually/modal-upload-stories-manually.component';
import {AddPostManuallyComponent} from '../../../../../components/private/campaigns/add-post-manually/add-post-manually.component';
import {ReportService} from '../../../../../services/report.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ModalViewStatsMediaComponent } from 'src/app/components/private/campaigns/report-campaign/modals/modal-view-stats-media/modal-view-stats-media.component';

@Component({
  selector: 'app-report-influencer-content-medias',
  templateUrl: './report-influencer-content-medias.component.html',
  styleUrls: ['./report-influencer-content-medias.component.scss']
})
export class ReportInfluencerContentMediasComponent implements OnInit {

  @Input() influencer;
  @Input() data;

  loading = true;

  currency_type = '';
  campaign_id = '';

  breakpoint;

  constructor(private dialog: MatDialog,
              private _reportService: ReportService,
              public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 400) ? 2 : 4;
    if (!this.data.campaign_id) {
      this.campaign_id = this.data.hash_report_id;
    } else if (this.data.campaign_id) {
      this.campaign_id = this.data.campaign_id;
    }
  }

  openShowStoryDialog(media, network): void {
    let config: any;
    media['origin'] = 'report';

    if (network === 'instagram') {
      config = {
        width: '500px',
        height: '500px',
        autoFocus: false,
        data: {
          media: media
        },
        backdropClass: 'story-dialog-backdrop-class',
        panelClass: 'story-dialog-panel-class'
      };
    } else if (network === 'youtube') {
      config = {
        width: 'auto',
        height: '100vh',
        autoFocus: false,
        data: {
          media: media
        },
        backdropClass: 'story-dialog-backdrop-class',
        panelClass: 'youtube-video-dialog-panel-class'
      };
    } else if (network === 'tiktok') {
      config = {
        width: 'auto',
        height: '100vh',
        autoFocus: false,
        data: {
          media: media
        },
        backdropClass: 'story-dialog-backdrop-class',
        panelClass: 'youtube-video-dialog-panel-class'
      };
    }

    this.dialog.open(ShowStoryModalComponent, config);
  }

  openAddManuallyDialog(influencer_id, network, media_type?): void {
    if (media_type === 'story') {
      this.dialog.open(ModalUploadStoriesManuallyComponent, {
        autoFocus: false,
        width: '80%',
        height: '50vh',
        data: {
          campaign_id: this.campaign_id,
          influencerUsername: this.influencer.username,
          influencerId: influencer_id,
          influencerLinkadiaAdUrl: this.influencer.linkadia_ad_url,
          profile_pic: this.influencer.profile_pic_url,
          dateStart: this.data.dateStart,
          dateEnd: this.data.dateEnd,
        }
      }).afterClosed().subscribe(() => {
        this.loading = true;
        this.getInfluencerReportData();
      });
    } else {
      const data = {
        campaign_id: this.campaign_id,
        instagram_id: this.data.influencer_id,
        network: network,
        media_type: media_type,
        username: this.influencer.username,
      };
      if (network === 'youtube') {
        data['media_type'] = media_type;
      }

      this.dialog.open(AddPostManuallyComponent, {
        width: '550px',
        height: '400px',
        autoFocus: false,
        data: data
      }).afterClosed().subscribe(res => {
        if (res === 'success') {
          this.loading = true;
          this.openSnackBar('The ' + this.displayMediaType(network, media_type) + ' has been added successfully.', 'Ok');
          this.getInfluencerReportData();
        }
      });
    }

  }


  openRemovePostDialog(mediaId, media_type): void {
    this.dialog.open(RemovePostComponent, {
      width: '550px',
      height: '240px',
      autoFocus: false,
      data: {
        campaign_id: this.campaign_id,
        media_id: mediaId,
        instagram_id: this.data.influencer_id,
        media_type: media_type,
      }
    }).afterClosed().subscribe(res => {
      if (res === 'success') {
        this.loading = true;
        this.getInfluencerReportData();
      }
    });
  }

  getInfluencerReportData() {
    if (!this.data.is_share) {
      this._reportService.getInfluencerReportData(this.campaign_id, this.data.influencer_id).subscribe(result => {
        if (result['status'].toString() === '200') {
          this.influencer = result['body'];
          this.filteringInfluencerMedia();
          this.loading = false;
        }
      }, () => {
        this.openSnackBar('There was an error loading the influencer data. Please try again.', 'Close');
      });
    } else {
      this._reportService.getSharedInfluencerReportData(this.campaign_id, this.data.influencer_id).subscribe(result => {
        if (result['status'].toString() === '200') {
          this.influencer = result['body'];
          this.filteringInfluencerMedia();
          this.loading = false;
        }
      }, () => {
        this.openSnackBar('There was an error loading the influencer data. Please try again.', 'Close');
      });
    }
  }

  filteringInfluencerMedia() {
    if (this.influencer.network === 'instagram') {
      const aux_posts = [];
      this.influencer['posts'].forEach((post) => {
        if (post['deleted'] === false) {
          aux_posts.push(post);
        }
      });
      this.influencer['posts'] = aux_posts;

    } else if (this.influencer.network === 'youtube') {
      const aux_videos = [];
      this.influencer['videos'].forEach((video) => {
        if (video['deleted'] === false) {
          aux_videos.push(video);
        }
      });
      this.influencer['videos'] = aux_videos;
    } else if (this.influencer.network === 'tiktok') {
      const aux_videos = [];
      this.influencer['tiktok_videos'].forEach((video) => {
        if (video['deleted'] === false) {
          aux_videos.push(video);
        }
      });
      this.influencer['videos'] = aux_videos;
    }
  }


  /**
   * @description Show snackbar with the messages and actions granted
   * @param message
   * @param action
   */
  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  redirectExternalUrl(url) {
    window.open(url, '_blank');
  }

  displayMediaType(network, media_type) {
    return network === 'instagram' ? 'post' : media_type;
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 2 : 4;
  }

  openViewStatsMedia(media, typeMedia) {
    this.dialog.open(ModalViewStatsMediaComponent, {
      panelClass: 'remove-padding',
      autoFocus: false,
      width: '650px',
      height: '650px',
      data: {
        media: media,
        typeMedia: typeMedia,
        campaign_id: this.campaign_id,
        influencer_id: this.data.influencer_id,
        influencer_network: this.influencer.network,
        is_share: this.data.is_share,
      }
    }).afterClosed().subscribe(() => {
      this.loading = true;
      // notificar al padre o hace ralgo aqui, ver no repetir las funciones
      this.getInfluencerReportData();
    });
  }

}
