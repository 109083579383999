import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProgressApprovalShareModalComponent} from '../../modals/progress-approval-share-modal/progress-approval-share-modal.component';
import {MatDialog} from '@angular/material';
import {ProgressService} from '../../../../../../services/progress.service';

@Component({
  selector: 'app-progress-header',
  templateUrl: './progress-header.component.html',
  styleUrls: ['./progress-header.component.scss']
})
export class ProgressHeaderComponent implements OnInit {

  @Input() approve_content_totals;
  @Input() influencers_ready;
  @Input() count_influencers_ready;
  @Input() first;
  @Input() second;
  @Input() loadingContent;
  @Input() campaign_id;
  @Input () real_owner_email;
  @Input () influencers_with_gift_form_data;
  @Input () progress;
  @Input () count_total_influencers_accepted_invited;
  @Input () userIsExecute;
  @Input () origin;

  @Output() callSelectTab = new EventEmitter<any>();

  constructor(
    private dialog: MatDialog,
    private _progressService: ProgressService,
  ) { }

  ngOnInit() {
  }


  selectTab(number) {
    this.callSelectTab.emit(number);
  }

  shareProgressContentApproval() {
    this.dialog.open(ProgressApprovalShareModalComponent, {
      width: '600px',
      height: '250px',
      autoFocus: false,
      data: {
        campaign_id: this.campaign_id,
      },
    });
  }

  exportCSV() {
    this._progressService.exportToCsv('campaign_personal_data.csv', this.influencers_with_gift_form_data);
  }

}
