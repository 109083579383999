import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../../../../environments/environment';
import {openPlansDialog} from '../../../../../../store/app/app.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../store';

@Component({
  selector: 'app-modal-advantages-performance-plan',
  templateUrl: './modal-advantages-performance-plan.component.html',
  styleUrls: ['./modal-advantages-performance-plan.component.scss']
})
export class ModalAdvantagesPerformancePlanComponent implements OnInit {

  constructor(private store: Store<State>) { }

  ngOnInit() {
  }


  redirectCallSales() {
    window.open('https://calendly.com/nicolas-11posts/plans-and-pricing', '_blank');
  }

  openPlansModal() {
    if ( environment.platform_id === '11posts' ) {
      this.store.dispatch(openPlansDialog());
    }
  }
}
