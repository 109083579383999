import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {COUNTRY_CODES} from '../../../../constants/countries';
import {AdminManagementService} from '../../../admin-management/store/admin-management.service';

@Component({
  selector: 'app-free-plan-form',
  templateUrl: './free-plan-form.component.html',
  styleUrls: ['./free-plan-form.component.scss']
})
export class FreePlanFormComponent implements OnInit, OnDestroy {

  private subscriptions = [];
  countries = COUNTRY_CODES;
  freeTrialForm: FormGroup;

  registered = false;

  constructor(private adminManagementService: AdminManagementService) {
    this.freeTrialForm =  new FormGroup({
      freeFormName: new FormControl('', [Validators.required]),
      freeFormSurname: new FormControl('', [Validators.required]),
      freeFormJobPosition: new FormControl('', [Validators.required]),
      freeFormCountry: new FormControl('', [Validators.required]),
      freeFormCompanyName: new FormControl('', [Validators.required]),
      freeFormNumberEmployees: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  registerFreeTrial() {
    const registeTrialForm = {
      name: this.freeTrialForm.controls['freeFormName'].value,
      surname: this.freeTrialForm.controls['freeFormSurname'].value,
      job_position: this.freeTrialForm.controls['freeFormJobPosition'].value,
      country: this.freeTrialForm.controls['freeFormCountry'].value,
      company_name: this.freeTrialForm.controls['freeFormCompanyName'].value,
      number_employees: this.freeTrialForm.controls['freeFormNumberEmployees'].value,
    };
    this.subscriptions.push(
        this.adminManagementService.registerFreeTrial(registeTrialForm).subscribe( response => {
          this.registered = true;
        }, () => {
          this.registered = false;
        })
    );
  }

}
