import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-reachability-vertical-progress',
  templateUrl: './reachability-vertical-progress.component.html',
  styleUrls: ['./reachability-vertical-progress.component.scss']
})
export class ReachabilityVerticalProgressComponent implements OnInit {

  @Input() reachabilities;

  constructor() { }

  ngOnInit() {}

  calculateHeight(weight: number): number {
    return Math.min(weight, 100);
  }

}
