import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ProgressService } from '../../../../services/progress.service';
import {ProgressApprovalShareModalComponent} from './modals/progress-approval-share-modal/progress-approval-share-modal.component';
import {ConfirmActionInfluencerStateComponent} from './modals/modal-confirm-action-influencer-state/confirm-action-influencer-state.component';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import * as selectors from '../../../../store/auth/authentication.selectors';
import { AccessSets } from '../../../../types/access-sets';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-progress-campaign',
  templateUrl: './progress-campaign.component.html',
  styleUrls: ['./progress-campaign.component.scss'],
})
export class ProgressCampaignComponent implements OnInit, OnDestroy {

  loading = false;
  loadingContent = false;

  campaign_id = '';
  campaign_name = '';

  progress: any;
  influencers: Array<any>;
  influencers_with_gift_form_data: Array<any> = [];
  influencers_invited: Array<any>;
  influencers_pending: Array<any>;
  influencers_rejected: Array<any>;
  influencers_canceled: Array<any>;
  influencers_ready: Array<any>;
  count_influencers_ready: number;
  count_total_influencers_accepted_invited;

  first = true;
  second = false;
  editInfluencerEmail = false;

  bindable_progress: any;
  approve_content_totals = {
    accepted: 0,
    pending: 0,
    total_active_proposals: 0,
    all_proposals: 0,
  };

  influencers_for_resend_invitation = [];

  userIsExecute = false;
  userAccessSets: AccessSets;
  private subscriptions = [];

  real_owner_email = '';

  constructor(
    private _progressService: ProgressService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    public router: Router,
    public store: Store<State>
  ) {
    this.loading = true;
    this.campaign_id = this.activatedRoute.params['value'].id;


    // This is for control the shits code in progress, instead of try find good practises generic sharing the progress
    if (!this.router.url.includes('sharedProgress')) {
      this.getProgress();
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectors.selectAccessSets).subscribe(access => {
        this.userAccessSets = access;
        this.manageProposals('VIEW_PROPOSALS');
      })
    );
    this.getGiftData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  manageProposals(key: string): void {
    if (Object.keys(this.userAccessSets).includes(key)) {
      this.userIsExecute = true;
    }
  }



  getProgress() {
    this._progressService.getProgress(this.campaign_id).subscribe(
      result => {
        if (result['status'].toString() === '200') {

          // FIRST TAB (PROGRESS)
          this.resettingRows();

          this.progress = result['body'];
          if ('real_owner_email' in this.progress) {
            this.real_owner_email = this.progress['real_owner_email'];
          }
          this.campaign_name = this.progress.campaign_name;
          this.influencers = this.progress.influencers;

          this.progress.influencers.forEach((influencer, index) => {
            if (
              influencer.state === 'accepted' ||
              influencer.state === 'paid'
            ) {
              this.influencers_ready.push(influencer);
              this.count_influencers_ready++;
              this.count_total_influencers_accepted_invited++;
            } else if (
              influencer.state === 'waiting_approval' ||
              influencer.state === 'waiting_email' ||
              influencer.state === 'invited'
            ) {
              if (influencer.state === 'invited') {
                this.count_total_influencers_accepted_invited++;
              }
              this.influencers_pending.push(influencer);
              if (influencer.state !== 'waiting_payment') {
                this.count_influencers_ready++;
              }
            } else if (influencer.state === 'rejected') {
              this.influencers_rejected.push(influencer);
              this.count_influencers_ready++;
            } else if (influencer.state === 'canceled') {
              this.influencers_canceled.push(influencer);
              this.count_influencers_ready++;
            }
          });


          // SECOND TAB (PROPOSALS)
          this.bindable_progress = JSON.parse(JSON.stringify(this.progress));
          this.bindable_progress.origin = 'progress';
          this.bindable_progress.hash_progress_id = '';
          const bindable_influencers = [];

          this.bindable_progress.influencers.forEach(influ => {
            if (influ.state === 'accepted' || influ.state === 'paid') {
              influ['accepted_proposals'] = 0;
              influ['pending_proposals'] = 0;
              influ['total_active_proposals'] = 0;

              influ.proposals.forEach(proposal => {
                if (proposal.proposal_status === 'accepted') {
                  influ['accepted_proposals']++;
                  influ['total_active_proposals']++;
                } else if (proposal.proposal_status === 'pending') {
                  influ['pending_proposals']++;
                  influ['total_active_proposals']++;
                }
              });

              this.approve_content_totals.accepted +=
                influ['accepted_proposals'];
              this.approve_content_totals.pending += influ['pending_proposals'];
              this.approve_content_totals.total_active_proposals +=
                influ['total_active_proposals'];
              this.approve_content_totals.all_proposals +=
                influ.proposals.length;

              bindable_influencers.push(influ);
            }
          });

          this.bindable_progress.influencers = bindable_influencers;
        }
        // Order influencers by network and username
        this.influencers_ready.sort((a, b) =>
          a.username === b.username ? 1 : -1
        );
        this.influencers_pending.sort((a, b) =>
          a.username === b.username ? 1 : -1
        );
        this.influencers_canceled.sort((a, b) =>
          a.username === b.username ? 1 : -1
        );
        this.influencers_rejected.sort((a, b) =>
          a.username === b.username ? 1 : -1
        );

        this.loading = false;
      },
      error => {
        if (
          error.status.toString() === '404' ||
          error.status.toString() === '400'
        ) {
          this.router.navigate(['/app/campaigns']);
        }
      }
    );
  }


  resettingRows() {
    this.progress = [];
    this.influencers = [];
    this.influencers_pending = [];
    this.influencers_invited = [];
    this.influencers_rejected = [];
    this.influencers_canceled = [];
    this.influencers_ready = [];
    this.count_influencers_ready = 0;
    this.count_total_influencers_accepted_invited = 0;
  }



  saveInfluencerEmail(influencer, email) {
    const regexp = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,15}$/;
    const resultEmail = regexp.test(email.value);
    if (resultEmail) {
      const invitation_email = {
        invitation_email: email.value,
      };
      this.progress.has_report = true;
      this._progressService
        .saveInfluencerEmail(
          this.campaign_id,
          influencer.influencer_id,
          invitation_email
        )
        .subscribe(
          result => {
            influencer.state = 'invited';
            influencer.invitation_mail = email.value;
            this.editInfluencerEmail = false;
            this.openSnackBar('Email send successfully', 'Close');
          },
          error => {
            this.openSnackBar(
              'Oops something went wrong, please try again later',
              'Close'
            );
          }
        );
    } else {
      this.openSnackBar('Please write an email correctly', 'Close');
    }
  }

  /**
   * @description Show snackbar with the messages and actions granted
   * @param message
   * @param action
   */
  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  openWhatsappModal(influencer_id) {
    this._progressService
      .getInfluencerPhoneNumber(this.campaign_id, influencer_id)
      .subscribe(
        result => {
          const urlRedirect = 'https://wa.me/' + result.body.influencer_phone;
          window.open(urlRedirect);
        },
        error => {
          this.openSnackBar(
            'Oops something went wrong, please try again later',
            'Close'
          );
        }
      );
  }

  deleteInfluencer(influencerId) {
    const dialogRef = this.dialog.open(ConfirmActionInfluencerStateComponent, {
      width: '40%',
      height: '30%',
      minWidth: '450px',
      minHeight: '200px',
      autoFocus: false,
      data: {
        campaign_id: this.campaign_id,
        influencer_id: influencerId,
        action: 'cancel',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== '') {
        if (result['status'] === 200) {
            this.openSnackBar('Influencer was removed successfully.', 'Ok');
          this.loading = true;
          if (this.influencers.length - 1 !== 0) {
            this.influencers_pending.forEach((influencer, idx) => {
              if (influencer.influencer_id === influencerId) {
                this.influencers_pending.splice(idx, 1);
              }
            });
          }
            this.getProgress();
          } else {
          this.openSnackBar('There has been an error. Please try again.', 'Ok');
        }
      }
    });
  }

  requestSocialLogin() {
    window.open(environment.icmRedirect, '_blank');
  }


  setVirtualScrollHeight(array_length) {
    if (array_length >= 10) {
      return '1300px';
    } else {
      return (array_length * 100).toString() + 'px';
    }
  }

  getGiftData() {
    this._progressService.getProgressExportCSV(this.campaign_id).subscribe( result => {
      if (result.status.toString() === '200') {
        this.influencers_with_gift_form_data = result.body;
      }
    });
  }

  selectTab(number) {
    this.loadingContent = true;
    if (number === 1) {
      this.first = true;
      this.second = false;
      this.bindable_progress.origin = 'progress';
    } else if (number === 2) {
      this.first = false;
      this.second = true;
      this.bindable_progress.origin = 'proposals';
    }
    setTimeout( () => { this.loadingContent = false; }, 300 );
  }

}
