import {environment} from '../../environments/environment';

//
export class RegisterRequest {
  username = '';
  password = '';
  attributes = {
    email: 'n/a',
    'custom:type_user': 'n/a',
    'custom:company_size': 'n/a',
    phone_number: '+340000000',
    'custom:worked_influencers': 'n/a',
    'custom:privacy_policy': 'n/a',
    'custom:newsletter': 'n/a',
    'custom:plan_type': 'FREE',
    'custom:language' : 'ES',
    'custom:first_name' : 'n/a',
    picture: 'n/a',
    'custom:company_name': 'n/a',
    'custom:company_number': 'n/a',
    nickname: 'n/a',
    'custom:client_agreement': 'false',
    'custom:advertiser_id': 'n/a',
    'custom:taxes': '0.21',
    address: 'n/a',
    'custom:country': 'n/a',
    'custom:city': 'n/a',
    'custom:state': 'n/a',
    'custom:zip_code': 'n/a',
    'custom:company_logo_image':
    environment.platform_register_company_logo,
  'custom:validated_account':
  environment.platform_register_validated_account,
    'custom:currency_type': 'EUR',
    'custom:custom_margin': '0.0',
  };
}
