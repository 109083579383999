import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatSnackBar} from '@angular/material';
import {FormControl, FormGroup} from '@angular/forms';
import {AdminManagementService} from '../../../../store/admin-management.service';

@Component({
  selector: 'app-complete-user-config',
  templateUrl: './complete-user-config.component.html',
  styleUrls: ['./complete-user-config.component.scss']
})
export class CompleteUserConfigComponent implements OnInit, OnDestroy {

  userConfigPlanForm: FormGroup;
  userConfigGroupForm: FormGroup;
  userConfigDataForm: FormGroup;
  userConfigExtendFreeTrialForm: FormGroup;


  changeGroup = false;
  changeConfig = false;
  changePlan = false;
  extendFreeTrial = false;

  radiosChanged = [];

  userGroupForUpdate;
  userPlanForUpdate;

  private subscriptions = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private adminManagementService: AdminManagementService,
              private snackBar: MatSnackBar,
              public dialog: MatDialog) {
    this.userConfigPlanForm = new FormGroup({
      'currentPlan': new FormControl(''),
    });
    this.userConfigGroupForm = new FormGroup({
      'currentGroup': new FormControl(''),
    });
    this.userConfigExtendFreeTrialForm = new FormGroup({
      'extendFreeTrial': new FormControl(''),
    });
  }

  ngOnInit() {
    if (this.data.actual_plan !== undefined) {
      this.userConfigPlanForm.controls['currentPlan'].setValue(this.data.actual_plan);
    }
    if (this.data.actual_group !== undefined) {
      this.userConfigGroupForm.controls['currentGroup'].setValue(this.data.actual_group);
    }
    if (this.data.extendedFreeTrialDays !== undefined) {
      this.userConfigGroupForm.controls['extendFreeTrial'].setValue(this.data.extendedFreeTrialDays);
    }
    this.userConfigDataForm = new FormGroup({});
    for (let index = 0; index < this.data.user_config.length; index++) {
      this.userConfigDataForm.controls[this.data.user_config[index]['title']] = new FormControl(this.data.user_config[index]['value']);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  saveChanges() {
    if (this.changeGroup) {
      this.subscriptions.push(
          this.adminManagementService.updateUserGroup(this.data.email,
              this.userGroupForUpdate).subscribe (() => {
            this.openSnackBar('User group updated successfully', 'Close');
          }, () => {
            this.openSnackBar('Oops something went wrong, please try again', 'Close');
          })
      );
      this.changeGroup = false;
      document.getElementById('currentGroup').classList.remove('border-orange');
    }

    if (this.changePlan) {
      this.subscriptions.push(
          this.adminManagementService.updateUserPlan(this.data.user_sub,
              this.userPlanForUpdate).subscribe (() => {
            this.openSnackBar('User plan updated successfully', 'Close');
          }, () => {
            this.openSnackBar('Oops something went wrong, please try again', 'Close');
          })
      );
      this.changePlan = false;
      document.getElementById('currentPlanComplete').classList.remove('border-orange');
    }

    if (this.extendFreeTrial) {
      this.subscriptions.push(
          this.adminManagementService.extendFreeTrial(this.data.email,
              this.userConfigExtendFreeTrialForm.controls['extendFreeTrial'].value).subscribe (() => {
            this.openSnackBar('Extend days free trial successfully', 'Close');
          }, () => {
            this.openSnackBar('Oops something went wrong, please try again', 'Close');
          })
      );
      this.extendFreeTrial = false;
    }

    if (this.changeConfig) {
      const attributesForUpdate = {};
      for (let index = 0; index < this.data.user_config.length; index ++ ) {
        attributesForUpdate[this.data.user_config[index]['title']] = this.userConfigDataForm.controls
            [this.data.user_config[index]['title']].value;
        this.data.user_config[index]['value'] = this.userConfigDataForm.controls
            [this.data.user_config[index]['title']].value;
      }
      for (let index = 0; index < this.radiosChanged.length; index ++ ) {
        document.getElementById('radioGroup' + this.radiosChanged[index]).classList.remove('border-orange');
      }
      this.subscriptions.push(
          this.adminManagementService.updateUserConfig(this.data.user_sub,
              attributesForUpdate).subscribe (() => {
            this.openSnackBar('User config updated successfully', 'Close');
          }, error => {
            this.openSnackBar('Oops something went wrong, please try again', 'Close');
          })
      );
      this.changeConfig = false;
    }

  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar']
    });
  }

  onChange(event, attributeName, index?) {
    if (attributeName === 'currentPlanComplete') {
      if (event.value !== this.data.actual_plan) {
        this.changePlan = true;
        for (let i = 0; i < this.data.plans.length; i++ ) {
          if (event.value === this.data.plans[i]['PLAN_NAME']) {
            this.userPlanForUpdate = {
              'plan_name': this.data.plans[i]['PLAN_NAME'],
              'plan_id': this.data.plans[i]['PLAN_ID']
            };
            document.getElementById('currentPlanComplete').classList.add('border-orange');
          }
        }
      } else {
        this.data.actual_plan = this.userPlanForUpdate;
        this.changePlan = false;
        document.getElementById('currentPlanComplete').classList.remove('border-orange');
      }
    } else if (attributeName === 'currentGroup') {
      this.changeGroup = true;
      this.userGroupForUpdate = event.value;
      if (event.value !== this.data.actual_group) {
        document.getElementById('currentGroup').classList.add('border-orange');
      } else {
        this.data.actual_group = this.userGroupForUpdate;
        this.changeGroup = false;
        document.getElementById('currentGroup').classList.remove('border-orange');
      }
    } else if (attributeName === 'radio') {
      this.radiosChanged.push(index);
      this.changeConfig = true;
      document.getElementById('radioGroup' + index).classList.add('border-orange');
    } else if (attributeName === 'extendFreeTrial') {
      this.extendFreeTrial = true;
      document.getElementById('extendFreeTrial').classList.add('border-orange');
    } else {
      this.changeConfig = true;
      document.getElementById(attributeName).classList.add('border-orange');
    }
  }

}
