import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';



@Component({
  selector: 'app-show-story-modal',
  templateUrl: './show-story-modal.component.html',
  styleUrls: ['./show-story-modal.component.css']
})
export class ShowStoryModalComponent implements OnInit {

  media;

  constructor(public dialogRef: MatDialogRef<ShowStoryModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _sanitizer: DomSanitizer,
              protected http: HttpClient) {
    this.media = data.media;
  }

  ngOnInit() {
    if (!this.data.is_insight && this.media.origin === 'report' && this.media.network === 'youtube') {
      let results;
      results = this.media.video_url.match('[\\?&]v=([^&#]*)');
      const video = (results === null) ? this.media.video_url : results[1];
      if (this.media.network === 'youtube') {
        this.media.video_url = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video);
      }
      this.media['media_type'] = 'video';
    } else if (this.media.hasOwnProperty('is_video')) {
      if (this.media.is_video) {
        this.media['media_type'] = 'story_video';
      } else {
        this.media['media_type'] = 'story_img';
      }
    }
  }

}
