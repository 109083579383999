import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-remember-update-payment-method',
  templateUrl: './modal-remember-update-payment-method.component.html',
  styleUrls: ['./modal-remember-update-payment-method.component.scss']
})
export class ModalRememberUpdatePaymentMethodComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  redirectCallSales() {
    window.open('https://calendly.com/nicolas-11posts/plans-and-pricing', '_blank');
  }
}
