import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Influencer} from 'src/app/types/influencer/influencer';
import {MatDialog} from '@angular/material/dialog';
import {ModalShareInfluencerGenerateLinkComponent} from '../../../../private/modals/modal-share-influencer-generate-link/modal-share-influencer-generate-link.component';
import {selectAppViewPort} from '../../../../../store/app/app.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../../store';

@Component({
  selector: 'app-influencer-info',
  templateUrl: './influencer-info.component.html',
  styleUrls: ['./influencer-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfluencerInfoComponent implements OnInit, OnDestroy {

  @Input() influencer: Influencer;

  @Input() inCard = true;

  @Input() inSharePublic: boolean;

  @Input() componentNameCalled: string;

  viewport;
  private subscriptions = [];

  constructor(private dialog: MatDialog,
              private store: Store<State>,
  ) {}


  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectAppViewPort).subscribe(result => {
        this.viewport = result;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  openModalShareInfluencerGenerateLink() {
    const dialogRef = this.dialog.open(ModalShareInfluencerGenerateLinkComponent, {
      width: '400px',
      height: '300px',
      autoFocus: false,
      data: {
        influencerId: this.influencer.id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
