import {createAction, props} from '@ngrx/store';

export const changeViewPort = createAction(
  '[App] Change View Port',
  props<{ viewPort: string }>()
);

export const openPlansDialog = createAction('[Search] Open Plans Dialog');
export const closePlansDialog = createAction('[Search] Close Plans Dialog');

export const openPreviousPlansDialog = createAction('[PreviousPlans] Open Previous Plans Dialog');
export const closePreviousPlansDialog = createAction('[PreviousPlans] Close Previous Plans Dialog');

export const openLimitsPlansExceedDialog = createAction('[LimitsPlansExceed] Open Previous LimitsPlansExceed Dialog',
    props<{ planName: string; errorMessage: string; }>(),
);
export const closeLimitsPlansExceedDialog = createAction('[LimitsPlansExceed] Close Previous LimitsPlansExceed Dialog');

export const openConfirmUpgradePlanDialog = createAction(
    '[Plans] Open Confirm Upgrade Plan Dialog',
    props<{ plan: any; productId: string; }>(),
);
export const closeConfirmUpgradePlanDialog = createAction('[Plans] Close Confirm Upgrade Plan Dialog');

export const getUserGroup = createAction('[App] Get User Group');
export const getUserGroupSuccess = createAction(
    '[Auth] Get User Group Success',
    props<{ userGroup: any }>()
);
export const getUserGroupFail = createAction(
  '[Auth] Get User Group Fail',
  props<{ error: any }>()
);


export const getPublicUserGroup = createAction(
    '[App] Get Public User Group',
    props<{ typeUserGroup: string; queryValue: string }>()
);
export const getPublicUserGroupSuccess = createAction(
    '[Auth] Get Public User Group Success',
    props<{ userGroup: any }>()
);
export const getPublicUserGroupFail = createAction(
    '[Auth] Get Public User Group Fail',
    props<{ error: any }>()
);

export const setLanguage = createAction('[Language] Set Language', props<{ language: string }>());

export const setCurrency = createAction('[Currency] Set Currency', props<{ currency: string }>());

export const setCurrencySymbol = createAction('[Currency] Set Currency Symbol', props<{ currencySymbol: string }>());
