import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as reducers from './modal-plans.reducers';
import { ModalPlansEffects } from './modal-plans.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(reducers.plansKey, reducers.reducer),
    EffectsModule.forFeature([ModalPlansEffects])
  ],
  exports: [
    StoreModule
  ]
})
export class ModalPlansStoreModule {

}
