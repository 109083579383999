import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InfluencerProfileService {

  influencer_url = environment.aws_resources.lambda_endpoint.api;
  analyze_influencer_url = environment.aws_resources.lambda_endpoint.api;

  constructor(private http: HttpClient) {
  }

  getInfluencerProfileFromInstagram(instagram_username) {
    // esta era la ultima llamada directa a instagram.
    return this.http.get('https://www.instagram.com/' + instagram_username + '/?__a=1').pipe(map((data: any) => {
      return data;
    }));
  }


  getInfluencerProfileUserDataInstagram (instagram_username) {
    // llamada a get_user_data_instagram
    let params = new HttpParams();
    params = params.append('username', instagram_username);
    return this.http.get(this.influencer_url + 'influencer/search', {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  getInfluencerProfileMedias(influencerId, medias) {
    return this.http.post(this.influencer_url + 'influencer/save_ig_pictures/' + influencerId, { medias }, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getInfluencerProfileFromTiktok(tiktokUsername) {
    return this.http.get('https://www.tiktok.com/api/user/detail/?uniqueId=' + tiktokUsername).pipe(map((data: any) => {
      return data;
    }));
  }

  public getInfluencerProfileFromElastic(influencer_id, network, campaign_id?) {
    // llamada a get_user_data from SOCIAL DATA
    let params = new HttpParams();
    params = params.append('influencer_id', influencer_id);
    params = params.append('network', network);
    params = params.append('new_profile', 'true');

    if ( campaign_id !== undefined ) {
      params = params.append('campaign_id', campaign_id);
    }
    return this.http.get(this.influencer_url + 'influencer/profile', {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  public getInfluencerProfileFromUsername(username, network, campaign_id?) {
    // llamada a get_user_data
    let params = new HttpParams();
    params = params.append('username', username);
    params = params.append('network', network);
    return this.http.get(this.influencer_url + 'influencer/profile', {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  public getSharedInfluencerProfileFromElastic(influencer_id, network, share_hash) {
    let params = new HttpParams();
    params = params.append('network', network);
    params = params.append('new_profile', 'true');
    return this.http.get(this.influencer_url + 'influencer/' + influencer_id + '/shared/' + share_hash, {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  analyzeInfluencer(influencer_username: string, instagram_id: string, network: string,  notify_user: string) {
    let params = new HttpParams();
    params = params.append('username', influencer_username);
    params = params.append('instagram_id', instagram_id);
    params = params.append('notify_user', notify_user);
    params = params.append('network', network);
    return this.http.get(this.analyze_influencer_url + 'crawl-control/light_crawl', {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  analyzeTiktokInfluencer(influencer_username: string, instagram_id: string, network: string,  notify_user: string) {
    let params = new HttpParams();
    params = params.append('username', influencer_username);
    params = params.append('influencer_id', instagram_id);
    params = params.append('notify_user', notify_user);
    const body = {};
    return this.http.post(this.analyze_influencer_url + 'crawl-control/tiktok_light_crawl', body, {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }


  analyzeAudience(influencer_username: string, instagram_id, network) {
    let params = new HttpParams();
    params = params.append('username', influencer_username);
    params = params.append('instagram_id', instagram_id);
    params = params.append('network', network);

    return this.http.get(this.analyze_influencer_url + 'crawl-control/full_crawl', {observe: 'response', params}).pipe(map((data: any) => {
        return data;
      }
    ));
  }


  newFullCrawl(username, id, network) {
    let params = new HttpParams();
    params = params.append('username', username);
    params = params.append('influencer_id', id);
    params = params.append('network', network);
    params = params.append('force_update', 'true');
    const body = {};
    return this.http.post(this.analyze_influencer_url + 'crawl-control/sync_full_crawl', body, { observe: 'response', params }).pipe(map((data: any) => {
          return data;
        }
    ));
  }



  public getInfluencerMedias(influencerId, network) {
    return this.http.post(this.influencer_url + 'influencer/get_post_pictures/' + influencerId + '/network/' + network, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }


  public getInfluencerMediasShareInfluencer(influencer_shared_id) {
    let params = new HttpParams();
    params = params.append('influencer_shared_id', influencer_shared_id);
    const body = {};
    return this.http.post(this.influencer_url + 'influencer/shared/get_post_pictures', body, { observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  public getInfluencerMediasShareCampaign(campaign_hash, influencer_id, network) {
    let params = new HttpParams();
    params = params.append('campaign_hash', campaign_hash);
    params = params.append('influencer_id', influencer_id);
    params = params.append('network', network);
    const body = {};
    return this.http.post(this.influencer_url + 'influencer/shared/get_post_pictures', body,  {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }
}
