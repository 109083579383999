import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Store} from '@ngrx/store';
import {State} from './store';
import * as appActions from './store/app/app.actions';
import {setCurrency, setCurrencySymbol, setLanguage} from './store/app/app.actions';
import {Subscription} from 'rxjs';
import {environment} from '../environments/environment';
import {Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {getAccessSets, getLoggedUser} from './store/auth/authentication.actions';
import * as selectorsAuth from './store/auth/authentication.selectors';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { ModalRememberUpdatePaymentMethodComponent } from './features/private/modals/modal-remember-update-payment-method/modal-remember-update-payment-method.component';
import * as selectorsApp from './store/app/app.selectors';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private store: Store<State>,
    private titleService: Title,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private router: Router,
    public translate: TranslateService
  ) {
    translate.addLangs(['en', 'es']);
  }

  ngOnInit() {

    const pathname = document.location.pathname;
    const href = document.location.href;

    if (pathname.includes('auth/login')) {
      this.handleLogin();
    } else if (pathname.includes('app/') || pathname.includes('adminManagement/')) {
      this.handlePrivateApp();
    } else if (this.isPublicDomain(href)) {
      this.handlePublicDomain();
    } else if (pathname.includes('auth/forgotpassword') || pathname.includes('auth/resendverificationemail')) {
      this.handleAuthOther();
    }

    this.updateTitleAndFavicon();
    this.subscribeToAccessSets();
    this.subscribeToBreakpoints();
  }

  private handleLogin() {
    this.store.dispatch(getAccessSets());
    this.store.dispatch(getLoggedUser());
    this.store.select(selectorsAuth.selectUser).subscribe(user => {
      if (user) {
        this.router.navigate(['/app/search']);
      }
    });
  }

  private handlePrivateApp() {
    this.store.dispatch(getLoggedUser());
    this.store.dispatch(getAccessSets());
    this.store.dispatch(appActions.getUserGroup());
  }

  private handlePublicDomain() {
    this.store.dispatch(appActions.getPublicUserGroup({ typeUserGroup: 'platform_id', queryValue: environment.platform_id }));
    this.store.dispatch(getAccessSets());
    this.store.dispatch(getLoggedUser());
    this.store.select(selectorsAuth.selectUser).subscribe(user => {
      if (user) {
        this.router.navigate(['/app/search']);
      }
    });
  }

  private handleAuthOther() {
    this.store.dispatch(appActions.getPublicUserGroup({ typeUserGroup: 'platform_id', queryValue: environment.platform_id }));
  }

  private isPublicDomain(href: string): boolean {
    const publicDomains = [
      'https://pax.binfluencer.io/',
      'https://pax-test.binfluencer.io/',
      'https://app.11posts.com/',
      'https://app-test.11posts.com/',
      'http://localhost:4200/'
    ];
    return publicDomains.includes(href);
  }

  private updateTitleAndFavicon() {
    this.store.select(selectorsApp.selectUserGroup).subscribe(userGroup => {
      if (userGroup !== null) {
        this.titleService.setTitle(userGroup.platform_name);
        this._document.getElementById('appFavicon').setAttribute('href', userGroup.logo_ico);
      }
    });
  }

  private subscribeToAccessSets() {
    this.store.select(selectorsAuth.selectAccessSets).subscribe(accessSets => {
      if (accessSets.language) {
        this.store.dispatch(setLanguage({ language: accessSets.language }));
        this.translate.setDefaultLang(accessSets.language);
      } else {
        this.translate.setDefaultLang('en');
        this.store.dispatch(setLanguage({ language: 'en' }));
      }
      if (accessSets.currency) {
        this.store.dispatch(setCurrency({ currency: accessSets.currency }));
        this.store.dispatch(setCurrencySymbol({ currencySymbol: accessSets.currency_symbol }));
      }
      if (accessSets.past_due_popup === true) {
        this.dialog.open(ModalRememberUpdatePaymentMethodComponent, {
          width: '470px',
          height: '350px',
          panelClass: 'remove-padding',
          autoFocus: false,
          data: {}
        }).afterClosed();
      }
    });
  }

  private subscribeToBreakpoints() {
    const breakpoints = [
      { query: Breakpoints.XSmall, type: 'handset' },
      { query: Breakpoints.Medium, type: 'tablet' },
      { query: Breakpoints.Small, type: 'tablet' },
      { query: Breakpoints.Large, type: 'web' },
      { query: Breakpoints.XLarge, type: 'large-web' }
    ];

    breakpoints.forEach(bp => {
      this.subscriptions.push(
        this.breakpointObserver.observe([bp.query]).subscribe(result => this.dispatchChangeViewPort(result, bp.type))
      );
    });
  }

  private dispatchChangeViewPort(result, type: string) {
    if (result.matches) {
      this.store.dispatch(appActions.changeViewPort({ viewPort: type }));
    }
  }
  

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
