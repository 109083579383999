import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {Influencer} from 'src/app/types/influencer/influencer';
import * as actions from './influencers.actions';

export const influencerKey = 'influencerEntity';

export interface InfluencerState extends EntityState<Influencer> {
  selectedInfluencerId: number | null;
  loadingProfile: boolean;
}

export const influencerAdapter: EntityAdapter<Influencer> = createEntityAdapter<Influencer>({});

export const influencerInitialState: InfluencerState = influencerAdapter.getInitialState({
  selectedInfluencerId: null,
  loadingProfile: false
});

export const influencersReducer = {
  loadInfluencer(state, { influencerId }) {
    return { ...state, selectedInfluencerId: influencerId, loadingProfile: true};
  },
  loadInfluencersSuccess(state, { influencers }) {
    return influencerAdapter.upsertMany(influencers, { ...state, selectedInfluencerId: null });
  },
  loadInfluencersTippingSuccess(state, { influencers }) {
    const influencer = [];
    influencer[0] = influencers;
    return influencerAdapter.upsertMany(influencer, { ...state, selectedInfluencerId: null , loadingProfile: false});
  },
  loadInfluencerSuccess(state, { influencer }) {
    return influencerAdapter.upsertOne(influencer, { ...state, loadingProfile: false });
  },
  loadInfluencerFail(state, { influencerId }) {
    const updated = Object.assign({}, state.entities[influencerId], { notInDatabase: true });
    return influencerAdapter.updateOne(updated, { ...state, selectedInfluencerId: null, loadingProfile: false});
  },
  lightCrawlInfluencerSuccess(state, { influencer }):  InfluencerState {
    const updated = Object.assign({}, influencer, { lightCrawled: true });
    return influencerAdapter.updateOne(updated, state);
  },
  lightCrawlInfluencerFail(state, { influencer }):  InfluencerState {
    const updated = Object.assign({}, influencer, { lightCrawled: false });
    return influencerAdapter.updateOne(updated, state);
  },
  fullCrawlInfluencer(state, { influencer }) {
    const updated = Object.assign({}, influencer, { crawlData: null, crawlError: false });
    return influencerAdapter.updateOne(updated, state);
  },
  fullCrawlInfluencerSuccess(state, { influencer, data }) {
    const crawlData = {
      status: data.status,
      statusText: data.statusText,
      url: data.url,
      ok: data.ok,
      type: data.type
    };
    const updated = Object.assign({}, influencer, { crawlData, crawlError: false });
    return influencerAdapter.upsertOne(updated, { ...state });
  },
  fullCrawlInfluencerFail(state, { influencer }) {
    const updated = Object.assign({}, influencer, { crawlData: null, crawlError: true });
    return influencerAdapter.updateOne(updated, state);
  }
};

export function reducer(state: InfluencerState | undefined, action: Action) {
  return createReducer(
    influencerInitialState,
    on(actions.loadInfluencer, influencersReducer.loadInfluencer),
    on(actions.loadInfluencersSuccess, influencersReducer.loadInfluencersSuccess),
    on(actions.loadInfluencerSuccess, influencersReducer.loadInfluencerSuccess),
    on(actions.loadInfluencerFail, influencersReducer.loadInfluencerFail),
    on(actions.lightCrawlInfluencerSuccess, influencersReducer.lightCrawlInfluencerSuccess),
    on(actions.loadInfluencersTippingSuccess, influencersReducer.loadInfluencersTippingSuccess),
    on(actions.lightCrawlInfluencerFail, influencersReducer.lightCrawlInfluencerFail),
    on(actions.fullCrawlInfluencerSuccess, influencersReducer.fullCrawlInfluencerSuccess),
    on(actions.fullCrawlInfluencerFail, influencersReducer.fullCrawlInfluencerFail),
    on(actions.loadInstagrammerSuccess, influencersReducer.loadInfluencerSuccess),
    on(actions.loadTiktokerSuccess, influencersReducer.loadInfluencerSuccess)
  )(state, action);
}
