import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminManagementService} from '../../store/admin-management.service';
import {ActivatedRoute} from '@angular/router';
import {TransferAdminModalComponent} from '../../modals/transfer-admin-modal/transfer-admin-modal.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-customer-settings',
  templateUrl: './customer-settings.component.html',
  styleUrls: ['./customer-settings.component.scss']
})
export class CustomerSettingsComponent implements OnInit, OnDestroy {

  cognitoSettings;
  campaigns;
  userConfig;

  dateFreeTrial = {};

  email;
  user_sub;
  user_email;
  loading;

  actual_plan;
  actual_group;
  extendedFreeTrialDays;

  groups;
  plans;

  private subscriptions = [];

  constructor(
      private adminManagementService: AdminManagementService,
      private activatedRouter: ActivatedRoute,
      private dialog: MatDialog
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    this.email = this.activatedRouter.params['value'].email;
    if (this.email !== undefined) {
     this.getCustomerSettings(this.email);
    }
    this.getListUserGroups();
  }


  getCustomerSettings(email) {
      this.loading = true;
      this.subscriptions.push(
          this.adminManagementService.getCustomerSettings(email, true).subscribe( result => {
              this.cognitoSettings = result.body.cognito_data.UserAttributes;
              this.userConfig = result.body.user_config_data.admin_user_config;
              this.plans =  result.body.user_config_data.plans;
              this.actual_plan = result.body.user_config_data.actual_plan;
              this.dateFreeTrial['end_trial_timestamp'] = result.body.user_config_data.end_trial_timestamp;
              this.dateFreeTrial['start_trial_timestamp'] = result.body.user_config_data.start_trial_timestamp;
              if ('extended_days_free_trial' in result.body.user_config_data) {
                  this.extendedFreeTrialDays = result.body.user_config_data.extended_days_free_trial;
              }
              this.user_sub = result.body.user_config_data.user_cognito_id;
              this.getActualGroup(this.user_sub);
              this.user_email = result.body.cognito_data.Username;
              this.campaigns = result.body.campaigns;
              this.loading = false;
              }, () => {
              this.loading = false;
          })
      );
  }

    getListUserGroups() {
        this.subscriptions.push(
            this.adminManagementService.getListUserGroups().subscribe( response => {
                this.groups = response.body;
            }, () => {
            })
        );
    }

    getActualGroup(userSub) {
        this.subscriptions.push(
            this.adminManagementService.getAdminUserGroup(userSub).subscribe( response => {
                this.actual_group = String(response.body.group_name);
            }, () => {
            })
        );
    }

    transferAccount() {
       const dialogRef = this.dialog.open(TransferAdminModalComponent, {
            width: '800px',
            height: '300px',
            autoFocus: false,
            data: {
                isTransferCampaign: false,
                user_sub: this.user_sub
            }
        });
        dialogRef.afterClosed().subscribe(() => {
            this.getCustomerSettings(this.email);
        });
    }
}
