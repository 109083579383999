import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-complete-user-attributes',
  templateUrl: './complete-user-attributes.component.html',
  styleUrls: ['./complete-user-attributes.component.scss']
})
export class CompleteUserAttributesComponent implements OnInit {

  attributesForm: FormGroup;
  attributesResponse = {};
  companyLogoName = '';
  companyLogoUrl = '';

  companyLogoNameAndValues = [
    {
      'Name': 'Grosso',
      'Value': 'https://test-pax-company-logo-image.s3-eu-west-1.amazonaws.com/grosso.png',
    },
    {
      'Name': 'Binfluencer',
      'Value': 'https://test-pax-company-logo-image.s3-eu-west-1.amazonaws.com/binfluencer.png',
    },
    {
      'Name': 'Adgravity',
      'Value': 'https://test-pax-company-logo-image.s3-eu-west-1.amazonaws.com/adgravity.png'
    },
    {
      'Name': 'Ipi',
      'Value': 'https://test-pax-company-logo-image.s3-eu-west-1.amazonaws.com/ipi.png'
    },
    {
      'Name': 'AlkimiAds',
      'Value': 'https://test-pax-company-logo-image.s3-eu-west-1.amazonaws.com/alkimiads.png',
    }
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.attributesForm = new FormGroup({
      'custom:client_agreement': new FormControl(''),
      'custom:validated_account': new FormControl(''),
      'custom:company_logo_image': new FormControl(''),
      'custom:currency_type': new FormControl(''),
      'custom:custom_margin': new FormControl(''),
    });
  }

  ngOnInit() {
    this.setAccountForm(this.data.user_config);
  }


  setAccountForm(attributes) {

    const name_attributes = [
      'custom:company_logo_image', 'custom:client_agreement', 'custom:validated_account',
      'custom:currency_type', 'email', 'custom:country', 'custom:company_name', 'custom:company_number', 'address',
      'custom:city', 'custom:state', 'custom:zip_code', 'custom:client_agreement', 'custom:validated_account',
      'phone_number', 'custom:custom_margin', 'sub', 'custom:newsletter', 'custom:privacy_policy', 'custom:plan_type',
      'email_verified', 'custom:type_user', 'picture', 'custom:taxes'
    ];
    // Get the parameters necessary for show
    for (let index_attribute = 0; index_attribute < attributes.length; index_attribute++) {
      for (let index_name_attributes = 0; index_name_attributes < name_attributes.length; index_name_attributes++) {
        if (attributes[index_attribute]['Name'] === name_attributes[index_name_attributes]) {
          this.attributesResponse[attributes[index_attribute]['Name']] = attributes[index_attribute]['Value'];
        }
      }
    }

    // Set the values in the form
    this.viewCompany(this.attributesResponse['custom:company_logo_image']);
    this.attributesForm.controls['custom:company_logo_image'].setValue(this.companyLogoName);
    this.attributesForm.controls['custom:client_agreement'].setValue(this.attributesResponse['custom:client_agreement']);
    this.attributesForm.controls['custom:validated_account'].setValue(this.attributesResponse['custom:validated_account']);
    this.attributesForm.controls['custom:currency_type'].setValue(this.attributesResponse['custom:currency_type']);
    this.attributesForm.controls['custom:custom_margin'].setValue(this.attributesResponse['custom:custom_margin']);
  }


  viewCompany(companyLogoImage) {
    this.companyLogoNameAndValues.forEach( (key) => {
      if (key['Name'] === companyLogoImage) {
        this.companyLogoUrl = key['Value'];
      } else if (key['Value'] === companyLogoImage) {
        this.companyLogoName = key['Name'];
      }
    });
  }
}
