import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './features/core/core.module';
import {AppComponent} from './app.component';
import {GlobalMessageErrorComponent} from './components/share/errors/global-message-error/global-message-error.component';
import {RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import {RecaptchaFormsModule} from 'ng-recaptcha/forms';
import {AppStoreModule} from './store/store.module';
import {SharedModule} from './features/shared/shared.module';
import {AdminManagementModule} from './features/admin-management/adminManagement.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    GlobalMessageErrorComponent,
  ],
  imports: [
    CoreModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AppRoutingModule,
    AppStoreModule,
    SharedModule,
    AdminManagementModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpTranslateLoader,
          deps: [HttpClient]
        },
      defaultLanguage: 'en',
      })
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LfVquEUAAAAAC_ZMin5J_rSE2YD_clf4VTp9YMk',
      } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'en',
    },
    {
      provide: 'googleTagManagerId', useValue: 'GTM-P6TWJP3'
    }
  ],
  entryComponents: [
    GlobalMessageErrorComponent,
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {  }

export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
