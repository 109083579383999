import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {CampaignsService} from '../../../../../services/campaigns.service';
import {ActivatedRoute} from '@angular/router';
import * as selectorsAuth from '../../../../../store/auth/authentication.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../../store';
import {MatSnackBar} from '@angular/material';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-admin-edit-audience',
  templateUrl: './admin-edit-audience.component.html',
  styleUrls: ['./admin-edit-audience.component.scss']
})
export class AdminEditAudienceComponent implements OnInit, OnDestroy {

  planNameShown = '';
  campaignId = '';
  influencerId = '';

  formCountriesAudience: FormGroup;
  formCitiesAudience: FormGroup;
  formGenderAudience: FormGroup;
  formAgeTotalAudience: FormGroup;
  formAgeMenAudience: FormGroup;
  formAgeWomenAudience: FormGroup;
  tapsForm: FormGroup;

  taps = [];

  audience: any;
  loading = true;

  private subscriptions = [];

  genderMaleFemaleButtonDisabled = true;
  countryLocationsButtonDisabled = true;
  cityLocationsButtonDisabled = true;
  weightTotalButtonDisabled = true;
  weightMenButtonDisabled = true;
  weightWomenButtonDisabled = true;

  constructor(
    private _campaignService: CampaignsService,
    private activatedRouter: ActivatedRoute,
    private store: Store<State>,
    private snackbar: MatSnackBar,
  ) {
    // Countries Form
    this.formCountriesAudience = new FormGroup({});
    // Cities Form
    this.formCitiesAudience = new FormGroup({});
    // Age Forms
    this.formAgeTotalAudience = new FormGroup({});
    this.formAgeMenAudience = new FormGroup({});
    this.formAgeWomenAudience = new FormGroup({});
    // Gender Form
    this.formGenderAudience = new FormGroup({});

    this.tapsForm = new FormGroup({
      'tapSelected': new FormControl(''),
    });
  }

  ngOnInit() {
    window.scrollTo( 0, 1000 );
    this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
      this.planNameShown = user.PLAN_NAME_SHOWN;
    });

    this.campaignId = this.activatedRouter.queryParams['value'].campaignId;
    this.influencerId = this.activatedRouter.queryParams['value'].influencerId;

    this.getAudience();
  }

  getAudience() {
    this.subscriptions.push(
      this._campaignService.adminGetAudience(this.campaignId, this.influencerId).subscribe( response => {
        this.audience = response.body;
        if (this.audience.stats.audience.followers) {
          this.taps.push('followers');
        }
        if (this.audience.stats.audience.likers) {
          this.taps.push('likers');
        }
        if (this.audience.stats.audience.commenters) {
          this.taps.push('commenters');
        }
        if (this.taps.length > 0) {
          this.tapsForm.controls['tapSelected'].setValue(this.taps[0]);
        }
        this.loading = false;
        this.setDataForm(this.audience);
      }, (error) => {
        this.loading = false;
        this.openSnackBar('Oops something went wrong, please try again', 'Close');
      })
    );
  }


  changeTapData() {
    this.setDataForm(this.audience);
  }

  /**
   * Aqui seteamos todos los valores de la response en el formulario reactivo, creandolos dinamicamente.
   * @param audience
   */
  setDataForm(audience) {
    // Set Gender Audience
    if (audience.stats.audience[this.tapsForm.controls['tapSelected'].value].gender) {
      for (let index = 0; index < audience.stats.audience[this.tapsForm.controls['tapSelected'].value].gender.length ; index++) {
        this.formGenderAudience.setControl('code' + index, new FormControl(audience.stats.audience[this.tapsForm.controls['tapSelected'].value].gender[index].code));
        this.formGenderAudience.setControl('weight' + index, new FormControl(audience.stats.audience[this.tapsForm.controls['tapSelected'].value].gender[index].weight.toFixed(2)));
      }

    }
    // Set City Locations Audience
    if ( audience.stats.audience[this.tapsForm.controls['tapSelected'].value].city_location) {
      for (let index = 0; index < audience.stats.audience[this.tapsForm.controls['tapSelected'].value].city_location.length ; index++) {
        this.formCitiesAudience.setControl('name' + index, new FormControl(audience.stats.audience[this.tapsForm.controls['tapSelected'].value].city_location[index].name));
        this.formCitiesAudience.setControl('thumbnail' + index, new FormControl(audience.stats.audience[this.tapsForm.controls['tapSelected'].value].city_location[index].thumbnail));
        this.formCitiesAudience.setControl('weight' + index, new FormControl(audience.stats.audience[this.tapsForm.controls['tapSelected'].value].city_location[index].weight.toFixed(2)));
      }
    }
    // Set Country Locations Audience
    if (audience.stats.audience[this.tapsForm.controls['tapSelected'].value].country_location) {
      for (let index = 0; index < audience.stats.audience[this.tapsForm.controls['tapSelected'].value].country_location.length ; index++) {
        this.formCountriesAudience.setControl('name' + index, new FormControl(audience.stats.audience[this.tapsForm.controls['tapSelected'].value].country_location[index].name));
        this.formCountriesAudience.setControl('thumbnail' + index, new FormControl(audience.stats.audience[this.tapsForm.controls['tapSelected'].value].country_location[index].thumbnail));
        this.formCountriesAudience.setControl('weight' + index, new FormControl(audience.stats.audience[this.tapsForm.controls['tapSelected'].value].country_location[index].weight.toFixed(2)));
      }
    }
    if (audience.stats.audience[this.tapsForm.controls['tapSelected'].value].age) {
      // Set Age Total Audience
      for (let index = 0; index < audience.stats.audience[this.tapsForm.controls['tapSelected'].value].age.total.length ; index++) {
        this.formAgeTotalAudience.setControl('range' + index, new FormControl(audience.stats.audience[this.tapsForm.controls['tapSelected'].value].age.total[index].range));
        this.formAgeTotalAudience.setControl('weight' + index, new FormControl(audience.stats.audience[this.tapsForm.controls['tapSelected'].value].age.total[index].weight.toFixed(2)));
      }
      // Set Age Men Audience
      for (let index = 0; index < audience.stats.audience[this.tapsForm.controls['tapSelected'].value].age.male.length ; index++) {
        this.formAgeMenAudience.setControl('range' + index, new FormControl(audience.stats.audience[this.tapsForm.controls['tapSelected'].value].age.male[index].range));
        this.formAgeMenAudience.setControl('weight' + index, new FormControl(audience.stats.audience[this.tapsForm.controls['tapSelected'].value].age.male[index].weight.toFixed(2)));
      }
      // Set Age Women Audience
      for (let index = 0; index < audience.stats.audience[this.tapsForm.controls['tapSelected'].value].age.female.length ; index++) {
        this.formAgeWomenAudience.setControl('range' + index, new FormControl(audience.stats.audience[this.tapsForm.controls['tapSelected'].value].age.female[index].range));
        this.formAgeWomenAudience.setControl('weight' + index, new FormControl(audience.stats.audience[this.tapsForm.controls['tapSelected'].value].age.female[index].weight.toFixed(2)));
      }
    }
  }

  /**
   * Esta funcion recibe un string para saber como formar la peticion,
   * ya que el back debe recibir todo el padre, segun la audiencia que modifiques
   * example, con que toques un pais debes enviar todo el paquete.
   * @param formForUpdate
   */
  onSubmit(formForUpdate) {
    // this.loading = true;
    let request = {};
    if (formForUpdate === 'gender') {
      const gender = [];
      for (let index = 0; index < this.audience.stats.audience[this.tapsForm.controls['tapSelected'].value].gender.length ; index++) {
        gender[index] = {
          weight: Number(this.formGenderAudience.controls['weight' + index].value),
          code: this.formGenderAudience.controls['code' + index].value,
        };
      }
      request = {
        gender: gender
      };
    } else if (formForUpdate === 'countries') {
      const country_location = [];
      for (let index = 0; index < this.audience.stats.audience[this.tapsForm.controls['tapSelected'].value].country_location.length ; index++) {
        country_location[index] = {
          name: this.formCountriesAudience.controls['name' + index].value,
          weight: Number(this.formCountriesAudience.controls['weight' + index].value),
          thumbnail: this.formCountriesAudience.controls['thumbnail' + index].value,
        };
      }
      request = {
        country_location: country_location
      };
    } else if (formForUpdate === 'cities') {
      const city_location = [];
      for (let index = 0; index < this.audience.stats.audience[this.tapsForm.controls['tapSelected'].value].city_location.length ; index++) {
        city_location[index] = {
          weight: Number(this.formCitiesAudience.controls['weight' + index].value),
          name: this.formCitiesAudience.controls['name' + index].value,
          thumbnail: this.formCitiesAudience.controls['thumbnail' + index].value,
        };
      }
      request = {
        city_location: city_location
      };
    } else if (formForUpdate === 'ageTotal' || formForUpdate === 'ageMen' || formForUpdate === 'ageWomen') {

      const total = [];
      for (let index = 0; index < this.audience.stats.audience[this.tapsForm.controls['tapSelected'].value].age.total.length ; index++) {
        total[index] = {
          weight: Number(this.formAgeTotalAudience.controls['weight' + index].value),
          range: this.formAgeTotalAudience.controls['range' + index].value,
        };
      }
      const male = [];
      for (let index = 0; index < this.audience.stats.audience[this.tapsForm.controls['tapSelected'].value].age.male.length ; index++) {
        male[index] = {
          weight: Number(this.formAgeMenAudience.controls['weight' + index].value),
          range: this.formAgeMenAudience.controls['range' + index].value,
        };
      }
      const female = [];
      for (let index = 0; index < this.audience.stats.audience[this.tapsForm.controls['tapSelected'].value].age.female.length ; index++) {
        female[index] = {
          weight: Number(this.formAgeWomenAudience.controls['weight' + index].value),
          range: this.formAgeWomenAudience.controls['range' + index].value,
        };
      }
      const age = {
        total: total,
        male: male,
        female: female
      };
      request = {
        age: age
      };
    }

    const key = this.tapsForm.controls['tapSelected'].value;
    const requestForSend = {
      [key]: request
    };
    this.subscriptions.push (
      this._campaignService.adminUpdateAudience(this.campaignId, this.influencerId, requestForSend).subscribe( () => {
        this.openSnackBar('Influencer updated successfully', 'Close');
        this.loading = false;

      }, () => {
        this.openSnackBar('Oops something went wrong, please try again', 'Close');
        this.loading = false;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar']
    });
  }

  onChange(name, index?) {
    switch (name) {
      case 'gender':
        this.genderMaleFemaleButtonDisabled = false;
        break;
      case 'countryLocations':
        this.countryLocationsButtonDisabled = false;
        break;
      case 'cityLocations':
        this.cityLocationsButtonDisabled = false;
        break;
      case 'weightWomen':
        this.weightWomenButtonDisabled = false;
        break;
      case 'weightMen':
        this.weightMenButtonDisabled = false;
        break;
      case 'weightTotal':
        this.weightTotalButtonDisabled = false;
        break;
    }
    if (index === undefined) {
      document.getElementById(name).classList.add('border-orange');
    } else {
      document.getElementById(name + index).classList.add('border-orange');
    }
  }
}
