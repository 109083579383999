import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'converterKMImpacts'
})
export class ConverterKMImpactsPipe implements PipeTransform {

  transform(follows: number): string {
    if (follows === 0) {
      return '0';
    }

    if (!follows) {
      // return '';
      return '0';
    }

    const formatNumber = (value: number, unit: string): string => {
      const stringValue = value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      if (stringValue.endsWith('.00')) {
        return `${stringValue.slice(0, -3)}${unit}`;
      } else {
        return `${stringValue}${unit}`;
      }
    };

    if (follows >= 1000000000) {
      const value = follows / 1000000000;
      return formatNumber(value, 'B');
    } else if (follows >= 1000000) {
      const value = follows / 1000000;
      return formatNumber(value, 'M');
    } else if (follows >= 1000) {
      const value = follows / 1000;
      return formatNumber(value, 'K');
    } else {
      return formatNumber(follows, '');
    }
  }





}
