import {Component, Input, OnDestroy} from '@angular/core';
import {ReportService} from '../../../../../../services/report.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ReportShareModalComponent} from '../../../report-share-modal/report-share-modal.component';
import {EmailDownloadAllContentModalComponent} from '../../modals/email-download-all-content-modal/email-download-all-content-modal.component';

@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss']
})
export class ReportHeaderComponent implements OnDestroy {

  @Input() report;
  @Input() campaign_id;
  @Input() real_owner_email;
  @Input() emailAccount;
  @Input() hash_report_id;
  @Input() shared;

  private subscriptions = [];

  constructor(private _reportService: ReportService,
              public snackBar: MatSnackBar,
              public dialog: MatDialog
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  sendToUpdateReportData() {
    this.subscriptions.push(
      this._reportService.sendToUpdateReportData(this.campaign_id).subscribe(
        resp => {
          if (resp.status.toString() === '200') {
            this.openSnackBar('The report will be updated shortly.', 'Close');
          } else {
            this.openSnackBar(
              'There was an error trying to update the report. Please try again.',
              'Close'
            );
          }
        },
        error => {
          if (error.status === 429) {
            this.openSnackBar(
                error.error.message,
                'Close'
            );
          } else {
            this.openSnackBar(
                'There was an error trying to update the report. Please try again.',
                'Close'
            );
          }
        }
      )
    );
  }

  shareReport(): void {
    this.dialog.open(ReportShareModalComponent, {
      width: '600px',
      height: '300px',
      autoFocus: false,
      data: {
        campaign_id: this.campaign_id,
      },
    });
  }

  downloadAllContent() {
    this.dialog.open(EmailDownloadAllContentModalComponent, {
      width: '490px',
      height: '320px',
      panelClass: 'remove-padding',
      autoFocus: false,
      data: {
        campaign_id: this.campaign_id,
        email: this.emailAccount,
      },
    });
  }

  updateShareReport() {
    this._reportService.updateShareReport(this.hash_report_id).subscribe(
      () => {
        this.openSnackBar('The report will be updated shortly.', 'Close');
      },
      err => {
        this.openSnackBar(
          err.error.message,
          'Close'
        );
      }
    );
  }

  exportCSV() {
    let dataCSV;
    this.subscriptions.push(
      this._reportService.getReportExportCSV(this.campaign_id).subscribe( result => {
        dataCSV = result.body;
        this._reportService.exportToCsv('report_data.csv', dataCSV);
      }, () => {
        this.openSnackBar('Oops something went wrong', 'Close');
      })
    );
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
