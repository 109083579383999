export class AuthenticatedUser {
  username = '';
  attributes = {};
  id_token = '';

  constructor(user) {
    this.username = user.username;
    this.attributes = user.attributes;
    this.id_token = user.signInUserSession.idToken.jwtToken;
  }

  getUsername() {
    return this.username;
  }

  getAttributes() {
    return this.attributes;
  }

  getAttribute(attribute_name: string) {
    return this.attributes[attribute_name];
  }

}
