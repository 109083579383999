import {AfterViewInit, Component, Input, OnInit, ViewChild,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProgressService} from '../../../../../../services/progress.service';
import {ProgressActionsConfirmationModalComponent} from '../../modals/progress-actions-confirmation-modal/progress-actions-confirmation-modal.component';
import {InfluencerProposalsModalComponent} from '../../modals/influencer-proposals-modal/influencer-proposals-modal.component';
import {ModalUploadProposalComponent} from '../../modals/modal-upload-proposal/modal-upload-proposal.component';
import {ProgressCampaignComponent} from '../../progress-campaign.component';
import {selectAppViewPort} from '../../../../../../store/app/app.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../store';

@Component({
  selector: 'app-progress-proposal-campaign',
  templateUrl: './progress-proposal-campaign.component.html',
  styleUrls: [
    './progress-proposal-campaign.component.scss',
  ],
})
export class ProgressProposalCampaignComponent
  implements OnInit, AfterViewInit {
  @Input() binded_progress: any;
  @ViewChild('myCheckbox', { static: false }) myAutoCheckbox: any;
  loading = false;
  hash_progress_id = '';

  campaign_id = '';
  influencers = [];
  approve_content_totals = {
    accepted: 0,
    pending: 0,
    total_active_proposals: 0,
  };

  private subscriptions = [];
  viewport;

  constructor(
    private _progressService: ProgressService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    private ProgressCampaign: ProgressCampaignComponent,
    public store: Store<State>
  ) {
    this.loading = true;
    this.campaign_id = this.activatedRoute.params['value'].id;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectAppViewPort).subscribe(result => {
        this.viewport = result;
      })
    );
    this.setProgressViewData();
  }

  ngAfterViewInit() {
    if (this.binded_progress.origin === 'shared') {
      this.myAutoCheckbox._elementRef.nativeElement.classList.remove(
        'mat-checkbox-disabled'
      );
    }
  }

  getSharedProgress(message?): void {
    this._progressService
      .getSharedProgressContentApproval(this.hash_progress_id)
      .subscribe(
        result => {
          if (result['status'] === 200) {
            this.binded_progress = result['body'];

            this.binded_progress.origin = 'shared';
            this.binded_progress.hash_progress_id = this.hash_progress_id;
            const bindable_influencers = [];

            this.binded_progress.influencers.forEach(influencer => {
              if (
                influencer.state === 'accepted' ||
                influencer.state === 'paid'
              ) {
                bindable_influencers.push(influencer);
              }
            });

            this.binded_progress.influencers = bindable_influencers;

            this.setProgressViewData();
            if (message !== undefined) {
              this.openSnackBar(message, 'OK');
            }
          }
        },
        () => {
          this.openSnackBar(
            'There was an error trying to refresh the data.',
            'OK'
          );
        }
      );
  }

  setProgressViewData() {
    this.approve_content_totals = {
      accepted: 0,
      pending: 0,
      total_active_proposals: 0,
    };

    this.hash_progress_id = this.binded_progress.hash_progress_id;
    this.influencers = this.binded_progress.influencers;
    this.binded_progress['completed'] = 0;

    this.influencers.forEach(influencer => {
      influencer['pending_proposals'] = 0;
      influencer['accepted_proposals'] = 0;
      influencer['total_active_proposals'] = 0;
      influencer['everything_approved'] = false;

      if (influencer.network === 'instagram') {
        influencer['posts_done'] = 0;
        influencer['stories_done'] = 0;
        influencer.proposals.forEach(proposal => {
          if (proposal.proposal_status === 'pending') {
            influencer['pending_proposals']++;
            influencer['total_active_proposals']++;
          } else if (proposal.proposal_status === 'accepted') {
            influencer['accepted_proposals']++;
            influencer['total_active_proposals']++;
            if (
              proposal.media_type === 'post_img' ||
              proposal.media_type === 'post_video'
            ) {
              influencer['posts_done']++;
            } else if (
              proposal.media_type === 'story_img' ||
              proposal.media_type === 'story_video'
            ) {
              influencer['stories_done']++;
            }
          }
        });
        influencer['everything_approved'] =
          influencer['posts_done'] >= influencer.instagram_services.posts &&
          influencer['stories_done'] >= influencer.instagram_services.stories;
      } else if (influencer.network === 'youtube') {
        influencer['mentions_done'] = 0;
        influencer['videos_done'] = 0;
        influencer.proposals.forEach(proposal => {
          if (proposal.proposal_status === 'pending') {
            influencer['pending_proposals']++;
            influencer['total_active_proposals']++;
          } else if (proposal.proposal_status === 'accepted') {
            influencer['accepted_proposals']++;
            influencer['total_active_proposals']++;
            if (proposal.media_type === 'mention') {
              influencer['mentions_done']++;
            } else if (proposal.media_type === 'video') {
              influencer['videos_done']++;
            }
          }
        });

        influencer['everything_approved'] =
          influencer['mentions_done'] >= influencer.youtube_services.mentions &&
          influencer['videos_done'] >= influencer.youtube_services.videos;

      } else if (influencer.network === 'tiktok') {
        influencer['tiktok_videos_done'] = 0;
        influencer.proposals.forEach(proposal => {
          if (proposal.proposal_status === 'pending') {
            influencer['pending_proposals']++;
            influencer['total_active_proposals']++;
          } else if (proposal.proposal_status === 'accepted') {
            influencer['accepted_proposals']++;
            influencer['total_active_proposals']++;
            if (proposal.media_type === 'video') {
              influencer['tiktok_videos_done']++;
            }
          }
        });

        influencer['everything_approved'] = influencer['videos_done'] >= influencer.tiktok_services.tiktok_videos;
      }

      if (influencer['everything_approved']) {
        this.binded_progress['completed']++;
      }

      this.approve_content_totals.accepted += influencer['accepted_proposals'];
      this.approve_content_totals.pending += influencer['pending_proposals'];
      this.approve_content_totals.total_active_proposals +=
        influencer['total_active_proposals'];
    });
  }

  acceptAllProposals() {
    let data: any;
    if (this.binded_progress.approve_all_proposals) {
      data = {
        title: 'Approve proposals automatically',
        content:
          'Are you sure you want to deactivate the "approve all proposals automatically" ?',
        action_button: 'Deactivate',
        spinner_action:
          'Deactivating the "approve all proposals automatically"',
      };
    } else {
      data = {
        title: 'Approve proposals automatically',
        content:
          this.approve_content_totals.pending !== 0
            ? 'The ' +
              this.approve_content_totals.pending +
              ' current pending proposals will be accepted. ' +
              'All proposals will be accepted automatically from now on.'
            : 'All proposals will be accepted automatically from now on.',
        action_button: 'Accept',
        spinner_action: 'Accepting all proposals',
      };
    }

    const config = {
      width: '35%',
      height: '25%',
      minWidth: '450px',
      minHeight: '250px',
      autoFocus: false,
      data: data,
    };

    this.dialog
      .open(ProgressActionsConfirmationModalComponent, config)
      .beforeClosed()
      .subscribe(closing_message => {
        if (closing_message === 'perform_action') {
          const message = this.binded_progress.approve_all_proposals
            ? 'The auto save proposals was disabled successfully.'
            : 'The auto save proposals was enabled successfully.';
          this._progressService
            .acceptAllProposals(this.hash_progress_id)
            .subscribe(
              resp => {
                if (resp.status.toString() === '200') {
                  this.getSharedProgress(message);
                } else {
                  this.openSnackBar(
                    'There was an error trying to accept all proposals.',
                    'OK'
                  );
                }
              },
              error => {
                this.openSnackBar(
                  'There was an error trying to accept all proposals.',
                  'OK'
                );
              }
            );
        }
      });
  }

  openInfluencerproposalsDialog(influencer) {
    const data = {
      influencer: influencer,
      hash_progress_id: this.hash_progress_id,
      binded_progress: this.binded_progress,
      campaign_id: this.campaign_id,
    };
    this.dialog
      .open(InfluencerProposalsModalComponent, {
        autoFocus: false,
        disableClose: true,
        data: data,
        width: '80%',
        height: '83%',
      })
      .afterClosed()
      .subscribe(res => {
        // if (res === 'true') {
          if (this.binded_progress.origin === 'shared') {
            this.getSharedProgress();
          } else {
            this.refreshProposals();
          }
        // }
      });
  }

  /**
   * @description Show snackbar with the messages and actions granted
   * @param message
   * @param action
   */
  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  uploadProposal(influencer, influencer_network) {
    this.dialog
      .open(ModalUploadProposalComponent, {
        autoFocus: false,
        data: {
          campaign_id: this.campaign_id,
          influencer_network: influencer_network,
          influencer_id: influencer.influencer_id,
        },
        backdropClass: 'story-dialog-backdrop-class',
        panelClass: 'story-dialog-panel-class',
      })
      .afterClosed()
      .subscribe(result => {
        if (result === 'uploaded') {
          this.refreshProposals();
        }
      });
  }

  refreshProposals() {
    this.loading = true;
    this._progressService.getProgress(this.campaign_id).subscribe(
      result => {
        if (result['status'].toString() === '200') {
          this.influencers = [];
          this.binded_progress = result['body'];
          for (
            let index = 0;
            index < this.binded_progress.influencers.length;
            index++
          ) {
            if (
              this.binded_progress['influencers'][index]['state'] === 'accepted'
            ) {
              this.influencers.push(this.binded_progress['influencers'][index]);
            }
          }

          this.influencers.forEach(influencer => {
            if (
              influencer.state === 'accepted' ||
              influencer.state === 'paid'
            ) {
              influencer['accepted_proposals'] = 0;
              influencer['pending_proposals'] = 0;
              influencer['total_active_proposals'] = 0;
              this.setInfluencersProposalsData(influencer);

              this.ProgressCampaign.approve_content_totals.pending = 0;
              this.ProgressCampaign.approve_content_totals.total_active_proposals = 0;

              this.ProgressCampaign.approve_content_totals.pending +=
                influencer['pending_proposals'];
              this.ProgressCampaign.approve_content_totals.total_active_proposals +=
                influencer['total_active_proposals'];
            }
          });
        }
        this.loading = false;
      },
      error => {
        if (error.status === 404 || error.status === 400) {
          this.router.navigate(['/app/campaigns']);
        }
      }
    );
  }

  setVirtualScrollHeight(array_length) {
    if (this.viewport === 'handset') {
      if (array_length >= 10) {
        return '1300px';
      } else {
        return (array_length * 420).toString() + 'px';
      }
    } else {
      if (array_length >= 10) {
        return '1300px';
      } else {
        return (array_length * 100).toString() + 'px';
      }
    }
  }

  setInfluencersProposalsData(influencer) {
    if (influencer.network === 'instagram') {
      influencer['posts_done'] = 0;
      influencer['stories_done'] = 0;
      influencer.proposals.forEach(proposal => {
        if (proposal.proposal_status === 'pending') {
          influencer['pending_proposals']++;
          influencer['total_active_proposals']++;
        } else if (proposal.proposal_status === 'accepted') {
          influencer['accepted_proposals']++;
          influencer['total_active_proposals']++;
          if (
            proposal.media_type === 'post_img' ||
            proposal.media_type === 'post_video'
          ) {
            influencer['posts_done']++;
          } else if (
            proposal.media_type === 'story_img' ||
            proposal.media_type === 'story_video'
          ) {
            influencer['stories_done']++;
          }
        }
      });
      influencer['everything_approved'] =
        influencer['posts_done'] >= influencer.instagram_services.posts &&
        influencer['stories_done'] >= influencer.instagram_services.stories;
    } else if (influencer.network === 'youtube') {
      influencer['mentions_done'] = 0;
      influencer['videos_done'] = 0;
      influencer.proposals.forEach(proposal => {
        if (proposal.proposal_status === 'pending') {
          influencer['pending_proposals']++;
          influencer['total_active_proposals']++;
        } else if (proposal.proposal_status === 'accepted') {
          influencer['accepted_proposals']++;
          influencer['total_active_proposals']++;
          if (proposal.media_type === 'mention') {
            influencer['mentions_done']++;
          } else if (proposal.media_type === 'video') {
            influencer['videos_done']++;
          }
        }
      });
      influencer['everything_approved'] =
        influencer['mentions_done'] >= influencer.youtube_services.mentions &&
        influencer['videos_done'] >= influencer.youtube_services.videos;
    } else if (influencer.network === 'tiktok') {
      influencer['tiktok_videos_done'] = 0;
      influencer.proposals.forEach(proposal => {
        if (proposal.proposal_status === 'pending') {
          influencer['pending_proposals']++;
          influencer['total_active_proposals']++;
        } else if (proposal.proposal_status === 'accepted') {
          influencer['accepted_proposals']++;
          influencer['total_active_proposals']++;
          if (proposal.media_type === 'video') {
            influencer['tiktok_videos_done']++;
          }
        }
      });
      influencer['everything_approved'] =
          influencer['tiktok_videos_done'] >= influencer.tiktok_services.tiktok_videos;
    }
  }
}
