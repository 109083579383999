import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {ModalCreateFavouritesListComponent} from '../modal-create-favourites-list/modal-create-favourites-list.component';
import {FavouritesService} from '../../../../../../services/favourites.service';
import {TranslateService} from '@ngx-translate/core';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-modal-favourites-list',
  templateUrl: './modal-favourites-list.component.html',
  styleUrls: ['./modal-favourites-list.component.scss']
})
export class ModalFavouritesListComponent implements OnInit, OnDestroy {

  loading: boolean;
  addingInfluencer = false;

  favourites: [ {
      favorite_list_name: '',
      influencer_in_favorite: '',
      favorite_list_id: '',
  }];

  private subscriptions = [];

  constructor(
      public dialog: MatDialog,
      private _favouritesService: FavouritesService,
      public dialogRef: MatDialogRef<ModalFavouritesListComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public snackBar: MatSnackBar,
      public router: Router,
      private translate: TranslateService,
  ) {}

  ngOnInit() {
      this.loading = true;
      this.getFavourites();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getFavourites() {
      this.subscriptions.push(
        this._favouritesService.getFavouritesList(this.data.influencerId).subscribe(result => {
          if (result.status === 200) {
            this.favourites = result.body;
          }
        })
      );
      setTimeout( () => {  this.loading = false; }, 500 );
  }

  createFavouritesList() {
    const dialogRef = this.dialog.open(ModalCreateFavouritesListComponent, {
      width: '35%',
      height: '25%',
      minWidth: '450px',
      minHeight: '250px',
      data: {},
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getFavourites();
    });
  }


  addInfluencerToFavouriteList(favouriteListId, influencerId, username, network) {
    this.addingInfluencer = true;
    this.loading = true;
    this.subscriptions.push(
        this._favouritesService.addInfluencerToFavouriteList(favouriteListId, influencerId, username,  network).subscribe(result => {
                this.getFavourites();
                this.snackBar.openFromComponent(CustomSnackbarComponent, {
                  data: { message: this.translate.instant('addInfluencerToFavouritesModal.influencerAddedSuccessfully'), type: 'success' },
                  duration: 3000,
                  horizontalPosition: 'right',
                  verticalPosition: 'top',
                  panelClass: 'success-snackbar',
                });
                this.addingInfluencer = false;
            },
            () => {
              this.addingInfluencer = false;
              this.snackBar.openFromComponent(CustomSnackbarComponent, {
                data: { message: this.translate.instant('customSnackbar.thereHasBeenError'), type: 'error' },
                duration: 3000,
                horizontalPosition: 'right',
                verticalPosition: 'top',
                panelClass: 'error-snackbar',
              });
        })
    );
    this.loading = false;
  }

viewFavourites() {
    this.router.navigate(['/app/favourites']);
    this.dialogRef.close();
}

}
