import {Component, Input, OnInit} from '@angular/core';
import {ViewMoreModalComponent} from '../../modals/view-more-modal/view-more-modal.component';
import {MatDialog} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-interests-affinity',
  templateUrl: './interests-affinity.component.html',
  styleUrls: ['./interests-affinity.component.scss']
})
export class InterestsAffinityComponent implements OnInit {

  @Input() data;
  @Input() disableEvents;
  @Input() tittleModal;
  
  
  col1: string;

  constructor(
      private dialog: MatDialog,
      private translate: TranslateService
  ) { }

  ngOnInit() {
    this.data.forEach(data => {
      if (Object.keys(data)[1] === 'icon') {
        this.col1 = 'icon';
      } else {
        this.col1 = 'thumbnail';
      }
    });
  }

  errorHandler(event) {
    event.target.src = '/assets/img/png/user_not_exists.png';
  }

  openViewMoreModal(data) {
    if (this.tittleModal === 'affinity') {
      if (this.disableEvents) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    }
  
    // Configuramos el modal data básico
    let modalData: any = {
      data: data,
      col1: this.col1,
      col2: 'names',
      titleModal: this.translate.instant(
        this.tittleModal === 'affinity' 
          ? 'influencerProfile.viewMoreModal.affinity' 
          : 'influencerProfile.viewMoreModal.interests'
      ),
    };
  
    // Añadimos 'col3' solo si es necesario
    if (this.tittleModal === 'affinity' && data[0].weight) {
      modalData.col3 = 'weight';
    }
  
    // Abrimos el modal con los datos configurados
    this.dialog.open(ViewMoreModalComponent, {
      width: 'auto',
      autoFocus: false,
      maxHeight: '90vh',
      data: modalData,
    });
  }
  
}
