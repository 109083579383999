import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminManagementService {

  constructor(private http: HttpClient) {}

  private apiUrl = environment.aws_resources.lambda_endpoint.api;
  private usersInfoUrl = environment.aws_resources.lambda_endpoint.api;

  getUserAttributes(email) {
    return this.http.get(this.apiUrl + 'account-management/' + email,
      {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getActiveCampaigns(hours) {
    let params = new HttpParams();
    params = params.append('dashboard_type', 'active_campaigns');
    if (hours !== undefined) {
      params = params.append('time_range', hours);
    }
    return this.http.get(
      this.apiUrl + 'account-management/dashboard/campaigns',
      {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  getManageCampaign(campaignId) {
    return this.http.get(
        this.apiUrl + 'account-management/admin/campaign/' + campaignId,
        {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  updateManageCampaign(campaignId, dataForUpdate) {
    const body = {
      'data_for_update': dataForUpdate,
    };
    return this.http.put(
        this.apiUrl + 'account-management/admin/campaign/' + campaignId, body,
        {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getLatestCampaigns(hours) {
    let params = new HttpParams();
    params = params.append('dashboard_type', 'latest_campaigns');
    if (hours !== undefined) {
      params = params.append('time_range', hours);

    }
    return this.http.get(
      this.apiUrl + 'account-management/dashboard/campaigns',
      {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  getLambdaErrors(hours) {
    let params = new HttpParams();
    if (hours !== undefined) {
      params = params.append('time_range', hours);

    }
    return this.http.get(
      this.apiUrl + 'account-management/dashboard/errors',
      {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  getSocialLoginData () {
    return this.http.get(
      this.apiUrl + 'account-management/dashboard/social_login_data',
      {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }


  getCrawlsData(hours) {
    let params = new HttpParams();
    if (hours !== undefined) {
      params = params.append('time_range', hours);

    }
    return this.http.get(
      this.apiUrl + 'account-management/dashboard/crawl_data',
      {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  getUserConfig(email) {
    return this.http.get(
      this.apiUrl + 'plans/get_user_config_info/' + email,
      {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }


  getCustomerSettings(email, isExecuted) {
    let params = new HttpParams();
    params = params.append('only_execute', isExecuted);
    return this.http.get(
        this.apiUrl + 'account-management/admin/user/' + email,
        {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  getUserChartsACtivity(username, time_range) {
    let params = new HttpParams();
    params = params.append('time_range', time_range);
    return this.http.get(
        this.apiUrl + 'account-management/admin/user_activity_data/' + username,
        {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));

  }

  getListUserGroups() {
    return this.http.get(
      this.apiUrl + 'plans/list_groups',
      {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getGroupByGroupName(groupName) {
    let params = new HttpParams();
    params = params.append('group_name', groupName);
    return this.http.get(
        this.apiUrl + 'plans/admin_get_user_group',
        {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }


  updateUserGroupTheme(value, groupName, isTheme) {
    let body;
    if (isTheme) {
      body = {
        group_name: groupName,
        group_params: {
          theme_style: value,
        }
      };
    } else {
      body = {
        group_name: groupName,
        group_params: {
          logo_url: value,
        }
      };
    }
    return this.http.post(
        this.apiUrl + 'plans/edit_user_group' , body,
        {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getAdminUserGroup(userCognitoId) {
    let params = new HttpParams();
    params = params.append('client_user_cognito_id', userCognitoId);
    return this.http.get(
      this.apiUrl + 'plans/admin_get_user_group',
      {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  updateUserPlan(user_cognito_id: string,  plan: any) {
    const body = {
      'user_cognito_id': user_cognito_id,
      'plan_name': plan.plan_name,
      'plan_id': plan.plan_id,
    };
    return this.http.put(this.apiUrl + 'plans/update_plan',
      body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  extendFreeTrial(user_email: string,  daysToExtend: number) {
    const body = {
      'days_to_extend': daysToExtend,
      'user_email' : user_email
    };
    return this.http.put(this.apiUrl + 'account-management/extend_free_trial',
        body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }


  updateUserGroup(email, groupName) {
    const body = {
      'username': email,
      'group_name': groupName,
    };
    return this.http.post(this.apiUrl + 'plans/assign_group',
      body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  updateUserConfig(user_cognito_id, user_config_parameters) {
    const body = {
      'user_config_parameters': user_config_parameters
    };
    return this.http.put(this.apiUrl + 'plans/update_user_config_info/' +
      user_cognito_id, body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  updateAttributes(email, user_attributes_for_update) {
    const body = {
      'user_attributes': user_attributes_for_update,
    };

    return this.http.put(this.apiUrl + 'account-management/' + email, body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getUsersInfo(paginationToken?, username?) {
    if (paginationToken === 0) {
      paginationToken = undefined;
    }
    if (username !== undefined ) {
      let params = new HttpParams();
      params = params.append('username_prefix', username);
      return this.http.get(this.usersInfoUrl + 'account-management/list_users',
          {observe: 'response', params}).pipe(map((data: any) => {
        return data;
      }));
    } else {
      if (paginationToken === undefined) {
        return this.http.get(this.usersInfoUrl + 'account-management/list_users/',
            {observe: 'response'}).pipe(map((data: any) => {
          return data;
        }));
      } else {
        let params = new HttpParams();
        params = params.append('pagination_token', paginationToken);
        return this.http.get(this.usersInfoUrl  + 'account-management/list_users/',
            {observe: 'response', params}).pipe(map((data: any) => {
          return data;
        }));
      }
    }


  }


  sendToFullCrawl(username) {
    let params = new HttpParams();
    params = params.append('username', username);

    return this.http.get(this.usersInfoUrl + 'account-management/invoke_send_to_full_crawl', { observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }


  manageCampaignUpdateInfluencerCorrectionIndex(campaign_id, influencer_id, correction_index) {
    const body = {
      influencer_id: influencer_id,
      correction_index: correction_index,
    };
    return this.http.post(this.usersInfoUrl + 'campaigns/' + campaign_id + '/correction_index', body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  updateInfluencerState(campaign_id, influencer_id, current_state) {
    const body = {
      current_state: current_state
    };
    return this.http.put(this.usersInfoUrl + 'campaigns/' + campaign_id + '/influencer/' + influencer_id, body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }


  registerFreeTrial(registerForm) {
    const body = {
      'name':  registerForm.name,
      'surname':  registerForm.surname,
      'job_position':  registerForm.job_position,
      'country':  registerForm.country,
      'company_name':  registerForm.company_name,
      'number_employees':  registerForm.number_employees,
    };
    return this.http.post(this.usersInfoUrl + 'plans/free-trial-form', body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));

  }

  transferCampaigns(campaign_id, sub, email) {
    const body = {
      campaign_id: campaign_id,
      old_client_sub: sub,
      new_client_username: email,
    };
    return this.http.put(this.usersInfoUrl + 'account-management/' + 'transfer_campaign', body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  transferAccount(sub, email) {
    const body = {
      old_client_sub: sub,
      new_client_username: email,
    };
    return this.http.put(this.usersInfoUrl + 'account-management/' + 'transfer_user', body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }
}
