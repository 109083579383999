import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ModalConfirmedPauseCancelPlanComponent } from '../modal-confirmed-pause-cancel-plan/modal-confirmed-pause-cancel-plan.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { ModalPlansService } from 'src/app/features/modal-plans/store/modal-plans.service';
import { Subscription } from 'rxjs';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import { getAccessSets, getLoggedUser } from '../../../../../../store/auth/authentication.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../store';

@Component({
  selector: 'app-modal-reactivate-plan',
  templateUrl: './modal-reactivate-plan.component.html',
  styleUrls: ['./modal-reactivate-plan.component.scss']
})
export class ModalReactivatePlanComponent implements OnInit, OnDestroy {

  loading = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalPlansService: ModalPlansService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ModalReactivatePlanComponent>,
    private store: Store<State>,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  reactivatePlan() {
    this.loading = true;
    this.subscriptions.push(
      this.modalPlansService.reactivateSubscriptionPlan().subscribe( () => {
        this.store.dispatch(getAccessSets());
        this.store.dispatch(getLoggedUser());
        this.dialog
        .open(ModalConfirmedPauseCancelPlanComponent, {
          width: '397px',
          height: 'auto',
          autoFocus: false,
          data: {
            componentCalled: 'reactivated'
          }
        })
        .afterClosed();
        this.loading = false;
        this.dialogRef.close();
      }, error => {
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: { message: error.error.error_message, type: 'error' },
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: 'error-snackbar',
        });
        this.loading = false;
        this.dialogRef.close();
      })
    );

  }


}
