import {Component, Input, OnInit} from '@angular/core';
import {ReportService} from '../../../../../../services/report.service';
import {FormControl, FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-top-content-report-comment',
  templateUrl: './top-content-report-comment.component.html',
  styleUrls: ['./top-content-report-comment.component.scss']
})
export class TopContentReportCommentComponent implements OnInit {

  @Input() topMedias;
  @Input() campaignId;
  @Input() comment;
  @Input() share_comment;
  @Input() isShare;

  topContentCommentsForm: FormGroup;

  constructor(
      private snackBar: MatSnackBar,
      private _reportService: ReportService,
  ) {
    this.topContentCommentsForm = new FormGroup({
      'topContentComment': new FormControl(''),
      'displayTopContentComment': new FormControl(false),
    });
  }

  ngOnInit() {
    if (this.comment) {
      this.topContentCommentsForm.controls.topContentComment.setValue(this.comment);
    }
    if (this.share_comment) {
      this.topContentCommentsForm.controls.displayTopContentComment.setValue(this.share_comment);
    }
  }

  saveTopContentComment() {
    const commentValue = this.topContentCommentsForm.controls.topContentComment.value;
    const displayValue = this.topContentCommentsForm.controls.displayTopContentComment.value;

    if (commentValue !== '') {
      const displayCommentInShare = displayValue === '' ? false : displayValue;
      this._reportService.saveGeneralPublishedComment(this.campaignId, commentValue, displayCommentInShare, false)
          .subscribe(
              () => this.openSnackBar('Saved successfully', 'Ok'),
              () => this.openSnackBar('Oops, something went wrong. Please try again', 'Ok')
          );
    }
  }


  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
