import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {Subscription} from 'rxjs';
import {ReportDashboardService} from '../../../../../../services/report-dashboard.service';

@Component({
  selector: 'app-modal-confirm-remove-item',
  templateUrl: './modal-confirm-remove-item.component.html',
  styleUrls: ['./modal-confirm-remove-item.component.scss']
})
export class ModalConfirmRemoveItemComponent implements OnInit, OnDestroy {

  loading;
  checkbox_form: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private _dashboardService: ReportDashboardService,
              public dialogRef: MatDialogRef<ModalConfirmRemoveItemComponent>,
              private snackBar: MatSnackBar) {

    this.checkbox_form = new FormGroup({
      'checkout_checkbox': new FormControl(false, [Validators.requiredTrue])
    });

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  deleteDashboard(dashboardId, item) {
    if (item === 'dashboard') {
      this.subscriptions.push(
          this._dashboardService.removeDashboard(dashboardId).subscribe( () => {
            this.openSnackBar('Removed successfully', 'Close');
            this.dialogRef.close('success');
          }, () => {
            this.openSnackBar('Oops something went wrong, please try again', 'Close');
          })
      );
    } else if (item === 'campaign') {
      this.subscriptions.push(
          this._dashboardService.removeDashboard(dashboardId, this.data.campaignId).subscribe( () => {
            this.openSnackBar('Removed successfully', 'Close');
            this.dialogRef.close('success');
          }, () => {
            this.openSnackBar('Oops something went wrong, please try again', 'Close');
          })
      );
    }

  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }


}
