import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';

import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {State} from '../store';
import {MatDialog, MatSnackBar} from '@angular/material';
import {environment} from '../../environments/environment';
import {openLimitsPlansExceedDialog} from '../store/app/app.actions';
import {ModalSelectBonusComponent} from '../features/private/modals/modal-select-bonus/modal-select-bonus.component';

@Injectable({
  providedIn: 'root',
})
export class Interceptor402Service implements HttpInterceptor {
  constructor(private router: Router,
              private store: Store<State>,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
      return next.handle(req)
          .pipe(tap(
              (response: HttpEvent<any>) => {
              },
              (error: HttpErrorResponse) => {
                  if (error.status === 402) {
                      if ( environment.platform_id === '11posts' ) {
                          if (error.error.limit_type === 'analyze') {
                              this.dialog.open(ModalSelectBonusComponent, {
                                  width: '700px',
                                  height: '640px',
                                  autoFocus: false,
                                  data: {
                                      limited: true,
                                  }
                              });
                          } else {
                              this.router.navigate(['/app/search']);
                              if (error.error.plan_name !== 'PAUSED') {
                                this.store.dispatch(openLimitsPlansExceedDialog({ planName: error.error['plan_name'], errorMessage: error.error['message']}));
                              }
                              // this.store.dispatch(openPreviousPlansDialog());
                          }
                      } else {
                          this.openSnackBar('Error, you reached the limits', 'Close');
                      }
                  }
              },
              () => {
              }
          ));
  }

    openSnackBar(message, action) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }
}
