import {Action, createReducer, on} from '@ngrx/store';
import * as actions from '../store/modal-plans.actions';
import {Plan} from '../types/plans';

export const plansKey = 'modal-plans', plansReducers = {

  getPlans(state) {
    return {...state, getPlansLoading: true, getPlansError: null};
  },
  getPlansSuccess(state, { plans }) {
    return {...state, plans, getPlansLoading: false, getPlansError: null};
  },
  getPlansError(state, {error}) {
    const message = error.message;
    return {...state, getPlansLoading: false, getPlansError: message};
  },
};

export interface PlanState {
  plans: Plan[];
  getPlansLoading: boolean;
  getPlansError: string;
}

export const initialPlanState = {
  plans: null,
  getPlansLoading: false,
  getPlansError: null,
};

export function reducer(state: PlanState | undefined, action: Action) {
  return createReducer(
    initialPlanState,
    on(actions.getPlans, plansReducers.getPlans),
    on(actions.getPlansSuccess, plansReducers.getPlansSuccess),
    on(actions.getPlansFail, plansReducers.getPlansError),
  )(state, action);
}
