import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {selectAppViewPort} from '../../../../../../store/app/app.selectors';
import {Store} from '@ngrx/store';
import {State} from 'src/app/store';
import {ModalEditInfluencerEmailComponent} from '../../modals/modal-edit-influencer-email/modal-edit-influencer-email.component';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {BriefCampaignForUpdateInterface} from '../../../../../../interfaces/brief-campaign-for-update-interface';
import {BriefService} from '../../../../../../services/brief.service';
import {form_tooltips} from '../../external/brief-tooltips';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalAddLinkInfluencerComponent} from './modals/modal-add-link-influencer/modal-add-link-influencer.component';
import {ModalAddCommentInfluencerComponent} from './modals/modal-add-comment-influencer/modal-add-comment-influencer.component';
import {BriefCampaignForUpdateClass} from '../../../../../../classes/brief-campaign-for-update-class';
import {Router} from '@angular/router';
import {ConfirmSendInvitationModalComponent} from '../../../confirm-send-invitation-modal/confirm-send-invitation-modal.component';
import {ConfirmEarlyStartDateModalComponent} from '../../../confirm-early-start-date-modal/confirm-early-start-date-modal.component';
import {ModalInvitationInfluencersWarningComponent} from '../../modals/modal-invitation-influencers-warning/modal-invitation-influencers-warning.component';

@Component({
  selector: 'app-brief-step3',
  templateUrl: './brief-step3.component.html',
  styleUrls: ['./brief-step3.component.scss']
})
export class BriefStep3Component implements OnInit, OnDestroy {

  @Input() influencers;
  @Input() campaign_id;
  @Input() brief_type;
  @Input() currency_type;
  @Input() activateSendProposalsButton;
  @Input() activateCreateReportButton;
  @Input() payment_type;
  @Input() campaign_name;
  @Input() start_date_under_one_week_from_today;
  @Input() pending_influencers_count;
  @Input() total_influencers_count;
  @Input() currency_symbol;

  @Output() callChangeShowStoriesUrl = new EventEmitter<any>();
  @Output() callInfluencerSelected = new EventEmitter<any>();


  brief_for_update: BriefCampaignForUpdateInterface;
  errorIndividualLink = false;
  form_tooltips = form_tooltips;
  countInfluencersNotApproved = 0;

  dataSource = new MatTableDataSource<any>();


  searchInfluencerForm: FormGroup;

  private subscriptions = [];
  viewport;

  @ViewChild('price', { static: false }) price: ElementRef;

  constructor(public store: Store<State>,
              private dialog: MatDialog,
              public snackBar: MatSnackBar,
              private _briefService: BriefService,
              public router: Router,
  ) {
    this.searchInfluencerForm = new FormGroup({
      'username': new FormControl('', [
        Validators.required
      ]),
    });
    this.brief_for_update = new BriefCampaignForUpdateClass();
  }

  static getInfluencerInitialTotalCost(influencer) {
    if (influencer.network === 'instagram') {
      const number_posts = influencer.instagram_services.posts;
      const number_stories = influencer.instagram_services.stories;
      const price_post = influencer.stats.price_per_post_plus_perc;
      const price_story = influencer.stats.price_per_story_plus_perc;
      return number_posts * price_post + number_stories * price_story;
    } else if (influencer.network === 'youtube') {
      const number_videos = influencer.youtube_services.videos;
      const number_mentions = influencer.youtube_services.mentions;
      const price_video = influencer.stats.price_per_video_plus_perc;
      const price_mention = influencer.stats.price_per_mention_plus_perc;
      return number_videos * price_video + number_mentions * price_mention;
    } else if (influencer.network === 'tiktok') {
      const number_videos = influencer.tiktok_services.tiktok_videos;
      const price_video = influencer.stats.price_per_tiktok_video_plus_perc;
      return number_videos * price_video;
    }
  }

  static checkAtLeastOneAddedOrWaitingPaymentStatus(influencers): boolean {
    return influencers.some(influencer => {
      if (
          influencer.state === 'added' ||
          influencer.state === 'waiting_payment'
      ) {
        return true;
      }
    });
  }

  ngOnInit() {
    this.dataSource = this.influencers;
    for (let i = 0; i < this.influencers.length; i++) {
      this.dataSource[i].total_cost = this.getInfluencerTotalCost(
          this.dataSource[i]
      );
      if (this.dataSource[i].state === 'added') {
        this.brief_for_update.influencers.push({
          influencer_id: this.dataSource[i].influencer_id,
          description:
              this.dataSource[i].description !== undefined
                  ? this.dataSource[i].description
                  : '',
          correction_index: this.dataSource[i].correction_index,
          custom_destination_url: this.dataSource[i].custom_destination_url,
        });
      }
      if (this.influencers[i].client_approval !== true) {
        this.countInfluencersNotApproved += 1;
      }
    }
    this.subscriptions.push(
        this.store.select(selectAppViewPort).subscribe(result => {
          this.viewport = result;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // mejora el pintado del *ngfor
  identify(index, item) {
    return item.name;
  }

  setVirtualScrollHeight(array_length) {
    if (this.viewport === 'handset') {
      if (array_length >= 10) {
        return '950px';
      } else {
        return (array_length * 400).toString() + 'px';
      }
    } else {
      if (array_length >= 10) {
        return '1300px';
      } else {
        return (array_length * 140).toString() + 'px';
      }
    }
  }


  editInfluencerEmail(influencer) {
    const dialogRef =  this.dialog.open(ModalEditInfluencerEmailComponent, {
      width: '500px',
      height: 'auto',
      autoFocus: false,
      data: {
        influencerId: influencer.influencer_id,
        campaignId: this.campaign_id,
        email: influencer.invitation_mail,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'success') {
        influencer.invitation_mail = result.invitation_mail.slice(0, 3) + '****@...';
      }
    });
  }

  validateInput(inputElement: HTMLInputElement, index: number) {
    let valor = inputElement.value;

    // Elimina todos los caracteres no numéricos, excepto comas y puntos
    valor = valor.replace(/[^\d,.]/g, '');

    // Reemplaza comas por vacío para que no afecten el formato
    valor = valor.replace(/,/g, '');

    // Divide la cadena en partes utilizando el punto como separador decimal
    const partes = valor.split('.');

    if (partes.length > 2) {
      // Si hay más de un punto decimal, elimina los extras
      valor = partes[0] + '.' + partes.slice(1).join('');
    } else {
      // Formatea la parte entera con comas como separadores de miles (estilo americano)
      partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Combina la parte entera y decimal con el punto como separador decimal
      valor = partes.join('.');
    }

    // Actualiza el valor del elemento de entrada
    inputElement.value = valor;
  }


  validateEntry(event: any): boolean {
    // const inputChar = String.fromCharCode(event.charCode);
    // // Permitir solo números, punto decimal y teclas de control (como retroceso)
    // return /^[\d.]+$/.test(inputChar) || event.charCode === 0 || event.charCode === 8;

    const inputChar = String.fromCharCode(event.charCode);
    // Permitir solo números, punto decimal, comas como separadores de miles y teclas de control (como retroceso)
    return /^[\d,.]+$/.test(inputChar) || event.charCode === 0 || event.charCode === 8;
  }

  setInfluencerCost(price_field, influencer) {
    if (price_field.value !== '') {
      // Quitar las comas de price_field.value si están presentes
      const priceWithoutCommas = price_field.value.replace(/,/g, '');

      const influencer_new_total_cost = Number(priceWithoutCommas).toFixed(2);
      const influencer_initial_total_cost = BriefStep3Component.getInfluencerInitialTotalCost(influencer);
      const correction_index =
          Number(influencer_new_total_cost) /
          Number(influencer_initial_total_cost);
      this.setCorrectionIndex(influencer.influencer_id, correction_index);
    }
  }

  setCorrectionIndex(influencer_id, correction_index) {
    for (let i = 0; i < this.influencers.length; i++) {
      if (
          this.dataSource[i].influencer_id.toString() ===
          influencer_id.toString()
      ) {
        this.dataSource[i].correction_index = Number(
            correction_index.toFixed(12)
        );
        this.dataSource[i].total_cost = this.getInfluencerTotalCost(
            this.dataSource[i]
        );
        this.brief_for_update.influencers = [
          {
            influencer_id: influencer_id,
            description: this.dataSource[i].description,
            correction_index: this.dataSource[i].correction_index,
            custom_destination_url: this.dataSource[i].custom_destination_url,
          },
        ];
        this.saveChanges('influencers');
      }
    }
  }


  getInfluencerTotalCost(influencer) {
    if (influencer.network === 'instagram') {
      const number_posts = influencer.instagram_services.posts;
      const number_stories = influencer.instagram_services.stories;
      const price_post = influencer.stats.price_per_post_plus_perc;
      const price_story = influencer.stats.price_per_story_plus_perc;
      return (
          (number_posts * price_post + number_stories * price_story) *
          influencer.correction_index
      );
    } else if (influencer.network === 'youtube') {
      const number_videos = influencer.youtube_services.videos;
      const number_mentions = influencer.youtube_services.mentions;
      const price_video = influencer.stats.price_per_video_plus_perc;
      const price_mention = influencer.stats.price_per_mention_plus_perc;
      return (
          (number_videos * price_video + number_mentions * price_mention) *
          influencer.correction_index
      );
    } else if (influencer.network === 'tiktok') {
      const number_videos = influencer.tiktok_services.tiktok_videos;
      const price_video = influencer.stats.price_per_tiktok_video_plus_perc;
      return (
          number_videos * price_video *
          influencer.correction_index
      );
    }
  }



  saveChanges(attribute) {
    const dict_attribute = {};
    dict_attribute[attribute] = this.brief_for_update.influencers;
    this._briefService.updateBrief(this.campaign_id, dict_attribute).subscribe(
        () => {},
        () => {
          this.openSnackBar('Error saving brief, please try again soon', 'Ok');
        }
    );
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  getComments(influencer, description) {
    this.brief_for_update.influencers = [
      {
        influencer_id: influencer.influencer_id,
        description: description.value,
        correction_index: influencer.correction_index,
        custom_destination_url: influencer.custom_destination_url,
      },
    ];
    influencer.description = description.value;
    this.saveChanges('influencers');
  }

  getIndividualDestinationUrl(influencer, url) {
    if (url.value === '') {
      this.errorIndividualLink = false;
      this.brief_for_update.influencers = [
        {
          influencer_id: influencer.influencer_id,
          custom_destination_url: url.value,
          description: influencer.description,
          correction_index: influencer.correction_index,
        },
      ];
      influencer.custom_destination_url = '';
      influencer.errorIndividualLink = false;
      this.saveChanges('influencers');
    } else {
      const reg = new RegExp('^(http|https)://');
      const urls = url.value.split(' ');
      let countLinksOk = 0;
      for (let index = 0; index < urls.length; index++) {
        if (reg.test(urls[index])) {
          countLinksOk += 1;
        }
      }
      influencer.errorIndividualLink = countLinksOk !== urls.length;
      if (!this.errorIndividualLink) {
        this.brief_for_update.influencers = [
          {
            influencer_id: influencer.influencer_id,
            custom_destination_url: url.value,
            description: influencer.description,
            correction_index: influencer.correction_index,
          },
        ];
        influencer.custom_destination_url = url.value;
        this.saveChanges('influencers');
      }
    }
  }

  decreaseTotalCost(influencer) {
    if (influencer.correction_index > 0) {
      influencer.correction_index -= 0.01;
      this.setCorrectionIndex(
          influencer.influencer_id,
          influencer.correction_index
      );
    }
  }

  increaseTotalCost(influencer) {
    influencer.correction_index += 0.01;
    this.setCorrectionIndex(
        influencer.influencer_id,
        influencer.correction_index
    );
  }


  openModalAddCommentInfluencer(influencer) {
    const dialogRef =  this.dialog.open(ModalAddCommentInfluencerComponent, {
      width: '500px',
      height: 'auto',
      autoFocus: false,
      data: {
        campaign_id: this.campaign_id,
        influencer_id: influencer.influencer_id,
        correction_index: influencer.correction_index,
        link: influencer.custom_destination_url,
        description: influencer.description
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'success') {
        influencer.description = result.comment;
      }
    });
  }

  openModalAddLinkInfluencer(influencer) {
    const dialogRef =  this.dialog.open(ModalAddLinkInfluencerComponent, {
      width: '500px',
      height: 'auto',
      autoFocus: false,
      data: {
        campaign_id: this.campaign_id,
        influencer_id: influencer.influencer_id,
        correction_index: influencer.correction_index,
        link: influencer.custom_destination_url,
        description: influencer.description

      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'success') {
        influencer.custom_destination_url = result.link;
        if (influencer.custom_destination_url === '') {
          this.callChangeShowStoriesUrl.emit(false);
        } else {
          this.callChangeShowStoriesUrl.emit(true);
        }
      }
    });
  }


  searchInfluencer($event) {
    if ($event.target.value === '') {
      this.dataSource = this.influencers;
    }
    this.dataSource = new MatTableDataSource(this.influencers.filter(e => e.username.toLowerCase().includes($event.target.value.toLowerCase())));
  }


  selectInfluencerPreview(influencer) {
    this.callInfluencerSelected.emit(influencer);
  }


  sendProposalsEmail() {
    if (this.pending_influencers_count > 0 ) {
      const dialogRef = this.dialog.open(ModalInvitationInfluencersWarningComponent, {
        width: '650px',
        height: 'auto',
        data: {
          total_influencers: this.total_influencers_count,
          pending_influencers: this.pending_influencers_count
        },
      });
      dialogRef.afterClosed().subscribe( result => {
        if (result === 'success') {
          if ( this.payment_type === 'report' ) {
            this.subscriptions.push(
                this._briefService.getBriefInvitation(this.campaign_id).subscribe(() => {
                  setTimeout( () => { this.router.navigate(['/app/report/' + this.campaign_id]); }, 2000 );
                }, () => {
                  this.openSnackBar('Ops, something went wrong, please try again', 'close');
                })
            );
          } else if ( this.payment_type === 'cpm') {
            if (this.start_date_under_one_week_from_today === true) {
              this.openDialogConfirmEarlyDate();
            } else {
              this.openDialogCheckout();
            }
          }
        }
      });
    } else {
      if ( this.payment_type === 'report' ) {
        this.subscriptions.push(
            this._briefService.getBriefInvitation(this.campaign_id).subscribe(() => {
              setTimeout( () => { this.router.navigate(['/app/report/' + this.campaign_id]); }, 2000 );
            }, () => {
              this.openSnackBar('Ops, something went wrong, please try again', 'close');
            })
        );
      } else if ( this.payment_type === 'cpm') {
        if (this.start_date_under_one_week_from_today === true) {
          this.openDialogConfirmEarlyDate();
        } else {
          this.openDialogCheckout();
        }
      }
    }
  }

  /**
   * PopUp Checkout (show the payments and the client choose go to paypal or go back)
   */
  openDialogCheckout(): void {
    let influencersForSendProposal = 0;
    this.influencers.forEach(influencer => {
      if (influencer.state === 'added') {
        influencersForSendProposal += 1;
      }
    });
    const dialogRef = this.dialog.open(ConfirmSendInvitationModalComponent, {
      width: '600px',
      height: '450px',
      autoFocus: false,
      data: {
        campaign_id: this.campaign_id,
        campaignName: this.campaign_name,
        influencersForSendProposal: influencersForSendProposal,
      },
    });

    dialogRef.afterClosed().subscribe();
  }

  /**
   * PopUp confirm early date (starts in less than 1 week)
   */
  openDialogConfirmEarlyDate(): void {
    let influencersForSendProposal = 0;
    this.influencers.forEach(influencer => {
      if (influencer.state === 'added') {
        influencersForSendProposal += 1;
      }
    });
    const dialogRef = this.dialog.open(ConfirmEarlyStartDateModalComponent, {
      width: '600px',
      height: '270px',
      autoFocus: false,
      data: {
        campaign_id: this.campaign_id,
        campaign_name: this.campaign_name,
        influencersForSendProposal: influencersForSendProposal,
      },
    });

    dialogRef.afterClosed().subscribe();
  }

}
