import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Sort} from '@angular/material';
import {AdminManagementService} from '../../../../store/admin-management.service';

@Component({
  selector: 'app-dashboard-lambda-errors',
  templateUrl: './dashboard-lambda-errors.component.html',
  styleUrls: ['./dashboard-lambda-errors.component.scss']
})
export class DashboardLambdaErrorsComponent implements OnInit, OnDestroy {

  form_lambda_errors_timeRange: FormGroup;

  displayedColumns: string[] = ['Lambda Name', 'Service', 'Date', 'Errors Count'];

  dataLambdaErrors;
  private subscriptions = [];
  loading = false;

  constructor(private adminManagementService: AdminManagementService) {
    this.form_lambda_errors_timeRange = new FormGroup({
      'crawl_timeRange': new FormControl('86400'),
    });
  }

  ngOnInit() {
    this.loading = true;
    this.getLambdaErrors('86400');

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  getLambdaErrors(hours) {
    this.loading = true;
    this.subscriptions.push(
        this.adminManagementService.getLambdaErrors(hours).subscribe( result => {
          this.dataLambdaErrors = result.body;
          if (this.dataLambdaErrors.total_lambda_errors !== 0) {
            this.dataLambdaErrors.lambda_errors.sort((a, b) => a.error_count > b.error_count ? -1 : a.error_count < b.error_count ? 1 : 0);
          }
          this.loading = false;
        }, () => {
          this.dataLambdaErrors = [];
          this.loading = false;
        })
    );
  }

  sortData(sort: Sort) {
    const data = this.dataLambdaErrors.lambda_errors.slice();
    if (!sort.active || sort.direction === '') {
      this.dataLambdaErrors.lambda_errors = data;
    }
    this.dataLambdaErrors.lambda_errors = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Errors Count':
          return this.compare(a.error_count, b.error_count, isAsc);
        case 'Lambda Name':
          return this.compare(a.function_name, b.function_name, isAsc);
        case 'Service':
          return this.compare(a.service, b.service, isAsc);
        case 'Date':
          return this.compare(a.timestamp, b.timestamp, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  changeTimeQuery(hours) {
    this.loading = true;
    this.getLambdaErrors(hours.value);
  }

}
