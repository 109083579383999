import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'markdown'
})
export class MarkdownPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}
  
  transform(value: any, args?: any): any {
    if (!value) return '';

    const html = this.markdownToHtml(value);
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  markdownToHtml(markdownText: string): any {
    if (!markdownText) return ''; // Verificar que la cadena no sea nula

    // Reemplazar las negritas **texto** por <strong>texto</strong>, recordar cambiar el estilo en cada sitio que se le llame se debe meter el estilo 
    // :host ::ng-deep strong {
    //   font-weight: bold;
    // }
    return markdownText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  }
  
}
