import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AdminManagementService} from '../../../../store/admin-management.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {FormControl, FormGroup} from '@angular/forms';
import {CompleteUserConfigComponent} from '../../modals/complete-user-config/complete-user-config.component';

@Component({
  selector: 'app-user-config',
  templateUrl: './user-config.component.html',
  styleUrls: ['./user-config.component.scss']
})
export class UserConfigComponent implements OnInit, OnDestroy {

  @Input() userConfig;
  @Input() actual_plan;
  @Input() dateFreeTrial;
  @Input() user_sub;
  @Input() groups;
  @Input() plans;
  @Input() actual_group;
  @Input() email;
  @Input() extendedFreeTrialDays;

  userPlanForUpdate: {};
  userGroupForUpdate;

  changePlan = false;
  changeGroup = false;
  changeConfig = false;

  daysResponseMessage;

  private subscriptions = [];

  userConfigPlanForm: FormGroup;
  userConfigDataForm: FormGroup;
  userConfigGroupForm: FormGroup;

  constructor(private adminManagementService: AdminManagementService,
              private snackBar: MatSnackBar,
              public dialog: MatDialog,
  ) {
    this.userConfigPlanForm = new FormGroup({
      'plans': new FormControl(''),
    });

    this.userConfigGroupForm = new FormGroup({
      'groups': new FormControl(''),
    });
  }

  ngOnInit() {
    if (this.actual_plan !== undefined) {
      this.userConfigPlanForm.controls['plans'].setValue(this.actual_plan);
    }
    if (this.actual_group !== undefined) {
      this.userConfigGroupForm.controls['groups'].setValue(this.actual_group);
    }
    this.userConfigDataForm = new FormGroup({});
    for (let index = 0; index < this.userConfig.length; index++) {
      this.userConfigDataForm.controls[this.userConfig[index]['title']] =
          new FormControl(this.userConfig[index]['value']);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  saveChanges(daysFreeTrial?) {
    if (this.changeGroup) {
      this.subscriptions.push(
          this.adminManagementService.updateUserGroup(this.email,
              this.userGroupForUpdate).subscribe (() => {
            this.openSnackBar('User group updated successfully', 'Close');
          }, () => {
            this.openSnackBar('Oops something went wrong, please try again', 'Close');
          })
      );
      this.actual_group = this.userGroupForUpdate;
      this.changeGroup = false;
      document.getElementById('userGroup').classList.remove('border-orange');
    }

    if (this.changePlan) {
      this.subscriptions.push(
          this.adminManagementService.updateUserPlan(this.user_sub,
              this.userPlanForUpdate).subscribe (() => {
            this.openSnackBar('User plan updated successfully', 'Close');
          }, () => {
            this.openSnackBar('Oops something went wrong, please try again', 'Close');
          })
      );
      this.actual_plan = this.userPlanForUpdate;
      this.changePlan = false;
      document.getElementById('currentPlan').classList.remove('border-orange');
    }

    if (this.changeConfig) {
      const attributesForUpdate = {};
      for (let index = 0; index < this.userConfig.length; index++) {
        attributesForUpdate[this.userConfig[index]['title']] = this.userConfigDataForm.controls
            [this.userConfig[index]['title']].value;
      }
      this.adminManagementService.updateUserConfig(this.user_sub,
          attributesForUpdate).subscribe(() => {
        this.openSnackBar('User config updated successfully', 'Close');
      }, () => {
        this.openSnackBar('Oops something went wrong, please try again', 'Close');
      });
      this.changeConfig = false;
    }

    if (daysFreeTrial) {
        this.subscriptions.push(
            this.adminManagementService.extendFreeTrial(this.email,
                daysFreeTrial).subscribe (response => {
                  this.dateFreeTrial.end_trial_timestamp = response.body.end_trial_timestamp;
                  this.openSnackBar(response.body.message, 'Close');
            }, () => {
                  this.openSnackBar('Oops something went wrong, please try again', 'Close');
            })
        );
    }

    for (let i = 0; i < this.userConfig.length; i++) {
      if (this.userConfig[i].type !== 'boolean') {
        document.getElementById(this.userConfig[i].title).classList.remove('border-orange');
      }
    }
  }


  moreInfoUserConfig() {
    const dialogRef = this.dialog.open(CompleteUserConfigComponent, {
      width: '1200px',
      height: 'auto',
      autoFocus: false,
      data: {
        user_config: this.userConfig,
        actual_plan: this.actual_plan,
        actual_group: this.actual_group,
        extendedFreeTrialDays: this.extendedFreeTrialDays,
        groups: this.groups,
        plans: this.plans,
        user_sub: this.user_sub,
        email: this.email,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar']
    });
  }

  onChange(event, attributeName) {
    if (attributeName === 'currentPlan') {
      if (event.value !== this.actual_plan) {
        this.changePlan = true;
        for (let i = 0; i < this.plans.length; i++ ) {
          if (event.value === this.plans[i]['PLAN_NAME']) {
            this.userPlanForUpdate = {
              'plan_name': this.plans[i]['PLAN_NAME'],
              'plan_id': this.plans[i]['PLAN_ID']
            };
            document.getElementById('currentPlan').classList.add('border-orange');
          }
        }
      } else {
        this.changePlan = false;
        document.getElementById('currentPlan').classList.remove('border-orange');
      }
    } else if (attributeName === 'userGroup') {
      this.changeGroup = true;
      this.userGroupForUpdate = event.value;
      if (event.value !== this.actual_group) {
        document.getElementById('userGroup').classList.add('border-orange');
      } else {
        this.changeGroup = false;
        document.getElementById('userGroup').classList.remove('border-orange');
      }
    } else {
      this.changeConfig = true;
      document.getElementById(attributeName).classList.add('border-orange');
    }
  }


}
