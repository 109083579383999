import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AdminManagementService} from '../../../../store/admin-management.service';

@Component({
  selector: 'app-dashboard-crawls',
  templateUrl: './dashboard-crawls.component.html',
  styleUrls: ['./dashboard-crawls.component.scss']
})
export class DashboardCrawlsComponent implements OnInit {


  // lightCrawlData;
  fullCrawlData;
  loading = true;

  form_crawls_timeRange: FormGroup;
  private subscriptions = [];

  constructor(private adminManagementService: AdminManagementService) {
    this.form_crawls_timeRange = new FormGroup({
      'crawl_timeRange': new FormControl('86400'),
    });
  }

  ngOnInit() {
    this.loading = true;
    this.subscriptions.push(
      this.adminManagementService.getCrawlsData(86400).subscribe( result => {
        // this.lightCrawlData = result.body.light_crawl_data;
        this.fullCrawlData = result.body.full_crawl_data;
        this.loading = false;
      }, () => {
        // this.lightCrawlData = [];
        this.fullCrawlData = [];
        this.loading = false;
      })
    );
  }

  changeTimeQuery(hours) {
    this.loading = true;
    this.subscriptions.push(
      this.adminManagementService.getCrawlsData(hours.value).subscribe( result => {
        // this.lightCrawlData = result.body.light_crawl_data;
        this.fullCrawlData = result.body.full_crawl_data;
        this.loading = false;
      }, () => {
        // this.lightCrawlData = [];
        this.fullCrawlData = [];
        this.loading = false;
      })
    );
  }
}
