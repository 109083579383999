import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppThemeService {
  mockThemeData: any;

  constructor(private http: HttpClient) {
  //   if (environment.platform_id === 'binfluencer') {
  //     this.mockThemeData = {
  //       '--primary-color': '#6098c8',
  //       '--primary-lighten-color': '#2caff6',
  //       '--primary-darken-color': '#2c5173',
  //       '--secondary-color': '#e64564',
  //       '--secondary-lighten-color': '#ea577a',
  //       '--secondary-darken-color': '#e44766',
  //       '--gradient-color':
  //           'linear-gradient(0deg, rgba(242, 223, 229, 1) 0%, rgba(242, 242, 251, 1) 80%)',
  //       '--filter-background-color':
  //           'linear-gradient(to bottom, #4d6477 0%, #253848 100%)',
  //       '--search-button-color': '#778b9c',
  //       '--search-text-color': '#a5b6c4',
  //       '--background-header-color': '#ffffff',
  //       '--background-body-color': '#ffffff',
  //       '--menu-front-color': '#8da2b2',
  //       '--menu-bg-color': '#dee6eb',
  //       '--default-text-color': '#a8a8a8',
  //       '--audience-primary-color': '#1f6eb0',
  //       '--audience-secondary-color': '#2caff6',
  //       '--success-color': '#65c687',
  //       '--error-color': '#f44336',
  //     };
  //   } else  if (environment.platform_id === '11posts') {
  //     this.mockThemeData = {
  //       '--primary-color': '#FAB82C', //done
  //       '--primary-lighten-color': '#54949E',
  //       '--primary-darken-color': '#226E65',
  //       '--secondary-color': '#542781', //done
  //       '--secondary-lighten-color': '#BB8A79',
  //       '--secondary-darken-color': '#AA8F74',
  //       '--gradient-color': 'linear-gradient(0deg, #FAB82C 0%, #350E3F 80%)',
  //       '--filter-background-color':
  //           'linear-gradient(to bottom, #4d6477 0%, #350E3F 0%)',
  //       '--search-button-color': '#FAB82C', //done
  //       '--search-text-color': '#8F8896',
  //       '--background-header-color': '#ffffff', //done
  //       '--background-body-color': '#ffffff',
  //       '--menu-front-color': '#8da2b2',
  //       '--menu-bg-color': '#dee6eb',
  //       '--default-text-color': '#a8a8a8',
  //       '--audience-primary-color': '#FAB82C', //done
  //       '--audience-secondary-color': '#FFD989', //done
  //       '--success-color': '#79E28A', //done
  //       '--error-color': '#FF8989', //done
  //     };
  //   }
  }

  getUserGroup(): Observable<any> {
    return this.http
      .get(environment.aws_resources.lambda_endpoint.api + 'plans/get_user_group')
      // .pipe(map(rawTheme => this.processTheme(rawTheme['theme_style']))
      // )
    ;
  }

   processTheme(theme): any {
    // Object.keys(this.mockThemeData).forEach(key => {
    //   document.documentElement.style.setProperty(key, this.mockThemeData[key]);
    // });
    Object.keys(theme).forEach(key => {
        document.documentElement.style.setProperty(key, theme[key]);
    });
  }

  getPublicUserGroup(typeUserGroup, queryValue): Observable<any> {
      return this.http.get(environment.aws_resources.lambda_endpoint.api + 'plans/get_public_user_group' + '/' + typeUserGroup + '/' + queryValue);
  }
}
