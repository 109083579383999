import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import { MatDialog } from '@angular/material';
import {ConfirmSendInvitationModalComponent} from '../confirm-send-invitation-modal/confirm-send-invitation-modal.component';

@Component({
  selector: 'app-confirm-early-start-date-modal',
  templateUrl: './confirm-early-start-date-modal.component.html',
  styleUrls: ['./confirm-early-start-date-modal.component.scss']
})
export class ConfirmEarlyStartDateModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog) {}

  ngOnInit() {
  }

  openDialogCheckout() {
    const dialogRef = this.dialog.open(ConfirmSendInvitationModalComponent, {
      width: '700px',
      height: '450px',
      autoFocus: false,
      data: {
        campaign_id: this.data.campaign_id,
        campaignName: this.data.campaign_name,
        influencersForSendProposal: this.data.influencersForSendProposal,
      }
    });

  dialogRef.afterClosed().subscribe(result => {
  });
  }

}
