import {Pipe, PipeTransform} from '@angular/core';

// This pipe adds the suffixes K and M to the numerical values ​​that need it
@Pipe({
  name: 'converterKM'
})
export class ConverterKMPipe implements PipeTransform {

  transform(follows: number): string {
    if (follows === 0) {
      return '0';
    }
    if (!follows) {
      // return '';
      return '0';
    }
    if (follows > 1000000000) {
      const VALUE = follows / 1000000000;
      const DECIMALS = VALUE.toFixed(1).toString();
      const AUX = DECIMALS.split('.');
      if (AUX[1] === '0') {
        return VALUE.toFixed(0).toString() + 'B';
      } else {
        return VALUE.toFixed(1).toString() + 'B';
      }
    } else if (follows >= 1000000000) {
      const VALUE = follows / 1000000000;
      return VALUE.toFixed(0).toString() + 'B';
    } else if (follows > 1000000) {
      const VALUE = follows / 1000000;
      const DECIMALS = VALUE.toFixed(1).toString();
      const AUX = DECIMALS.split('.');
      if (AUX[1] === '0') {
        return VALUE.toFixed(0).toString() + 'M';
      } else {
        return VALUE.toFixed(1).toString() + 'M';
      }
    } else if (follows >= 1000000) {
      const VALUE = follows / 1000000;
      return VALUE.toFixed(0).toString() + 'M';
    } else if (follows > 1000) {
      const VALUE = follows / 1000;
      const DECIMALS = VALUE.toFixed(1).toString();
      const AUX = DECIMALS.split('.');
      if (AUX[1] === '0') {
        return VALUE.toFixed(0).toString() + 'K';
      } else {
        return VALUE.toFixed(1).toString() + 'K';
      }
    } else if (follows >= 1000) {
      const VALUE = follows / 1000;
      return VALUE.toFixed(0).toString() + 'K';
    } else {
      return follows.toFixed();
    }
  }
}
