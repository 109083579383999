import { Component, OnDestroy, OnInit} from '@angular/core';
import {CampaignsService} from '../../../services/campaigns.service';
import {CampaignsInterface} from '../../../interfaces/campaigns-interface';
import {MatDialog, MatSnackBar, Sort} from '@angular/material';
import {NewCampaignComponent} from './new-campaign/new-campaign.component';
import {Router} from '@angular/router';
import {ConfirmDeleteCampaignModalComponent} from './confirm-delete-campaign-modal/confirm-delete-campaign-modal.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {State} from '../../../store';
import {environment} from '../../../../environments/environment';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import {TranslateService} from '@ngx-translate/core';
import * as selectorsAuth from '../../../store/auth/authentication.selectors';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss'],
})
export class CampaignsComponent implements OnInit, OnDestroy {

  instagram_id = '';
  loading: boolean;
  campaigns: Array<CampaignsInterface>;
  campaignsForShow: Array<CampaignsInterface>;

  campaignsUsernameForm: FormGroup;

  displayedColumns: string[] = ['CAMPAIGN NAME', 'CREATION DATE', 'LAUNCH DATE', 'CURRENCY', 'CAMPAIGN STATUS', 'REMOVE CAMPAIGN'];


  private subscriptions = [];


  /// COSAS ADMIN
  errorMessage = '';
  emailFound = '';
  adminSearchCampaignsFromEmail: FormGroup;
  displayedAdminColumns: string[] = ['EMAIL', 'CAMPAIGN NAME', 'CREATION DATE', 'LAUNCH DATE', 'CURRENCY', 'CAMPAIGN STATUS', 'REMOVE CAMPAIGN'];
  campaignsLoadError = false;
  adminCampaignsSearched = false;
  planNameShown = '';

  constructor(
    private _campaignsService: CampaignsService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public router: Router,
    public store: Store<State>,
    private translate: TranslateService,
  ) {
    this.campaignsUsernameForm = new FormGroup({
      'username': new FormControl('')
    });
    this.adminSearchCampaignsFromEmail = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(
          '^[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$'
        ),
      ]),
      'executed_checkbox': new FormControl(false, [])
    });
  }

  ngOnInit() {
    this.loading = true;
    this.getCampaigns();
    this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
      this.planNameShown = user.PLAN_NAME_SHOWN;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getCampaigns() {
    this.subscriptions.push(
      ...[
        this._campaignsService.getCampaigns().subscribe(result => {
          this.adminCampaignsSearched = false;
          this.campaigns = result;
          this.campaigns = this.campaigns.slice();
          this.campaignsForShow = this.campaigns;
          this.loading = false;
          this.campaignsLoadError = false;
          this.emailFound = '';
          this.adminSearchCampaignsFromEmail.controls['email'].setValue('');
        }, () => {
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: this.translate.instant('shareCampaignPlanModal.oopsError'), type: 'error' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'error-snackbar',
          });
          this.campaignsForShow = [];
          this.loading = false;
          this.campaignsLoadError = true;
          this.emailFound = '';
          this.adminCampaignsSearched = false;
          this.adminSearchCampaignsFromEmail.controls['email'].setValue('');
        })
      ]);
  }

  sortData(sort: Sort) {
    const data = this.campaignsForShow.slice();
    if (!sort.active || sort.direction === '') {
      this.campaignsForShow = data;
    }
    this.campaignsForShow = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'CAMPAIGN NAME':
          return this.compare(a.campaign_name, b.campaign_name, isAsc);
        case 'CREATION DATE':
          return this.compare(a.creation_date, b.creation_date, isAsc);
        case 'LAUNCH DATE':
          return this.compare(a.campaign_launch_time, b.campaign_launch_time, isAsc);
          case 'CURRENCY':
            return this.compare(a.currency, b.currency, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    // campaign launch time undefined, campañas antiguas y campañas sin ejecutar
    if (a === undefined) {
      a = 1286287812;
    }
    if (b === undefined) {
      b = 1286287812;
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  goExampleCampaign() {
    this.router.navigate(['/app/campaign/' + environment.exampleCampaign]);
  }

  openNewCampaignDialog(): void {
    const dialogRef = this.dialog.open(NewCampaignComponent, {
      width: '30%',
      height: '300px',
      data: {
        instagram_id: '',
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (res['status'] === 'created' || res.status === 'added') {
          this.getCampaigns();
        }
      }
    });
  }

  viewCampaign(campaign_id) {
    this.router.navigate(['/app/campaign', campaign_id]);
  }

  confirmDeleteCampaignModal(campaign_id, campaign_name): void {
    const dialogRef = this.dialog.open(ConfirmDeleteCampaignModalComponent, {
      width: '680px',
      height: '250px',
      autoFocus: false,
      data: {
        campaign_id: campaign_id,
        campaign_name: campaign_name,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getCampaigns();
    });
  }



  
  filterCampaignsByUsername() {
    const campaignsUsername = this.campaignsUsernameForm.controls.username.value.toLowerCase();
    this.campaignsForShow = this.filterCampaignByUsername(this.campaigns, campaignsUsername);
  }


  filterCampaignByUsername(campaigns: any[], username: string): any[] {
    return campaigns.filter(campaign => campaign.campaign_name.toLowerCase().includes(username));
  }


  //// ADMIN FUNCTIONS
  adminmSearchCampaigns() {
    this.loading = true;
    this.adminCampaignsSearched = true;
    const costumerEmail = this.adminSearchCampaignsFromEmail.controls['email'].value;
    const request = {
      'client_username': costumerEmail,
      'only_executed': this.adminSearchCampaignsFromEmail.controls['executed_checkbox'].value,
    };
    this.subscriptions.push(
      ...[
        this._campaignsService.getBinfluCampaigns(request).subscribe(result => {
          this.campaignsForShow = result.body;
          this.campaignsForShow = this.campaignsForShow.slice();
          this.campaigns = this.campaignsForShow;
          this.loading = false;
          this.campaignsLoadError = false;
          this.emailFound = costumerEmail;
        }, error => {
          if ( error.error.message === 'User not found.' ) {
            this.snackBar.openFromComponent(CustomSnackbarComponent, {
              data: { message: 'User not found', type: 'error' },
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: 'error-snackbar',
            });
          } else {
            this.errorMessage = 'Oops something went wrong loading the campaigns, please contact with us or try again more later';
            this.campaigns = [];
            this.campaignsLoadError = true;
          }
          this.loading = false;
        })
      ]);
  }


  searchAdminCampaigns() {
    this.loading = true;
    this.emailFound = '';
    const requestBinfluEmails = {
      'client_username': environment.binflu_emails,
      'only_executed': this.adminSearchCampaignsFromEmail.controls['executed_checkbox'].value,
    };
    this.adminSearchCampaignsFromEmail.controls.executed_checkbox.setValue(false);
    this.subscriptions.push(
      ...[
        this._campaignsService.getBinfluCampaigns(requestBinfluEmails).subscribe(result => {
          this.adminCampaignsSearched = true;
          this.campaignsForShow = result.body;
          this.campaignsForShow = this.campaignsForShow.slice();
          this.campaigns = this.campaignsForShow;
          this.loading = false;
          this.campaignsLoadError = false;
        }, error => {
          if ( error.error.message === 'User not found.' ) {
            this.snackBar.openFromComponent(CustomSnackbarComponent, {
              data: { message: 'User not found', type: 'error' },
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: 'error-snackbar',
            });
          } else {
            this.errorMessage = 'Oops something went wrong loading the campaigns, please contact with us or try again more later';
            this.campaigns = [];
            this.campaignsLoadError = true;
          }
          this.loading = false;
        })
      ]);
  }
}
