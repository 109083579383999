import {Component, Input, OnInit} from '@angular/core';
import {ModalConfirmationSendPreviousEmailComponent} from '../../modals/modal-confirmation-send-previous-email/modal-confirmation-send-previous-email.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-preview-email-influencer',
  templateUrl: './preview-email-influencer.component.html',
  styleUrls: ['./preview-email-influencer.component.scss'],
})
export class PreviewEmailInfluencerComponent implements OnInit {

  @Input() monetary_compensation;
  @Input() username;
  @Input() brand_name;
  @Input() date_start;
  @Input() date_end;
  @Input() hashtags;
  @Input() mentions;
  @Input() remuneration;
  @Input() stories_url;
  @Input() network;
  @Input() description;
  @Input() comment;
  @Input() language_for_emails;
  @Input() numPosts;
  @Input() numStories;
  @Input() numYoutubeVideos;
  @Input() numYoutubeMentions;
  @Input() numTiktokVideos;
  @Input() show_stories_url;
  @Input() campaign_id;
  @Input() influencer_id;
  @Input() activateSendProposalsButton;
  @Input() groupName;
  @Input() currency_type;
  @Input() currency_symbol;


  constructor(private dialog: MatDialog) {}

  ngOnInit() {}


  sendPreviousEmail() {
    this.dialog.open(ModalConfirmationSendPreviousEmailComponent, {
      width: '500px',
      height: 'auto',
      data: {
        campaignId: this.campaign_id,
        influencerId: this.influencer_id,
      }
    });
  }
}
