import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor() {}

  getTheme() {
    const primary = getComputedStyle(document.documentElement).getPropertyValue(
      '--primary-color'
    );
    const primaryLighten = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--primary-lighten-color');
    const primaryDarken = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--primary-darken-color');
    const secondary = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--secondary-color');
    const secondaryLighten = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--secondary-lighten-color');
    const secondaryDarken = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--secondary-darken-color');
    const audienceLighten = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--audience-primary-color');
    const audienceDarken = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--audience-secondary-color');
    const gradientPublic = getComputedStyle(
        document.documentElement
    ).getPropertyValue('--gradient-public');
    return {
      primary,
      primaryLighten,
      primaryDarken,
      secondary,
      secondaryLighten,
      secondaryDarken,
      audienceLighten,
      audienceDarken,
      gradientPublic,
    };
  }
}
