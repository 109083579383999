export const form_tooltips = {
    start_date: 'This is the day where the campaign starts. Influencers can begin to post in this date. The influencer will receive ' +
        'this information.',
    early_start_date: 'Your campaign will start in less than 7 days. Take into account that the negociation with the influencers ' +
        'and the sending process of a physical gift if it is the case can delay the start of the campaign.',
    start_date_before_today: 'Your campaign can\'t start before the current time. Please change the start date.',
    end_date: 'This is the day where the campaign finishes. Influencers will be posting until this day. The influencer will ' +
        'receive this information.',
    end_date_extended: 'The end day has been extended. Influencers will be posting until this day. The influencer will receive ' +
        'this information.',
    brand_name: 'This is the name of the brand that will be promoted in the campaign. The influencer will receive this information.',
    brand_logo: 'This is the logo of the brand that will be promoted in the campaign. The influencer will receive this information.',
    send_physical_gift: 'In case that you need to send a physical gift to the influencers to be displayed in their content, mark “Yes”.' +
        ' Later on you will receive the address of the influencer to send them the gift.',
    total_campaign_cost: 'This is the total cost of the campaign.',
    investment_already_made : 'This is the amount of the campaign that you have already paid for. This number is different from zero ' +
        'when you have already paid once and you introduce new influencers.',
    to_be_paid : 'This is the amount of the campaign that has to be paid (taxes not yet included).',
    monetary_compensation: 'CPM - Influencers will receive a fixed monetary compensation for their promotion. Influencers will receive ' +
        'an email with a proposal that they will need to accept. You will be charged for this compensation plus the campaign report.',
    compulsory_elements: 'These are the elements that the influencers need to include in their content.',
    hashtags: 'These are the hashtags that the influencers need to include in their posts and stories. We use these hashtags in order ' +
        'to track the content that belongs to campaign. ',
    mentions: 'These are the mentions that the influencers need to include in their posts and stories.',
    influencer_automatic_replacement: 'By clicking this checkbox, Binfluencer will automatically replace influencers that reject your ' +
        'proposal for look-alike influencers until obtaining a similar reach and frequency.',
    campaign_general_description: 'Tell the influencer what they need to do or what you are looking for in this campaign. Be as ' +
        'specific as possible. All participating influencers will receive this campaign general description.',
    add_comment_influencer: 'Use these comments to tell the influencer specific things that only him or her has to know or do.',
    url: 'This is the URL where the influencers will redirect visitors to with their Instagram stories (Swipe up button).',
    under_price_warning_message: 'Lowering the recommended price results in less acceptance by the influencers.',
    under_price_critical_message: 'Influencers usually reject campaigns where the recommended price is reduced. ',
    language_for_emails: 'Select the language in which you want the invitation emails to be sent.',
    monetary_for_emails: 'Select the monetary in which you want the invitation emails to be sent.',
};
