import {Component, ElementRef, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {ReportService} from '../../../../services/report.service';

@Component({
  selector: 'app-report-share-modal',
  templateUrl: './report-share-modal.component.html',
  styleUrls: ['./report-share-modal.component.scss']
})
export class ReportShareModalComponent implements OnInit, OnDestroy {

  url = 'Your URL will show up here';

  copyUrl = false;
  loading = true;

  private subscriptions = [];

  constructor(@Inject(MAT_DIALOG_DATA) public report: any,
              public _reportService: ReportService,
              public snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.generateUrl();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  copyToClipboardUrl() {
    this.copyUrl = true;
    const new_html_element = document.createElement('textarea');
    new_html_element.style.position = 'fixed';
    new_html_element.style.left = '0';
    new_html_element.style.top = '0';
    new_html_element.style.opacity = '0';
    new_html_element.value = this.url;
    document.body.appendChild(new_html_element);
    new_html_element.focus();
    new_html_element.select();
    document.execCommand('copy');
    document.body.removeChild(new_html_element);
    this.openSnackBar('Copied to clipboard', 'Close');
    setTimeout( () => { this.copyUrl = false; }, 700 );
  }

  generateUrl() {
    this.subscriptions.push(
      this._reportService.shareReport(this.report.campaign_id).subscribe(res => {
        this.url = res.body.shared_url;
        this.loading = false;
      }, () => {
        this.openSnackBar('Oops something went wrong, please try again', 'Close');
        this.loading = false;
      })
    );

  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }


}
