// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  icmRedirect: 'https://www.campaigns-test.11posts.com',
  exampleCampaign: '52f3d34d-6157-404c-b6f6-cea6f00969c8',
  platform_id: '11posts',
  platform_logo: '/assets/png/11posts.png',
  platform_custom_validated_account: 'true',
  platform_icon: '/assets/img/ico/11posts.ico',
  paddle_vendor: 13106,
  websocketChatBrief: 'wss://h5qb46m7i8.execute-api.eu-west-1.amazonaws.com/test',
  binflu_emails: [
    'miguel.dfg@hotmail.com', 'ivan@binfluencer.es',
    'daniel.garcia@binfluencer.io', 'jorge.palencia@binfluencer.io'
  ],
  platform_register_validated_account:
      'true',
  platform_register_company_logo:
      '11posts',
  aws_resources: {
    // Region for AWS Resources
    region: 'eu-west-1',

    // API key
    api_key: 'tADbyYfwDGIl6Rh8UweV4o1YF3E4wzs3ba39nTH6',

    // Cognito user pool
    cognito: {
      identity_pool_id: 'eu-west-1:8f1106b9-7c2f-421e-8463-707d8b738816',
      user_pool_id: 'eu-west-1_m9eZya3jn',
      client_id: '2aduma4d9dstqa2gl6gu2cgved',
      endpoint: 'https://cognito-idp.eu-west-1.amazonaws.com',
      identity_endpoint: 'cognito-identity.eu-west-1.amazonaws.com',
    },

    // Lambda endpoint
    lambda_endpoint: {
      api:
        'https://api-test.binfluencer.io/',
    },

    // S3
    s3: {
      endpoint: 'https://s3-eu-west-1.amazonaws.com/',
      buckets: {
        profile_image: 'test-pax-customer-profile-image',
        company_logo: 'test-pax-brand-logo',
        presentation_logo: 'test-pax-presentation-logo',
        inspirational_pictures: 'test-pax-inspirational-pictures',
        report_insight_images: 'test-pax-report-insight-images',
        proposals: 'test-pax-progress-proposals',
        stories: 'test-pax-stories-instagram',
        dashboards_logo: 'test-pax-dashboard-logo',
        user_group_logo: 'test-pax-company-logo-image',
      },
    },
  },
  intercom: {
    config: {
      appId: 'oljxdm76',
      updateOnRouterChange: true,
    },
  },
  permissions: {
    search: ['VIEW_SEARCH'],
    influencer: ['VIEW_SEARCH'],
    influencerDetails: ['VIEW_SEARCH'],
    campaigns: ['LIST_CAMPAIGNS', 'VIEW_CAMPAIGN'],
    campaignDetails: ['LIST_CAMPAIGNS', 'VIEW_CAMPAIGN'],
    brief: ['VIEW_BRIEF'],
    progress: ['VIEW_PROGRESS'],
    report: ['VIEW_REPORT'],
    admin: ['ADMIN_ACCESS_OTHER_CAMPAIGNS', 'ADMIN_ACCOUNT_MANAGEMENT', 'ADMIN_ACTIVE_CAMPAIGNS', 'ADMIN_HEADER', 'ADMIN_LIST_USERS', 'ADMIN_REQUESTS'],
  },
};
