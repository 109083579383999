import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-info-update-influencers',
  templateUrl: './modal-info-update-influencers.component.html',
  styleUrls: ['./modal-info-update-influencers.component.scss']
})
export class ModalInfoUpdateInfluencersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
