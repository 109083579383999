import {Component, OnInit} from '@angular/core';
import * as selectorsAuth from '../../../store/auth/authentication.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../store';
import {AuthenticatedUser} from '../../../classes/authenticated-user-class';
import {AmplifyService} from 'aws-amplify-angular';
import {environment} from '../../../../environments/environment';
import {selectAppViewPort} from '../../../store/app/app.selectors';
import {ModalPlansComponent} from '../../../features/modal-plans/components/modal-plans/modal-plans.component';
import {MatDialog} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})

export class AccountSettingsComponent implements OnInit {

  upload_image_spinner = false;


  upload_error_message = '';
  planNameShown = '';
  email = '';
  company_name = '';
  user_sub = '';
  viewport = '';
  url_image = 'n/a';
  plaftormName = environment.platform_id;

  cancellation_effective_date = '';
  paused_from = '';
  plan_to_reactivate = '';

  tapSelected = 'editProfile';
  authenticated_user: AuthenticatedUser;


  constructor(
      private _amplifyService: AmplifyService,
      private store: Store<State>,
      private dialog: MatDialog,
      private route: ActivatedRoute,
      public router: Router
  ) {
    this.getAllAttributes();
  }

  ngOnInit() {
    this.store.select(selectAppViewPort).subscribe(result => {
      this.viewport = result;
    });
    this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
      this.planNameShown = user.PLAN_NAME_SHOWN;
      this.cancellation_effective_date = user.cancellation_effective_date;
      this.paused_from = user.paused_from;
      this.plan_to_reactivate = user.plan_to_reactivate_name;
    });
    // Accede a los parámetros de la URL
    this.route.params.subscribe(params => {
    // Comprueba si el parámetro 'invoices' está presente y su valor
    const invoicesParam: boolean = params['invoices'] === 'true';
    
    // Activa la función según el valor del parámetro 'invoices'
    if (invoicesParam) {
      this.tapSelected = 'viewInvoices';
    }
  });
  }

  async getAllAttributes() {
    const user = await this._amplifyService.auth().currentAuthenticatedUser({ bypassCache: true });
    this.authenticated_user = new AuthenticatedUser(user);
    if (this.authenticated_user.attributes['picture'] !== undefined) {
      this.url_image = this.getUrlImageWithTime(this.authenticated_user.getAttribute('picture'));
    }
    this.user_sub = this.authenticated_user.getAttribute('sub');
    this.email = this.authenticated_user.getAttribute('email');
    this.company_name = this.authenticated_user.getAttribute('custom:company_name');
  }

  getUrlImageWithTime(url_image) {
    if (url_image !== 'n/a') {
      return url_image + '?ts=' + new Date().getTime();
    } else {
      return url_image;
    }
  }


  uploadImage(fileInput: any): void {
    this.upload_image_spinner = true;
    this.upload_error_message = '';
    const file = fileInput.target.files[0];
    if (file.size <= 5242880) {
      if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg') {
        const extension = this.getExtensionFromImage(file.type);
        const config = {
          bucket: environment.aws_resources.s3.buckets.profile_image,
          region: environment.aws_resources.region,
          level: 'public',
          customPrefix: {
            public: ''
          },
        };
        this._amplifyService.storage().vault.put(this.user_sub + extension, file, config).then(() => {
          const url = 'https://s3-eu-west-1.amazonaws.com/' + config.bucket + '/' + this.user_sub + extension;
          this.saveProfileImageUrlInCognito(url);
        }).catch(() => {
          this.upload_error_message = 'Oops, something went wrong. Please try again later.';
          this.upload_image_spinner = false;
        });
      } else {
        this.upload_error_message = 'Unsupported file type. Supported file types are: jpg, jpeg and png';
        this.upload_image_spinner = false;
      }
    } else {
      this.upload_error_message = 'The file exceeds 5MB';
      this.upload_image_spinner = false;
    }
  }


  saveProfileImageUrlInCognito(url) {
    const profile_image_attribute = {
      picture: url
    };
    this._amplifyService.auth().currentAuthenticatedUser({ bypassCache: true }).then(user => {
      this._amplifyService.auth().updateUserAttributes(user, profile_image_attribute).then(() => {
        this.getAllAttributes().then(() => {
          this.upload_image_spinner = false;
        });
      });
    });
  }



  getExtensionFromImage(file_type: string): string {
    return file_type.replace('image/', '.');
  }


  changeTapSelected(tapName) {
    const canCancel = 
    this.plaftormName === '11posts' &&
    this.planNameShown !== 'FREE' &&
    this.cancellation_effective_date === undefined &&
    this.paused_from === undefined &&
    this.plan_to_reactivate === undefined;  
    if (tapName === 'choosePlan') {
      this.dialog
           .open(ModalPlansComponent, {
             width: '1400px',
             height: '900px',
             panelClass: 'container-modal-plans',
             autoFocus: false,
             data: {
              myAccount: canCancel,
             }
           })
           .afterClosed();
    } else {
      this.tapSelected = tapName;
    }
  }
}
