import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatDialog, MatSnackBarRef} from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent implements OnInit {


  constructor(
      @Inject(MAT_SNACK_BAR_DATA) public data: any,
      private snackbarRef: MatSnackBarRef<CustomSnackbarComponent>,
      private dialog: MatDialog,
      private router: Router
  ) { }

  ngOnInit() {}

  dismissSnackbar() {
    this.snackbarRef.dismiss();
  }

  
  getBonus() {
    this.snackbarRef.dismiss();
    this.dialog.closeAll();
    this.router.navigate(['/app/account/']);
  }
}
