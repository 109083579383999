import {Injectable} from '@angular/core';
import {InfluencerProfileService} from 'src/app/services/influencer-profile.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {Influencer} from 'src/app/types/influencer/influencer';
import {InfluencersService as InfluencersCardsService} from 'src/app/services/influencers-cards.service';
import {of} from 'rxjs';
import {InfluencerAudience} from 'src/app/types/influencer/influencer-audience';
import isEmpty from 'lodash/isEmpty';
import 'rxjs-compat/add/observable/empty';
import {Store} from '@ngrx/store';
import {State} from '../index';

// import * as actions from './influencers.actions';

@Injectable({
  providedIn: 'root'
})
export class InfluencersService {


  constructor(
    private influencerService: InfluencersCardsService,
    private profileService: InfluencerProfileService,
  ) { }

  getInfluencers(params) {
    return this.influencerService.getInfluencers(params).pipe(
      map(response => {
        if (response.body) {
          return {
            hits: response.body.hits.value,
            last_page: response.body.last_page,
            hits_pagination: response.body.hits_pagination,
            tags: response.body.tags,
            influencers: response.body.influencers.map(this.parseInfluencerCard)
          };
        }
        return {
          hits: 0,
          last_page: false,
          hits_pagination: 0,
          tags: {},
          influencers: []
        };
      }),
    );
  }

  loadInfluencer(id, network) {
    let influencerSaved = null;
    return this.profileService.getInfluencerProfileFromElastic(id, network).pipe(
      map(response => this.parseInfluencerProfile(response.body)),
      tap(influencer => {
        influencerSaved = influencer;
      }),
      switchMap(influencer => {
        if (influencer.type === 'instagram') {
          return of(influencer);

          // esto lo comento, es la llamada antigua hacia instagram que se sustituyo por nuestrao get instragram user data
          // return this.loadInstagrammer(influencer.nick).pipe(
          //   map(instagrammer => {
          //     if (instagrammer.id !== influencer.id) {
          //       this.profileService.analyzeInfluencer( influencer.nick, influencer.id, influencer.type, 'False').subscribe();
          //       return Object.assign({}, influencerSaved, instagrammer, { hasChangedName: true });
          //     }
          //     if (instagrammer.id === null) {
          //       return Object.assign({}, null, { notInDatabase: true });
          //     }
          //     return Object.assign({}, influencerSaved, instagrammer);
          //   }),
          //   catchError(_ => of(Object.assign({}, influencer))),
          // );
        } else if (influencer.type === 'tiktok') {
          return of(influencer);
          // return this.loadTiktoker(influencer.nick).pipe(
          //   map(tiktoker => {
          //     // if (tiktoker.id !== influencer.id) {
          //     //   return Object.assign({}, influencerSaved, tiktoker, { hasChangedName: true });
          //     // }
          //     return Object.assign({}, influencerSaved, tiktoker);
          //   })
          // );
        } else if (influencer.type === 'youtube') {
          return of(influencer);
        } else {
          return of(influencer);
        }
      })
    );
  }

  searchInstagrammerByNick(nick) {
    // todo miguel esto lo he comentando con el nuevo search, ver realmente si hace algo con el cacheado pero en otras redes no lo hace.
    return this.profileService.getInfluencerProfileFromElastic(nick, 'instagram').pipe(
        map(response => this.parseInfluencerProfile(response.body))
    );
    // let savedInfluencer = null;
    // return this.loadInstagrammer(nick).pipe(
    //   tap(influencer => {
    //     (savedInfluencer = influencer);
    //   }),
    //   switchMap(influencer => this.profileService.getInfluencerProfileFromElastic(influencer.id, 'instagram').pipe(
    //     map(response => Object.assign({}, this.parseInfluencerProfile(response.body), savedInfluencer)),
    //     catchError(_ => of(Object.assign({}, savedInfluencer, { notInDatabase: true }))),
    //   ))
    // );
  }

  searchInstagrammerByUsername(nick) {
    // todo miguel esto lo he comentando con el nuevo search, ver realmente si hace algo con el cacheado pero en otras redes no lo hace.
    return this.profileService.getInfluencerProfileFromElastic(nick, 'instagram').pipe(
        map(response => this.parseInfluencerProfile(response.body))
    );
    // let savedInfluencer = null;
    // return this.loadInstagrammer(nick).pipe(
    //     tap(influencer => {
    //       (savedInfluencer = influencer);
    //     }),
    //     switchMap(influencer => this.profileService.getInfluencerProfileFromUsername(influencer.id, 'instagram').pipe(
    //         map(response => Object.assign({}, this.parseInfluencerProfile(response.body), savedInfluencer)),
    //         catchError(_ => of(Object.assign({}, savedInfluencer, { notInDatabase: true }))),
    //     ))
    // );
  }

  searchTiktokerByNick(nick) {
    // esto esta comentado por que no llamamos a tiktok todavia.
    // let savedInfluencer = null;
    // return this.loadTiktoker(nick).pipe(
    //   tap(influencer => (savedInfluencer = influencer)),
    //   switchMap(influencer => this.profileService.getInfluencerProfileFromElastic(influencer.id, 'tiktok').pipe(
    //     map(response => Object.assign({}, this.parseInfluencerProfile(response.body), savedInfluencer)),
    //     catchError(_ => of(Object.assign({}, savedInfluencer, { notInDatabase: true })))
    //   ))
    // );
    return this.profileService.getInfluencerProfileFromElastic(nick, 'tiktok').pipe(
      map(response => this.parseInfluencerProfile(response.body))
    );
  }

  searchTiktokerByUsername(nick) {
    return this.profileService.getInfluencerProfileFromUsername(nick, 'tiktok').pipe(
        map(response => this.parseInfluencerProfile(response.body))
    );
  }

  searchYoutuberByChannel(channel) {
    return this.profileService.getInfluencerProfileFromElastic(channel, 'youtube').pipe(
      map(response => this.parseInfluencerProfile(response.body))
    );
  }


  searchYoutuberByUsername(channel) {
    return this.profileService.getInfluencerProfileFromUsername(channel, 'youtube').pipe(
        map(response => this.parseInfluencerProfile(response.body))
    );
  }

  newFullCrawl(username, id, type) {
    return this.profileService.newFullCrawl(username, id, type).pipe(
        map(response => this.parseInfluencerProfile(response.body))
    );
  }


  loadInstagrammer(username) {
    // return this.profileService.getInfluencerProfileFromInstagram(username).pipe(
    //   map(response => this.parseInstagrammer(response)),
    //   // si la llamada a instagram peta, llamamos a nuestro get_user_data_instagram
    //   catchError( () => {
    //     return this.profileService.getInfluencerProfileUserDataInstagram(username).pipe(
    //       map(resp => this.parseInstagrammer(resp.body)),
    //       catchError( () => {
    //         // vaya bien o mal, siempre se debe llamar a nuestro get_user_data, de modo
    //         // que aqui controlamos cuando va mal, entonces llammos a get_user_data
    //         return this.profileService.getInfluencerProfileFromElastic(username, 'instagram').pipe(
    //           map(respon => this.parseInfluencerProfile(respon.body)),
    //           // aqui deberiamos de controlar que pasa si nuestro get_user_data peta, devolvemos influencer como null
    //           // y decimos que no esta en nuestra base de datos para instagrammer not found
    //           // catchError(_ => of(Object.assign({}, null, { notInDatabase: true }))),
    //         );
    //       })
    //     );
    //   }),
    // );

    /// Esto es provisional mientras esta petando instagram, problemas CORS
    //   return this.profileService.getInfluencerProfileUserDataInstagram(username).pipe(
    //     map(resp => this.parseInstagrammer(resp.body)),
        // catchError( () => {
        //   return this.profileService.getInfluencerProfileFromElastic(username, 'instagram').pipe(
        //     map(respon => this.parseInfluencerProfile(respon.body)));
        // })
      // );
  }

  loadTiktoker(username) {
    return this.profileService.getInfluencerProfileFromTiktok(username).pipe(
      map(response => this.parseTiktoker(response))
    );
  }

  lightCrawl(influencer: Influencer, reponseUser = 'False') {
    const influencerId = influencer.type === 'youtube' ? influencer.username : influencer.id;
    if (influencer.type === 'tiktok') {
      return this.profileService.analyzeTiktokInfluencer(influencer.username, influencerId, influencer.type, reponseUser);
    } else {
      return this.profileService.analyzeInfluencer(influencer.username, influencerId, influencer.type, reponseUser);
    }
  }

  fullCrawl(influencer: Influencer) {
    return this.profileService.analyzeAudience(influencer.username, influencer.id, influencer.type);
  }

  private parseInfluencerCard(rawInfluencer) {
    // parseo del response del search influencers, miguel
    if (Object.keys(rawInfluencer).length > 0) {
      let response;
      response = {
        id: rawInfluencer.influencer_id,
        type: rawInfluencer.network,
        full_name: rawInfluencer.full_name,
        username: rawInfluencer.username,
        profile_pic_url: rawInfluencer.profile_pic_url,
        engagement_rate: rawInfluencer.engagement_rate,
        followed_by_count: rawInfluencer.followed_by_count,
        audience_card: rawInfluencer.audience_card,
        avg_views: rawInfluencer.avg_views,
        location: rawInfluencer.location,
        hide: rawInfluencer.hide,
        // location: [{
        //   accent_city: rawInfluencer.location !== '' ? rawInfluencer.location : '-'
        // }],
        crawlData: null,
        crawlError: false
      };
      return response;
    }
  }

  public parseInfluencerProfile(rawInfluencer): Influencer {
    let response: Influencer = null;
    // aqui hay que parsear el nuevo influencer lateral profile
    if (Object.keys(rawInfluencer.influencer).length > 0) {
      response = {
        id: rawInfluencer.influencer.influencer_id,
        type: rawInfluencer.influencer.network,
        age_group: rawInfluencer.influencer.age_group,
        avg_comments: rawInfluencer.influencer.avg_comments,
        comments_growth: rawInfluencer.influencer.comments_growth,
        in_favorites: rawInfluencer.influencer.in_favorites,
        avg_likes: rawInfluencer.influencer.avg_likes,
        avg_reels_plays: rawInfluencer.influencer.avg_reels_plays,
        avg_views: rawInfluencer.influencer.avg_views,
        views_growth: rawInfluencer.influencer.views_growth,
        brand_affinity: rawInfluencer.influencer.brand_affinity,
        description: rawInfluencer.influencer.description,
        engagement_rate: rawInfluencer.influencer.engagement_rate,
        engagement_rate_top: rawInfluencer.influencer.engagement_rate_top,
        followed_by_count: rawInfluencer.influencer.followed_by_count,
        followers_growth: rawInfluencer.influencer.followers_growth,
        full_name: rawInfluencer.influencer.full_name,
        fetch_timestamp: rawInfluencer.influencer.fetch_timestamp,
        gender_influencer: rawInfluencer.influencer.gender_influencer,
        growth: rawInfluencer.influencer.growth,
        hashtags: rawInfluencer.influencer.hashtags,
        interests: rawInfluencer.influencer.interests,
        is_verified: rawInfluencer.influencer.is_verified,
        language: rawInfluencer.influencer.language,
        last_post_plot: rawInfluencer.influencer.last_post_plot,
        social_contacts: rawInfluencer.influencer.social_contacts,
        likes_growth: rawInfluencer.influencer.likes_growth,
        location: rawInfluencer.influencer.location,
        country_location: rawInfluencer.influencer.country_location,
        country_thumbnail_url: rawInfluencer.influencer.country_thumbnail_url,
        lookalikes: rawInfluencer.influencer.lookalikes,
        media_count: rawInfluencer.influencer.media_count,
        mentions: rawInfluencer.influencer.mentions,
        paid_post_performance: rawInfluencer.influencer.paid_post_performance,
        posts_with_hidden_likes_percentage: rawInfluencer.influencer.posts_with_hidden_likes_percentage,
        price_per_post_plus_perc: rawInfluencer.influencer.price_per_post_plus_perc,
        price_per_story_plus_perc: rawInfluencer.influencer.price_per_story_plus_perc,
        price_per_mention_plus_perc: rawInfluencer.influencer.price_per_mention_plus_perc,
        price_per_video_plus_perc: rawInfluencer.influencer.price_per_video_plus_perc,
        price_per_tiktok_video_plus_perc: rawInfluencer.influencer.price_per_tiktok_video_plus_perc,
        profile_pic_url: rawInfluencer.influencer.profile_pic_url,
        username: rawInfluencer.influencer.username,
        audience: rawInfluencer.audience,
        invitation_mail: rawInfluencer.influencer.invitation_mail,
        sponsored_post: rawInfluencer.sponsored_post,
        popular_post: rawInfluencer.popular_post,
        est_impressions_posts: rawInfluencer.influencer.est_impressions_posts,
        est_impressions_stories: rawInfluencer.influencer.est_impressions_stories,
      };
    }
    return response;
  }

  // Aqui tengo que ver coomo considerar si vengo de instagram o de get_user_data_instagram
  // todo miguel controlar esto cuando hagamos el flujo de todas las llamadas.
  public parseInstagrammer(instagrammer): Influencer {
    // si la peticion viene de instagram directamente, deberiamos quitar el body, si es lambda lo mantenemos.
    if (instagrammer['graphql'].user.biography) {
      const id = instagrammer.graphql && instagrammer.graphql.user && instagrammer.graphql.user.id ? instagrammer.graphql.user.id : null;
      const influencer: any = {};
      if (id) {
        influencer.id = id;
      }
      let medias = [];
      if (instagrammer['graphql']) {
        if (instagrammer.graphql['user']) {
          influencer.type = 'instagram';
          influencer.name = instagrammer.graphql.user.full_name;
          // influencer.nick = instagrammer.graphql.user.username;
          influencer.isPrivate = instagrammer.graphql.user.is_private;
          /// comento este profilePicture por que estan dandonos un error blocked by response
          // influencer.profilePicture = instagrammer.graphql.user.profile_pic_url_hd;
          influencer.biography = instagrammer.graphql.user.biography;
          const rawMedias = instagrammer.graphql.user.edge_owner_to_timeline_media.edges;
          if (Array.isArray(rawMedias)) {
            medias = rawMedias.map(raw => {
              const node = raw.node;
              return {
                thumbnail: node.display_url,
                url: node.shortcode
              };
            });
          }
        }
      }
      influencer.medias = medias;
      return influencer;
    } else {
      // este else contempla que instagram no ha respondido entonces devolvemos algunos params de elastic
      const influencer: any = {};
      const id = instagrammer.graphql && instagrammer.graphql.user && instagrammer.graphql.user.id ? instagrammer.graphql.user.id : null;
      if (id) {
        influencer.id = id;
      }
      influencer.full_name = instagrammer.graphql.user.full_name;
      influencer.username = instagrammer.graphql.user.username;
      return influencer;
    }
  }

  private parseTiktoker(tiktoker): Influencer {
    const influencer: any = {};
    if (tiktoker.userInfo.user) {
      influencer.type = 'tiktok';
      influencer.id = 'tt_' + tiktoker.userInfo.user.id;
      influencer.name = tiktoker.userInfo.user.username;
      influencer.full_name = tiktoker.userInfo.user.full_name;
      influencer.profilePicture = tiktoker.userInfo.user.avatarThumb;
      influencer.biography = tiktoker.userInfo.user.signature;
    }
    return influencer;
  }

  private parseChartData(rawAudience, network): InfluencerAudience {
    if (isEmpty(rawAudience)) {
      return null;
    }
    const audience: Partial<InfluencerAudience> = {
      age: {
        anyInterval: null,
        menInterval: null,
        womenInterval: null
      }
    };
    if ('age' in rawAudience) {
      audience.age.anyInterval = rawAudience.age.age_interval_any.map(this.mapInterval);
      audience.age.menInterval = rawAudience.age.age_interval_men.map(this.mapInterval);
      audience.age.womenInterval = rawAudience.age.age_interval_women.map(this.mapInterval);
    }

    if ('audience_gender_percentage' in rawAudience) {
      audience.genderPercentage = {
        male: rawAudience.audience_gender_percentage.male_percentage,
        female: rawAudience.audience_gender_percentage.female_percentage
      };
    }

    if ('inactive_followers_percentage' in rawAudience) {
      audience.inactiveFollowers = rawAudience.inactive_followers_percentage;
    }

    if ('location' in rawAudience) {
      // Only instagram have cities locations audience
      if (network === 'instagram') {
        if ('audience_city_locations' in rawAudience.location) {
          audience.cities = rawAudience.location.audience_city_locations.map(element => {
            return {
              countryCity: element.country_city,
              name: element.name,
              value: element.value
            };
          });
        }
      } else {
        if ('audience_languages' in rawAudience) {
          audience.languages = rawAudience.audience_languages;
        }
      }
      // All networks have country locations audience
      if ('audience_country_locations' in rawAudience.location) {
        audience.countries = rawAudience.location.audience_country_locations.map(element => {
          return {
            name: element.name,
            value: element.value
          };
        });
      }
    }
    return audience as InfluencerAudience;
  }

  private mapInterval(element) {
    return {
      count: element.count,
      agesSume: element.ages_sum,
      percentage: element.percentage,
      halfwayAge: element.halfway_age
    };
  }
}
