import {createFeatureSelector, createSelector} from '@ngrx/store';
import {appKey, AppState} from './app.reducer';

export const selectApp = createFeatureSelector<AppState>(appKey);

export const selectAppViewPort = createSelector(
  selectApp,
  (state: AppState) => state.viewPort
);


export const selectUserGroup = createSelector(
    selectApp,
    state => state.userGroup
);



export const selectLanguage = createSelector(
    selectApp,
    state => state.language
);

export const selectCurrency = createSelector(
    selectApp,
    state => state.currency
);

export const selectCurrencySymbol = createSelector(
    selectApp,
    state => state.currencySymbol
);
