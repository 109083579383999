import { Injectable } from '@angular/core';
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { AmplifyService } from 'aws-amplify-angular';

@Injectable({
  providedIn: 'root',
})
export class RefreshSessionService {
  constructor(private _amplifyService: AmplifyService) {}

  refreshSession() {
    this._amplifyService
      .auth()
      .currentAuthenticatedUser()
      .then(user => {
        this._amplifyService
          .auth()
          .userSession(user)
          .then(session => {
            user.refreshSession(
              session.getRefreshToken(),
              (error, newSession) => {
                if (error) {
                } else {
                  user.setSignInUserSession(new CognitoUserSession(newSession));
                }
              }
            );
          });
      });
  }
}
