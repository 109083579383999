import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {InfluencersService} from '../../../store/influencers/influencers.service';
import {Subscription} from 'rxjs';
import {Influencer} from '../../../types/influencer/influencer';
import {InfluencerProfileService} from '../../../services/influencer-profile.service';
import {CampaignsModalComponent} from '../campaigns/campaigns-modal/campaigns-modal.component';

@Component({
    selector: 'app-influencer-profile',
    templateUrl: './influencer-profile.component.html',
    styleUrls: ['./influencer-profile.component.scss'],
})
export class InfluencerProfileComponent implements OnInit, OnDestroy {
    loadingProfile: boolean;
    loadingFullCrawl: boolean;
    errorFullCrawl: boolean;
    // authenticated_user: AuthenticatedUser;
    currency_type: string;
    id: string;
    network: string;
    username: string;
    errorFullCrawlMessage: string;
    // theme: any;
    influencer: Influencer;

    private subscriptions: Subscription[] = [];


    @Output() add: EventEmitter<Influencer> = new EventEmitter();


    constructor(
        public _influencerService: InfluencersService,
        public _influencerProfileService: InfluencerProfileService,
        public activatedRoute: ActivatedRoute,
        public router: Router,
        public snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog
    ) {
        // this.getAllAttributes();
    }


    ngOnInit() {
        this.loadingProfile = true;
        this.activatedRoute.queryParams.subscribe(params => {
            this.id = params['id'];
            if (params.hasOwnProperty('username')) {
                this.username = params['username'];
            }
            this.network = params['network'];
            this.getInfluencerUserData(this.id, this.network, this.username);
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    fullCrawlInfluencer(username, id, type) {
        this.loadingFullCrawl = true;
        this._influencerService.newFullCrawl(username, id, type).subscribe( response => {
            this.influencer = response;
            this.loadingFullCrawl = false;
            this.errorFullCrawl = false;
            this.cdr.detectChanges();
        }, error => {
            this.loadingFullCrawl = false;
            this.errorFullCrawl = true;
            this.errorFullCrawlMessage = error.error.message;
            this.cdr.detectChanges();
        });
    }


    getInfluencerUserData(influencerId, network, username): void {
        this.subscriptions.push(
            this._influencerProfileService.getInfluencerProfileFromElastic(influencerId, network).subscribe( response => {
                if (response.status  === 204) {
                    this.loadingProfile = false;
                } else {
                    this.influencer = this._influencerService.parseInfluencerProfile(response.body);
                    this.loadingProfile = false;
                }
            }, () => {
                this.loadingProfile = false;
                this.openSnackBar('There was an error loading the page. PLease try again.', 'Close');
            })
        );
    }



    openSnackBar(message, action) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    scrollTo(element: any): void {
        (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }

    /**
     * Method that opens the campaigns list modal
     * @param insta_id It passes the instagram_id from the specific card for adding it to a campaign
     */
    openCampaignsDialog(influencer): void {
        this.dialog.open(CampaignsModalComponent, {
            width: '790px',
            height: '350px',
            panelClass: 'remove-padding',
            autoFocus: false,
            data: {
                instagram_id: influencer.id,
                network: influencer.type,
                full_name: influencer.full_name,
                profile_pic_url: influencer.profile_pic_url,
                username: influencer.username
            },
        });
    }

}
