import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-modal-invitation-influencers-warning',
  templateUrl: './modal-invitation-influencers-warning.component.html',
  styleUrls: ['./modal-invitation-influencers-warning.component.scss']
})
export class ModalInvitationInfluencersWarningComponent implements OnInit {

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }


  sendProposals() {

  }

}
