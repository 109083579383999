import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InfluencersService} from '../../../../services/influencers-cards.service';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-modal-share-influencer-generate-link',
  templateUrl: './modal-share-influencer-generate-link.component.html',
  styleUrls: ['./modal-share-influencer-generate-link.component.scss']
})
export class ModalShareInfluencerGenerateLinkComponent implements OnInit, OnDestroy {

  loading = false;
  copyUrl = false;
  url = '';

  private subscriptions = [];

  constructor(private snackBar: MatSnackBar,
              private _userService: InfluencersService,
              @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.generateShareLink();

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  copyToClipboardUrl() {
    this.copyUrl = true;
    const new_html_element = document.createElement('textarea');
    new_html_element.style.position = 'fixed';
    new_html_element.style.left = '0';
    new_html_element.style.top = '0';
    new_html_element.style.opacity = '0';
    new_html_element.value = this.url;
    document.body.appendChild(new_html_element);
    new_html_element.focus();
    new_html_element.select();
    document.execCommand('copy');
    document.body.removeChild(new_html_element);
    this.openSnackBar('Copied to clipboard', 'Close');
    setTimeout( () => { this.copyUrl = false; }, 700 );
  }


  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  generateShareLink() {
    this.subscriptions.push(
        ...[
          this._userService.shareInfluencerGenerateLink(this.data.influencerId).subscribe( response => {
            this.url = response.body.influencer_shared_url;
            this.loading = false;
          }, () => {
            this.url = 'Error, try again';
            this.openSnackBar('Oops something went wrong, please try again', 'Close');
            this.loading = false;
          })
        ]);
  }

}
