import {Component, Inject, OnInit} from '@angular/core';
import {CampaignsService} from '../../../../../../services/campaigns.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-confirm-action-influencer-state',
  templateUrl: './confirm-action-influencer-state.component.html',
  styleUrls: ['./confirm-action-influencer-state.component.css']
})
export class ConfirmActionInfluencerStateComponent implements OnInit {
  loading = false;

  constructor(private _campaignsService: CampaignsService,
              public dialogRef: MatDialogRef<ConfirmActionInfluencerStateComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}

  removeInfluencer() {
    this.loading = true;
    this._campaignsService.removeInfluencerFromCampaign(this.data.campaign_id, this.data.influencer_id).subscribe( result => {
      this.dialogRef.close(result);
    }, error => {
      this.dialogRef.close(error);
    });
  }

  acceptInfluencer() {
    this.loading = true;
    this._campaignsService.acceptInfluencer(this.data.campaign_id, this.data.influencer_id).subscribe( result => {
      this.dialogRef.close(result);
    }, error => {
      this.dialogRef.close(error);
    });
  }

}
