import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {AmplifyService} from 'aws-amplify-angular';
import {switchMap} from 'rxjs/operators';
import 'rxjs/add/operator/do';
import {environment} from '../../environments/environment';
import {AuthenticatedUser} from '../classes/authenticated-user-class';
import {Router} from '@angular/router';
import {Intercom} from 'ng-intercom';

@Injectable({
  providedIn: 'root',
})
export class PaxInterceptorService implements HttpInterceptor {
  token;
  user: AuthenticatedUser;

  api = environment.aws_resources.lambda_endpoint.api;

  publicComponents = [
    this.api + 'authentication',
    this.api + 'last-mille-influencers',
    this.api + 'campaigns/shared',
    this.api + 'progress/shared',
    this.api + 'reports/shared/',
    this.api + 'campaigns-dashboard/shared/',
    this.api + 'influencer/shared',
    this.api + 'reports/send_to_upsert_report_data/',
    this.api + 'plans/get_public_user_group/',
    this.api + 'account-management/pre_register',
    this.api + 'account-management/register',
    this.api + 'influencer/upgrade_campaign',
    this.api + 'influencer/shared/get_post_pictures/',
    this.api + 'reports-influencers/shared',
    'https://www.instagram.com/',
    // 'https://www.tiktok.com/',
      '/assets/i18n/en.json',
      '/assets/i18n/es.json',
    this.api + 'account-management/get_list_currencies'
  ];

  constructor(
    private _amplifyService: AmplifyService,
    private router: Router,
    private intercom: Intercom
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // View the public components for let the http request
    for (let index = 0; index < this.publicComponents.length; index++) {
      if (req.url.startsWith(this.publicComponents[index])) {
        return next.handle(req);
      }
    }
    if (
      !(
        req.url.startsWith(
          environment.aws_resources.lambda_endpoint.api + 'influencer/'
        ) && req.url.includes('shared')
      )
    ) {
      return from(this.getSession()).pipe(
        switchMap(session => {
          if (
            session.getIdToken().decodePayload()['custom:validated_account'] ===
            'false'
          ) {
            this.intercom.shutdown();
            this._amplifyService
              .auth()
              .signOut()
              .then(() => {
                this.router.navigate(['/auth/login'], {
                  queryParams: { validated_account: false },
                });
              });
          }
          const QUERY = req.clone({
            setHeaders: {
              'Content-Type': 'application/json',
              'x-api-key': environment.aws_resources.api_key,
              Authorization: session.getIdToken().getJwtToken(),
            },
          });
          return next.handle(QUERY);
        })
      );
    } else {
      return next.handle(req);
    }
  }

  getSession() {
    return this._amplifyService.auth().currentSession();
  }
}
