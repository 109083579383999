import {influencerAdapter, influencerKey, InfluencerState} from './influencers.reducer';


import {createFeatureSelector, createSelector} from '@ngrx/store';

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = influencerAdapter.getSelectors();

export const selectInfluencer = createFeatureSelector<InfluencerState>(influencerKey);

// select the array of book ids
export const selectInfluencerIds = createSelector(
  selectInfluencer,
  selectIds
);

// select the dictionary of book entities
export const selectInfluencerEntities = createSelector(
  selectInfluencer,
  selectEntities
);

// select the array of books
export const selectInfluencerArray = createSelector(
  selectInfluencer,
  selectAll
);

// select the total book count
export const selectInfluencerTotal = createSelector(
  selectInfluencer,
  selectTotal
);

export const selectLoadingProfile = createSelector(
  selectInfluencer,
  state => state.loadingProfile
);

