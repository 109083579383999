import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InfluencerProfileService} from '../../../../services/influencer-profile.service';
import {MatSidenav} from '@angular/material/sidenav';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {ThemeService} from '../../../../services/theme.service';
import {InfluencersService} from '../../../../store/influencers/influencers.service';
import {Subscription} from 'rxjs';
import {ProfileNeedUpdateModalComponent} from '../detail-campaign/modals/profile-need-update-modal/profile-need-update-modal.component';

@Component({
  selector: 'app-sidenav-influencer-profile',
  templateUrl: './sidenav-influencer-profile.component.html',
  styleUrls: [
    './sidenav-influencer-profile.component.scss',
  ]
})
export class SidenavInfluencerProfileComponent implements OnInit, OnDestroy {
  @Input() binded_influencer_data: any;
  @Input() componentNameCalled: string;
  @Input() binded_sidenav: MatSidenav;
  @Input() currencyType: string;
  @Input() currency_symbol: string;

  isPublic: boolean;
  loadingProfile: boolean;
  network: string;

  influencer: any;
  theme: any;

  currency_type;

  private subscriptions: Subscription[] = [];

  constructor(
    private _profileInfluencerProfileService: InfluencerProfileService,
    private _influencerService: InfluencersService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    themeService: ThemeService) {
    this.theme = themeService.getTheme();
  }



  ngOnInit() {
    this.getInfluencerProfile();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getInfluencerProfile() {
    this.loadingProfile = true;
    this.network = this.binded_influencer_data.network;
    // getInfluencer DETAIL-CAMPAIGN
    if (this.binded_influencer_data.origin === 'campaign_plan') {
      this.currency_type = this.binded_influencer_data.currency_type;
      this.currency_symbol = this.binded_influencer_data.currency_symbol;
        this.isPublic = false;
        this.componentNameCalled = 'lateralSearchProfile';
        this.subscriptions.push(
          this._profileInfluencerProfileService.getInfluencerProfileFromElastic(this.binded_influencer_data.influencer_id,
            this.network, this.binded_influencer_data.campaign_id).subscribe(response => {
          if (response.status.toString() === '200') {
            this.influencer = this._influencerService.parseInfluencerProfile(response.body);
            this.influencer['lateralProfile'] = true;
            this.loadingProfile = false;
          }  else {
            this.errorGetInfluencerCloseSidenav();
          }
        }, error => {
          this.errorGetInfluencerCloseSidenav();
            if (error.status.toString() === '426') {
              this.binded_sidenav.close();
              this.openModalProfileNeedUpdate();
            }
        })
      );

      // getInfluencer SHARE-CAMPAIGN
    } else if (this.binded_influencer_data.origin === 'share_campaign_plan') {
      this.isPublic = true;
      this.componentNameCalled = 'shareDetailCampaign';
      this.subscriptions.push(
          this._profileInfluencerProfileService.getSharedInfluencerProfileFromElastic(this.binded_influencer_data.influencer_id,
              this.network, this.binded_influencer_data.shared_campaign_plan_hash).subscribe(response => {
            if (response.status.toString() === '200') {
              this.influencer = this._influencerService.parseInfluencerProfile(response.body);
              this.influencer['lateralProfile'] = true;
              this.influencer['shared_campaign_plan_hash'] = this.binded_influencer_data.shared_campaign_plan_hash;
              this.loadingProfile = false;
            }  else {
              this.errorGetInfluencerCloseSidenav();
            }
          }, error => {
            if (error.status.toString() === '426') {
              this.binded_sidenav.close();
              this.openModalProfileNeedUpdate();
            }
            this.errorGetInfluencerCloseSidenav();
          })
      );
      // this.subscriptions.push(
      //     this._profileInfluencerProfileService.getSharedInfluencerProfileFromElastic(this.binded_influencer_data.influencer_id, this.network, this.binded_influencer_data.shared_campaign_plan_hash).subscribe(response => {
      //     if (response.status.toString() === '200') {
      //       this.influencer = this._influencerService.parseInfluencerProfile(response.body);
      //       this.influencer['lateralProfile'] = true;
      //       this.loadingProfile = false;
      //     } else {
      //       this.errorGetInfluencerCloseSidenav();
      //     }
      //   }, () => {
      //     this.errorGetInfluencerCloseSidenav();
      //   })
      // );
    }
  }

  openModalProfileNeedUpdate(): void {
    let data;
    if (this.isPublic) {
      data  = {
        campaignId: this.binded_influencer_data.shared_campaign_plan_hash,
        isPublic: this.isPublic,
        influencerId: this.binded_influencer_data.influencer_id,
      };
    } else {
      data  = {
        campaignId: this.binded_influencer_data.campaign_id,
        isPublic: this.isPublic,
        influencerId: this.binded_influencer_data.influencer_id,
      };
    }
    this.dialog.open(ProfileNeedUpdateModalComponent, {
      width: '762px',
      height: '384px',
      autoFocus: false,
      data: data,
      panelClass: 'border-container-modal'
    });
  }


  errorGetInfluencerCloseSidenav() {
    this.binded_sidenav.close();
    this.openSnackBar('There was an error loading the profile.', 'OK');
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  scrollTo(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
