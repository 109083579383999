import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ReportService} from '../../../../../services/report.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-report-influencer-content-modal',
  templateUrl: './report-influencer-content-modal.component.html',
  styleUrls: ['./report-influencer-content-modal.component.scss']
})
export class ReportInfluencerContentModalComponent implements OnInit {
  influencer: any;
  path_id: string;
  currency_type: string;
  is_share: boolean;

  loading = true;

  report: any;
  timeForUpdate;
  showHoursLastUpdate;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ReportInfluencerContentModalComponent>,
              private _reportService: ReportService,
              public snackBar: MatSnackBar) {
    this.currency_type = data.currency_type;
    this.is_share = data.is_share;
  }

  ngOnInit() {
    this.getInfluencerReportData();
  }

  getInfluencerReportData() {
    if (!this.data.is_share) {
      this._reportService.getInfluencerReportData(this.data.campaign_id, this.data.influencer_id).subscribe(result => {
        if (result['status'].toString() === '200') {
          this.influencer = result['body'];
          const todayDate = Date.now();
          const today =  Math.floor(new Date(todayDate).getTime() / 1000);
          const lastUpdate = new Date(this.influencer.crawl_medias_ts).getTime();
          const diffMiliSeconds = today - lastUpdate;
          const horas = Math.abs(Math.floor(diffMiliSeconds / 3600));
          const minutos = Math.abs(Math.floor((diffMiliSeconds % 3600) / 60));
          this.timeForUpdate = horas + ':' + minutos;
          this.showHoursLastUpdate = horas <= 24;
          this.filteringInfluencerMedia();
          this.loading = false;
        }
      }, () => {
        this.openSnackBar('There was an error loading the influencer data. Please try again.', 'Close');
        this.dialogRef.close();
      });
    } else {
      this._reportService.getSharedInfluencerReportData(this.data.campaign_id, this.data.influencer_id).subscribe(result => {
        if (result['status'].toString() === '200') {
          this.influencer = result['body'];
          this.filteringInfluencerMedia();
          this.loading = false;
        }
      }, () => {
        this.openSnackBar('There was an error loading the influencer data. Please try again.', 'Close');
        this.dialogRef.close();
      });
    }
  }

  filteringInfluencerMedia() {
    if (this.influencer.network === 'instagram') {
      const aux_posts = [];
      this.influencer['posts'].forEach((post) => {
        if (post['deleted'] === false) {
          aux_posts.push(post);
        }
      });
      this.influencer['posts'] = aux_posts;

    } else if (this.influencer.network === 'youtube') {
      const aux_videos = [];
      this.influencer['videos'].forEach((video) => {
        if (video['deleted'] === false) {
          aux_videos.push(video);
        }
      });
      this.influencer['videos'] = aux_videos;
    } else if (this.influencer.network === 'tiktok') {
      const aux_videos = [];
      this.influencer['tiktok_videos'].forEach((video) => {
        if (video['deleted'] === false) {
          aux_videos.push(video);
        }
      });
      this.influencer['videos'] = aux_videos;
    }
  }

  /**
   * @description Show snackbar with the messages and actions granted
   * @param message
   * @param action
   */
  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
