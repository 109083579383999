import {Injectable} from '@angular/core';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class WebsocketService {
    private socket$: WebSocketSubject<any>;
    private readonly url: string = environment.websocketChatBrief;
        // 'wss://h5qb46m7i8.execute-api.eu-west-1.amazonaws.com/test';
        // 'wss://rcps3p5rn7.execute-api.eu-west-1.amazonaws.com/prod';

    public connectionStatus: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    private conversationFinishedSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);
    private timeoutId: any; // Declara la variable timeoutId aquí

    constructor() {}

    briefGenerateConversation(campaignId, idea, accessToken, count?) {
        this.conversationFinishedSubject =  new BehaviorSubject([]);
        this.createConexion(accessToken);
        this.createAction(campaignId, idea, count ? count : undefined);
        // Define una variable para el tiempo de espera
        const timeWaiting = 3000; // 5 segundos
        // Escucha las respuestas del servidor
        this.socket$.subscribe(
            (serverMessage) => {
                // Maneja la respuesta del servidor aquí
                // Agrega el mensaje a la conversación
                if (!serverMessage.conversation_finished) {
                    let currentConversation = this.conversationFinishedSubject.value;
                    currentConversation = currentConversation.concat(serverMessage.content);
                    this.conversationFinishedSubject.next(currentConversation);
                } else {
                    this.conversationFinishedSubject.complete();
                }
                // Reinicia el temporizador de tiempo de espera
                clearTimeout(this.timeoutId);
                this.timeoutId = setTimeout(() => {
                    // Aquí puedes tomar medidas cuando la conversación ha finalizado debido a la inactividad
                    this.closeConexion();
                }, timeWaiting);
            },
            (error) => {
                // Maneja errores de WebSocket aquí
                this.closeConexion();
            }
        );
    }

    createConexion(accessToken) {
        // Agregar el token de autorización al encabezado de la solicitud WebSocket
        const socketUrlWithToken = `${this.url}?Auth=${accessToken}`;
        this.socket$ = webSocket({
            url: socketUrlWithToken,
            openObserver: {
                next: () => {
                    this.connectionStatus.next(true);
                },
            },
            closeObserver: {
                next: () => {
                    this.closeConexion();
                },
            },
        });
    }

    closeConexion() {
        this.connectionStatus.next(false);
        this.socket$.complete();
    }

    createAction(campaignId, idea, count?) {
        // Envía una acción al servidor
        const payload = {
            action: 'briefMain',
            campaign_id: campaignId,
            client_prompt: idea,
        };
        if (count !== undefined) {
            payload['conversation_count'] = count;
        }
        this.socket$.next(payload);
    }


    getFinishedConversation(): Observable<string[]> {
        return this.conversationFinishedSubject.asObservable();
    }

}
