import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'converterTimeToHourDate'
})
export class ConverterTimeToHourDate implements PipeTransform {

  transform(value: number): string {
    const hours = Math.floor( value / 3600 );
    const minutes: string | number = Math.floor( (value % 3600) / 60 );
    const seconds: string | number = value % 60;

    let timeConverted = '';
    if (hours === 0 && minutes === 0) {
      timeConverted = seconds + ' seconds';
    } else if ( hours === 0) {
      timeConverted = ('0' + minutes).slice(-2) + ' minutes';
    } else {
      timeConverted = ('0' + hours).slice(-2) + ' hours ' + ('0' + minutes).slice(-2) + '  minutes';
    }

    return timeConverted;
  }

}
