import {Component, Input, OnInit} from '@angular/core';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {ThemeService} from '../../../../../../services/theme.service';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

  @Input() chartData;
  @Input() chartType;

  // Values of the line
  public lineChartData: ChartDataSets[] = [
    { data: [], label: ''},
  ];
  // Horizontal Positions
  // public lineChartLabels = ['', '', '', '', '', '', '', '', ''];

  // Colors of the lines
  public lineChartColors = [
    { // empty for after set the colors from the theme
      backgroundColor: '',
      borderColor: '',
      pointBackgroundColor: '',
      pointBorderColor: '',
      pointHoverBackgroundColor: '',
      pointHoverBorderColor: '',
    },
  ];
  // Este es el encargado de crear la barra vertical, despues en el oninit le metemos los values
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        display: true,
        type: 'time',
        distribution: 'series',
        gridLines: {
          display: true
        },
        time: {
          unit: 'week',
          isoWeekday: true,
          tooltipFormat: 'MMM',
          displayFormats: {
            week: 'MMM'
          }
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 15,
          beginAtZero: true,
          suggestedMin: 0,
        }
      }],
      yAxes: [ {
        ticks: {
          precision: 0,
          userCallback: function(follows, index, labels) {
            if (follows === 0) {
              return '0';
            }
            if (!follows) {
              // return '';
              return '0';
            }
            if (follows > 1000000000) {
              const VALUE = follows / 1000000000;
              const DECIMALS = VALUE.toFixed(1).toString();
              const AUX = DECIMALS.split('.');
              if (AUX[1] === '0') {
                return VALUE.toFixed(0).toString() + 'B';
              } else {
                return VALUE.toFixed(1).toString() + 'B';
              }
            } else if (follows >= 1000000000) {
              const VALUE = follows / 1000000000;
              return VALUE.toFixed(0).toString() + 'B';
            } else if (follows > 1000000) {
              const VALUE = follows / 1000000;
              const DECIMALS = VALUE.toFixed(1).toString();
              const AUX = DECIMALS.split('.');
              if (AUX[1] === '0') {
                return VALUE.toFixed(0).toString() + 'M';
              } else {
                return VALUE.toFixed(1).toString() + 'M';
              }
            } else if (follows >= 1000000) {
              const VALUE = follows / 1000000;
              return VALUE.toFixed(0).toString() + 'M';
            } else if (follows > 1000) {
              const VALUE = follows / 1000;
              const DECIMALS = VALUE.toFixed(1).toString();
              const AUX = DECIMALS.split('.');
              if (AUX[1] === '0') {
                return VALUE.toFixed(0).toString() + 'K';
              } else {
                return VALUE.toFixed(1).toString() + 'K';
              }
            } else if (follows >= 1000) {
              const VALUE = follows / 1000;
              return VALUE.toFixed(0).toString() + 'K';
            } else {
              return follows.toFixed();
            }

          },
        }
      }]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: '',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: ''
          }
        },
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: '',
          borderColor: 'red',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'red',
            content: ''
          }
        },
      ],
    },
  };

  public lineChartLegend = true;
  public lineChartType = 'line';
  public barChartPlugins = [pluginAnnotations];

  theme;

  constructor(themeService: ThemeService) {
    this.theme = themeService.getTheme();
  }

  ngOnInit() {
    // Set the colors from the theme
    // this.lineChartColors[0].backgroundColor = this.addAlpha(this.theme.audienceLighten, 0.7);
    // this.lineChartColors[0].pointBackgroundColor = this.theme.secondaryLighten;
    // this.lineChartColors[0].pointBorderColor = this.theme.secondary;
    // this.lineChartColors[0].pointHoverBackgroundColor = this.theme.secondaryDarken;
    // this.lineChartColors[0].pointHoverBorderColor = this.theme.secondary;
    this.lineChartColors[0].borderColor =  this.theme.secondary;

    // this.lineChartData[0].label = this.chartType;
    for (let index = 0; index < this.chartData.length ; index++) {
      if (this.chartType === 'followers') {
        this.lineChartData[0].data.push({y: this.chartData[index].followers, x: new Date(this.chartData[index].month)});
      } else if (this.chartType === 'following') {
        this.lineChartData[0].data.push({y: this.chartData[index].following, x: new Date(this.chartData[index].month)});
      } else if (this.chartType === 'likes') {
        this.lineChartData[0].data.push({y: this.chartData[index].avg_likes, x: new Date(this.chartData[index].month)});
      }
    }
  }

  addAlpha(color: string, opacity: number): string {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }


}
