import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-modal-refresh-influencer-data',
  templateUrl: './modal-refresh-influencer-data.component.html',
  styleUrls: ['./modal-refresh-influencer-data.component.scss']
})
export class ModalRefreshInfluencerDataComponent implements OnInit {

  more30days: boolean;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    const fechaActual = new Date();
    const fechaAlmacenada = new Date(this.data.fetch_timestamp * 1000);
    const diferenciaEnMilisegundos = fechaActual.getTime() - fechaAlmacenada.getTime();
    const diferenciaEnDias = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);
    this.more30days = diferenciaEnDias > 30;
  }

}
