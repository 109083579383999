import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../../store/auth/authentication.service';

@Component({
  selector: 'app-show-invoices-settings',
  templateUrl: './show-invoices-settings.component.html',
  styleUrls: ['./show-invoices-settings.component.scss']
})
export class ShowInvoicesSettingsComponent implements OnInit, OnDestroy {

  @Input() email;

  private subscriptions = [];

  loading = true;
  invoices = [];
  paymentInformation;

  payment_update_url: string;

  constructor(private _authService: AuthenticationService) { }

  ngOnInit() {
    this.loading = true;
    this.subscriptions.push(
      this._authService.getUserInvoices().subscribe( response => {
        if ('billing_info' in response.body) {
          this.paymentInformation = response.body.billing_info.payment_information;
          this.payment_update_url = response.body.billing_info.payment_update_url;
        }
        this.invoices = response.body.order_list;
        this.loading = false;
      }, () => {
        this.invoices = [];
        this.loading = false;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  openInvoiceUrl(url) {
    window.open(url, '_blank');
  }

}
