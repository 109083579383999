import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, takeUntil, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import * as actions from './influencers.actions';
import {InfluencersService} from './influencers.service';
import {InfluencerState} from './influencers.reducer';
import {select, Store} from '@ngrx/store';
import {selectInfluencerEntities} from './influencers.selectors';
import {Influencer} from 'src/app/types/influencer/influencer';

@Injectable()
export class InfluencersEffects {

  constructor(
    private actions$: Actions,
    private service: InfluencersService,
    private store: Store<InfluencerState>
  ) { }

  /* perfil lateral del influencer, aqui se devuelve el cacheado si procede */
  loadInfluencer$ = createEffect(() => this.actions$.pipe(
    ofType(actions.loadInfluencer),
    withLatestFrom(this.store.pipe(select(selectInfluencerEntities))),
    switchMap(([action, entities]) => {
      if (entities[action.influencerId] && entities[action.influencerId].cached) {
        return of(entities[action.influencerId]).pipe(
          map(influencer => actions.loadInfluencerSuccess({ influencer }))
        );
      }
      return this.service.loadInfluencer(action.influencerId, action.network).pipe(
        takeUntil(this.actions$.pipe(
          ofType(actions.cancelLoadInfluencer)
        )),
        map(influencer => actions.loadInfluencerSuccess({ influencer })),
        catchError(error => of(actions.loadInfluencerFail({ influencerId: error.id })))
      );
    })
  )
  );

  // todo miguel, esto esta comentado, aqui era para llamar a los lightcrawl cuando abrimos perfiles del influencer, si se quiere reactivar solo hace falta descomentar y ver el if.
  // lightCrawlWhenLoadInfluencer$ = createEffect(() => this.actions$.pipe(
  //   ofType(actions.loadInfluencerSuccess),
  //   map(action => {
  //       // if (action.influencer.type !== 'instagram') {
  //       //     return actions.lightCrawlInfluencer({ influencer: action.influencer });
  //       // } else {
  //       //     return actions.lightCrawlInfluencerSuccess({ influencer: action.influencer });
  //       // }
  //
  //       return actions.lightCrawlInfluencerSuccess({ influencer: action.influencer });
  //   })
  // ));


  lightCrawlInfluencer$ = createEffect(() => this.actions$.pipe(
    ofType(actions.lightCrawlInfluencer),
    switchMap(action => this.service.lightCrawl(action.influencer).pipe(
      map(_ => actions.lightCrawlInfluencerSuccess({ influencer: action.influencer })),
      catchError(_ => of(actions.lightCrawlInfluencerFail({ influencer: action.influencer })))
    ))
  ));

  fullCrawlInfluencer$ = createEffect(() => this.actions$.pipe(
    ofType(actions.fullCrawlInfluencer),
    switchMap(action => this.service.fullCrawl(action.influencer).pipe(
      map(data => actions.fullCrawlInfluencerSuccess({ influencer: action.influencer, data })),
      catchError(_ => of(actions.fullCrawlInfluencerFail({ influencer: action.influencer })))
    ))
  ));


  loadInstagrammerFail$ = createEffect(() => this.actions$.pipe(
    ofType(actions.loadInstagrammerFail),
    map(action => {
      const influencer: Influencer = {
        username: action.influencer,
        type: 'instagram',
        id: ''
      };
      // return actions.lightCrawlInfluencer({ influencer }); todo miguel esto era antes del new-search y era llamar al lightcrawl cuando peta el get profile
        return influencer;

    })
  ));

  loadInstagrammer$ = createEffect(() => this.actions$.pipe(
    ofType(actions.loadInstagrammer),
    switchMap(action => {
      const influencerSearched = action.name;
      return this.service.searchInstagrammerByNick(action.name).pipe(
        map(influencer => {
          return actions.loadInfluencerSuccess({ influencer });
        }),
        catchError(error => {
          return of(actions.loadInstagrammerFail({ influencer: influencerSearched }));
        })
      );
    })
  ));


    loadInstagrammerByUsername$ = createEffect(() => this.actions$.pipe(
        ofType(actions.loadInstagrammerByUsername),
        switchMap(action => {
            const influencerSearched = action.name;
            return this.service.searchInstagrammerByUsername(action.name).pipe(
                map(influencer => {
                    return actions.loadInfluencerSuccess({ influencer });
                }),
                catchError(error => {
                    return of(actions.loadInstagrammerFail({ influencer: influencerSearched }));
                })
            );
        })
    ));

  loadTiktoker$ = createEffect(() => this.actions$.pipe(
    ofType(actions.loadTiktoker),
    switchMap(action => {
      const influencerSearched = action.name;
      return this.service.searchTiktokerByNick(action.name).pipe(
        map(influencer => {
          return actions.loadInfluencerSuccess({ influencer });
        }),
        catchError(error => {
          return of(actions.loadTiktokerFail({ influencer: influencerSearched }));
        })
      );
    })
  ));

    loadTiktokerByUsername$ = createEffect(() => this.actions$.pipe(
        ofType(actions.loadTiktokerByUsername),
        switchMap(action => {
            const influencerSearched = action.name;
            return this.service.searchTiktokerByUsername(action.name).pipe(
                map(influencer => {
                    return actions.loadInfluencerSuccess({ influencer });
                }),
                catchError(error => {
                    return of(actions.loadTiktokerFail({ influencer: influencerSearched }));
                })
            );
        })
    ));

  loadTiktokerFail$ = createEffect(() => this.actions$.pipe(
    ofType(actions.loadTiktokerFail),
    map(action => {
      const influencer: Influencer = {
        username: action.influencer,
        type: 'tiktok',
        id: ''
      };
      // return actions.lightCrawlInfluencer({ influencer }); todo miguel esto era antes del new-search y era llamar al lightcrawl cuando peta el get profile
        return influencer;
    })
  ));

  loadYoutuber$ = createEffect(() => this.actions$.pipe(
    ofType(actions.loadYoutuber),
    switchMap(action => {
      return this.service.searchYoutuberByChannel(action.name).pipe(
        map(influencer => {
          return actions.loadInfluencerSuccess({influencer});
        }),
        catchError(error => {
          return of(actions.loadYoutuberFail({influencer: action.name}));
        })
      );
    })
  ));

    loadYoutuberByUsername$ = createEffect(() => this.actions$.pipe(
        ofType(actions.loadYoutuberByUsername),
        switchMap(action => {
            return this.service.searchYoutuberByUsername(action.name).pipe(
                map(influencer => {
                    return actions.loadInfluencerSuccess({influencer});
                }),
                catchError(error => {
                    return of(actions.loadYoutuberFail({influencer: action.name}));
                })
            );
        })
    ));

}
