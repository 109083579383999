import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BriefService {

  private briefUrl = environment.aws_resources.lambda_endpoint.api;

  constructor(private http: HttpClient) { }

  getBrief(campaign_id: string) {
    return this.http.get(this.briefUrl + 'brief/' + campaign_id, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  updateBrief(campaign_id: string, body: any) {
    return this.http.put(this.briefUrl + 'brief/' + campaign_id, body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getBriefInvitation(campaign_id: string) {
    const body = {
      is_preview_email: false
    };
    return this.http.post(this.briefUrl + 'brief/' + campaign_id + '/invitation', body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
}

  sendPreviousEmailInvitation(campaign_id: string, influencerId) {
    const body = {
      is_preview_email: true,
      influencer_id: influencerId
    };
    return this.http.post(this.briefUrl + 'brief/' + campaign_id + '/invitation', body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  cancelInfluencer( campaign_id: string, influencer_id: string) {
    return this.http.put(this.briefUrl + 'brief/' + campaign_id + '/influencer/' + influencer_id + '/invitation', null, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  extendCampaign(campaignId, newDateEnd) {
    const body = {
      extend_date: newDateEnd
    };
    return this.http.put(this.briefUrl + 'brief/' + campaignId + '/extend_days', body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  updateInfluencerEmail(campaignId, influencerId, email) {
    const body = {
      invitation_mail: email
    };
    return this.http.put(this.briefUrl + 'brief/' + campaignId + '/change-email/' + influencerId, body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  addMusicLink(musicLink) {
    const body = {
      video_url: musicLink,
    };
    return this.http.post(this.briefUrl + 'brief' + '/get-music-info', body, {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }
}
