import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import { ReportService } from '../../../../services/report.service';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-remove-post',
  templateUrl: './remove-post.component.html',
  styleUrls: ['./remove-post.component.css']
})
export class RemovePostComponent implements OnInit {

  sending_post = false;

  constructor(private _reportService: ReportService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<RemovePostComponent>,
              private translate: TranslateService,
              public snackBar: MatSnackBar) {}

  ngOnInit() {}

  removePost() {
    this.sending_post = true;
    if (this.data.media_type !== 'insight') {
      this._reportService.removePost(this.data.campaign_id, this.data.instagram_id, this.data.media_id).subscribe(result => {
        if (result['status'] === 200) {
          this.dialogRef.close('success');
          this.sending_post = false;
        }
      }, () => {
        this.dialogRef.close();
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: { message: this.translate.instant('report.reportInfluencers.removeMediaModal.thereWasErrorTryingRemove') + this.data.media_type + '.', type: 'error' },
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: 'error-snackbar',
        });
        this.sending_post = false;
      });
    } else {
      this._reportService.uploadInsights(this.data.campaign_id , this.data.media_id, '').subscribe(response => {
        if (response['status'] === 200) {
          this.dialogRef.close('success');
          this.sending_post = false;
        }
      }, () => {
        this.dialogRef.close('error');
      });
    }

  }
}
