import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { CustomSnackbarComponent } from '../../shared/components/custom-snackbar/custom-snackbar.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-unlock-create-presentation-modal',
  templateUrl: './unlock-create-presentation-modal.component.html',
  styleUrls: ['./unlock-create-presentation-modal.component.scss']
})
export class UnlockCreatePresentationModalComponent implements OnInit, OnDestroy {

  showFeedback = false;
  loading = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private _campaignService: CampaignsService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  watchVideo() {
    window.open('https://www.loom.com/share/375b54d0465042fc884c69c37713c9f4?sid=4f73ac08-492d-4d51-9ee0-f57360fbff3e ', '_blank');
  }

  getFreeTrial() {
    this.loading = true;
    this.subscriptions.push(
      this._campaignService.unlockCreatePresentation().subscribe( () => {
        this.loading = false;
        this.showFeedback = true;
      }, () => {
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: { message: this.translate.instant('customSnackbar.thereHasBeenError'), type: 'error' },
          duration: 4000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: 'error-snackbar',
        });
      })
    );
  }

}
