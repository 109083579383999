import { NativeDateAdapter } from '@angular/material';
import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export class DatePickerConfigClass extends NativeDateAdapter {
  constructor() {
    super('es-ES', new Platform());
  }

  getFirstDayOfWeek(): number {
    return 1;
  }

  parse(value: any): Date | null {
    if (typeof value === 'string' &&
        value.search('/') === 2 &&
        8 <= value.length &&
        value.length <= 10) {
      const str = value.split('/');
      const day = Number(str[0]);
      const month = Number(str[1]) - 1;
      const year = Number(str[2]);

      if ((day >= 1 && day <= 31) &&
          (month >= 0 && month <= 11) &&
          (year >= 1970)) {
        return new Date(year, month, day);
      }
    }
  }
}
