import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as actions from './authentication.actions';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AuthenticationState} from './authentication.reducers';
import * as Appactions from '../app/app.actions';


@Injectable()
export class AuthenticationEffects {
  constructor(
    private actions$: Actions,
    public router: Router,
    private service: AuthenticationService,
    private store: Store<AuthenticationState>
  ) {}

  accessSets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getAccessSets),
      switchMap(_ =>
        this.service
          .getAccessSets()
          .pipe(map(accessSets => actions.getAccessSetsSuccess({ accessSets })))
      )
    )
  );

  currentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getLoggedUser),
      switchMap(_ => {
        return this.service.getCurrentSession().pipe(
          map(user => actions.loginUserSuccess({ user })),
          catchError(error => of(actions.loginUserFail({ error })))
        );
      })
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loginUser),
      switchMap(action =>
        this.service.login(action.user).pipe(
          tap(_ => this.store.dispatch(actions.getAccessSets())),
          tap( _ => this.store.dispatch(Appactions.getUserGroup())),
          tap(_ => {
            if (action.returnUrl === '' || action.returnUrl === '/') {
              return this.router.navigate(['/app/search']);
            }
            if (action.returnUrl.startsWith('?')) {
              const url = action.returnUrl.substring(1);
              const urlParams = JSON.parse(
                '{"' + url.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
                (key, value) => (key === '' ? value : decodeURIComponent(value))
              );
                return this.router.navigate(['/app/search'], {
                queryParams: urlParams,
              });

            // Esta seccion contempla cuando intenta hacer login volviendo de la url del perfil independiente del correo.
            // Solo contempla esta casuistica, la del logout habria que meter un and en este else if y modificar el if padre.
            } else if ( action.returnUrl.startsWith('/app/influencer?')) {
              let url = action.returnUrl.substring(1);
              url = url.split('?')[1];
              const urlParams = JSON.parse(
                '{"' + url.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
                (key, value) => (key === '' ? value : decodeURIComponent(value))
              );
              return this.router.navigate(['app/influencer'], {
                queryParams: urlParams,
              });
            } else {
                if (action.returnUrl.includes('/app/')) {
                    return this.router.navigate([`${action.returnUrl}`]);
                } else {
                    return this.router.navigate([`/app/${action.returnUrl}`]);
                }
            }
          }),
          map(user => actions.loginUserSuccess({ user })),
          catchError(error => of(actions.loginUserFail({ error })))
        )
      )
    )
  );

  signUpStep1$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.signUpStep1),
      switchMap(action =>
        this.service.signUpStep1(action.email).pipe(
          map(hash => {
            return actions.signUpStep1Success({ hash: '' });
          }),
          tap(({ hash }) =>
            this.router.navigate(['/auth/registerStep2/' + hash])
          ),
          catchError(error => {
            if (error.status === 307) {
              const hash = error.error.split('/')[5];
              this.router.navigate(['/auth/registerStep2/' + hash]);
              return of(actions.signUpStep1Success({ hash: hash }));
            } else {
              return of(actions.signUpStep1Fail({ error }));
            }
          })
        )
      )
    )
  );

  getEmailFromHash$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getEmailFromHash),
      switchMap(action => {
        return this.service.getEmailFromHash(action.hash).pipe(
          map(email => {
            return actions.getEmailFromHashSuccess({
              email: email['register_email'],
            });
          }),
          catchError(error => of(actions.getEmailFromHashFail({ error })))
        );
      })
    )
  );

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.signUp),
      switchMap(action =>
        this.service.signUp(action.request).pipe(
          map(username => actions.signUpSuccess({ email: username })),
          tap(({ email }) => this.router.navigate(['/auth/thankyou'])),
          catchError(error => of(actions.signUpFail({ error })))
        )
      )
    )
  );

  resendVerificationEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.resendVerificationEmail),
      switchMap(action =>
        this.service.resendVerificationEmail(action.email).pipe(
          map(email => actions.resendVerificationEmailSuccess()),
          tap(_ =>
            this.router.navigate(['/auth/login'], {
              queryParams: {
                email: action.email,
                from: 'sendVerificationEmail',
              },
            })
          ),
          catchError(error =>
            of(actions.resendVerificationEmailFail({ error }))
          )
        )
      )
    )
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.forgotPassword),
      switchMap(action =>
        this.service.forgotPassword(action.email).pipe(
          map(email => actions.forgotPasswordSuccess()),
          tap(_ =>
            this.router.navigate(['/auth/forgotpassword', action.email])
          ),
          catchError(error => of(actions.forgotPasswordFail({ error })))
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.resetPassword),
      switchMap(action =>
        this.service
          .submitForgotPassword({
            email: action.email,
            code: action.code,
            password: action.password,
          })
          .pipe(
            map(email => actions.resetPasswordSuccess()),
            tap(_ =>
              this.router.navigate(['/auth/login'], {
                queryParams: { email: action.email, from: 'forgot' },
              })
            ),
            catchError(error => of(actions.resetPasswordFail({ error })))
          )
      )
    )
  );
}
