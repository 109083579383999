import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalConfirmedPauseCancelPlanComponent } from '../modal-confirmed-pause-cancel-plan/modal-confirmed-pause-cancel-plan.component';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { ModalPlansService } from 'src/app/features/modal-plans/store/modal-plans.service';
import { getAccessSets, getLoggedUser } from '../../../../../../store/auth/authentication.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../store';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-modal-pause-plan',
  templateUrl: './modal-pause-plan.component.html',
  styleUrls: ['./modal-pause-plan.component.scss']
})
export class ModalPausePlanComponent implements OnInit, OnDestroy {

  loading = false;

  monthSelected = 1;

  actualDate = '';

  reasonPauseSubscriptionForm: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private modalPlansService: ModalPlansService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ModalPausePlanComponent>,
    private store: Store<State>,
  ) {
    this.reasonPauseSubscriptionForm = new FormGroup({
      'reason': new FormControl('')
    });
   }

  ngOnInit() {
    this.getFormattedDate(1);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getFormattedDate(monthsToAdd: number): void {
    const today = new Date();

    // Sumar los meses
    today.setMonth(today.getMonth() + monthsToAdd);
  
    const day = today.getDate().toString().padStart(2, '0'); // Asegurar dos dígitos
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Mes en formato numérico
    const year = today.getFullYear();
  
    // Generar fecha en formato ISO
    this.actualDate = `${year}-${month}-${day}`;
  }



  pauseSubscription() {
    this.loading = true;
    const secondsInAMonth = 30 * 24 * 60 * 60;  // 2,592,000 segundos (aproximadamente)
    const totalSeconds = this.monthSelected * secondsInAMonth;
    this.subscriptions.push(
      this.modalPlansService.pauseSubscriptionPlan(totalSeconds, this.reasonPauseSubscriptionForm.controls.reason.value).subscribe( ( response ) => {
        this.store.dispatch(getAccessSets());
        this.store.dispatch(getLoggedUser());
        this.dialog
        .open(ModalConfirmedPauseCancelPlanComponent, {
          width: '397px',
          height: 'auto',
          autoFocus: false,
          data: {
            componentCalled: 'paused',
            datePaused: response['pause_effective_date']
          }
        })
        this.loading = false;
        this.dialogRef.close();
      }, error => {
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: { message: error.error.error_message, type: 'error' },
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: 'error-snackbar',
        });
        this.loading = false;
      })
    );
  }


  openModalSelectCancelPause() {
    import('../modal-select-cancel-pause-plan/modal-select-cancel-pause-plan.component').then(({ ModalSelectCancelPausePlanComponent }) => {
      this.dialog
      .open(ModalSelectCancelPausePlanComponent, {
        width: '415px',
        height: 'auto',
        panelClass: 'container-modal-plans',
        autoFocus: false,
        data: {
        }
      })
    });
  }

}
