import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccessSetsGuard} from './guards/access-sets.guard';
import {environment} from '../environments/environment';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/authentication/authentication.module').then(
        mod => mod.AuthenticationModule
      ),
  },
  {
    path: 'app/search',
    loadChildren: () =>
      import('./features/search/search.module').then(mod => mod.SearchModule),
    canActivate: [AccessSetsGuard],
    data: {
      permissions: [environment.permissions.search],
    },
  },
  {
    path: 'app/search/freeTrial',
    loadChildren: () =>
        import('./features/search/search.module').then(mod => mod.SearchModule),
    canActivate: [AccessSetsGuard],
    data: {
      permissions: [environment.permissions.search],
    },
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./features/private/private.module').then(
        mod => mod.PrivateModule
      ),
    canActivate: [AccessSetsGuard],
    data: {
      permissions: [environment.permissions.search],
    },
  },
  { path: 'app', pathMatch: 'full', redirectTo: '/app/search' },

  {
    path: 'gift-form',
    loadChildren: () =>
      import('./features/gift/gift.module').then(mod => mod.GiftModule),
  },
  {
    path: 'shared',
    loadChildren: () =>
      import('./features/shared-report/shared-report.module').then(
        mod => mod.SharedReportModule
      ),
  },
  {
    path: 'sharedDashboard',
    loadChildren: () =>
        import('./features/shared-report-dashboard/shared-report-dashboard.module').then(
            mod => mod.SharedReportDashboardModule
        ),
  },
  {
    path: 'adminManagement',
    loadChildren: () =>
        import('./features/admin-management/adminManagement.module').then(
            mod => mod.AdminManagementModule
        ),
    canActivate: [AccessSetsGuard],
    data: {
      permissions: [environment.permissions.admin],
    },
  },
  {
    path: 'sharedProgress',
    loadChildren: () =>
      import('./features/shared-progress/shared-progress.module').then(
        mod => mod.SharedProgressModule
      ),
  },
  {
    path: 'sharedCampaign',
    loadChildren: () =>
      import('./features/shared-campaign/shared-campaign.module').then(
        mod => mod.SharedCampaignModule
      ),
  },
  {
    path: 'shared-influencer',
    loadChildren: () =>
      import('./features/shared-influencer/shared-influencer.module').then(
        mod => mod.SharedInfluencerModule
      ),
  },
  {
    path: 'sharedInfluencerReport',
    loadChildren: () =>
      import('./features/shared-influencer-report/shared-influencer-report.module').then(
        mod => mod.SharedInfluencerReportModule
      ),
  },
  // TODO create 404 page
  { path: '**', pathMatch: 'full', redirectTo: '/auth/login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
