import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ReportService} from '../../../../services/report.service';

@Component({
  selector: 'app-add-post-manually',
  templateUrl: './add-post-manually.component.html',
  styleUrls: ['./add-post-manually.component.scss']
})
export class AddPostManuallyComponent implements OnInit {

  sendingMedia = false;
  mediaForm: FormGroup;

  constructor(private _reportService: ReportService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<AddPostManuallyComponent>,
              public snackBar: MatSnackBar) {
    this.mediaForm = new FormGroup({
      'media': new FormControl('', [
        Validators.required
      ])
    });
  }

  static getInstagramShortcode(route) {
    if (route.includes('https://www.instagram.com/reel/')) {
      route = route.replace('https://www.instagram.com/reel/', '');
    } else if (route.includes('https://www.instagram.com/tv/')) {
      route = route.replace('https://www.instagram.com/tv/', '');
    } else if (route.includes('https://www.instagram.com/')) {
        const parts = route.split('/p/');
        route = parts[1];
    }
    if (route.includes('?')) {
      const aux_arr = route.split('?');
      route = aux_arr[0];
    } else {
      const aux_arr = route.split('/');
      route = aux_arr[0];
    }
    return route;
  }

  ngOnInit() {}

  addPostManually() {
    this.sendingMedia = true;
    if (this.data.network === 'instagram') {
      URL = AddPostManuallyComponent.getInstagramShortcode(this.mediaForm.get('media').value);
      this._reportService.addPostManually(this.data.campaign_id, this.data.instagram_id, URL).subscribe(result => {
      this.dialogRef.close('success');
      this.sendingMedia = false;
      }, error => {
        this.dialogRef.close();
        this.openSnackBar('Oops something went wrong, try again', 'Close');
        this.sendingMedia = false;
      });
    } else if (this.data.network === 'youtube') {
      const youtube_url = this.mediaForm.get('media').value;
      let youtube_url_match;
      let regex;
      let urlForSend;
      if (youtube_url.includes('shorts')) {
        regex = /shorts\/(.+)/;
        youtube_url_match = regex.exec(youtube_url);
        urlForSend = youtube_url_match[1];
      } else {
        regex = /(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))([^\?&"'>]+)/;
        youtube_url_match = regex.exec(youtube_url);
        urlForSend = youtube_url_match[5];
      }
      this._reportService.addPostManually(this.data.campaign_id, this.data.instagram_id, urlForSend, this.data.media_type).subscribe(() => {
        this.dialogRef.close('success');
        this.sendingMedia = false;
      }, () => {
        this.dialogRef.close();
        this.openSnackBar('Oops something went wrong, please try again more later', 'Close');
        this.sendingMedia = false;
      });
    } else if (this.data.network === 'tiktok') {
      let tiktokUrl = this.mediaForm.get('media').value.replace('https://www.tiktok.com/@' + this.data.username + '/video/', '');
      if (tiktokUrl.indexOf("tiktok") !== -1) {
        tiktokUrl = this.mediaForm.get('media').value.replace('https://www.tiktok.com/@' + this.data.username + '/photo/', '');
      }
      this._reportService.addPostManually(this.data.campaign_id, this.data.instagram_id, tiktokUrl, this.data.media_type).subscribe(() => {
        this.dialogRef.close('success');
        this.sendingMedia = false;
      }, () => {
        this.dialogRef.close();
        this.openSnackBar('Oops something went wrong, please try again more later', 'Close');
        this.sendingMedia = false;
      });
    }

  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
