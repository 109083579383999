import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {CampaignsService} from '../../../../../services/campaigns.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-modal-reject-reason',
    templateUrl: './modal-reject-reason.component.html',
    styleUrls: ['./modal-reject-reason.component.scss']
})
export class ModalRejectReasonComponent implements OnInit, OnDestroy {

    loading = false;
    campaign_id = '';
    influencer_id = '';

    reason_form: FormGroup;
    private subscriptions: Subscription[] = [];


    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private _campaignsService: CampaignsService,
                private dialogRef: MatDialogRef<ModalRejectReasonComponent>) {
        this.campaign_id = data.campaign_id;
        this.influencer_id = data.influencer_id;

        this.reason_form = new FormGroup({
            'reason': new FormControl('', [Validators.required]),
        });
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    rejectInfluencer() {
        this.loading = true;
        const influencer_ids = this.data.influencers 
        ? this.data.influencers.map(influencer => influencer.influencer_id) 
        : [this.data.influencer_id];
        if (this.data.isShare) {
            this.subscriptions.push(
                this._campaignsService.shareCampaignRejectReason(this.campaign_id, influencer_ids, this.reason_form.controls['reason'].value).subscribe(result => {
                    this.dialogRef.close(result);
                }, error => {
                    this.dialogRef.close(error);
                })
            );
        } else {
            this.subscriptions.push(
                this._campaignsService.cancelInfluencer(this.campaign_id, influencer_ids, this.reason_form.controls['reason'].value).subscribe(result => {
                    this.dialogRef.close(result);
                }, error => {
                    this.dialogRef.close(error);
                })
            );
        }

    }

    approveInfluencer() {
        this.loading = true;
        const influencer_ids = this.data.influencers 
        ? this.data.influencers.map(influencer => influencer.influencer_id) 
        : [this.data.influencer_id];
        if (this.data.isShare) {
            this.subscriptions.push(
                this._campaignsService.shareCampaignApproveInfluencer(this.campaign_id, influencer_ids).subscribe(result => {
                    this.dialogRef.close(result);
                }, error => {
                    this.dialogRef.close(error);
                })
            );
        } else {
            this.subscriptions.push(
                this._campaignsService.approveInfluencer(this.campaign_id, influencer_ids).subscribe(result => {
                    this.dialogRef.close(result);
                }, error => {
                    this.dialogRef.close(error);
                })
            );
        }
    }

}
