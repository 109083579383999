import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ModalPlansService} from '../../../modal-plans/store/modal-plans.service';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {environment} from '../../../../../environments/environment';
import {AuthenticatedUser} from '../../../../classes/authenticated-user-class';
import * as selectorsAuth from '../../../../store/auth/authentication.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../store';

declare var Paddle: any;


@Component({
  selector: 'app-modal-select-bonus',
  templateUrl: './modal-select-bonus.component.html',
  styleUrls: ['./modal-select-bonus.component.scss']
})
export class ModalSelectBonusComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  bonus = [];

  amount = '';
  idSelected;
  email;

  isSelected = false;
  loading = false;

  authenticated_user: AuthenticatedUser;

  constructor(
      private _plansService: ModalPlansService,
      private snackBar: MatSnackBar,
      public dialogRef: MatDialogRef<ModalSelectBonusComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private store: Store<State>
  ) {
    Paddle.Setup({
      vendor: environment.paddle_vendor,
    });
    if (!environment.production) {
      Paddle.Environment.set('sandbox');
    }
  }

  ngOnInit() {
    this.loading = true;
    this.subscriptions.push(
        this.store.select(selectorsAuth.selectUser).subscribe(user => {
          this.email = user.username;
        }),
    );
    this.subscriptions.push(
        this._plansService.getBonus().subscribe( response => {
          this.bonus = response.body;
          this.loading = false;
        }, () => {
          this.openSnackBar('Oops something went wrong, please try again', 'Ok');
          this.loading = false;
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  selectBonus(bonus) {
    // Primero, desmarcamos todas las cajas
    this.bonus.forEach((c) => (c.checked = false));
    // Luego, marcamos la caja clicada
    bonus.checked = true;
    this.amount = bonus.quantity;
    this.idSelected = bonus.product_id;
    this.isSelected = true;
  }

  buyBonus(bonusId) {
    Paddle.Checkout.open(
        {
          product: bonusId,
          email: this.email,
          disableLogout: true,
          successCallback: (data) => {
            this.dialogRef.close();
            this.openSnackBar('Your transaction has been completed successfully, now you can enjoy more requests', 'Ok');
            // this.subscriptions.push(
            //     ...[
            //       this.modalPlansService.savePaddlePayment(data).subscribe( () => {
            //         this.store.dispatch(getLoggedUser());
            //         this.store.dispatch(getAccessSets());
            //         this.dialogRef.close();
            //         // aqui iria la llamada a googletagmanager
            //         // const gtmTag = {
            //         //   event: 'checkoutSuccess',
            //         //   data: 'my-custom-event',
            //         // };
            //         // this.gtmService.pushTag(gtmTag);
            //         this.dialog.open(ModalThanksPageComponent, {
                        // width: '410px',
                        // height: 'auto',
                        // autoFocus: false,
                        // data: {
                        //     planName: plan.planName,
                        // }
            //         });
            //       }, () => {
            //         this.openSnackBar('Oops, something went wrong, please contact with us', 'Close');
            //       })
            //     ]
            // );
          },
          closeCallback: (data) => {
            // if the user cancel the paddle modal
            // alert('Your purchase has been cancelled, we hope to see you again soon!');
            this.openSnackBar('You closed the payment window, please try again', 'Close');
          }
        }
    );
  }

}
