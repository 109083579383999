import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../store';
import {ModalPlansService} from '../../../../store/modal-plans.service';
import {Observable, Subscription} from 'rxjs';
import {getAccessSets, getLoggedUser} from '../../../../../../store/auth/authentication.actions';
import {MAT_DIALOG_DATA, MatDialog, MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {ModalThanksPageComponent} from '../../../modal-thanks-page/modal-thanks-page.component';
import { PlanState } from 'src/app/features/modal-plans/store/modal-plans.reducers';
import * as selectorsAuth from '../../../../../../store/auth/authentication.selectors';
import * as selectors from '../../../../../modal-plans/store/modal-plans.selectors';
import * as actions from '../../../../../modal-plans/store/modal-plans.actions';
import { map } from 'rxjs/operators';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-modal-confirm-upgrade-plan',
  templateUrl: './modal-confirm-upgrade-plan.component.html',
  styleUrls: ['./modal-confirm-upgrade-plan.component.scss']
})
export class ModalConfirmUpgradePlanComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  loading = false;
  deployContentCardsPlan = false;

  email = '';
  planNameShown = '';

  isDowngrade = false;

  plans$: Observable<any[]>;

  constructor(private store: Store<State>,
              private modalPlansService: ModalPlansService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar,
              public router: Router,
              private dialog: MatDialog,
              public storePlans: Store<PlanState>,
              private translate: TranslateService,
              
  ) {

  }

  ngOnInit() {
    this.storePlans.dispatch(actions.getPlans());
    this.subscriptions.push(
      this.storePlans.select(selectorsAuth.selectUser).subscribe(user => {
        this.email = user.username;
      }),
    );
    this.storePlans.select(selectorsAuth.selectAccessSets).subscribe(user => {
      this.planNameShown = user.PLAN_NAME_SHOWN;
    });
    this.plans$ = this.storePlans.select(selectors.selectPlans).pipe(
      map(plans => plans ? plans.filter(plan => plan.planName === this.data.plan.planName || plan.planName === this.planNameShown) : [])
    );
    const downgradeMap: { [key: string]: { [key: string]: boolean } } = {
      SEARCH: { PREMIUM: false, GENIUS: false },
      PREMIUM: { GENIUS: false, SEARCH: true },
      GENIUS: { PREMIUM: true, SEARCH: true },
    };
    
    this.isDowngrade = downgradeMap[this.planNameShown] && downgradeMap[this.planNameShown][this.data.plan.planName] !== undefined 
    ? downgradeMap[this.planNameShown][this.data.plan.planName] 
    : false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  trackByPlan(index, item) {
    return !item ? null : item.id;
  }

  confirmUpgradePlan() {
      this.loading = true;
      const plan_to_update = {
          plan_id: this.data.productId,
          plan_name: this.data.plan.planName,
      };
        this.subscriptions.push(
            ...[
                this.modalPlansService.upgradePlan(plan_to_update).subscribe( result => {
                  this.dialog.closeAll();
                    this.dialog.open(ModalThanksPageComponent, {
                        width: '410px',
                        height: 'auto',
                        autoFocus: false,
                        data: {
                          planName: this.data.plan.planName,
                        }
                    });
                    this.store.dispatch(getLoggedUser());
                    this.store.dispatch(getAccessSets());
                    this.loading = false;
                }, () => {
                    this.loading = false;
                    this.snackBar.openFromComponent(CustomSnackbarComponent, {
                      data: { message: this.translate.instant('customSnackbar.thereHasBeenError'), type: 'error' },
                      duration: 3000,
                      horizontalPosition: 'right',
                      verticalPosition: 'top',
                      panelClass: 'error-snackbar',
                    });
                })
            ]
        );
  }

  openSnackBar(message, action) {
      this.snackBar.open(message, action, {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
      });
  }


}
