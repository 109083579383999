import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSnackBar, Sort} from '@angular/material';
import {FavouritesService} from '../../../../services/favourites.service';
import {ModalCreateFavouritesListComponent} from './modals/modal-create-favourites-list/modal-create-favourites-list.component';
import {ModalRemoveFavouritesListComponent} from './modals/modal-remove-favourites-list/modal-remove-favourites-list.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormControl, FormGroup} from '@angular/forms';
import {CampaignsModalComponent} from '../../../../components/private/campaigns/campaigns-modal/campaigns-modal.component';
import {MatSidenav} from '@angular/material/sidenav';
import {AuthenticatedUser} from '../../../../classes/authenticated-user-class';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import {TranslateService} from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { ModalRefreshInfluencerDataComponent } from '../../modals/modal-refresh-influencer-data/modal-refresh-influencer-data.component';
import {InfluencersService} from '../../../../store/influencers/influencers.service';
import { ModalInfoUpdateInfluencersComponent } from './modals/modal-info-update-influencers/modal-info-update-influencers.component';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FavouritesComponent implements OnInit, OnDestroy {

  loading = false;
  favouritesLoadError: false;

  favourites = [];
  favouritesForShow = [];
  main_class = '';
  columnsToDisplay: string[] =  [ 'SELECT' , 'favorite_list_name', 'total_influencers','ADD'];
  columnsToDisplayWithExpand = [ 'SELECT' , 'favorite_list_name',  'total_influencers','ADD', 'expand'];
  expandedElement: {} | null;

  // apertura influencer profile
  @ViewChild('sidenav', { static: false }) profile_sidenav: MatSidenav;
  bindable_influencer_data: any;
  authenticated_user: AuthenticatedUser;
  currency_type: string;
  currency_symbol: string;
  loading_profile = false;


  multipleInfluencersSelected = [];
  private subscriptions = [];

  checkbox_form: FormGroup;
  influencerSelectedForm: FormGroup;

  favouritesUsernameForm: FormGroup;

  constructor(
      private _favouritesService: FavouritesService,
      private dialog: MatDialog,
      private snackBar: MatSnackBar,
      private translate: TranslateService,
      public _influencerService: InfluencersService,

  ) {
    this.influencerSelectedForm = new FormGroup({});
    this.checkbox_form = new FormGroup({
      'checkbox': new FormControl(true)
    });
    this.favouritesUsernameForm = new FormGroup({
      'username': new FormControl('')
    });
  }

  ngOnInit() {
    this.getFavourites();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getFavourites() {
    this.loading = true;
    this.subscriptions.push(
        this._favouritesService.getFavouritesList().subscribe(result => {
          if (result.status === 200) {
            this.favourites = result.body;
            this.favouritesForShow = this.favourites;
            this.favourites.forEach((favourite) => {
              favourite.influencerSelected = favourite.total_influencers > 0;
            });
          } else {
            this.favourites = [];
          }
          this.multipleInfluencersSelected = this.favourites;
          this.loading = false;
        }, () => {
          this.favourites = [];
          this.loading = false;
        })
    );
  }

  createFavouritesList() {
    const dialogRef = this.dialog.open(ModalCreateFavouritesListComponent, {
      width: '35%',
      height: '25%',
      minWidth: '450px',
      minHeight: '250px',
      data: {},
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.getFavourites();
      }
    });
  }

  filterFavouritesByUsername() {
    const favouritesUsername = this.favouritesUsernameForm.controls.username.value.toLowerCase();
    this.favouritesForShow = this.filterInfluencerByUsername(this.favourites, favouritesUsername);
  }

  filterInfluencerByUsername(favourites: any[], username: string): any[] {
    return favourites.filter(favourite => favourite.favorite_list_name.toLowerCase().includes(username));
  }

  removeFavourites(event, favourites) {
    // event.stopPropagation();
    const dialogRef = this.dialog.open(ModalRemoveFavouritesListComponent, {
      width: '600px',
      height: '320px',
      autoFocus: false,
      data: {
        favourites_id: favourites.favorite_list_id,
        favourites_name: favourites.favorite_list_name,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.getFavourites();
      }
    });
  }

  removeInfluencerFavourites(event, influencer, favorite_list_id) {
    const dialogRef = this.dialog.open(ModalRemoveFavouritesListComponent, {
      width: '600px',
      height: '320px',
      autoFocus: false,
      data: {
        favourites_id: favorite_list_id,
        influencer_id: influencer.influencer_id,
        favourites_name: influencer.username,
        network: influencer.network,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.getFavourites();
      }
    });
  }

  sortData(sort: Sort) {
    const data = this.favourites.slice();
    if (!sort.active || sort.direction === '') {
      this.favourites = data;
    }
    this.favourites = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Influencer Username':
          return this.compare(a.influencer_username, b.influencer_username, isAsc);
        case 'Network':
          return this.compare(a.network, b.network, isAsc);
        case 'Influencer Link':
          return this.compare(a.influencer_link, b.influencer_link, isAsc);
        case 'Activity Date':
          return this.compare(a.activity_ts, b.activity_ts, isAsc);
        case 'Activity Type':
          return this.compare(a.activity_type, b.activity_type, isAsc);
        case 'Page Loaded':
          return this.compare(a.page_loaded, b.page_loaded, isAsc);
        default:
          return 0;
      }
    });
  }


  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  getInfluencers(favourite?, selectInfluencersValue?) {
    if (!favourite || favourite.influencers) {
      return; // Si ya hay influencers definidos, no hacer nada
    }
  
    if (favourite.total_influencers > 0) {
      this.subscriptions.push(
        this._favouritesService.getInfluencersFavouritesList(favourite.favorite_list_id).pipe(
          tap(result => {
            if (result.status === 200) {
              const influencers = result.body;
              favourite.influencers = influencers;
    
              influencers.forEach((influencer, index) => {
                influencer.isSelected = true;
                const controlValue = selectInfluencersValue !== undefined ? selectInfluencersValue : true;
                this.influencerSelectedForm.setControl('influencer_' + index, new FormControl(controlValue));
              });
    
              if (selectInfluencersValue === false) {
                influencers.forEach((influencer, index) => {
                  this.influencerSelectedForm.controls['influencer_' + index].setValue(selectInfluencersValue);
                  influencer.isSelected = selectInfluencersValue;
                });
              }
            }
          })
        ).subscribe()
      );
    }
  }
  
  

  addInfluencertoCampaign(element) {
    let dataInfluencer;
  
    if (element.influencer_id) {
      dataInfluencer = {
        instagram_id: element.influencer_id,
        network: element.network,
        full_name: element.full_name,
        profile_pic_url: element.profile_pic_url,
        username: element.username,
      };
    } else if (element.influencers === undefined && element.total_influencers > 0) {
      this.subscriptions.push(
        this._favouritesService.getInfluencersFavouritesList(element.favorite_list_id).subscribe(result => {
          if (result.status === 200) {
            dataInfluencer = { influencers: result.body };
          } else {
            this.openErrorCustomSnackbar();
            return;
          }
          this.openCampaignsModal(dataInfluencer);
        }, () => {
          this.openErrorCustomSnackbar();
          return;
        })
      );
      return;
    } else {
      const influencersSelected = Array.isArray(element.influencers) ? element.influencers.filter(influencer => influencer.isSelected) : [];
      dataInfluencer = {
        influencers: influencersSelected,
      };
    }
  
    this.openCampaignsModal(dataInfluencer);
  }


  openErrorCustomSnackbar() {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message: this.translate.instant('customSnackbar.thereHasBeenError'), type: 'error' },
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'error-snackbar',
    });
  }
  
  openCampaignsModal(dataInfluencer) {
    this.dialog.open(CampaignsModalComponent, {
      width: '790px',
      height: '350px',
      panelClass: 'remove-padding',
      autoFocus: false,
      data: dataInfluencer,
    });
  }

  viewInfluencersSelected (element) {
    if (element === undefined) {
      return 0;
    } else 
    if (Array.isArray(element.influencers)) {
      const influencersSelected = [];
      element.influencers.forEach((influencer) => {
        if (influencer.isSelected) {
          influencersSelected.push(influencer);
        }
      });
      return influencersSelected.length;
    } else {
      return element.total_influencers;
    }
  }

  downloadCSV (element) {
    this.loading = true;
    let dataCSV;
    this.subscriptions.push(
      this._favouritesService.getFavouritesExportCSV(element.favorite_list_id).subscribe( result => {
        dataCSV = result.body;
        this._favouritesService.exportToCsv('favourites_data.csv', dataCSV);
        this.loading = false;
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: { message: this.translate.instant('favourites.downloadCsvSuccess'), type: 'success' },
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: 'success-snackbar',
        });
      }, () => {
        this.openErrorCustomSnackbar();
        this.loading = false;
      })
    );
  }


  changeInfluencerSelected(event, index, influencer, favourite) {
    influencer.isSelected = event.checked;
    this.influencerSelectedForm.controls['influencer_' + index].setValue(event.checked);
    this.validateIfHaveInfluencerSelected(favourite);
  }


  validateIfHaveInfluencerSelected (favourite) {
    let countInfluencersSelected = 0;
    favourite.influencers.forEach((influ) => {
      if (influ.isSelected) {
        countInfluencersSelected += 1;
      }
    });
    favourite.influencerSelected = countInfluencersSelected > 0;
  }


  changeStateAllInfluencers(event, favourite) {
    favourite.influencerSelected = event.checked;
    if (!favourite.influencers && favourite.total_influencers > 0) {
      this.getInfluencers(favourite, event.checked);
    } else {
      favourite.influencers.forEach((influencer, index) => {
        this.influencerSelectedForm.controls['influencer_' + index].setValue(event.checked);
        influencer.isSelected = event.checked;
      });
    }
  }

  setMainScroll() {
    this.main_class = '';
  }

  openProfile(event) {
    this.profile_sidenav.toggle();
    this.bindable_influencer_data = {
      origin: 'campaign_plan',
      influencer_id: event.influencer_id,
      network: event.network,
      currency_type: this.currency_type,
      currency_symbol: this.currency_symbol,
      campaign_id: event.campaign_id,
    };
    this.main_class = 'cards-wrapper-without-scroll';
  }


  updateInfluencer(influencer) {
    const dialogRef = this.dialog.open(ModalRefreshInfluencerDataComponent, {
        width: '550px',
        height: 'auto',
        autoFocus: false,
        data: {
            fetch_timestamp: influencer.fetch_timestamp
        },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.loading = true;
        this.subscriptions.push(
          this._influencerService.newFullCrawl(influencer.username, influencer.influencer_id, influencer.network).subscribe( () => {
            this.snackBar.openFromComponent(CustomSnackbarComponent, {
              data: { message: this.translate.instant('favourites.influencerAnalyzed'), type: 'success' },
              duration: 5000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: 'success-snackbar',
            });
            this.getFavourites();
          }, () => {
            this.loading = false;
            this.openErrorCustomSnackbar();
          })
        );
      }
    });
}


updateInfluencers(favourite) {
  const dialogRef = this.dialog.open(ModalRefreshInfluencerDataComponent, {
      width: '550px',
      height: 'auto',
      autoFocus: false,
      data: {
          fetch_timestamp: undefined,
          influencersLength: favourite.total_influencers
      },
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result === 'success') {
      this.loading = true;
      this.updateInfluencersRequest(favourite);
    }
  });
}

  async updateInfluencersRequest(favourite) {
    if (favourite.influencers === undefined) {
      await this.getInfluencers(favourite);
    }
    await new Promise(resolve => setTimeout(resolve, 1500)); 
    if (favourite.influencers === undefined) {
      this.snackBar.openFromComponent(CustomSnackbarComponent, {
        data: { message: this.translate.instant('customSnackbar.thereHasBeenError'), type: 'error' },
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: 'error-snackbar',
      });
      return;
    }
    let influencers = favourite.influencers.map((influencer: any) => ({
      username: influencer.username,
      influencer_id: influencer.influencer_id,
      network: influencer.network
    }));
    this.subscriptions.push(
      this._favouritesService.fullCrawlInfluencers(influencers).subscribe( response => {
        this.loading = false;
        if (response.status === 207) {
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: response.body.message, type: 'warning', isFavourite: true },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'warning-snackbar',
          });
        } else {
          const dialogRef = this.dialog.open(ModalInfoUpdateInfluencersComponent, {
            width: '450px',
            height: '250px',
            data: {},
          });
          dialogRef.afterClosed().subscribe(() => {
            this.getFavourites();
          });
        }
      }, () => {
        this.loading = false;
        this.openErrorCustomSnackbar();
      })
    );
  }
}