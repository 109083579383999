import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {AuthenticatedUser} from '../../../../../../classes/authenticated-user-class';
import {TranslateService} from '@ngx-translate/core';
import {CampaignsService} from '../../../../../../services/campaigns.service';

@Component({
  selector: 'app-campaign-plan-share-modal',
  templateUrl: './campaign-plan-share-modal.component.html',
  styleUrls: ['./campaign-plan-share-modal.component.scss']
})
export class CampaignPlanShareModalComponent implements OnInit, OnDestroy {

  // Public properties
  url: string;
  copyUrl = false;
  deployDetails = false;
  showInputEditImpressions = false;
  loading = false;
  isFirstExecution = true;
  campaignForm: FormGroup;
  authenticated_user: AuthenticatedUser;

  // Private properties
  private subscriptions = [];

  @ViewChild('total_cost_input', { static: false }) total_cost_input: ElementRef;

  constructor(
      public dialogRef: MatDialogRef<CampaignPlanShareModalComponent>,
      @Inject(MAT_DIALOG_DATA) public campaign: any,
      public _campaignService: CampaignsService,
      public router: Router,
      private snackBar: MatSnackBar,
      private translate: TranslateService,
  ) {
    this.campaignForm = new FormGroup({
      'total_cost': new FormControl(),
      'margin': new FormControl(),
    });
  }


  ngOnInit() {
    // Suscribirse al evento de clic fuera del diálogo
    this.dialogRef.backdropClick().subscribe(() => {
      this.closeCampaignPlanDialog();
    });
    if (this.campaign.campaign_total_cost_share === undefined) {
      this.campaign.campaign_total_cost_share = this.campaign.total_cost;
    }
    this.loading =  true;
    this.campaignForm.controls.margin.setValue(this.campaign.additional_margin);
    this.campaignForm.controls.total_cost.setValue(this.campaign.campaign_total_cost_share);
    this.subscribeToCampaignShare(this.campaign.campaign_id, this.campaign.additional_margin, this.campaign.campaign_total_cost_share);
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  copyToClipboardUrl(): void {
    this.copyUrl = true;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    setTimeout( () => { this.copyUrl = false; }, 700 );
  }


  generateUrl(): void {
    this.subscribeToCampaignShare(this.campaign.campaign_id, this.campaignForm.get('margin').value,
        this.campaignForm.get('total_cost').value);
  }

  private subscribeToCampaignShare(campaignId: number, additionalMargin: number, totalCost: number): void {
    const subscription = this._campaignService
        .shareCampaign(campaignId, Number(additionalMargin) + 1, totalCost)
        .subscribe({
          next: res => {
            this.handleSuccessfulResponse(res);
          },
          error: () => {
            this.handleError();
          }
        });

    this.subscriptions.push(subscription);
  }

  private handleSuccessfulResponse(res: any): void {
    this.url = res.body.campaign_shared_url;
    if (!this.isFirstExecution) {
      this.openSnackBar(this.translate.instant('shareCampaignPlanModal.changesSavedSuccessfully'), 'Ok');
    }
    this.loading = false;
    this.isFirstExecution = false;
  }

  private handleError(): void {
    if (!this.isFirstExecution) {
      this.openSnackBar(this.translate.instant('shareCampaignPlanModal.oopsError'), 'Ok');
    }
    this.loading = false;
    this.isFirstExecution = false;
  }


  setAdditionalMargin() {
    if (this.campaignForm.controls.margin.value === '') {
      this.campaignForm.controls.margin.setValue(0);
    }
    this.campaignForm.controls.total_cost.setValue(((this.campaignForm.controls.margin.value / 100) + 1) * this.campaign.total_cost);
    this.campaign.campaign_total_cost_share = this.campaignForm.controls.total_cost.value;
    this.generateUrl();
  }

  validateInput() {
    // Obtén la referencia al elemento de entrada
    const inputElement = this.total_cost_input.nativeElement;
    let valor = inputElement.value;

    // Elimina todos los caracteres no numéricos, excepto comas y puntos
    valor = valor.replace(/[^\d,.]/g, '');

    // Reemplaza comas por vacío para que no afecten el formato
    valor = valor.replace(/,/g, '');

    // Divide la cadena en partes utilizando el punto como separador decimal
    const partes = valor.split('.');

    if (partes.length > 2) {
      // Si hay más de un punto decimal, elimina los extras
      valor = partes[0] + '.' + partes.slice(1).join('');
    } else {
      // Formatea la parte entera con comas como separadores de miles (estilo americano)
      partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Combina la parte entera y decimal con el punto como separador decimal
      valor = partes.join('.');
    }

    // Actualiza el valor del elemento de entrada
    inputElement.value = valor;
  }


  validateEntry(event: any): boolean {
    const inputChar = String.fromCharCode(event.charCode);
    // Permitir solo números, punto decimal, comas como separadores de miles y teclas de control (como retroceso)
    return /^[\d,.]+$/.test(inputChar) || event.charCode === 0 || event.charCode === 8;
  }

  validateEntryPercentage(event: KeyboardEvent): boolean {
    return /^\d+$/.test(String.fromCharCode(event.charCode)) || event.charCode === 0 || event.charCode === 8;
  }

  setTotalCost() {
    if (this.campaignForm.controls.total_cost.value === '') {
      this.campaignForm.controls.total_cost.setValue(this.campaign.total_cost);
      this.campaignForm.controls.margin.setValue(0);
      this.subscribeToCampaignShare(this.campaign.campaign_id, 0, this.campaign.total_cost);
      this.campaign.campaign_total_cost_share = this.campaign.total_cost;
    } else {
      const totalCost = parseFloat(this.campaignForm.controls.total_cost.value.replace(/,/g, ''));
      const inputTotalCost = parseFloat(this.campaignForm.controls.total_cost.value.replace(/,/g, ''));
      const campaignTotalCost = this.campaign.total_cost;

      if (campaignTotalCost <= 0 || totalCost <= 0 || inputTotalCost < campaignTotalCost) {
        this.campaignForm.controls.margin.setValue(0);
      } else {
        const additionalMargin = (totalCost / campaignTotalCost) * 100 - 100;
        this.campaignForm.controls.margin.setValue(Math.round(additionalMargin));
      }
  
      const parsedValue = parseFloat(this.campaignForm.controls.total_cost.value.replace(/,/g, ''));
  
      this.campaign.campaign_total_cost_share = Number(isNaN(parsedValue) ? '' : (parsedValue % 1 === 0 ? parsedValue.toFixed(0) : parsedValue.toFixed(2)));
      this.subscribeToCampaignShare(this.campaign.campaign_id, this.campaignForm.get('margin').value, Number(isNaN(parsedValue) ? '' : (parsedValue % 1 === 0 ? parsedValue.toFixed(0) : parsedValue.toFixed(2))));
    }
  }

  resetInputs() {
    this.campaign.additional_margin = 0;
    this.campaignForm.controls.margin.setValue(0);
    this.campaign.campaign_total_cost_share = this.campaign.total_cost.toFixed(2);
    this.campaignForm.controls.total_cost.setValue(this.campaign.campaign_total_cost_share);
    this.generateUrl();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar']
    });
  }

  closeCampaignPlanDialog() {
    this.dialogRef.close({
      campaign_total_cost_share: this.campaign.campaign_total_cost_share,
      additional_margin: Number(this.campaignForm.controls.margin.value) + 1,
    });
  }

}
