import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-totals',
  templateUrl: './totals.component.html',
  styleUrls: ['./totals.component.scss']
})
export class TotalsComponent implements OnInit {

  @Input() report;
  @Input() currency_type;
  @Input() currency_symbol;

  showImpacts = false;
  atLeastOneInstagram = false;
  atLeastOneYoutube = false;
  atLeastOneTiktok = false;


  constructor() { }

  ngOnInit() {
    this.report.influencers_in_report.some((influencer) => {
      if (influencer.network === 'instagram') {
        this.atLeastOneInstagram = true;
      }
      if (influencer.network === 'youtube') {
        this.atLeastOneYoutube = true;
      }
      if (influencer.network === 'tiktok') {
        this.atLeastOneTiktok = true;
      }
    });
  }

  changeShowImpacts() {
    this.showImpacts = !this.showImpacts;
  }

}
