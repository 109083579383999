import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-lateral-profile-taps',
  templateUrl: './lateral-profile-taps.component.html',
  styleUrls: ['./lateral-profile-taps.component.scss']
})
export class LateralProfileTapsComponent implements OnInit {

  @Input() avg_comments;
  @Input() sponsoredPosts;
  @Input() popularPosts;

  @Input() network;

  constructor() { }

  ngOnInit() {}


  scrollTo(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
}
