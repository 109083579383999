import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-interest-affinity-chart',
  templateUrl: './interest-affinity-chart.component.html',
  styleUrls: ['./interest-affinity-chart.component.scss', '../locations-by-country/locations-by-country.component.scss']
})
export class InterestAffinityChartComponent implements OnInit {

  @Input() interests: [];

  constructor() { }

  ngOnInit() {
  }

}
