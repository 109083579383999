import {Injectable, Input} from '@angular/core';
import {BehaviorSubject} from 'rxjs';


@Injectable({
    providedIn: 'root'
})


export class HeaderSharedService {

    hasBrief$ = new BehaviorSubject(null);
    hasProgressProposal$ = new BehaviorSubject(null);
    hasReport$ = new BehaviorSubject(null);
    hashCampaign$ = new BehaviorSubject(null);

    constructor() {}

}
