import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MatSnackBar} from '@angular/material';
import {FavouritesService} from '../../../../../../services/favourites.service';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-modal-create-favourites-list',
  templateUrl: './modal-create-favourites-list.component.html',
  styleUrls: ['./modal-create-favourites-list.component.scss']
})
export class ModalCreateFavouritesListComponent implements OnInit, OnDestroy {

  new_favourites_form: FormGroup;
  loading = false;

  private subscriptions = [];

  constructor(private _favouritesService: FavouritesService,
              public dialogRef: MatDialogRef<ModalCreateFavouritesListComponent>,
              public snackBar: MatSnackBar,
              private translate: TranslateService,
            ) {
    this.new_favourites_form = new FormGroup({
      'favourites_name': new FormControl('', [
        Validators.required,
        Validators.maxLength(25)
      ])
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  createFavourites(favourites_name) {
    this.loading = true;
    if (this.new_favourites_form.valid) {
      this.subscriptions.push(
          this._favouritesService.createFavouritesList(favourites_name).subscribe( () => {
            this.snackBar.openFromComponent(CustomSnackbarComponent, {
              data: { message: this.translate.instant('customSnackbar.favouritesCreatedSuccessfully'), type: 'success' },
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: 'success-snackbar',
          });
            this.dialogRef.close('success');
          }, () => {
            this.snackBar.openFromComponent(CustomSnackbarComponent, {
              data: { message: this.translate.instant('customSnackbar.thereHasBeenError'), type: 'error' },
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: 'error-snackbar',
            });
            this.dialogRef.close();
          })
      );
    }
  }


  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
