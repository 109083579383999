import {createAction, props} from '@ngrx/store';
import {Influencer} from 'src/app/types/influencer/influencer';

export const getInfluencers = createAction('[Influencers] Load Influencers');

export const loadInfluencersSuccess = createAction(
    '[Influencers] Load Influencers Success',
    props<{ influencers: Influencer[], hits: number, hits_pagination: number, last_page: boolean, tags: {} }>());

export const loadInfluencersFail = createAction('[Influencers] Load Influencers Fail');

export const loadInfluencer = createAction('[Influencers] Load Influencer',
    props<{ influencerId, network }>());
export const loadInfluencerSuccess = createAction('[Influencers] Load Influencer Success',
    props<{ influencer: Influencer }>());
export const loadInfluencerFail = createAction('[Influencers] Load Influencer Fail',
    props<{ influencerId: string }>());
export const cancelLoadInfluencer = createAction('[Influencers] Cancel Load Influencer');

export const lightCrawlInfluencer = createAction('[Influencers] light crawl',
    props<{ influencer: Influencer }>());
export const lightCrawlInfluencerSuccess = createAction('[Influencers] light crawl success',
    props<{ influencer: Influencer }>());
export const lightCrawlInfluencerFail = createAction('[Influencers] light crawl fail',
    props<{ influencer: Influencer }>());


export const fullCrawlInfluencer = createAction('[Influencers] full crawl',
    props<{ influencer: Influencer }>());
export const fullCrawlInfluencerSuccess = createAction('[Influencers] full crawl success',
    props<{ influencer: Influencer, data: any }>());
export const fullCrawlInfluencerFail = createAction('[Influencers] full crawl fail',
    props<{ influencer: Influencer }>());

export const loadInstagrammer = createAction('[Influencers] Load Instagrammer by name', props<{ name: string }>());
export const loadInstagrammerByUsername = createAction('[Influencers] Load Instagrammer by username', props<{ name: string }>());
export const loadInstagrammerSuccess = createAction(
    '[Influencers] Load Instagrammer by name success',
    props<{ influencer: Influencer }>());
export const loadInstagrammerFail = createAction('[Influencers] Load Instagrammer by name fail', props< { influencer: string }>());


export const loadYoutuber = createAction('[Influencers] Load Youtuber by name', props<{ name: string }>());
export const loadYoutuberByUsername = createAction('[Influencers] Load Youtuber by username', props<{ name: string }>());
export const loadYoutuberSuccess = createAction(
    '[Influencers] Load Youtuber by name success',
    props<{ influencer: Influencer }>());
export const loadYoutuberFail = createAction('[Influencers] Load Youtuber by name fail', props< { influencer: string }>());

export const loadTiktoker = createAction('[Influencers] Load Tiktoker by name', props<{ name: string }>());
export const loadTiktokerByUsername = createAction('[Influencers] Load Tiktoker by Username', props<{ name: string }>());
export const loadTiktokerSuccess = createAction(
  '[Influencers] Load Tiktoker by name success',
  props<{ influencer: Influencer }>());
export const loadTiktokerFail = createAction('[Influencers] Load Tiktoker by name fail', props< { influencer: string }>());

export const loadInfluencersTippingSuccess = createAction('[Influencers]  Add influencer tipping selected Success', props<{ influencers: Influencer }>());
