import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-analyze-influencer',
  templateUrl: './analyze-influencer.component.html',
  styleUrls: ['./analyze-influencer.component.css']
})
export class AnalyzeInfluencerComponent implements OnInit {
  message = '';
  ok_sub_message = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data.data.status === 201) {
      this.message = 'This influencer will be analyzed shortly';
      this.ok_sub_message = 'You will receive an email with a link to this influencer\'s analyzed profile. ' +
        'This action usually takes 5 minutes.';
    } else if (this.data.data.status === 200) {
      this.message = 'This influencer is already being analyzed';
      this.ok_sub_message = 'You will receive an email with a link to this influencer\'s analyzed profile. ' +
        'This action usually takes 5 minutes.';
    } else {
      this.message = 'Oops something went wrong, please try again later';
    }
  }

  ngOnInit() {
  }

}
