import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {ProgressService} from '../../../../../../services/progress.service';

@Component({
  selector: 'app-modal-confirm-resend-invitation',
  templateUrl: './modal-confirm-resend-invitation.component.html',
  styleUrls: ['./modal-confirm-resend-invitation.component.scss']
})
export class ModalConfirmResendInvitationComponent implements OnInit {

  campaign_id = '';
  influencers_ids = '';

  loading = false;
  resended = false;
  status_response = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private _progressService: ProgressService,
              public snackBar: MatSnackBar) {

    this.influencers_ids = data.influencers_ids;
    this.campaign_id = data.campaign_id;
  }

  ngOnInit() {}


  resendInvitation() {
    this.loading = true;
    this._progressService.resendInvitation(this.campaign_id, this.influencers_ids).subscribe(result => {
      this.resended = true;
      this.status_response = result.body.message + ' successfully';
      this.loading = false;
    }, error => {
      if (error.status === 403) {
        this.status_response = 'Influencer/s have already been invited recently, please retry later';
      } else {
        this.status_response = 'Error, sometimes went wrong';
      }
      this.resended = true;
      this.loading = false;
    });
  }
}
