import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Intercom } from 'ng-intercom';
import { AmplifyService } from 'aws-amplify-angular';
import { AuthenticatedUser } from '../../../classes/authenticated-user-class';
import { from, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { openPlansDialog, setLanguage } from '../../../store/app/app.actions';
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import * as selectorsAuth from '../../../store/auth/authentication.selectors';
import { environment } from '../../../../environments/environment';
import * as selectorsApp from '../../../store/app/app.selectors';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../store/auth/authentication.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ModalReactivatePlanComponent } from 'src/app/features/private/components/cancel-pause-plan/modals/modal-reactivate-plan/modal-reactivate-plan.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewChecked, OnDestroy {
  username = '';
  plan_type = '';
  profile_image = '';
  client_email = '';
  company_name = '';
  redirect_url_after_logout = '';
  planNameShown = '';
  platformBrandLogo = '';
  userHash = '';
  end_trial_days;
  componentSelected = '';
  payment_status = '';
  paused_from = '';
  cancellation_effective_date = '';

  url_search = true;

  user: AuthenticatedUser;

  redirect_url_after_logout_query_params: any;

  user$: Observable<any>;
  logo$: Observable<string>;

  private subscriptions = [];

  languageSelected = 'en';

  constructor(
    private router: Router,
    public intercom: Intercom,
    private _amplifyService: AmplifyService,
    private route: ActivatedRoute,
    private store: Store<State>,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService,
    private _authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    if (window.location.href.includes('search')) {
      this.componentSelected = 'search';
    } else if (window.location.href.includes('campaigns')) {
      this.componentSelected = 'campaigns';
    } else if (window.location.href.includes('favourites')) {
      this.componentSelected = 'favourites';
    }
    this.redirect_url_after_logout = this.formatUrlAfterLogout(
      this.redirect_url_after_logout
    );
    this.setUserData();
    this.user$ = from(this._amplifyService.auth().currentAuthenticatedUser());
    this.logo$ = this.user$.pipe(
      filter(data => data),
      map(data => data.attributes['custom:company_logo_image'])
    );
    this.checkUrl();
    this.route.queryParams.subscribe(
      queryParams => (this.redirect_url_after_logout_query_params = queryParams)
    );
    this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
      // this.planNameShown = 'FREE TRIAL';
      // this.end_trial_days = 5;
      // this.payment_status = 'past_due';
      this.paused_from = user.paused_from;
      this.cancellation_effective_date = user.cancellation_effective_date;
      this.payment_status = user.payment_status;
      this.planNameShown = user.PLAN_NAME_SHOWN;
      this.end_trial_days = user.end_trial_days;
    });
    // this.store.select(selectorsAuth.selectUser).subscribe(user => {
    //   if (user.userHash) {
    //     this.userHash = user.userHash;
    //   }
    // });
    this.store.select(selectorsApp.selectLanguage).subscribe(response => {
      this.languageSelected = response;
      // this.translate.setDefaultLang('response');
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewChecked() {
    this.subscriptions.push(
      this.store.select(selectorsApp.selectUserGroup).subscribe(userGroup => {
        if (userGroup !== null) {
          this.platformBrandLogo = userGroup.logo_url;
          this.cdr.detectChanges();
        }
      }),
      this.store.select(selectorsAuth.selectUser).subscribe(user => {
        this.userHash = '';
        if (user) {
          if (user.userHash) {
            this.userHash = user.userHash;
          }
        }
      })
    );
  }


  checkUrl() {
    this.url_search = this.router.url.includes('search') ? true : false;
  }

  async setUserData() {
    const user = await this._amplifyService.auth().currentAuthenticatedUser();
    this.user = new AuthenticatedUser(user);
    this.username = this.user.getUsername();
    this.plan_type = this.user.getAttribute('custom:plan_type');
    if (this.user.attributes['picture'] !== undefined) {
      this.profile_image = this.getUrlImageWithTime(
        this.user.getAttribute('picture')
      );
    }
    this.platformBrandLogo = this.user.getAttribute(
      'custom:company_logo_image'
    );
    this.company_name = this.user.getAttribute('custom:company_name');
    this.client_email = this.user.getAttribute('email');
    this.intercom.boot(this.getIntercomData());
  }

  getNameToShow(): string {
    let name_to_show = this.username;
    if (this.company_name !== 'n/a' && this.company_name !== '') {
      name_to_show = this.company_name;
    }
    return name_to_show;
  }

  getIntercomData(): object {
    const intercom_data = {
      app_id: 'oljxdm76',
      email: this.client_email,
      user_hash: this.userHash,
      widget: {
        activator: '#intercom',
      },
    };
    if (this.company_name !== '' && this.company_name !== 'n/a') {
      intercom_data['name'] = this.company_name;
    }
    return intercom_data;
  }

  logout(): void {
    this.intercom.shutdown();
    const url_to_redirect = '/auth/login';
    window.localStorage.setItem('userViewModalFreeTrial', 'false');

    if (Object.keys(this.redirect_url_after_logout_query_params).length > 0) {
      this.redirect_url_after_logout = '?';
      Object.entries(this.redirect_url_after_logout_query_params).forEach(
        ([key, value]) => (this.redirect_url_after_logout += `${key}=${value}&`)
      );
      this.redirect_url_after_logout = this.redirect_url_after_logout.slice(
        0,
        -1
      );
    }

    this._amplifyService
      .auth()
      .signOut()
      .then(() => {
        this.router.navigate([url_to_redirect], {
          queryParams: { returnUrl: this.redirect_url_after_logout },
        });
      });
  }

  getUrlImageWithTime(url_image) {
    if (url_image !== 'n/a') {
      return url_image + '?ts=' + new Date().getTime();
    }
  }

  formatUrlAfterLogout(url) {
    for (let i = 0; i < this.route.snapshot.url.length; i++) {
      url += '/' + this.route.snapshot.url[i]['path'];
    }
    url += '?';
    for (const key in this.route.snapshot.queryParams) {
      if (this.route.snapshot.queryParams.hasOwnProperty(key)) {
        url += key + '=' + this.route.snapshot.queryParams[key] + '&';
      }
    }
    return url.slice(0, -1);
  }


  openModalPlans() {
    if (environment.platform_id === '11posts') {
      this.store.dispatch(openPlansDialog());
    }
  }

  openManageSubscriptionPlanPaused(isCancel) {
    this.dialog
    .open(ModalReactivatePlanComponent, {
      width: '410px',
      height: 'auto',
      autoFocus: false,
      data: {
        paused_from: this.paused_from,
        isCancel: isCancel
      }
    })
    .afterClosed();
  }

  redirectCallSales() {
    window.open('https://calendly.com/nicolas-11posts/plans-and-pricing', '_blank');
  }

  switchLang(lang: string) {
    this._authenticationService.updateLanguageAccessSets(lang).subscribe(() => {
      this.store.dispatch(setLanguage({ language: lang }));
      this.translate.use(lang);
      this.languageSelected = lang;
    }, error => {
      this.openSnackBar(error.error.message, 'Ok');
    });
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  getClientName(email: string): string {
    return email.split('@')[0]; // Obtener el nombre de usuario antes del "@" en el correo electrónico
  }
}
