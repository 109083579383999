import { Pipe, PipeTransform } from '@angular/core';

// This pipe adds the ‰ suffix to the percentage variables
// NOTE: It has to be used combined with percentPipe (example: {{ data | percent | perMillePipe }})
@Pipe({
  name: 'perMillePipe'
})
export class PerMilleSymbolPipe implements PipeTransform {

  transform(money: any): any {
    const result = money.split('%');

    return result[0].toString() + ' ‰';
  }
}