import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalPausePlanComponent } from '../modal-pause-plan/modal-pause-plan.component';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { ModalConfirmedPauseCancelPlanComponent } from '../modal-confirmed-pause-cancel-plan/modal-confirmed-pause-cancel-plan.component';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import { Subscription } from 'rxjs';
import { ModalPlansService } from 'src/app/features/modal-plans/store/modal-plans.service';
import { getAccessSets, getLoggedUser } from '../../../../../../store/auth/authentication.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../store';

@Component({
  selector: 'app-modal-cancel-plan-form',
  templateUrl: './modal-cancel-plan-form.component.html',
  styleUrls: ['./modal-cancel-plan-form.component.scss']
})
export class ModalCancelPlanFormComponent implements OnInit, OnDestroy {

  loading = false;

  cancelSubscriptionForm: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private _plansService: ModalPlansService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ModalCancelPlanFormComponent>,
    private store: Store<State>,
  ) { 
    this.cancelSubscriptionForm = new FormGroup({
      // Campo para el motivo (textarea)
      reason: new FormControl('', [Validators.required]),
      // Checkboxes para cada motivo (se pueden inicializar en false)
      thisIsTemporary: new FormControl(false),
      subscriptionIsTooExpensive: new FormControl(false),
      imNotComfortable: new FormControl(false),
      iveReachedMyGoals: new FormControl(false),
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  cancelSubscription() {
    this.loading = true;
    // Definimos `formCancelReason` con un tipo indexado
    const formCancelReason: { [key: string]: any } = {
      reason: this.cancelSubscriptionForm.controls.reason.value,
    };
    // Añadimos solo los checkboxes que están seleccionados (true)
    if (this.cancelSubscriptionForm.controls.thisIsTemporary.value) {
      formCancelReason.thisIsTemporary = true;
    }
    if (this.cancelSubscriptionForm.controls.subscriptionIsTooExpensive.value) {
      formCancelReason.subscriptionIsTooExpensive = true;
    }
    if (this.cancelSubscriptionForm.controls.imNotComfortable.value) {
      formCancelReason.imNotComfortable = true;
    }
    if (this.cancelSubscriptionForm.controls.iveReachedMyGoals.value) {
      formCancelReason.iveReachedMyGoals = true;
    }
    this.subscriptions.push(
      this._plansService.cancelSubscriptionPlan(formCancelReason).subscribe( response => {
        this.store.dispatch(getAccessSets());
        this.store.dispatch(getLoggedUser());
        this.dialog
        .open(ModalConfirmedPauseCancelPlanComponent, {
          width: '410px',
          height: 'auto',
          autoFocus: false,
          data: {
           componentCalled: 'cancelled',
           cancellation_effective_date: response['cancellation_effective_date']
          }
        })
        .afterClosed();
        this.loading = false;
        this.dialogRef.close();
      }, (error) => {
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: { message: error.error.message, type: 'error' },
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: 'error-snackbar',
        });
        this.loading = false;
      })
  );

  }

  isSubmitDisabled(): boolean {
    // Obtener el valor del campo 'reason' y aplicar trim si existe y es un string
    const reasonControl = this.cancelSubscriptionForm.get('reason');
    const reason = reasonControl && typeof reasonControl.value === 'string' ? reasonControl.value.trim() : '';
  
    // Crear una lista con los valores de cada checkbox
    const checkboxes = [
      this.cancelSubscriptionForm.get('thisIsTemporary') ? this.cancelSubscriptionForm.get('thisIsTemporary')!.value : false,
      this.cancelSubscriptionForm.get('subscriptionIsTooExpensive') ? this.cancelSubscriptionForm.get('subscriptionIsTooExpensive')!.value : false,
      this.cancelSubscriptionForm.get('imNotComfortable') ? this.cancelSubscriptionForm.get('imNotComfortable')!.value : false,
      this.cancelSubscriptionForm.get('iveReachedMyGoals') ? this.cancelSubscriptionForm.get('iveReachedMyGoals')!.value : false,
    ];
  
    // Verificar que el reason no esté vacío y que al menos un checkbox esté seleccionado
    return !reason || !checkboxes.some((checked) => checked === true);
  }
  
  

  pauseSubscriptionPlan() {
    this.dialog
    .open(ModalPausePlanComponent, {
      width: '470px',
      height: 'auto',
      autoFocus: false,
      data: {
      }
    })
    .afterClosed();
  }


  openModalCancelPlan() {
    import('../modal-cancel-plan/modal-cancel-plan.component').then(({ ModalCancelPlanComponent }) => {
      this.dialog
      .open(ModalCancelPlanComponent, {
        width: '410px',
        height: 'auto',
        autoFocus: false,
        panelClass: 'remove-padding',
        data: {
        }
      })
    });
  }

}
