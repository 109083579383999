import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AdminManagementService} from '../../store/admin-management.service';
import {AmplifyService} from 'aws-amplify-angular';
import {environment} from '../../../../../environments/environment';
import {AuthenticatedUser} from '../../../../classes/authenticated-user-class';
import {MatSnackBar} from '@angular/material';
import * as uuid from 'uuid';

@Component({
    selector: 'app-user-groups',
    templateUrl: './user-groups.component.html',
    styleUrls: ['./user-groups.component.scss']
})
export class UserGroupsComponent implements OnInit {

    userConfigGroupForm: FormGroup;
    groupForUpdateForm: FormGroup;

    groups = [];
    groupData = [];

    loading = false;
    upload_image_spinner = false;

    private subscriptions = [];

    upload_error_message = '';
    user_sub = '';
    url_image = 'n/a';

    authenticated_user: AuthenticatedUser;
    constructor(
        private adminManagementService: AdminManagementService,
        private _amplifyService: AmplifyService,
        private snackBar: MatSnackBar
    ) {

        this.userConfigGroupForm = new FormGroup({
            'groups': new FormControl(''),
        });

    }

    ngOnInit() {
        this.getUserGroups();
    }

    async getAllAttributes() {
        const user = await this._amplifyService.auth().currentAuthenticatedUser({ bypassCache: true });
        this.authenticated_user = new AuthenticatedUser(user);
        if (this.authenticated_user.attributes['picture'] !== undefined) {
            this.url_image = this.getUrlImageWithTime(this.authenticated_user.getAttribute('picture'));
        }
        this.user_sub = this.authenticated_user.getAttribute('sub');
    }

    getUserGroups() {
        this.loading = true;
        this.subscriptions.push(
            this.adminManagementService.getListUserGroups().subscribe( response => {
                this.groups = response.body;
                this.groups.shift();
                this.loading = false;
            }, () => {
                this.loading = false;
            })
        );
    }

    onChange(event) {
        this.loading = true;
        const formControls = {};
        this.subscriptions.push(
            this.adminManagementService.getGroupByGroupName(event.value).subscribe( response => {
                this.groupData = response.body;
                for (const key in this.groupData['theme_style']) {
                    if (this.groupData['theme_style'].hasOwnProperty(key)) {
                        formControls[key] = new FormControl(this.groupData['theme_style'][key]);
                    }
                }
                this.groupForUpdateForm = new FormGroup(formControls);
                this.loading = false;
            }, () => {
                this.loading = false;
            })
        );
    }

    keysDict(): string[] {
        return Object.keys(this.groupData);
    }

    uploadImage(fileInput: any): void {
        let file;
        this.upload_image_spinner = true;
        this.upload_error_message = '';
        file = fileInput.target.files[0];
        if (file.size <= 5242880) {
            if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg') {
                const extension = this.getExtensionFromImage(file.type);
                const config = {
                    bucket: environment.aws_resources.s3.buckets.user_group_logo,
                    region: environment.aws_resources.region,
                    level: 'public',
                    customPrefix: {
                        public: ''
                    },
                };
                const random_uuid = uuid.v4();
                this._amplifyService.storage().vault.put(this.groupData['group_name'] + '/' + this.groupData['group_name'] + random_uuid + extension, file, config).then(() => {
                    const url = 'https://s3-eu-west-1.amazonaws.com/' + config.bucket + '/' + this.groupData['group_name'] + '/' + this.groupData['group_name'] + random_uuid + extension  ;
                    this.saveProfileImageUrlInCognito(url);
                }).catch(() => {
                    this.upload_error_message = 'Oops, something went wrong. Please try again later.';
                    this.upload_image_spinner = false;
                });
            } else {
                this.upload_error_message = 'Unsupported file type. Supported file types are: jpg, jpeg and png';
                this.upload_image_spinner = false;
            }
        } else {
            this.upload_error_message = 'The file exceeds 5MB';
            this.upload_image_spinner = false;
        }
    }

    getExtensionFromImage(file_type: string): string {
        return file_type.replace('image/', '.');
    }

    saveProfileImageUrlInCognito(url) {
        this.url_image = url;
        this.updateUserGroup(url, false);
        this.upload_image_spinner = false;
        // this.getAllAttributes().then(() => {
        //     this.updateUserGroup(url, false);
        //     this.upload_image_spinner = false;
        // });
    }

    getUrlImageWithTime(url_image) {
        if (url_image !== 'n/a') {
            return url_image + '?ts=' + new Date().getTime();
        } else {
            return url_image;
        }
    }

    updateUserGroup(value, isTheme) {
        this.loading = true;
        this.subscriptions.push(
            this.adminManagementService.updateUserGroupTheme(value, this.groupData['group_name'], isTheme).subscribe( () => {
                this.openSnackBar('Updated successfully', 'Ok');
                this.loading = false;
            }, () => {
                this.openSnackBar('Oops something went wrong, please try again', 'Ok');
                this.loading = false;
            })
        );
    }


    openSnackBar(message, action) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }
}
