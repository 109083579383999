import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-campaign-plan-stats',
  templateUrl: './campaign-plan-stats.component.html',
  styleUrls: ['./campaign-plan-stats.component.scss']
})
export class CampaignPlanStatsComponent implements OnInit {


  @Input() stats;

  networkSelected: string;
  networkStats: [];
  selectingNetworkForRefresh = false;

  constructor() { }

  ngOnInit() {
    if (this.stats.instagram) {
      this.networkSelected = 'instagram';
      this.networkStats = this.stats.instagram;
    } else if (this.stats.youtube) { 
      this.networkSelected = 'youtube';
      this.networkStats = this.stats.youtube;
    } else if (this.stats.tiktok) { 
      this.networkSelected = 'tiktok';
      this.networkStats = this.stats.tiktok;
    }
  }

  changeNetwork(network) {
    this.selectingNetworkForRefresh = true;
    this.networkSelected = network;
    this.networkStats = this.stats[this.networkSelected];
    setTimeout( () => { this.selectingNetworkForRefresh = false; }, 10 );
  }

}
