import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalCancelPlanFormComponent } from '../modal-cancel-plan-form/modal-cancel-plan-form.component';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PlanState } from 'src/app/features/modal-plans/store/modal-plans.reducers';
import * as selectorsAuth from '../../../../../../store/auth/authentication.selectors';
import * as selectors from '../../../../../modal-plans/store/modal-plans.selectors';
import * as actions from '../../../../../modal-plans/store/modal-plans.actions';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-modal-cancel-plan',
  templateUrl: './modal-cancel-plan.component.html',
  styleUrls: ['./modal-cancel-plan.component.scss']
})
export class ModalCancelPlanComponent implements OnInit, OnDestroy {

  plans$: Observable<any[]>;
  email = '';
  planNameShown = '';
  deployContentCardsPlan = false;

  private subscriptions: Subscription[] = [];

  constructor(
    public store: Store<PlanState>,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.store.dispatch(actions.getPlans());
    this.subscriptions.push(
      this.store.select(selectorsAuth.selectUser).subscribe(user => {
        this.email = user.username;
      }),
    );
    this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
      this.planNameShown = user.PLAN_NAME_SHOWN;
    });
    this.plans$ = this.store.select(selectors.selectPlans).pipe(
      map(plans => plans ? plans.filter(plan => plan.planName === 'FREE' || plan.planName === this.planNameShown) : [])
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  cancelSubscription() {
    this.dialog
    .open(ModalCancelPlanFormComponent, {
      width: '430px',
      height: 'auto',
      autoFocus: false,
      data: {
      }
    })
    .afterClosed();
  }

  trackByPlan(index, item) {
    return !item ? null : item.id;
  }


  openModalSelectCancelPause() {
    import('../modal-select-cancel-pause-plan/modal-select-cancel-pause-plan.component').then(({ ModalSelectCancelPausePlanComponent }) => {
      this.dialog
      .open(ModalSelectCancelPausePlanComponent, {
        width: '415px',
        height: 'auto',
        panelClass: 'container-modal-plans',
        autoFocus: false,
        data: {
        }
      })
    });
  }
  

}
